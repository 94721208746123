import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  jobs: any;
  searchVal: string;
  isFullTIme: boolean;
  isRemote: boolean;
  isContract: boolean;
  employment_type_id: string;
  currentPage: any;
  isEmp: boolean;
  isSalary: boolean;
  shareJob: any;
  isShare: boolean;
  userType: string;
  url: string;
  jobID: any;
  last_page: number;
  checked: boolean
  get_artical: {}[];
  get_jobAppliedData: any;
  per_page: number;
  last_page_JobApplied: number;
  currentJobApplied: number;
  filterData: string[];
  showNudgePopup: boolean;
  showMessage:boolean;
  viewApplication:boolean;
  userData: any;
  salary_start: any;
  salary_end: any;
  applicantQuestions:{}[];
  executiveDetails:{}[];
  selectedExecutiveId:number;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jobListingApiCallID: string = "";
  apiGetUserTypeCallId: string = "";
  apiGetArticalsCallID: string = "";
  applicationDetailsCase:string="";
  jobsAppliedId: string = "";
  jobApplicantId: string = "";
  nudgeApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      jobs: [],
      searchVal: "",
      isFullTIme: false,
      isRemote: false,
      isContract: false,
      employment_type_id: "",
      currentPage: 1,
      isEmp: false,
      isSalary: false,
      shareJob: [],
      showMessage:false,
      isShare: false,
      userType: "",
      url: "",
      jobID: "",
      last_page: 0,
      checked: false,
      get_artical: [],
      applicantQuestions:[],
      get_jobAppliedData: [],
      executiveDetails:[],
      per_page: 3,
      last_page_JobApplied: 0,
      currentJobApplied: 1,
      filterData: [],
      showNudgePopup: false,
      viewApplication:false,
      userData: {},
      salary_start: 0,
      salary_end: 0,
      selectedExecutiveId:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.jobListingApiCallID) {
        this.setJobList(responseJson)
      } else if (apiRequestCallId === this.apiGetUserTypeCallId) {
        this.apiGetUserTypeCallIdFunc(responseJson)
      } else if (apiRequestCallId === this.apiGetArticalsCallID) {
        this.setState({ get_artical: responseJson?.data })
      }
      else if (apiRequestCallId === this.jobsAppliedId) {
        this.setJobAppliedList(responseJson)
      }
      else if (apiRequestCallId === this.nudgeApiId) {
        if (responseJson?.data?.attributes?.contents === "The user has a sent remainder to request a review of their application.") {
          this.showNudgePopup()
        }
        else if(responseJson?.message==="To utilize this functionality, please upgrade to our premium plan."){
this.setState({showMessage:true})
        }
        else if (responseJson?.message === "Can't send nudge to this company") {
          alert("please try after some time.")
        }
      }
      else if (apiRequestCallId === this.jobApplicantId) {
        this.setJobAppliedList(responseJson)

      } else if (apiRequestCallId === this.applicationDetailsCase) {
        this.setState({ applicantQuestions: responseJson })
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

navigateNudge=()=>{
  this.props.navigation.navigate("SubscriptionBilling2")
  window.scrollTo(0,0)
}
  showNudgePopup = () => {
    this.setState({ showNudgePopup: true });
  };
  hideNudgePopup = () => {
    this.setState({ showNudgePopup: false });
  };
  hideMessagePopup = () => {
    this.setState({ showMessage: false });
  };
  async componentDidMount() {
    super.componentDidMount();
    this.getJobList();
    this.getUserType(false);
    this.get_artical_post();

    window.location.pathname === "/JobsApplied" && this.getJobsAppliedData();
    window.location.pathname === "/Applicants" && this.getJobsApplicantData();
  }

  shareJobPopUp = (data: any) => {
    this.setState({ shareJob: data, url: window.location.href, jobID: data.id }, () => {
      this.setState({ isShare: true })
    })
  }

  startChange = (e: any) => this.setState({ salary_start: e.target.value })

  endChange = (e: any) => this.setState({ salary_end: e.target.value })

  navigateToProfile = () => {
    this.props.navigation.navigate("UserProfileBasicBlockweb", { id: `${localStorage.getItem("user_id")}` })
    window.scrollTo(0,0)
  }

  postNudgeApi = (userDataa: any) => {
    this.setState({ userData: userDataa })
    const token = localStorage.getItem("authToken") as string;
    const httpBody = {
      data: {
        attributes: {
          job_application_id: userDataa.attributes.id,
          contents: "The user has a sent remainder to request a review of their application."
        }
      }
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.nudgeApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nudgeApiEndpoint}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getJobsAppliedData = async () => {
    const token = localStorage.getItem("authToken") as string;
    let endpoint = `${configJSON.jobsAppliedEndpoint}?page=${this.state.currentJobApplied}&per_page=${this.state.per_page}`
    if (this.state.filterData.length > 0) {
      endpoint = `${configJSON.jobsAppliedEndpoint}?status=${JSON.stringify(this.state.filterData)}&page=${this.state.currentJobApplied}&per_page=${this.state.per_page}`
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobsAppliedId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getJobsApplicantData = async () => {
    const token = localStorage.getItem("authToken") as string;
    let endpoint = `${configJSON.jobApplicantEndpoint}?page=${this.state.currentJobApplied}&per_page=${this.state.per_page}`
    if (this.state.filterData.length > 0) {
      endpoint = `${configJSON.jobApplicantEndpoint}?status=${JSON.stringify(this.state.filterData)}&page=${this.state.currentJobApplied}&per_page=${this.state.per_page}`
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobApplicantId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserType = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const user_id = id ? id : localStorage.getItem("user_id") as string;


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserTypeCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPointURL + '/' + user_id
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (this.state.currentPage !== prevState.currentPage || this.state.salary_end !== prevState.salary_end || this.state.salary_start !== prevState.salary_start || this.state.checked !== prevState.checked || this.state.searchVal !== prevState.searchVal || this.state.employment_type_id !== prevState.employment_type_id) {
      this.getJobList()
    }
    else if (this.state.currentJobApplied !== prevState.currentJobApplied || this.state.filterData !== prevState.filterData) {
      if (this.state.userType === "company") {
        this.getJobsApplicantData()
      }
      else if (this.state.userType === "executive") {
        this.getJobsAppliedData()
      }
    }
  }
  dateFunction = (date: any) => {
    const inputDate = new Date(date);
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString('en-US', { month: 'long' });
    const year = inputDate.getFullYear();

    const dayWithSuffix = (day: any) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${dayWithSuffix(day)} ${month} ${year}`;
  };
  handleTitle(item: any) {
    return this.state.userType !== "company" ? item.attributes?.job?.data?.attributes.job_title : `${item?.attributes?.executive?.first_name} ${item.attributes.executive?.last_name}`

  }
  handleCompanyName(item: any) {
    return this.state.userType !== "company" ? item?.attributes?.job.data?.attributes?.company?.company_name : item.attributes?.job?.data?.attributes?.job_title
  }
  handleTitlePopUp() {
    return this.state.userType !== "company" ? this.state.userData?.attributes?.job?.data?.attributes?.job_title : `${this.state.userData?.attributes?.executive?.first_name} ${this.state?.userData?.attributes?.executive?.last_name}`

  }
  handleCompanyPopUp() {
    return this.state.userType !== "company" ? this.state.userData?.attributes?.job.data?.attributes?.company?.company_name : this.state.userData?.attributes?.job.data?.attributes?.job_title
  }


  checkDataFound() {
    return this.state.userType !== "company" ? "You have not applied to any job." : "Data not found."
  }
  empFilter = () => {
    this.setState({ isEmp: !this.state.isEmp })
  }

  salaryFilter = () => {
    this.setState({ isSalary: !this.state.isSalary })
  }

  handleFullTIme = (e: { target: { value: string, checked: boolean } }) => {
    if (!e.target.checked) {
      this.setState({ employment_type_id: "", isFullTIme: !this.state.isFullTIme })
    } else {
      this.setState({
        employment_type_id: e.target.value,
      }, () => {
        this.setState({
          isFullTIme: !this.state.isFullTIme, isRemote: false,
          isContract: false,
        })
      })
    }
  }

  handleRemote = (e: { target: { value: string, checked: boolean } }) => {
    if (!e.target.checked) {
      this.setState({ employment_type_id: "", isRemote: !this.state.isRemote })
    } else {
      this.setState({
        employment_type_id: e.target.value
      }, () => {
        this.setState({
          isRemote: !this.state.isRemote, isFullTIme: false,
          isContract: false,
        })
      })
    }
  }

  handleContract = (e: { target: { value: string, checked: boolean } }) => {
    if (!e.target.checked) {
      this.setState({ employment_type_id: "", isContract: !this.state.isContract })
    } else {
      this.setState({
        employment_type_id: e.target.value
      }, () => {
        this.setState({
          isContract: !this.state.isContract, isFullTIme: false,
          isRemote: false,
        })
      })
    }
  }
  handleFilterToggle = (item: any) => {
    this.setState((prevState: any) => {
      const isSelected = prevState.filterData.includes(item);
      const updatedFilters = isSelected
        ? prevState.filterData.filter((filter: any) => filter !== item)
        : [...prevState.filterData, item];
      this.setState({ currentJobApplied: 1 })
      return { filterData: updatedFilters };
    });
  };
  searchFilter = (e: { target: { value: string } }) => {
    this.setState({ searchVal: e.target.value })
  }

  setJobList = (responseJson: any) => {
    this.setState({ jobs: responseJson.data, last_page: responseJson.meta.last_page })
  }
  setJobAppliedList = (responseJson: any) => {
    this.setState({ get_jobAppliedData: responseJson?.data, last_page_JobApplied: responseJson?.meta?.last_page })
  }
  getJobList = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jobListingContentType,
      token: token,
    };
    let endpointURL = configJSON.jobListingEndPoint + `/list?page=${this.state.currentPage}&per_page=5&employment_type_id=${this.state.employment_type_id}&filter=${this.state.searchVal}`
    if (this.state.salary_start.length > 0) {
      endpointURL = configJSON.jobListingEndPoint + `/list?page=${this.state.currentPage}&salary_range_start=${Number(this.state.salary_start)}&per_page=5&employment_type_id=${this.state.employment_type_id}&filter=${this.state.searchVal}`
    }
    if (this.state.salary_end.length > 0) {
      endpointURL = configJSON.jobListingEndPoint + `/list?page=${this.state.currentPage}&salary_range_end=${Number(this.state.salary_end)}&per_page=5&employment_type_id=${this.state.employment_type_id}&filter=${this.state.searchVal}`
    }
    if (this.state.salary_start.length > 0 && this.state.salary_end.length > 0) {
      endpointURL = configJSON.jobListingEndPoint + `/list?page=${this.state.currentPage}&salary_range_start=${Number(this.state.salary_start)}&salary_range_end=${Number(this.state.salary_end)}&per_page=5&employment_type_id=${this.state.employment_type_id}&filter=${this.state.searchVal}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobListingApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  get_artical_post = async () => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetArticalsCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getArticalAPI + '?page=1&per_page=3'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  viewApplication = (data: any) => {
    this.applicationDetails(data?.id)
    this.setState({ viewApplication: true, selectedExecutiveId: data?.attributes?.id })
    if (this.state.userType == "company") {
      this.getUserType(data.attributes.executive?.id)
    }
    else {
      this.getUserType(data?.attributes?.job?.data?.attributes?.company?.id)
    }
  }



  applicationDetails = (id: any) => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applicationDetailsCase = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting2/jobs/application_details/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  apiGetUserTypeCallIdFunc = (responseJson: any) => {
    if (!this.state.viewApplication ) {
      this.setState({ userType: responseJson?.data?.type })
    } else {
      this.setState({ executiveDetails: responseJson?.data?.attributes })
    }
  }

  // Customizable Area End
}
