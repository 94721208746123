import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  file: any;
  profileImageData: any;
  backgroundImageData: any;
  aboutYourself: string;
  profile: any;
  boardBiography: any[];
  backgroundImage: any;
  profileImage: any;
  aboutText: any;
  profileData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoardBiographyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addBiographyApiCallId: any;
  showProfileApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      file: "",
      profileImageData: "",
      backgroundImageData: "",
      aboutYourself: "",
      boardBiography: [],
      token: "",
      profile: "",
      backgroundImage: "",
      profileImage: "",
      aboutText: "",
      profileData: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    const authTokenReq = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(authTokenReq);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = localStorage.getItem("authToken") as string;
      this.setState({ token: token }, () => {
        this.getAllBiographyData();
        this.getProfileData();
      });
      console.log("token data",this.state.token)
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.addBiographyApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const bgImage = item[1].attributes.background_image;
              const proImage = item[1].attributes.profile_image;
              const aboutData = item[1].attributes.about_yourself;
              this.setState({ backgroundImage: bgImage });
              this.setState({ profileImage: proImage });
              this.setState({ aboutText: aboutData });
            });
          }
        }
        if (apiRequestCallId === this.showProfileApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data =
                `${item[1].attributes.first_name}` +
                " " +
                `${item[1].attributes.last_name}`;
              this.setState({ profileData: data });
            });
          }
        }
      }

      runEngine.debugLog("API Message Recived", message);

      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        backgroundImageData: URL.createObjectURL(img),
        file: img,
      });
    }
  };

  onProfileImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        profileImageData: URL.createObjectURL(img),
        profile: img,
      });
    }
  };

  setAboutYourself = (text: string) => {
    this.setState({
      aboutYourself: text,
    });
  };

  AddBiography(): boolean {
    const header = {
      token: this.state.token,
    };

    let formdata = new FormData();

    formdata.append("background_image", this.state.file);
    formdata.append("profile_image", this.state.profile);
    formdata.append("about_yourself", this.state.aboutYourself);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addBiographyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddBigraphy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllBiographyData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBiographyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddBigraphy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getProfileData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
