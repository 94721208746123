import React  from "react";
import CheckoutFormController from "./CheckOutFormController.web";
import CheckoutForm from "../../../components/src/CheckoutForm.web";
import { Elements } from "react-stripe-elements";
export default class CheckoutFormStrip extends CheckoutFormController{
  
 
  render() {
    const appearance = {
      theme: 'stripe',
    };
    const clientSecret=this.state.planResponseData?.clientSecret
    const options = {
      clientSecret,
      appearance
    };

  
    return <>
   {  this.props.loadStripe && <Elements
     //@ts-ignore
     options={options} 
      stripe={this.props.loadStripe}>
      <CheckoutForm  secretKey={clientSecret} navigation={this.props.navigation} article={this.state.article} amount={this.state.number}/>
    </Elements>
    }
    </>
  }
}