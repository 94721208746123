// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Button
} from "@material-ui/core";
import './termsAndConditions.css';
import TopNavWeb from "../../../components/src/TopNav.web";
import FooterWeb from "../../../components/src/FooterWeb";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Checkbox,
} from "./assets";

// Customizable Area End
import TermsAndConditionsController from "./TermsAndConditionsController.web";
import {
  CustomCheckBox,
} from "../../../components/src/CustomStyle.web";


export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  goBack = () => {
    window.history.back();
  }

  IconCheckbox = () => (
    <img src={Checkbox} alt="checkbox" className="checkbox-icon" />
  );

  handleAgreeClick = () => {
    if (this.state.isAgreeChecked) {
      // Redirect to the SignupPage component
      //this.props.history.push("/EmailAccountRegistration");
      window.location.href = "/EmailAccountRegistration?isAgreed=true";
     }
  };

  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <>
        <div style={{
          height: '600vh', backgroundImage: "linear-gradient(rgba(122, 146, 220, 0.41), rgba(211, 211, 211, 0))",
        }}>
          <TopNavWeb />
          <Container>
            <Grid container>
              <Grid item sm={12} style={{ marginTop: '150px' }}>
                <div className="back-button" style={{ display: 'flex' }}>
                  <ArrowBackIosIcon onClick={this.goBack} style={{cursor: 'pointer', marginTop: '20px', fontSize: '30px', color: '#738cd8' }} />
                  <h1 style={{ fontSize: '55px', color: '#5a75c7' }}>Terms & <br></br>Conditions</h1>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.tearm_data }}
                  className="html-container" // Add any necessary CSS classes
                  style={{ padding: '0px 30px' }}
                ></div>
              </Grid>
              <Grid item sm={12} style={{ marginTop: '10px' }}>
                <div className="terms-condition" style={{ padding: '0px 30px' }}>
                  <CustomCheckBox
                    data-test-id="agreeWith"
                    name="checkedAgreeWith"
                    color="primary"
                    value={this.state.isAgreeChecked}
                    checked={this.state.isAgreeChecked}
                    onChange={this.handleCheckboxChange}
                    icon={this.IconCheckbox()}
                  />

                  <span className="label-wrap">I have read and agree to these Terms and Conditions</span>

                </div>
              </Grid>

              <Grid item sm={12} className="btn_row_save" style={{ marginBottom: '40px' }}>
                <div>
                  <Button type="button"
                    data-test-id="btnCancel"
                    color="primary"
                    className="customCancel_btn">Cancel</Button>
                  <Button
                    type="submit"
                    data-test-id="btnSave"
                    color="primary"
                    className="customSave_btn"
                    disabled={!this.state.isAgreeChecked} // Disable the button if the checkbox is not checked
                    onClick={this.handleAgreeClick}
                  >
                    Agree
                  </Button>
                </div>

              </Grid>
            </Grid>
          </Container>
          <FooterWeb />
        </div>
      </>
    );
  }
}

    // Customizable Area End
// Customizable Area Start
// Customizable Area End

// Customizable Area End