Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SubscriptionBilling2";
exports.labelBodyText = "SubscriptionBilling2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.subScriptionPlansAPIEndPoint = 'bx_block_plan/plans';

// Customizable Area End