import React from "react";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography, Grid, Button, useMediaQuery } from "@material-ui/core";
import { boardsiLogo, boardsiLogoM } from "../../blocks/landingpage/src/assets";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Link } from "react-router-dom";

const color = createTheme({
  palette: {
    secondary: {
      main: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainContainer: {

    background: "linear-gradient(179deg, #879cdb, #536fc2 100%) no-repeat",
    height: "auto",
    padding: "130px",
    [theme.breakpoints.down('sm')]: {
      padding: "30px",
    },
  },

  li: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 0",
    // marginLeft:"50px"
    // marginLeft:'10px'
  },
  link: {
    color: "#fff",
    marginLeft: "5px",
    textDecoration: "none"
  },
  headding: {
    marginBottom: "20px",
    fontSize: "40px",
    fontWeight: 600,
    // marginLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  itemGridStyle: {
    paddngLeft: 50
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '80px',
    lineHeight: '100px',
    textAlign: 'center',
    letterSpacing: '1.7625px',
    color: '#FFFFFF',
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      fontSize: "28px",
      lineHeight: "40px",
      marginTop: "50px"
    }
  },
  button: {
    maxWdth: "300px",
    marginTop: "30px",
    textTransform: "none",
    letterSpacing: "1.5px",
    whiteSpace: "nowrap",
    color: "#879cdb",
    fontWeight: 700,
    backgroundColor: "#ffff",
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px'
    },
  },
  logo: {
    marginBottom: "35px",
    height: '70px',
    width: '310px',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: 'auto'
    }
  },
  copyright: {
    textAlign: "center",
    padding: "10px"
  },
  address: {
    marginLeft: '10px',
    fontFamily: 'poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    [theme.breakpoints.down("xs")]: {
      fontSize: '18px'
    }
  },
  LocationOnIcon1: {
    marginTop: '-50px'
  },
  LocationOnIcon2: {
    marginTop: '-230px'
  },
  bcontent: {
    maxWidth: "300px",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '33px',
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify',
      fontSize: '18px',
      maxWidth: "100%",

    }
  },
  crNotice: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px"
    }
  }
}));
const FooterWeb = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <ThemeProvider theme={color}>
      <div className={classes.mainContainer}>
        <Grid container spacing={6} >
          <Grid item xs={12} md={5}>
            <img src={boardsiLogoM} className={classes.logo} />
            <Typography style={{ color: 'white' }} className={classes.bcontent}>
              At Boardsi, we hold the belief that robust leadership and a
              wealth of experience are key to a company's growth and success.
              We take great pleasure in establishing mutually beneficial relationships
              between executives and businesses.
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/EmailAccountRegistration"
              className={classes.button}
            >
              Get start for free!
            </Button>
          </Grid>
          <Grid item xs={12} sm={8} md={5} >
            <Typography
              variant="h3"
              style={{ color: 'white' }}
              className={classes.headding}
            >
              Headquarters:
            </Typography>
            <ul>
              <li className={classes.li}>
                <LocationOnIcon fontSize="large" style={{ color: 'white' }} className={classes.LocationOnIcon1}></LocationOnIcon>
                <Typography style={{ color: 'white', marginBottom: "10px" }} className={classes.address}>
                  9121 W. Russell Road,<br></br> Suite 116<br></br>
                  Las Vegas, NV 89148
                </Typography>
              </li>
            </ul>
            <Typography
              variant="h3"
              style={{ color: 'white' }}
              className={classes.headding}
            >
              Other Offices:
            </Typography>
            <ul>
              <li className={classes.li}>
                <LocationOnIcon fontSize="large" style={{ color: 'white', marginBottom: "10px" }} className={classes.LocationOnIcon2}></LocationOnIcon>
                <Typography style={{ color: 'white' }} className={classes.address}>
                  5272 Robert J Mathews<br></br> Pkwy<br></br>
                  El Dorado Hills, CA <br></br>95762<br></br>
                  730 Sandhill Road.<br></br> Suite 225 <br></br>Reno, NV 89521-6009<br></br>
                  206 G Street, Suite 2<br></br>
                  Petaluma, CA 94952
                </Typography>
              </li>
              {/* <li className={classes.li}>
                <PhoneIcon fontSize="large" color="secondary" />
                <Typography color="secondary">+11234567866</Typography>
              </li> */}
            </ul>
            <Typography
              variant="h3"
              style={{ color: 'white' }}
              className={classes.headding}
            >
              Call Us:
            </Typography>
            <ul>
              <li className={classes.li}>
                <PhoneIcon fontSize="large" style={{ color: 'white', marginBottom: "10px" }} />
                <Typography style={{ color: 'white' }} className={classes.address}>
                  +1 (916) 750-2537
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}  md={2}>
            <Typography
              variant="h3"
              className={classes.headding}
              style={{ color: 'white' }}
            >
              Social {!isMatch && <br />} media
            </Typography>
            <ul>
              <li className={classes.li}>
                <FacebookIcon style={{ color: 'white', marginRight: "12px" }} />
                <Typography style={{ color: 'white' }}>
                  <a className={classes.link} href="https://www.facebook.com/Official.boardsi/" target="_blank" rel="noopener noreferrer">
                    Boardsi
                  </a>
                </Typography>
              </li>
              <li className={classes.li}>
                <InstagramIcon style={{ color: 'white', marginRight: "12px" }} />
                <Typography style={{ color: 'white' }}>
                  <a className={classes.link} href="https://www.instagram.com/official.boardsi/" target="_blank" rel="noopener noreferrer">
                    Boardsi
                  </a>
                </Typography>
              </li>
              <li className={classes.li}>
                <TwitterIcon style={{ color: 'white', marginRight: "12px" }} />
                <Typography style={{ color: 'white' }}>
                  <a className={classes.link} href="https://twitter.com/boardsi2" target="_blank" rel="noopener noreferrer">
                    Boardsi
                  </a>
                </Typography>
              </li>
              <li className={classes.li}>
                <LinkedInIcon style={{ color: 'white', marginRight: "12px" }} />
                <Typography style={{ color: 'white' }}>
                  <a className={classes.link} href="https://www.linkedin.com/company/boardsi/" target="_blank" rel="noopener noreferrer">
                    Boardsi
                  </a>
                </Typography>
              </li>
              <li className={classes.li}>
                <YouTubeIcon style={{ color: 'white', marginRight: "12px" }} />
                <Typography style={{ color: 'white' }}>
                  <a className={classes.link} href="https://www.youtube.com/channel/UCtOo-Hp7a5_JL02VhjNRoeQ" target="_blank" rel="noopener noreferrer">
                    Boardsi
                  </a>
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" style={{ color: 'white' }} className={classes.text}>
            What are you <br /> waiting for?
          </Typography>

        </Grid>
      </div>
      <div className={classes.copyright}>
        <Typography className={classes.crNotice}>
          Copyright &copy; 2023 Boardsi all right reserved
        </Typography>
      </div>
    </ThemeProvider>
  );
};
export default FooterWeb;
