import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import { rawCountries } from "../../../components/src/rawCountryData";
import * as Yup from "yup"
import { validationHandle } from "../../../components/src/WorkExperienceFile";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  formData: any;
  aboutYourself: string;
  token: string;
  start_year: string;
  employementData: any[];
  profileImageData: any;
  profile: any;
  profileData: string;
  profileImage: string;
  file: string;
  backgroundImageData: string;
  userName: string;
  backgroundImage: string;
  industryData: any[];
  skillsData: any[];
  userType: string;
  degreeData: any[];
  successMsg: string;
  start_date: string;
  phoneNumber: string;
  countryCode: any;
  openDialog: boolean;
  errors: any;
  workStartDate: string;
  workEndDate: string;
  workStartYear: string;
  EndYear: string;
  educationStartDate: string;
  educationEndDate: string;
  educationStartYear: string;
  educationEndYear: string;
  issueDate: string;
  issueYear: string;
  employmentTypes: string[];
  employmentTypeId: number;
  companyData: any;
  tagline: string;
  usename_account_creation: string;
  executiveAccountInitialState: any;
  collapse_open: boolean
  CollapseOpen_account_creation: boolean;
  response_message_account_creation: string;
  account_creation_res_message: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface ChipData {
  key: number;
  label: string;
}


export default class AccountCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  getEmploymentTypeApiCallId: any;
  accountCreateApiCallId: string;
  showProfileApiCallId: string;
  getSkillsApiCallId: string;
  getIndustriesApiCallId: string;
  getDegreesApiCallId: string;
  getDegreeApiCallId: string;
  addBiographyApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    const year = moment(new Date(), "YYYY");
    const date = moment(new Date(), "MMMM");
    this.state = {
      // Customizable Area Start
      profileImageData: "",
      aboutYourself: "",
      backgroundImageData: "",
      profile: "",
      start_date: "",
      start_year: "",
      phoneNumber: "",
      countryCode: rawCountries[0][3],
      userName: "",
      usename_account_creation: "",
      backgroundImage: "",
      formData: {
        basic: {
          about_yourself: "",
          full_phone_number: "",
          linkedin_url: "",
          address: "",
        },
        skill_ids: [],
        user_work_experiences: [
          {
            title: "",
            company_name: "",
            location: "",
            employment_type_id: "",
            currently_work_here: false,
            start_date: new Date,
            start_year: new Date,
            end_date: new Date,
            end_year: new Date,
            skill_set: ""
          },
        ],
        educations: [
          {
            school_name: "",
            start_date: date,
            start_year: year,
            end_date: date,
            end_year: year,
            grade: "",
            degree_id: "",
            location: "",
          },
        ],
        certificates: [
          {
            name: "",
            issuing_organization: "",
            issue_date: date,
            issue_year: year,
          },
        ],
      },

      token: "",
      employementData: [],
      profileData: "",
      profileImage: "",
      file: "",
      industryData: [],
      skillsData: [],
      userType: "",
      degreeData: [],
      successMsg: "",
      openDialog: false,
      workStartDate: "",
      workEndDate: "",
      workStartYear: "",
      EndYear: "",
      educationStartDate: "",
      educationEndDate: "",
      educationStartYear: "",
      educationEndYear: "",
      issueDate: "",
      issueYear: "",
      employmentTypes: [],
      employmentTypeId: 0,
      companyData: {
        company_size: "",
        industry: "",
        location: "",
        website: "",
        start_date: new Date,
        year: new Date,
        specialities: "",
        full_phone_number: "",
      },
      tagline: "",
      executiveAccountInitialState: {
        title: "",
        company_name: "",
        location: "",
        employment_type_id: "",
        currently_work_here: false,
        start_date: new Date,
        start_year: new Date,
        end_date: new Date,
        end_year: new Date,
        skill_set: "",
        school_name: "",
        education_start_date: new Date,
        education_start_year: new Date,
        education_end_date: new Date,
        education_end_year: new Date,
        grade: "",
        degree_id: "",
        school_location: "",
        certificate_name: "",
        issuing_organization: "",
        issue_date: new Date,
        issue_year: new Date,
      },
      errors: {
        title: "",
        company_name: "",
        school_name: "",
        degree_id: "",
        certificate_name: "",
        issuing_organization: "",
      },
      collapse_open: false,
      CollapseOpen_account_creation: false,
      response_message_account_creation: "",
      account_creation_res_message: "error",
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        backgroundImageData: URL.createObjectURL(img),
        file: img,
      }, () => {
        this.AddBiography()
      });

    }
  };

  onProfileImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        profileImageData: URL.createObjectURL(img),
        profile: img,
      }, () => {
        this.AddBiography();
      });

    }
  };


  handleClose = () => {
    this.setState({ openDialog: false });
  };



  async componentDidMount() {
    const authTokenReq = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(authTokenReq);
    let token = localStorage.getItem("authToken") as string;
    this.setState({ token: token }, () => {
      this.getEmploymentType();
      this.getProfileData();
      this.getSkills();
      this.getDegrees();
      this.getIndustries();
      this.getAllBiographyData();
    });
  }


  async receive(_from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson) {
        this.navigateFunc(apiRequestCallId)
        switch (apiRequestCallId) {
          case this.createAccountApiCallId:
            if (!responseJson.errors) {
              this.setState({ successMsg: responseJson.message });
              this.setState({ openDialog: true });
              return false;
            } else {
              this.setState({ account_creation_res_message: 'error' })
              Object.entries(responseJson.errors as Record<string, Record<string, string>>).reverse().forEach(([keyData, valueData]) => {
                Object.entries(valueData).forEach(([key]) => {
                  this.account_creation_date_error(key)
                });
              });
            }
            break;
          case this.addBiographyApiCallId:
            const { data: { attributes: { about_yourself, background_image, profile_image } } } = responseJson;
            this.setState({ backgroundImage: background_image, profileImage: profile_image, aboutYourself: about_yourself });
            break;
          case this.getEmploymentTypeApiCallId:
            {
              Object.entries(responseJson).forEach((item: any) => {
                this.setState({ employementData: item[1] });
              });
            }
            break;
          case this.getIndustriesApiCallId:
            {
              Object.entries(responseJson).forEach((item: any) => {
                this.setState({ industryData: item[1] });
              });
            }
            break;
          case this.getSkillsApiCallId:
            {
              Object.entries(responseJson).forEach((item: any) => {
                this.setState({ skillsData: item[1] });
              });
            }
            break;
          case this.getDegreesApiCallId:
            {
              Object.entries(responseJson).forEach((item: any) => {
                this.setState({ degreeData: item[1] });
              });
            }
            break;
          case this.showProfileApiCallId:
            {
              Object.entries(responseJson).forEach((item: any) => {
                const data = `${item[1].attributes.email}`;
                const userType = `${item[1].attributes.user_type}`;
                const userName =
                  `${item[1].attributes.first_name}` +
                  " " +
                  `${item[1].attributes.last_name}`;
                this.setState({ profileData: data });
                this.setState({ userName: userName });
                this.setState({ userType: userType });
                const newCompanyData = {
                  company_size: item[1].attributes.company_size,
                  industry:item[1].attributes. industry,
                  location: item[1].attributes.location,
                  website: "",
                  start_date: new Date,
                  year: new Date,
                  specialities:"",
                  full_phone_number: "",
                };
                this.setState({companyData:newCompanyData})
              });
            }
            break;
          default:
            break;
        }


      }
      runEngine.debugLog("API Message Recived", message);
    }
    // Customizable Area End

  }


  // Customizable Area Start

  account_creation_date_error(key: any) {
    const errorMessages: Record<string, string> = {
      'certificates.issue_year': "certificates issue year need to update",
      'certificates.issue_date': "certificates issue date need to update",
      'educations.end_year': "Educations end year need to update",
      'educations.end_date': "Educations end date need to update",
      'educations.start_year': "Educations start year need to update",
      'educations.start_date': "Educations start date need to update",
      'user_work_experiences.end_year': "Work Experiences end year need to update",
      'user_work_experiences.end_date': "Work Experiences end date need to update",
      'user_work_experiences.start_year': "Work Experiences start year need to update",
      'user_work_experiences.start_date': "Work Experiences start date need to update",
    };
  
    const errorMessage = errorMessages[key];
  
    if (errorMessage) {
      this.account_creation_date_error_fun(errorMessage);
    } 
  }
  
  
  account_creation_date_error_fun(message: string) {
    this.setState({ response_message_account_creation: message })
    this.setState({ CollapseOpen_account_creation: true })
    setTimeout(() => {
      this.setState({ CollapseOpen_account_creation: false });
    }, 3000);
  }

  CollapseOpenClose_account_creation_fun = () => {
    this.setState({ CollapseOpen_account_creation: false })
  }


  navigateFunc = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.accountCreateApiCallId) {
      this.setState({ successMsg: 'success' });
      this.setState({ openDialog: true });
      return true;
    }
  }

  countryCodeHandler = (e: { target: { value: string } }) => {
    this.setState({ countryCode: e.target.value })
  }

  phoneNumberHandler = (e: { target: { value: string } }) => {
    this.setState({ phoneNumber: e.target.value })
  }


  accountCreation = (values: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token,
    };

    const data = {
      account: {
        basic: {
          ...values,
          full_phone_number: this.state.countryCode + values.full_phone_number,
          start_date: this.state.start_date,
          year: this.state.start_year,
          bio: this.state.tagline
        }
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountCreateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountCreateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handle_usename_account_creation = (e: { target: { value: string } }) => { this.setState({ usename_account_creation: e.target.value }) }

  validationSchemaExecutive = Yup.object().shape({
    title: Yup.string().required("Please enter designation").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
    employment_type_id: Yup.string().trim().required("Please enter employment type"),
    company_name: Yup.string().required("Please enter company name").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
    school_name: Yup.string().required("Please enter school name").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
    degree_id: Yup.string().required("Please enter issuing degree"),
    certificate_name: Yup.string().required("Please enter certificate name").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
    issuing_organization: Yup.string().required("Please enter issuing organization").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
  });

  companyValidationSchema = Yup.object().shape({
    company_size: Yup.string().required("This field is required"),
    industry: Yup.string().required("This field is required").test('no-leading-space', 'Invalid input', (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }),
    full_phone_number: Yup.string().matches(/^\d{7,15}$/, "Please enter a valid phone number").required("Please enter phone number "),
  });



  createAccount(values: any) {

    const newData = {
      basic: {
        about_yourself: this.state.tagline,
        address: "",
        user_name: this.state.usename_account_creation,
      },
      skill_ids: [],
      user_work_experiences: [
        {
          title: values.title,
          company_name: values.company_name,
          location: values.location,
          employment_type_id: values.employment_type_id,
          currently_work_here: values.currently_work_here,
          start_date: this.state.workStartDate,
          start_year: this.state.workStartYear,
          end_date: this.state.workEndDate,
          end_year: this.state.EndYear,
          skill_set: values.skill_set
        },
      ],
      educations: [
        {
          school_name: values.school_name,
          start_date: this.state.educationStartDate,
          start_year: this.state.educationStartYear,
          end_date: this.state.educationEndDate,
          end_year: this.state.educationEndYear,
          grade: values.grade,
          degree_id: values.degree_id,
          location: values.school_location,
        },
      ],
      certificates: [
        {
          name: values.certificate_name,
          issuing_organization: values.issuing_organization,
          issue_date: this.state.issueDate,
          issue_year: this.state.issueYear,
        },
      ],
    }


    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token,
    };

    const data = {
      account: newData
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CreateAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  formValues = (values: any) => {
    const { user_work_experiences, educations, certificates } = values;
    const workExperience = user_work_experiences[0];
    const education = educations[0];
    const certificateDetail = certificates[0];
    const data = {
      basic: {
        about_yourself: this.state.tagline,
        address: "",
      },
      skill_ids: [],
      user_work_experiences: [{
        ...workExperience,
        start_date: this.state.workStartDate,
        end_date: this.state.workEndDate,
        start_year: this.state.workStartYear,
        end_year: this.state.EndYear,
        employment_type_id: this.state.employmentTypeId
      }],
      educations: [{
        ...education,
        start_date: this.state.educationStartDate,
        start_year: this.state.educationStartYear,
        end_year: this.state.educationEndYear,
        end_date: this.state.educationEndDate
      }],
      certificates: [{
        ...certificateDetail,
        issue_date: this.state.issueDate,
        issue_year: this.state.issueYear
      }]
    }

    return data
  }

  AddBiography(): boolean {
    const header = {
      token: this.state.token,
    };

    let formdata = new FormData();

    formdata.append("background_image", this.state.file);
    formdata.append("profile_image", this.state.profile);

    const requestMessage_AddBiography = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addBiographyApiCallId = requestMessage_AddBiography.messageId;

    requestMessage_AddBiography.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddBigraphy
    );
    requestMessage_AddBiography.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage_AddBiography.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage_AddBiography.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage_AddBiography.id, requestMessage_AddBiography);
    return true;
  }

  getAllBiographyData() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBiographyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddBigraphy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getProfileData() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getEmploymentType() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmploymentTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmploymentType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSkills() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSkillsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkills
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getIndustries() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndustriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIndustries
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDegrees() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDegreesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDegrees
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
