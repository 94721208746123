import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  user_name: string;
  profile_image: string;
  background_image: string;
  about_yourself: string;
  user_role: string;
  tagline: string;
  ApplicantId: any;
  educations:  any;
  workExperiences: any;
  first_name: string;
  last_name: string;
  appliedJobs: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileData: string = "";
  apiGetWorkExperience : string = "";
  apiGetEducationCallId: string = "";
  apiGetAllDetails: string = "";
  apiAppliedJobsCallID:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      user_name: "",
      profile_image: "",
      background_image: "",
      about_yourself: "",
      user_role: "",
      tagline:  "",
      ApplicantId: null,
      educations: [],
      workExperiences: [],
      first_name: "",
      last_name: "",
      appliedJobs: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && !responseJson.errors){
      if(apiRequestCallId === this.apiGetAllDetails){ 
        this.setDetails(responseJson)
      }else if(apiRequestCallId === this.apiAppliedJobsCallID){
        console.log(responseJson,"detailjson")
       this.setState({appliedJobs: responseJson.data})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
   const applicantId = localStorage.getItem("applicant-id")
   this.setState({ApplicantId:applicantId},() => {
    this.getAllDetails();
    this.getAppliedJobs();
   })
  }

  navigator = (id: number) => {
    this.props.navigation.navigate("JobDetails",{id:id});
  }

  setDetails = (responseJson: any) => {
    if(responseJson.data){
     const {profile_image,background_image,user_type,first_name,last_name,work_experiences,educations} =  responseJson?.data?.attributes;
     this.setState({
        user_name:first_name+" "+last_name ,
        profile_image:profile_image,
        background_image: background_image,
        about_yourself: responseJson?.data?.attributes?.about_yourself ,
        user_role: user_type,
        tagline: responseJson?.data?.attributes?.tagline,
        educations: educations,
        workExperiences: work_experiences
      })
    }
    }

  getAppliedJobs = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAppliedJobsCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Executive_applied_Jobs+`?account_id=${this.state.ApplicantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }  


  getAllDetails = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllDetails = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsEndPoint+"/"+this.state.ApplicantId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
