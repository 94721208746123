import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup"
import moment from "moment";

// Customizable Area Start
export interface CompanyInterface {
  school_name: string,
  degree_id: number | string,
  start_date: any,
  start_year: any,
  end_date: any,
  end_year: any,
  grade: string,
  activities: string,
  description: string,
  location: string
}
export interface CertificateInterface {
  name: string,
  issuing_organization: string,
  issue_date: any,
  issue_year: any,
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}



interface S {
  // Customizable Area Start
  token: any;
  userRole: string;
  company_name: string;
  background_image: string;
  profile_image: string;
  about_yourself: string;
  location: string;
  specialities: string;
  updateCompanyBackgImg: boolean;
  website: string;
  start_date: any;
  full_phone_number: string
  industry: string;
  bio:any;
  company_size: string;
  linkedin_url: string;
  countryCode: string
  first_name: string;
  last_name: string;
  phoneNumber: string;
  educations: [];
  work_experiences: [];
  tagline: string;
  model_work_experience_add: boolean;
  model_company_details_add: boolean;
  workId: number;
  workExperience: {
    title: string,
    employment_type_id: any,
    company_name: string,
    location: string,
    start_date: any,
    end_date: any,
    start_year: any,
    end_year: any,
    currently_work_here: boolean,
    skill_set: any
  },
  startDate: any,
  isAddEdu: boolean,
  addCertificate: boolean;
  certificateDetails: any;
  endDate: any,
  startYear: any,
  endYear: any,
  currentWorkExperience: string,
  currentExperienceDetails: any,
  certificates: any[];
  currentCompany: string;
  updateEducation: boolean,
  isAddOpen: boolean,
  educationDetail: CompanyInterface,
  updateCertificate: boolean,
  certificateDetail: CertificateInterface;
  currentCertificate: string;
  industryTypes: [],
  employmentTypes: [];
  degrees: [];
  updateBackImg: boolean;
  isProfileImg: boolean;
  chooseFile: any;
  updateProfileImg: boolean;
  educationDetails: any;
  degreeId: any;
  employmentTypeId: any;
  skillsData: any[];
  degreeName: string;
  year: any;
  userProfileMsgCollapseOpen: boolean;
  userProfileMsg_response: string;
  userProfileMsg_res_message: string;
  companyData: any;
  start_dateInitial: any,
  year_initial: any;
  editIconView: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  apiGetDataCallId: string = "";
  apiUpdateWorkExperience: string = "";
  apiAddWorkExperience: string = "";
  apiUpdateEducationDetailapicallid: string = "";
  apiAddEducation: string = "";
  apiRemoveProfileCallId: string = "";
  apiUpdateAboutYourself: string = ""
  apiAddCertificateCallId: string = "";
  apiUpdateCompanyDetailId: string = ""
  apiCertificateCallId: string = "";
  apiGetEmploymentCallId: string = "";
  apiGetSkillsCallId: string = ""
  apiGetIndustryId: string = "";
  getDegreeApiCallId: string = "";
  apiUploadFile: string = "";
  apiUpdateProfileCallId: string = "";
  apiDetailsProfileCallId: string = "";

  month: string[] = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      userRole: "",
      company_name: "",
      background_image: "",
      profile_image: "",
      tagline: "",
      bio:"",
      about_yourself: "",
      location: "",
      industry: "",
      company_size: "",
      updateCompanyBackgImg: false,
      linkedin_url: "",
      countryCode: "",
      first_name: "",
      specialities: "",
      website: "",
      start_date: `${new Date().getDate()} ${this.month[new Date().getMonth()]}`,
      full_phone_number: "",
      last_name: "",
      phoneNumber: '',
      educations: [],
      work_experiences: [],
      model_work_experience_add: false,
      model_company_details_add: false,
      workId: 0,
      addCertificate: false,
      certificateDetails: {},
      workExperience: {
        title: "",
        employment_type_id: "",
        company_name: "",
        location: "",
        currently_work_here: false,
        start_date: new Date,
        start_year: new Date,
        end_date: new Date,
        end_year: new Date,
        skill_set: "",

      },
      isAddEdu: false,
      startDate: new Date,
      endDate: new Date,
      startYear: new Date,
      endYear: new Date,
      currentWorkExperience: "",
      currentExperienceDetails: {},
      certificates: [],
      currentCompany: "",
      updateEducation: false,
      isAddOpen: false,
      educationDetail: {
        school_name: "",
        degree_id: "",
        start_date: new Date,
        start_year: new Date,
        end_date: new Date,
        end_year: new Date,
        grade: "",
        activities: "",
        description: "",
        location: ""
      },
      updateCertificate: false,
      certificateDetail: {
        name: "",
        issuing_organization: "",
        issue_date: new Date,
        issue_year: new Date,
      },
      currentCertificate: "",
      industryTypes: [],
      employmentTypes: [],
      degrees: [],
      updateBackImg: false,
      isProfileImg: false,
      chooseFile: {},
      updateProfileImg: false,
      educationDetails: {},
      degreeId: 0,
      employmentTypeId: "",
      skillsData: [],
      degreeName: "",
      year: "",
      userProfileMsgCollapseOpen: false,
      userProfileMsg_res_message: "",
      userProfileMsg_response: "",
      companyData: {
        company_size: "",
        industry: "",
        location: "",
        website: "",
        start_date: new Date,
        year: new Date,
        specialities: "",
        full_phone_number: "",
      },
      start_dateInitial: new Date,
      year_initial: new Date,
      editIconView: true,
    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start   
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.error) {
      switch (apiRequestCallId) {
        case this.apiGetDataCallId: responseJson?.data?.attributes && this.getInitialData(responseJson);
          break;
        case this.apiDetailsProfileCallId: responseJson?.data?.attributes && this.getInitialData(responseJson);
          break;
        case this.apiUpdateWorkExperience:
          this.handleUpdateWorkExperience(responseJson)
          break;
        case this.apiCertificateCallId:
         this.handleEditCertificateResponse(responseJson)
          break;
        case this.apiUpdateEducationDetailapicallid:
          this.handleUpdateEducationDetailapicallid(responseJson)
          break;
        case this.apiGetIndustryId: this.setState({ industryTypes: responseJson.data });
          break;
        case this.apiGetEmploymentCallId: this.setState({ employmentTypes: responseJson.data });
          break;
        case this.getDegreeApiCallId: this.setState({ degrees: responseJson.data });
          break;
        case this.apiRemoveProfileCallId: this.getMenuItems();
          this.setState({ userProfileMsgCollapseOpen: true, model_company_details_add: false, userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", });
          setTimeout(() => {
            this.setState({ userProfileMsgCollapseOpen: false, updateProfileImg: false, updateBackImg: false });
          }, 3000);
          break;
        case this.apiUploadFile:
          this.setState({
            userProfileMsgCollapseOpen: true, userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully",
            background_image: responseJson.data.attributes.background_image,
          });
          setTimeout(() => {
            this.setState({ userProfileMsgCollapseOpen: false, updateBackImg: false, });
          }, 3000);
          break;
        case this.apiUpdateProfileCallId:
          this.setState({ userProfileMsgCollapseOpen: true, chooseFile: {}, profile_image: responseJson.data.attributes.profile_image, userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", })
          setTimeout(() => {
            this.setState({ userProfileMsgCollapseOpen: false, updateProfileImg: false, });
          }, 3000);
          break;
        case this.apiAddEducation:
          this.handleAddEducation(responseJson)
          break;
        case this.apiAddWorkExperience:
          this.handleAddWorkExperience(responseJson)
          break;
        case this.apiAddCertificateCallId: this.handleAddCerificateResponse(responseJson)
       
          break;
        case this.apiUpdateAboutYourself:
          this.setState({ updateBackImg: true, userProfileMsgCollapseOpen: true, userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", })
          setTimeout(() => {
            this.setState({ updateBackImg: false, userProfileMsgCollapseOpen: false, });
          }, 3000);
          this.getMenuItems();
          break;
        case this.apiUpdateCompanyDetailId:
          this.hanldeComapnyResponse(responseJson)
          break;
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  hanldeComapnyResponse(responseJson:any){
    if (responseJson?.errors && responseJson?.errors.length > 0) {
      this.handleErrorsForuserprofile(responseJson?.errors)
      }else{
  this.setState({ userProfileMsgCollapseOpen: true, userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", });
  setTimeout(() => {
    this.setState({ updateBackImg: false, userProfileMsgCollapseOpen: false, model_company_details_add: false, });
  }, 3000);
  this.getMenuItems();
}
  }
  async componentDidMount() {
    super.componentDidMount();
    const pathname = window.location.pathname;
    const id = pathname.split('/').pop();
    if (id === localStorage.getItem("user_id") && localStorage.getItem("teamMember_ReadOnly") !== 'true') {
      this.setState({ editIconView: true })
      this.getToken();
      this.getMenuItems();
      this.getList();
      this.getIndustyType();
      this.getDegree();
    }
    else {
      this.setState({ editIconView: false })
      this.detailsApiFunction(id)

    }
  }
  handleEditCertificateResponse(responseJson: any){
    if (responseJson?.errors && responseJson?.errors.length > 0) {
      this.handleErrorsForuserprofile(responseJson?.errors)
      }
      else {
        this.setState({ userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", userProfileMsgCollapseOpen: true });
        this.setUpdatedCertificates();
      }
  }
  handleUpdateEducationDetailapicallid(responseJson: any) {
    if (responseJson?.errors && responseJson?.errors.length > 0) {
    this.handleErrorsForuserprofile(responseJson?.errors)
    }
    else {

      this.setState({ userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", userProfileMsgCollapseOpen: true });
      this.setUpdatedEduDetail();
    }
  }
  handleAddEducation(responseJson: any) {
    if (responseJson?.errors && responseJson?.errors.length > 0) {
    this.handleErrorsForuserprofile(responseJson?.errors)
    }
    else {

      this.getMenuItems();
      this.setState({ userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", userProfileMsgCollapseOpen: true });
      setTimeout(() => {
        this.setState({ userProfileMsgCollapseOpen: false, isAddEdu: false });
      }, 3000);
    }
  }
  handleAddCerificateResponse(responseJson: any){
    if (responseJson?.errors && responseJson?.errors.length > 0) {
      this.handleErrorsForuserprofile(responseJson?.errors)
      }
      else {
        this.getMenuItems()
        this.setState({ userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", userProfileMsgCollapseOpen: true });
        setTimeout(() => {
          this.setState({ addCertificate: false, userProfileMsgCollapseOpen: false });
        }, 3000);
      }
  }
  handleUpdateWorkExperience(responseJson: any) {
    if (responseJson?.errors && responseJson?.errors.length > 0) {
    this.handleErrorsForuserprofile(responseJson?.errors)
    }
    else {
      this.setState({ userProfileMsg_res_message: "success", userProfileMsg_response: "Updated successfully", userProfileMsgCollapseOpen: true });
      this.setUpdatedWorkExperience();
    }
  }


  handleAddWorkExperience(responseJson: any) {
    if (responseJson?.errors && responseJson?.errors.length > 0) {
    this.handleErrorsForuserprofile(responseJson?.errors )
    }

     else {
      this.setState({
        userProfileMsg_res_message: "success",
        userProfileMsg_response: "Updated successfully",
        userProfileMsgCollapseOpen: true,
        workExperience: {
          title: "",
          employment_type_id: "",
          company_name: "",
          location: "",
          currently_work_here: false,
          start_date: new Date(),
          start_year: new Date(),
          end_date: new Date(),
          end_year: new Date(),
          skill_set: "",
        },
        startDate: new Date(),
        endDate: new Date(),
        endYear: new Date(),
        startYear: new Date(),
      });
      setTimeout(() => {
        this.setState({ userProfileMsgCollapseOpen: false, isAddOpen: false });
      }, 3000);
      this.getMenuItems();
    }
  }
  handleErrorsForuserprofile(errors: any) {
    for (const error of errors) {
      for (const errorKey in error) {
          if (error.hasOwnProperty(errorKey)) {
              const errorMessage = error[errorKey];
              if (errorKey === "start_year" || errorKey === "start_date" || errorKey === "end_date" || errorKey === "end_year") {
                  this.handlError(errorMessage);
              }
          }
      }
  }
  
}
handlError(error:string){
  this.setState({
    userProfileMsg_res_message: "error",
    userProfileMsg_response:error,
    userProfileMsgCollapseOpen: true
  });

  setTimeout(() => {
    this.setState({ userProfileMsgCollapseOpen: false });
  }, 3000);
}

  CollapseOpenClose_userProfileMsg_fun = () => {
    this.setState({ userProfileMsgCollapseOpen: false })
  }
  companyNameHandler = (e: any) => {
    this.setState({ company_name: e.target.value })

  }
  detailsApiFunction = (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDetailsProfileCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.detailsApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  update_about_yourself = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };

    const data = new FormData;

    const value = this.state.userRole === "company" ? this.state.tagline : this.state.about_yourself

    data.append("about_yourself", value)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateAboutYourself = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBioEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateBioMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCompanyDetail = (values: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    let body;
    body = {
      ...values,
      start_date: this.state.start_dateInitial,
      full_phone_number: this.state.countryCode + this.state.phoneNumber,
      year: this.state.year_initial,
      bio: this.state.tagline,
      company_name: this.state.company_name
    }
    const data = {
      account: {
        basic: body
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateCompanyDetailId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateCompanyEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateCompanyMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  AddCertificate(values: any) {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const body = {
      ...values,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      start_year: this.state.startDate,
      end_year: this.state.endDate,
      issue_year: values.issue_date
    }

    const data = {
      data: {
        attributes: body
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddCertificateCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCertificateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addCertificateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async addUpdatedExperience() {
    await this.getMenuItems();
    this.setState({ isAddOpen: false })
  }

  AddWorkExperience(values: any,startYear:any,endYear:any) {
    console.log("difjri",values)
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);
  
 
 
      const  body = {
         ...values,
         start_date: `${startDate.getDate()} ${this.month[startDate.getMonth()]}`,
         end_date: values.currently_work_here ? null:`${endDate.getDate()} ${this.month[endDate.getMonth()]}`,
         start_year:startYear,
         end_year: values.currently_work_here ? null:endYear,
         employment_type_id: values.employment_type_id,
       }
      


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddWorkExperience = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addWorkExperienceEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: body
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addCertificateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeProfilePicture = (key: string) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };

    const data = new FormData;

    data.append(key, "true")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveProfileCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiRemoveProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeProfileMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  AddEducation(values: any,startYear:any,endYear:any) {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const endDate = new Date(this.state.endDate);
    const startDate = new Date(this.state.startDate);
    const body = {
      ...values,
      end_date: `${endDate.getDate()} ${this.month[endDate.getMonth()]}`,
      start_date: `${startDate.getDate()} ${this.month[startDate.getMonth()]}`,
      start_year: startYear,
      end_year: endYear,
    }

    const data = {
      data: {
        attributes: body
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddEducation = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAddEducationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiAddEducationMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  uploadFile = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };

    const data = new FormData;
    data.append("background_image", this.state.chooseFile)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUploadFile = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadFileMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  updateProfileImg = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };

    const data = new FormData;
    data.append("profile_image", this.state.chooseFile)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateProfileCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadFileMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setUpdatedCertificates() {
    setTimeout(() => {
      this.setState({ userProfileMsgCollapseOpen: false, updateCertificate: false });
    }, 3000);
    this.getMenuItems()
  }

  setUpdatedEduDetail() {
    setTimeout(() => {
      this.setState({ userProfileMsgCollapseOpen: false, updateEducation: false });
    }, 3000);
    this.getMenuItems();
  }

  getInitialData = (responseJson: any) => {
    const { data: { attributes: {
      user_type,
      company_name,
      background_image,
      profile_image,
      about_yourself,
      location,
      company_size,
      linkedin_url,
      first_name,
      tagline,
      bio,
      last_name,
      educations: { data },
      work_experiences,
      industry,
      certificates,
      specialities,
      start_date,
      full_phone_number,
      website,
      year
    } } } = responseJson;
    const newCompanyData = {
      company_size: company_size,
      industry: industry,
      location: location,
      website: website,
      start_date: start_date,
      year: year,
      specialities: specialities,
      full_phone_number: full_phone_number,
      start_dateInitial: start_date,
      year_initial: year
    };
    this.setState({
      companyData: newCompanyData,
    });
    this.setState({
      userRole: user_type,
      tagline: bio,
      company_name: company_name,
      company_size: company_size,
      background_image: background_image,
      profile_image: profile_image,
      about_yourself: about_yourself,
      bio:bio,
      location: location,
      linkedin_url: linkedin_url,
      first_name: first_name,
      last_name: last_name,
      educations: data,
      work_experiences: work_experiences.data,
      industry: industry,
      certificates: certificates.data,
      start_date: start_date,
      website: website,
      full_phone_number: full_phone_number,
      specialities: specialities,
      year: year,
      startDate: new Date(),
      endDate: new Date(),
      endYear: new Date(),
      startYear: new Date(),
    })
  }

  editEducationDetail = (values: CompanyInterface) => {

    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    if (moment(this.state.endDate, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const enddate = new Date(this.state.endDate);
      this.setState({ endDate: `${enddate.getDate()} ${this.month[enddate.getMonth()]}` })
    }
    if (moment(this.state.startDate, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.startDate);
      this.setState({ startDate: `${startDate.getDate()} ${this.month[startDate.getMonth()]}` })
    }
    if (moment(this.state.startYear, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.startYear);
      this.setState({ startYear: startDate.getFullYear() })
    }
    if (moment(this.state.endYear, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.endYear);
      this.setState({ endYear: startDate.getFullYear() })
    }

    const body = {
      ...values,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      start_year: this.state.startYear,
      end_year: this.state.endYear,
    }
    const data = {
      data: {
        attributes: body
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateEducationDetailapicallid = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEducationEndPoint + '/' + this.state.currentCompany
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateEducationMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  updateCertificate = (values: CertificateInterface) => {
    const token = localStorage.getItem("authToken") as string;

    const body = {
      ...values,
      issue_year: values.issue_date,
      expiry_date: this.state.endDate,
      expiry_year: this.state.endYear,
    }


    const data = {
      data: {
        attributes: body
      }
    }

    const header = {
      token: token,
      "Content-Type": configJSON.updateCertificateContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apiCertificateCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCertificateEndPoint + "/" + this.state.currentCertificate
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateCertificateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  
  setUpdatedWorkExperience = () => {
    this.getMenuItems();
    setTimeout(() => {
      this.setState({ userProfileMsgCollapseOpen: false, model_work_experience_add: false });
    }, 3000);
  }
handleComapnyData(){
  return this.state.company_name ? this.state.company_name : "No Data"
}
handleIndustryData(){
  return this.state.industry ? this.state.industry : "No Data"
}
handleStartedOnDate(){
  return this.state.start_date ? this.state.start_date.slice(0, 5) + "-" + this.state.year : "No Data"
}
handleCompanySize(){
  return this.state.company_size?this.state.company_size:"no data"
}
handleLocation(){
  return this.state.location ? this.state.location : "No Data"
}
  editWorkExperience = (values: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    if (moment(this.state.startYear, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.startYear);
      this.setState({ startYear: startDate.getFullYear() })
    }
    if (moment(this.state.startDate, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.startDate);
      this.setState({ startDate: `${startDate.getDate()} ${this.month[startDate.getMonth()]}` })
    }
    if (moment(this.state.endDate, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const enddate = new Date(this.state.endDate);
      this.setState({ endDate: `${enddate.getDate()} ${this.month[enddate.getMonth()]}` })
    }

    if (moment(this.state.endYear, "YYYY-MM-DDTHH:mm:ss:SSSZ", true).isValid()) {
      const startDate = new Date(this.state.endYear);
      this.setState({ endYear: startDate.getFullYear() })
    }
    if(!this.state.endDate){
      const enddate=new Date();
      this.setState({ endDate: `${enddate.getDate()} ${this.month[enddate.getMonth()]}` })
    }
    if(!this.state.endYear){
      const endYear=new Date();
      this.setState({ endYear: `${endYear.getDate()} ${this.month[endYear.getMonth()]}` })
    }
    const body = {
      ...values,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      end_year: this.state.endYear,
      start_year: this.state.startYear,
      employment_type_id: values.employment_type_id,
    }


    const { account_id, created_at, employment_type, industry, profile_headline, description, ...newObj } = body

    const data = {
      data: {
        attributes: newObj
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateWorkExperience = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateWorkExperienceEndPoint + '/' + this.state.currentWorkExperience
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateWorkExperienceMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getMenuItems = async () => {
    const token = localStorage.getItem("authToken") as string;
    const user_id = localStorage.getItem("user_id") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profilebios + '/' + user_id
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getList() {

    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEmploymentCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmploymentType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getIndustyType() {

    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetIndustryId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIndustryIdEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDegree() {
    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDegreeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDegreeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  handleAddClose = () => {
    this.setState({ isAddOpen: false })
  }
  handleAddOpen = () => {
    this.setState({ isAddOpen: true })
  }
  handleOpen = () => {
    this.setState({ model_work_experience_add: true });
  };
  handleClose = () => {
    this.setState({ model_work_experience_add: false });
  };



  // Customizable Area End

}
