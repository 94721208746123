import React from "react";

// Customizable Area Start
import {
  Grid,
  IconButton,
  Box,
  MenuItem,
  Typography,
  Select,
  makeStyles,
  styled
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Yup from "yup";
import { Formik, getIn } from "formik";
import { CustomAccSelect, CustomAccTextField } from "./CustomStyle.web";
import CustomDatePicker from "./CustomDatePicker.web";
import { rawCountries } from "./rawCountryData";
const CustomSelectCode = styled(Select)({
  '& .MuiSelect-icon': {
    display: 'none'
  },
  '& .MuiSvgIcon-root': {
    position: 'relative',
    right: 30,
  },
  "& .MuiInput-underline.Mui-focused::after": {
    borderColor: 'transparent !important',
    border: 'none !important',
    borderBottom: 'none !important',
  },
  '& .MuiSelect-select:focus': {
    background: 'transparent !important',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: 0,
    width: 70,
    textOverflow: 'unset',
    textAlign: 'center',
    '& .country': {
      display: 'none',
    },
    '& .code': {
      borderRight: 'none',
      margin: 0,
      padding: 0,
    }
  },
})
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  code: {
    minWidth: 40,
    borderRight: "1px solid #ccc",
    paddingRight: 8,
    marginRight: 8
  },
  customSelect: {
    width: 80,
    "& .code": {
      borderRight: "none",
      paddingRight: 0,
      marginRight: 0
    },
    "& .country": {
      display: "none"
    },
  },
}));
export const Model_company_details_add_fun = (props: any) => {
  const {
    companyData2,
    handleClose_company_details,
    Reff,
    countryCode,
    updateCompanyDetail,
    changeCountryCode,
    selectRefCurrentClick,
    saveButton,
    fullNumberChangeHandler,
    onChangeHandler,
    yearChangeHandler
  } = props;
  const classes = useStyles();
  const checkValidation=/^(?!.* {2,}).*[^ ].*$/;
  const validationSchema = Yup.object().shape({
    company_size: Yup.string().required(),
    industry: Yup.string()
      .required("This field is required.....")
      .test(
        'no-leading-space',
        'Invalid input',
        (value) => {
          if (!value) return true;
          return checkValidation.test(value);

        }
      ),
    full_phone_number: Yup.string()
      .required('Please enter your phone number.....')
      .matches(
        /^((0091)|(\+91)|0?[6789]\d{9})$/,
        "Must Contain 10 Digits"
      ),
  });
  
  
  
  const month = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
  const companySize = [`${companyData2?.company_size}`, "1-50", "51-200", "201-500", "501-1000"]
  const getMonth = companyData2?.start_dateInitial ? companyData2?.start_dateInitial.split("-")[1]:new Date().toISOString().split("-")[1];
  const getDate = companyData2?.start_dateInitial ? companyData2?.start_dateInitial.split("-")[0]:new Date().toISOString().split("-")[2].split("T")[0];
  const countries = [[companyData2?.full_phone_number.slice(0, -10), "", "", companyData2?.full_phone_number?.slice(0, -10)], ...rawCountries];
  
  const companyData = {
    company_size: companyData2?.company_size,
    industry: companyData2?.industry,
    location: companyData2?.location,
    website: companyData2?.website,
    start_date:companyData2?.year_initial ?  new Date(`${companyData2.year_initial}-${getMonth}-${getDate}`):new Date(`${new Date().getFullYear()}-${getMonth}-${getDate}`),
    specialities: companyData2?.specialities,
    full_phone_number: companyData2?.full_phone_number?.substr(companyData2?.full_phone_number?.slice(0, -10)?.length, 10),
  };
  
  return (
    <div className="popup_wrp">
      <div className="popup_header">
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Company Details</h2>
          </Grid>
          <Grid item sm={1} style={{ textAlign: 'right' }}>
            <IconButton onClick={handleClose_company_details} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Formik
        initialValues={companyData}
        validationSchema={validationSchema}
        onSubmit={values => {
          // istanbul ignore next
          updateCompanyDetail(values)
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (<form autoComplete="off" onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Typography className="text-field">Company Size*</Typography>

              <Box sx={{ paddingBottom: '12px', height: '60px' }}>
                <CustomAccSelect
                  onChange={handleChange}
                  IconComponent={() => <ExpandMoreIcon style={{ fontSize: "36px" }} />}
                  value={values.company_size}
                  name="company_size"
                  className="CustomAccSelect downarrow"
                >
                  {companySize.map((size) => {
                    return (
                      <MenuItem value={size}>{size}</MenuItem>
                    )
                  })}
                </CustomAccSelect>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography className="text-field">Industry Type*</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="industry"
                  type="text"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"Industry type"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.industry}
                  onChange={handleChange}
                  error={touched.industry &&
                    Boolean(
                      getIn(
                        errors,
                        "industry"
                      )
                    )}
                />
                {touched.industry && Boolean(
                  getIn(
                    errors,
                    "industry"
                  )
                ) && <Typography style={{ fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex", alignItems: "center" }}>
                   {
                      getIn(
                        errors,
                        "industry"
                      )
                    }</Typography>
                }
              </Box>

            </Grid>
            <Grid item sm={6} >
              <Typography className="text-field">Started On Date</Typography>
              <Box  sx={{ paddingBottom: "12px", position: 'relative' }}>
                <CustomDatePicker
                  name="start_date"
                  views={["date", "month"]}
                  maxDate={new Date}
                  onChange={(val) => {
                    onChangeHandler(val, setFieldValue, month)
                  }}
                  data-test-id="test_id_user_work_experiences_date" value={values.start_date}
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography className="text-field">Year</Typography>
              <Box sx={{ paddingBottom: "12px", position: 'relative' }}>
                <CustomDatePicker
                  name="start_date"
                  views={["year"]}
                  maxDate={new Date}
                  data-test-id="user_work_experiences_start_year_test_id"
                  value={`${values.start_date}`} onChange={(val: any) => {
                    yearChangeHandler(val, setFieldValue)
                  }} />

              </Box>
            </Grid>
            <Grid item sm={6} >
              <Typography className="text-field">Phone number</Typography>
              <Box sx={{
                height: '63px',
                paddingBottom: '12px',
                border: '1px solid #536fc2',
                display: "flex",
                borderRadius: '6px',
                position: 'relative',
                marginBottom: "6px",
              }}>
                <CustomSelectCode
                  style={{
                    backgroundColor: 'transparent',
                    width: "70px",
                    height: '60px',
                    border: '0px',
                    borderRadius: '10px',
                    position: 'relative',
                    zIndex: 9,
                    cursor: 'pointer',
                    marginLeft: "5px"
                  }}
                  value={countryCode?.includes("+")?countryCode:`+${countryCode}`}
                  ref={Reff}
                  onChange={(e:any)=>{
                    changeCountryCode(e)} }
                  
                >
                  {countries.map((country: any) => {
                    return (
                      <MenuItem value={`+${country[3]}`}>
                        <span className={`${classes.code} code`}>+ {country[3]}</span>
                        <span className={`country`}>{`${country[0]}`}</span>
                      </MenuItem>
                    )
                  })}
                </CustomSelectCode>
                <input type="number"
                  name="full_phone_number"
                  maxLength={10}
                  style={{ border: '0px', outline: 'none', marginTop: '3px', marginLeft: '17px', height: '56px', width: '64%' }}
                  value={values.full_phone_number}
                  onChange={(e) => { fullNumberChangeHandler(e, setFieldValue) }}
                />
                <div style={{
                  display: 'flex',
                  position: 'absolute',
                  top: '17px',
                  left: '60px',
                  cursor: 'pointer',
                }}
                  onClick={selectRefCurrentClick}
                >
                  <ExpandMoreIcon
                    style={{
                      color: 'grey',
                    }}
                  />
                </div>
              </Box>

              {errors.full_phone_number && <p className="alet_msg" style={{ color: 'rgb(205, 76, 57)', lineHeight: "21px", marginLeft: '14px', marginBottom: '12px', fontSize: "14px", position: "relative", fontWeight: 400, }}>{errors.full_phone_number}</p>}
            </Grid>
            <Grid item sm={6}>
              <Typography className="text-field" style={{ position: "relative" }}>Location</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="location"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"location"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.location}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography className="text-field" style={{ position: "relative" }}>Website</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="website"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"website"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.website}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography className="text-field">Specialities</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="specialities"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"specialities"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.specialities}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            {saveButton()}
          </Grid>
        </Box>
      </form>
      )}
      </Formik>
    </div>
  );
}