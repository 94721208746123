// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  Typography,
  Grid,
  Tab,
  Tabs,
  TextField,
  Card,
  Button,
  Switch,
  withStyles,
  Collapse,
  IconButton,
  InputAdornment,
  Container,
  Dialog,
  CircularProgress,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
  styled,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {
  CustomAccTextField
} from "../../../components/src/CustomStyle.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Alert from '@material-ui/lab/Alert';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import './Settings.css';
import TextAreaWithButtons from '../../../components/src/TextArea.web';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  navebarLogo, no_user, view_Rectangle, Eyeopen, EyeSlash
} from "./assets";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Settings5Controller, { Props } from "./Settings5Controller.web";
import { Pagination } from "@material-ui/lab";
import moment from "moment";

import Customformweb from "../../customform/src/Customform.web";
import DropDown from "../../../components/src/AccDropdown.web";
import { ApplicationsSection } from "../../../components/src/ApplicationsSection";
import { Link } from 'react-router-dom';
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ViewApplication } from "../../../components/src/ViewApplication";
import { CompanyPopUp, VideoPlayer } from "../../../components/src/CompanyPopUp.web";
import ClearIcon from '@material-ui/icons/Clear';
const DivStyle = styled('div')({
  paddingLeft: "30px !important",

  "@media(min-width:900px) and (max-width:1157px)": {
    paddingLeft: "50px !important"
  },
  "@media(max-width:900px)": {
    paddingLeft: "30px !important"
  }
})
// Customizable Area End

const theme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        "&$selected": {
          fontWeight: "bold", // Change font-weight of active tab
          fontStyle: "poppins",
          fontSize: "16px",
          // Change font-style of active tab
        },
      },
      selected: {},
    },
  },

});


const CustomSwitch = withStyles({
  root: {
    width: 130,
    height: 40,
    borderRadius: 50,
    padding: 0,
    // backgroundColor: "#0040ff"
  },
  thumb: {
    width: 70,
    height: 40,
    borderRadius: 50,
  },
  switchBase: {
    color: 'white',
    '&$checked': {
      '& + $track': {
        backgroundColor: 'gray',
      },
    },
  },
  track: {
    backgroundColor: 'grey',
    opacity: 0.5,
    borderRadius: 15,
    position: 'relative',
    '&:after, &:before': {
      color: 'white',
      fontSize: '19px',
      position: 'absolute',
      top: '5px',
    },
    '&:after': {
      content: "'YES'",
      left: '14px',
    },
    '&:before': {
      content: "'NO'",
      right: '14px',
    },
  },
  checked: {},
})(Switch);

const useStyles = makeStyles((theming: any) => ({
  activityShortDesc: {
    [theming.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginTop: "6px",
      marginBottom: "6px",
    }
  }
}))

const DialogBox = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "40%"
  },
  "& .MuiDialog-paper": {
    overflowY: "unset"
  },
  "@media (max-width: 900px)": {
    "& .MuiDialog-paperWidthSm": {
      width: "100%"
    }
  }
})

const MainDialog = styled(`div`)({
  padding: "25px 40px 50px 40px",
  "@media (max-width:600px)": {
    padding: "20px"
  },
})

const MAX_CHARACTERS = 500;
const team_permissions = [
  {
    "id": 'Read Only',
    "value": "Read Only"
  },
  {
    "id": 'Read & Write',
    "value": "Read & Write"
  },
];

export function TabPanel(props: Props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}

    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default class Settings extends Settings5Controller {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.inputRef = React.createRef<HTMLInputElement>();

    // Customizable Area End
  }
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;

  notification_data_loop() {
    return (
      <>
        {this.state.getNotifiData?.length > 0 && this.state.getNotifiData.map((itemi: any, index: number) => (
          <div className="notification_div_row">
            {itemi.attributes.is_read ? '' : <div className="notification_red_dot">
              <FiberManualRecordIcon className="red_dot_icon_notification" />
            </div>}
            <Card className="settings_notification_card_row" data-id={itemi.id} key={itemi.id}>
              <Grid container className="img_name_row" alignItems="center" spacing={2}>
                <Grid item md={1} xs={2} spacing={2}>
                  <div>
                    {itemi.attributes.from.profile_image ? <img src={itemi.attributes.from.profile_image} alt="user" style={{ borderRadius: '59%', width: '50px', height: '50px' }} />
                      : <img src={no_user} alt="user" style={{ borderRadius: '59%', width: '50px', height: '50px' }} />}
                  </div>
                </Grid>
                <Grid item md={2} xs={10} spacing={2} className="notification_fromname_data">
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      {itemi.attributes.from.name ? itemi.attributes.from.name : "--"}
                    </label>
                  </Typography>
                  <Typography className="notifcation_data_created">{moment.utc(itemi.attributes.created_at).local().fromNow()}</Typography>
                </Grid>
                <Grid item md={2} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      {itemi.attributes.headings ? itemi.attributes.headings : "--"}
                    </label>
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field" style={{ fontSize: '13px' }}>
                    {itemi.attributes.contents ? itemi.attributes.contents : "--"}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={5} spacing={2}>
                  <div className="setting_notifi_view_more_div">
                    <a data-test-id="popup-click"
                      href={itemi.attributes.app_url && itemi.attributes.app_url.type === "job_details" ? "/JobDetails/" + itemi.attributes.app_url.job_id : "#"}
                      onClick={() => this.onViewMoreCLick(itemi)}
                      className="setting_notifi_view_more" style={{ cursor: 'pointer' }}
                    >
                      View More
                    </a>
                  </div>

                </Grid>
                <Grid item md={1} xs={7} spacing={2}>
                  <div className="notification_three_dot_icon_div">
                    <IconButton
                      aria-controls={`notification_menu_${itemi.id}`}
                      aria-haspopup="true"
                      data-id={itemi.id}
                      onClick={(e) => this.handleClick_show_menuoption(e, itemi.id)}
                      className="notification_menu_icon"
                      data-test-id={`notification_menu_icon_test_id`}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      id={`notification_menu_${itemi.id}`}
                      className="notification_menu_div"
                      anchorEl={this.state.anchorEl_notification_menu[itemi.id] || null}
                      open={Boolean(this.state.anchorEl_notification_menu[itemi.id])}
                      onClose={() => this.handleClose_show_menuoption(itemi.id)}
                      data-test-id={`notification_menu_div_test_id`}
                    >
                      <MenuItem className={`notification_menu_item${itemi.attributes.is_read ? ' hidden' : ''}`} data-test-id={`readSingleNofication_btn`} onClick={() => this.readSingleNofication(itemi.id)} data-id={itemi.id}>
                        Mark as Read
                      </MenuItem>
                      <MenuItem className="notification_menu_item" data-test-id={`delete_SingleNofication_btn`} onClick={() => this.delete_SingleNofication(itemi.id)} data-id={itemi.id}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>

                </Grid>
              </Grid>
            </Card>
          </div>
        ))}
      </>
    )
  }

  notification_settings() {
    return (
      <>
        <div className="settings_notification">
          <div className="settings_page">
            <div className="settings_page_title" style={{ paddingBottom: '30px' }}>
              <h2>Settings</h2>
            </div>
          </div>
          <div className="my_profile_tab">
            <div className="my_profile_tab_title">
              <h3>Notifications</h3>
              <p style={{ color: 'gray', fontSize: '15px' }}>We may still send you important notifications about your account outside of your notification settings</p>
            </div>
          </div>
          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
          <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <a
              color="primary"
              className="setting_notifi_markall_as_read"
              onClick={this.readAllNofication}
              data-test-id="readAllNofication_btn"
            >Mark all as read</a>
          </div>
          <Grid container className="activity-main">
            <Grid item lg={12} xs={12}>
              <div>

                {this.state.getNotifiData?.length > 0 ? this.notification_data_loop() : <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Notification data not found.
                </Typography>}





                {this.state.getNotifiData?.length > 0 && (
                  <Dialog fullWidth className="model_popup" scroll="body" open={this.state.viewApplication}  >

                    <div className="">
                      {this.state.userRole === "company" ?
                        <ViewApplication data-test-id="viewPopup" applicantQuestions={this.state.applicantQuestions} executiveDetails={this.state.executiveDetails} closeModal={() => { this.setState({ viewApplication: false }) }} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />
                        :
                        <CompanyPopUp data-test-id="companypopup" applicantQuestions={this.state.applicantQuestions} companyDetails={this.state.executiveDetails} closeModal={() => { this.setState({ viewApplication: false }) }} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />
                      }
                    </div>

                  </Dialog>
                )}
              </div>
            </Grid>
            {this.state.getNotifiData?.length > 0 && (
              <Grid className="activity-pagination nex_page_pagination" item xs={12} lg={12}>
                <Pagination size="small" data-test-id="pagination_notification" onChange={this.handlePageChange_notification} count={this.state.totalPagesNotification} page={this.state.currentPageNotification} color="primary" variant="outlined" shape="rounded" />
              </Grid>
            )}
          </Grid>
        </div>
      </>
    )
  }

  company_profile() {
    return (
      <>
        <div className="my_profile_section">
          <div className="settings_page">
            <div className="settings_page_title" style={{ paddingBottom: '30px' }}>
              <h2>Settings</h2>
            </div>
          </div>
          <div className="my_profile_tab">
            <div className="my_profile_tab_title">
              <h3>Company Profile</h3>
              <p style={{ color: 'gray', fontSize: '15px' }}>Update your company photo and personal details here</p>
            </div>
          </div>
          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
          <form >
            <div className="user_name_row">
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Public Profile
                    </label>
                    <p style={{ color: 'gray', fontSize: '15px' }}>This will be displayed on your profile.</p>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="company_name"
                    data-test-id="txtInputCompanyName"
                    placeholder={"Company Name"}
                    fullWidth
                    variant="outlined"
                    onChange={this.update_company_name}
                    value={this.state.company_name}
                    defaultValue={this.state.company_name}
                  />
                </Grid>
                <Grid item md={4} xs={12} spacing={2}>

                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="company_website"
                    className="company_website_link"
                    data-test-id="txtInputcompany_website"
                    placeholder={"Website Link"}
                    fullWidth
                    variant="outlined"
                    onChange={this.update_company_website}
                    value={this.state.company_website}
                    defaultValue={this.state.company_website}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          www.
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

            </div>
            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />

            <div className="photo_row">
              <Grid container spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Company Logo
                    </label>
                  </Typography>
                  <p style={{ color: 'gray', fontSize: '15px' }}>Update your company logo and then choose where you want it to display</p>
                </Grid>
                <Grid item md={3} xs={12} spacing={2} style={{ textAlign: 'center' }}>
                  {this.state.Profilepic ? <img src={this.state.Profilepic} style={{ width: '100px', height: '100px', borderRadius: '59%' }} /> : <img src={no_user} style={{ width: '100px', height: '100px', borderRadius: '59%' }} />}
                </Grid>
                <Grid item md={5} xs={12} spacing={2} style={{ marginTop: '15px' }}>
                  <Button onClick={() => {
                    this.inputRef.current?.click()
                  }}
                    className="Uploadbutton btn-block" variant="contained" color="primary"
                    style={{ width: "100%", textTransform: 'inherit' }} >
                    Upload new
                  </Button>
                  <input type="file" ref={this.inputRef} style={{ display: 'none' }} onChange={(e: { target: { files: any } }) => {
                    this.setState({
                      chooseFile: e.target.files[0],
                      Profilepic: URL.createObjectURL(e.target.files[0])
                    });
                  }} />
                  <Button data-test-id="removeimgBtn" onClick={this.remove_profile_ph} className="btn-block"
                    style={{ width: "100%", textTransform: 'inherit', color: '#3f51b5', }}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </div>

            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
            <div className="bio_row">
              <Grid container spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Tagline
                    </label>
                  </Typography>
                  <p style={{ color: 'gray', fontSize: '15px' }}>
                    A quick snapshot of your company.
                  </p>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextAreaWithButtons
                    value={this.state.bio}
                    onChange={this.handleTextAreaChange}
                    data-test-id="txtInputbio_company"
                    maxLength={MAX_CHARACTERS}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {this.state.bio ? MAX_CHARACTERS - this.state.bio.length : MAX_CHARACTERS} characters remaining
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
            <div className="user_name_row">
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Social Profile
                    </label>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    className="social_link_company"
                    name="twitter_url"
                    data-test-id="txtInputtwitterurl"
                    placeholder={"Twitter"}
                    fullWidth
                    variant="outlined"
                    onChange={this.update_company_twitter_link}
                    value={this.state.twitter_url}
                    defaultValue={this.state.twitter_url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          twitter.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12} spacing={2}>

                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    className="social_link_company"
                    name="facebook_url"
                    data-test-id="txtInputfacebookurl"
                    placeholder={"Facebook"}
                    fullWidth
                    variant="outlined"
                    onChange={this.update_company_fb_link}
                    value={this.state.facebook_url}
                    defaultValue={this.state.facebook_url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          facebook.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12} spacing={2}>

                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="linkedlin_url"
                    data-test-id="txtInputlinkedlinurl"
                    placeholder={"Linkedlin"}
                    fullWidth
                    variant="outlined"
                    className="social_link_company"
                    onChange={this.update_company_linked_link}
                    value={this.state.linkedin_url}
                    defaultValue={this.state.linkedin_url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          linkedlin.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

            </div>

            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '10px' }} />
            <Grid container>
              <Grid item sm={12} xs={12} className="btn_row_save">
                <div>
                  <Button type="button"
                    data-test-id="btnCancel"
                    color="primary"
                    className="customCancel_btn">Cancel</Button>
                  <Button
                    type="button"
                    data-test-id="btnSave_company_profile"
                    color="primary"
                    className="customSave_btn"
                    onClick={this.handleSubmit_settings}>Save Changes</Button>
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item sm={12} xs={12} className="message_show_company_profile">
                <div style={{ marginTop: '15px' }}>
                  <Collapse in={this.state.CollapseOpen}>
                    <Alert
                      variant="filled"
                      severity={this.state.password_res_message === 'success' ? 'success' : "error"}
                      action={
                        <IconButton
                          color="inherit"
                          size="small"
                          aria-label="close"
                          data-test-id="txtInputMessagePassword"
                          onClick={this.CollapseOpenClose_fun}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.password_response}
                    </Alert>
                  </Collapse>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    )
  }

  company_team() {
    return (
      <>
        <div className="my_profile_section">
          <div className="settings_page">
            <div className="settings_page_title" style={{}}>
              <h2>Team</h2>
              <p style={{ color: 'gray', fontSize: '15px' }}>Manage your team members and their account permissions here</p>
            </div>
          </div>

          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
          <div className="company_team" style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} spacing={2}>
                <Typography className="text-field">
                  <label style={{ fontWeight: 'bold' }}>
                    Invite team members
                  </label>
                  <p style={{ color: 'gray', fontSize: '15px' }}>Get your projects up and running faster by inviting your team or collaborate</p>
                </Typography>
              </Grid>
              <Grid item md={8} xs={12} spacing={2}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12} spacing={2} >

                    {this.state.inviteRows.map((row, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item md={8} xs={12} sm={12} spacing={2}>
                          <Box className="crossIcon">
                            {<IconButton data-test-id='remove-email' style={{ visibility: index === 0 ? 'hidden' : 'visible' }} onClick={() => this.removeEmail(index)}>
                              <ClearIcon fontSize="small" />
                            </IconButton>}
                            <CustomAccTextField
                              name="invite_email"
                              data-test-id='txtInputinvite_email'
                              fullWidth={true}
                              variant="outlined"
                              id={`invite_email_${index}`}
                              value={row.invite_email}
                              onChange={(e) => this.handleChangeInviteEmail(index, e)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" className="icon_input_div">
                                    <MailOutlineOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={4} xs={12} spacing={2}>
                          <DropDown
                            data-test-id='company_team_test_id_invite_permission'
                            name="invite_permission"
                            value={row.invite_permission}
                            onChange={(e) => this.handleChang_invite_permission(index, e)}
                            items={team_permissions}
                          />
                        </Grid>
                      </Grid>

                    ))}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item md={8} xs={12} spacing={2}>
                    <div className="add-other">
                      <Button
                        type="button"
                        data-test-id="btnAdd_another"
                        startIcon={<AddIcon />}
                        className="company_team_Add_another_btn"
                        onClick={this.handleAddAnother}
                      >
                        Add another
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12} spacing={2}>
                    <div>
                      <Button
                        type="button"
                        data-test-id="btnSave_company_team_invite_btn"
                        color="primary"
                        className="company_team_invite_btn"
                        startIcon={<MailOutlineOutlinedIcon />}
                        onClick={this.handleSubmit_company_invite_team}
                      >
                        Send Invites
                      </Button>
                    </div>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12} xs={12} className="message_show_invite_team">
                <div style={{ marginTop: '15px' }}>
                  <Collapse in={this.state.CollapseOpen_invite_team} className="bg">

                    <Alert

                      variant="filled"
                      severity={this.state.invite_team_message === 'success' ? 'success' : "error"}
                      action={
                        <IconButton
                          onClick={this.CollapseOpenClose_fun} color="inherit"
                          size="small"
                          data-test-id="txtInputMessagePassword"
                          aria-label="close"
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.invite_team_response}
                    </Alert>

                  </Collapse>
                </div>
              </Grid>
            </Grid>
          </div>

          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />

          <div className="company_team" style={{ marginTop: '50px' }}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} spacing={2}>
                <Typography className="text-field">
                  <label style={{ fontWeight: 'bold' }}>
                    Team members
                  </label>
                  <p style={{ color: 'gray', fontSize: '15px' }}>Manage your existing team and change roles/permissions</p>
                </Typography>
              </Grid>
              <Grid item md={8} xs={12} spacing={2}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12} spacing={2} >

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead style={{ background: '#f0f0f0' }}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell style={{ color: 'grey' }}>Name</TableCell>
                            <TableCell style={{ color: 'grey' }}>Role</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        {this.state.teamMembers?.team_members?.data.map((item: any, index: any) => {
                          return (

                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    onChange={() => { }}
                                    color="primary"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Box style={{ display: 'flex' }}>
                                    <Box style={{ margin: '5px' }}>
                                      <img src={item.attributes.profile_image ? item.attributes.profile_image : no_user} style={{ width: '45px', height: '45px', borderRadius: '59%' }} />
                                    </Box>
                                    <Box className="name-email">
                                      <Typography>
                                        {item.attributes?.account?.first_name}  {item.attributes?.account?.last_name}
                                      </Typography>
                                      <Typography style={{ fontSize: '13px', color: 'grey' }}>
                                        {item.attributes.email}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell><Typography style={{ color: 'grey' }}> {item.attributes.permission}  </Typography></TableCell>
                                <TableCell><Box data-test-id='delete-member' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.deleteMember(item.id)}> Delete </Box></TableCell>
                                <TableCell data-test-id='edit-member' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { this.editPermission(item) }}>Edit</TableCell>
                              </TableRow>
                            </TableBody>
                          )
                        })}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item sm={12} xs={12} className="message_show_invite_team">
                    <div style={{ marginTop: '15px' }}>
                      <Collapse in={this.state.deleteEditPopUp} className="bg">
                        <Alert
                          variant="filled"
                          severity={'success'}
                          action={
                            <IconButton
                              onClick={this.CollapseOpenClose_fun} color="inherit"
                              size="small"
                              data-test-id="txtInputMessagePassword"
                              aria-label="close"
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.editDeleteStatus}
                        </Alert>

                      </Collapse>
                    </div>
                  </Grid>
                </Grid>

                {this.state.teamMembers?.team_members?.data?.length > 0 && (
                  <Grid className="activity-pagination nex_page_pagination" item xs={12} lg={12}>
                    <Pagination size="small" data-test-id="pagination_teams" onChange={this.handlePageChange_teams} count={this.state.totalPagesTeams} page={this.state.currentPageTeams} color="primary" variant="outlined" shape="rounded" />
                  </Grid>
                )}
              </Grid>

            </Grid>
            <DialogBox
              open={this.state.showEditPopup}
            >
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '10px 40px' }}>
                <IconButton edge="end" color="inherit" onClick={() => { this.setState({ showEditPopup: false }) }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <MainDialog style={{ padding: '20px' }}>

                <Grid container style={{ border: "1px solid #879cdb", borderRadius: "8px", marginBottom: "10px" }}>
                  <Grid sm={6} xs={12} style={{ padding: "10px" }}>
                    <Typography style={{ fontSize: "14px", fontWeight: 500, color: "grey" }}>
                    </Typography>

                    <Grid item md={4} xs={12} spacing={2}>
                      <div className="dialog-dropdown">
                        <DropDown
                          name="invite_permission"
                          data-test-id='company_team_test_id_invite_permission_second'
                          value={this.state.selectedEditPermission}
                          onChange={(e) => this.handleChang_edit_permission(e)}
                          items={team_permissions}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid sm={6} xs={12} style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <Box className="approve-btn">
                      <Button data-test-id='edit-approve' onClick={() => this.onEditApprove()} style={{ backgroundColor: "#536fc2", color: "#fff", fontWeight: 700, marginRight: "1px", textTransform: "none" }}>
                        OK
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </MainDialog>
            </DialogBox>
          </div>
        </div >
      </>
    )
  }

  handleVideoPic(BackgroundPic: any) {
    return BackgroundPic && typeof BackgroundPic === 'string' && BackgroundPic?.includes("mp4") ? <VideoPlayer videoSource={BackgroundPic} style={{ objectFit: 'cover', height: '360px', width: '100%' }} /> :
      <img src={BackgroundPic} alt="banner" style={{ objectFit: 'cover', height: '360px', width: '100%' }} />
  }

  appearance_page(profile: string) {
    return (
      <>
        <div className="apperance_section">
          <div className="settings_page" style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px' }} >
            <div className="settings_page_title">
              <h2>Settings</h2>
            </div>
          </div>
          <div className="my_profile_tab" style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px' }} >
            <div className="my_profile_tab_title">
              <h3>Profile</h3>
            </div>
          </div>
          <div className="banner_row_apperance" style={{ paddingTop: '30px' }}>
            {this.state.BackgroundPic ? this.handleVideoPic(this.state.BackgroundPic) :
              <img src={view_Rectangle} alt="banner" style={{ objectFit: 'cover', height: '360px', width: '100%' }} />
            }
          </div>
          <div className="user_profile_apperance" style={{ position: this.state.BackgroundPic !== null && this.state.BackgroundPic?.includes("mp4") ? "relative" : "unset" }}>
            <Grid container alignItems="center" style={{ paddingTop: '25px', }}>
              <Grid item md={2} xs={12}>
                <div style={{ marginTop: '-100px', paddingLeft: '50px' }}>
                  {this.state.Profilepic ? <img src={this.state.Profilepic} style={{ width: '150px', height: '150px', borderRadius: '59%' }} /> : <img src={no_user} style={{ width: '150px', height: '150px', borderRadius: '59%' }} />}
                </div>
              </Grid>
              <Grid item md={10} xs={12}>
                <DivStyle  >
                  <h3>{this.state.first_name} {this.state.last_name}</h3>
                </DivStyle>
              </Grid>
            </Grid>
          </div>
          <div className="form_apperance" style={{ padding: '50px' }}>
            <form >
              <div className="user_name_row" >
                <Grid container alignItems="center" spacing={2} style={{ paddingTop: '25px', }}>
                  <Grid item md={6} xs={12} spacing={2}>
                    <Typography className="text-field">
                      <label style={{ fontWeight: 'bold' }}>
                        {`${profile === 'executive' ? "First Name" : "Company Name"}`}
                      </label>
                    </Typography>
                    <TextField
                      name="first_name"
                      data-test-id="txtInputFirstNameapperance"
                      placeholder={"First Name"}
                      fullWidth
                      variant="outlined"
                      value={this.state.first_name}
                      defaultValue={this.state.first_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} spacing={2}>
                    <Typography className="text-field">
                      <label style={{ fontWeight: 'bold' }}>
                        Last Name
                      </label>
                    </Typography>
                    <TextField
                      name="last_name"
                      data-test-id="txtInputLastNameapperance"
                      placeholder={"Last Name"}
                      fullWidth
                      variant="outlined"
                      value={this.state.last_name}
                      defaultValue={this.state.last_name}
                    />
                  </Grid>

                </Grid>
                <Grid container alignItems="center" spacing={2} style={{ paddingTop: '25px', }}>
                  <Grid item md={6} xs={12} spacing={2}>
                    <Typography className="text-field">
                      <label style={{ fontWeight: 'bold' }}>
                        Email address
                      </label>
                    </Typography>
                    <TextField
                      name="user_eamil"
                      data-test-id="txtInputEmailapperance"
                      placeholder={"Email"}
                      fullWidth
                      variant="outlined"
                      value={this.state.email}
                      defaultValue={this.state.email}
                      style={{ padding: '0px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} spacing={2}>
                  </Grid>
                </Grid>
                {profile === 'executive' && <Grid container alignItems="center" spacing={2} style={{ paddingTop: '25px', }}>
                  <Grid item md={6} xs={12} spacing={2}>
                    <Typography className="text-field">
                      <label style={{ fontWeight: 'bold' }}>
                        Role
                      </label>
                    </Typography>
                    <TextField
                      name="user_role"
                      data-test-id="txtInputeroleapperance"
                      placeholder={"Role"}
                      fullWidth
                      variant="outlined"
                      value={this.state.userRole}
                      defaultValue={this.state.userRole}
                      style={{ textTransform: 'capitalize' }}
                      className="user_role_class"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} spacing={2}>
                  </Grid>
                </Grid>}
                <Grid container alignItems="center" spacing={2} style={{ paddingTop: '30px', }}>
                  <Grid item md={12} xs={12} spacing={2}>
                    <Typography className="text-field">
                      <label style={{ fontWeight: 'bold' }}>
                        Description
                      </label>
                    </Typography>
                    <TextAreaWithButtons
                      value={this.state.bio}
                      defaultValue={this.state.bio}
                      data-test-id="txtInputbioapperance"
                      maxLength={MAX_CHARACTERS}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {this.state.bio ? MAX_CHARACTERS - this.state.bio.length : MAX_CHARACTERS} characters remaining
                    </Typography>
                  </Grid>
                </Grid>
                {profile === 'company' && <Grid>
                  <Typography style={{ padding: '40px 0 15px 0' }}> <label style={{ fontWeight: 'bold' }}>Social Profiles</label></Typography>
                  <ul style={{ display: 'flex' }}>
                    <li className='list'>
                      <Typography style={{ color: 'black' }}>
                        <a className='link' href="https://www.facebook.com/Official.boardsi/" target="_blank" rel="noopener noreferrer">
                          <FacebookIcon fontSize="large" style={{ color: 'black', marginRight: "12px" }} />
                        </a>
                      </Typography>
                    </li>

                    <li className='list'>
                      <Typography style={{ color: 'black' }}>
                        <a className='link' href="https://twitter.com/boardsi2" target="_blank" rel="noopener noreferrer">
                          <TwitterIcon fontSize="large" style={{ color: 'black', marginRight: "12px" }} />
                        </a>
                      </Typography>
                    </li>
                    <li className='list'>

                      <Typography style={{ color: 'black' }}>
                        <a className='link' href="https://www.linkedin.com/company/boardsi/" target="_blank" rel="noopener noreferrer">
                          <LinkedInIcon fontSize="large" style={{ color: 'black', marginRight: "12px" }} />
                        </a>
                      </Typography>
                    </li>
                  </ul>
                </Grid>}
              </div>
            </form>
          </div>
        </div>
      </>
    )
  }

  company_settings() {
    return (
      <>
        <Grid item xs={12} sm={12} md={2} className="grid-container" style={{ backgroundColor: '#5370c2' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            className="tabs"
            indicatorColor="primary"
            style={{
              height: "899px",
              fontSize: 55,
              color: "White",
              fontWeight: "bold",
            }}

          >
            {localStorage.getItem("teamMember_ReadOnly") !== 'true' && <Tab
              label="Profile"
              {...this.a11yProps(0)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />}
            <Tab
              label="My Activity"
              {...this.a11yProps(1)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            {localStorage.getItem("teamMember_ReadOnly") !== 'true' && <Tab
              label="Password"
              {...this.a11yProps(2)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />}
            <Tab
              label="Notifications"
              {...this.a11yProps(3)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            {localStorage.getItem("teamMember_ReadOnly") !== 'true' && <Tab
              label="Team"
              {...this.a11yProps(4)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />}
            <Tab
              label="Appearance"
              {...this.a11yProps(5)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />

            {localStorage.getItem("teamMember_ReadOnly") !== 'true' && <Tab
              label="Custom Form"
              {...this.a11yProps(6)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />}
          </Tabs>
        </Grid>

        {localStorage.getItem("teamMember_ReadOnly") !== 'true' ? <Grid item xs={12} sm={12} md={10} className="tabs-content-right">
          <TabPanel value={this.state.value} index={0}
            navigation={undefined}
            id={""} classes={undefined}
          >
            {this.company_profile()}  </TabPanel>
          <TabPanel
            value={this.state.value}
            index={1} navigation={undefined} id={""}
            classes={undefined}
          >
            {this.activityLog()} </TabPanel>
          <TabPanel value={this.state.value} index={2}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            {this.executive_password_update()} </TabPanel>
          <TabPanel value={this.state.value} index={3}
            navigation={undefined}
            id={""} classes={undefined}
          >
            {this.notification_settings()} </TabPanel>
          <TabPanel value={this.state.value}
            index={4}
            navigation={undefined} id={""}
            classes={undefined}
          >
            {this.company_team()} </TabPanel>
          <TabPanel
            value={this.state.value}
            index={5} navigation={undefined}
            id={""} classes={undefined}
          >
            {this.appearance_page('company')} </TabPanel>
          <TabPanel
            value={this.state.value}
            index={6} navigation={undefined}
            id={""} classes={undefined}
          >
            <><Customformweb navigation={undefined} id={""} /></> </TabPanel>
        </Grid> :


          <Grid item xs={12} sm={12} md={10} className="tabs-content-right">
            <TabPanel
              value={this.state.value}
              index={0}
              navigation={undefined}
              id={""}
              classes={undefined}
            >
              {this.activityLog()}
            </TabPanel>

            <TabPanel
              value={this.state.value}
              index={1}
              navigation={undefined}
              id={""}
              classes={undefined}
            >
              {this.notification_settings()}
            </TabPanel>

            <TabPanel
              value={this.state.value}
              index={2}
              navigation={undefined}
              id={""} classes={undefined}
            >
              {this.appearance_page('company')}
            </TabPanel>

          </Grid>


        }
      </>
    )
  }


  activityLog() {
    const activity_log_sorting_data = [
      {
        "id": 'sort',
        "value": "Sort"
      },
      {
        "id": 'default',
        "value": "Most Recent"
      },
      {
        "id": 'atoz',
        "value": "Alphabetical A-Z"
      },
      {
        "id": 'ztoa',
        "value": "Alphabetical Z-A"
      },
    ];
    return (
      <>
        <Container className="my-activity">
          <Grid container className="activity-header">
            <Grid item xs={8} md={9}>
              <Typography style={{ fontWeight: "bolder" }} variant="h6">My Activity</Typography>
            </Grid>
            <Grid item xs={4} md={3}>
              <DropDown
                name='activity_log_sorting'
                value={this.state.activityLogSorting}
                onChange={this.handleChange_activityLogSorting}
                items={activity_log_sorting_data}
                data-test-id="activity_log_sorting_test_id"
              />
            </Grid>
          </Grid>
          <Divider style={{ height: '2px', marginBottom: '15px', marginTop: '15px' }} />
          <Grid container className="activity-main">
            <Grid item lg={12} xs={12}>
              <Typography style={{ marginBottom: '15px', color: '#999999' }}>Sorted by Newest</Typography>
            </Grid>
            <Grid item lg={12} xs={12} md={12}>
              {
                this.state.activityLogs && this.state.activityLogs.map(post => {
                  return (
                    <Card className="activeLog_card" key={post.id}>
                      <Grid container className="activity-card" spacing={2}>
                        <Grid item md={10} xs={12} spacing={2}>
                          <Typography color="primary" variant="h6">{post.attributes.title}</Typography>
                          <p>{post.attributes.description}</p>
                        </Grid>
                        <Grid item xs={12} md={2} spacing={2}>
                          <Typography>{moment.utc(post.attributes.created_at).local().fromNow()}</Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })
              }

            </Grid>
            <Grid className="activity-pagination nex_page_pagination" item xs={12} lg={12}>
              <Pagination size="small" data-test-id="pagination_activelog" onChange={this.handlePageChange_ActivityLog} count={this.state.totalPagesActivatyLog} page={this.state.currentPageActivityLog} color="primary" variant="outlined" shape="rounded" />
            </Grid>
          </Grid>

        </Container>
      </>
    )
  }

  executive_password_update() {
    return (
      <>
        <div className="my_profile_section setting_page_eye">
          <div className="settings_page">
            <div className="settings_page_title" style={{ paddingBottom: '30px' }}>
              <h2>Settings</h2>
            </div>
          </div>
          <div className="my_profile_tab">
            <div className="my_profile_tab_title">
              <h3>Password</h3>
              <p style={{ color: 'gray', fontSize: '15px' }}>Please enter your current password to reset your password</p>
            </div>
          </div>
          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
          <form >
            <div className="current_password_row">
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Current Password
                    </label>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="current_password"
                    data-test-id="txtInputCurrentPassword"
                    placeholder={"Current Password"}
                    error={Boolean(this.state.current_password_error)}
                    helperText={this.state.current_password_error}
                    fullWidth
                    variant="outlined"
                    type={
                      this.state.enableCurrentPasswordField ? "password" : "text"
                    }
                    value={this.state.current_password}
                    onChange={this.currentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowCurrentPassword}
                            edge="end"
                            data-test-id="handleClickShowCurrentPassword_test_id"
                          >
                            {this.state.enableCurrentPasswordField ? (
                              <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />

                            ) : (
                              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

              </Grid>
            </div>

            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
            <div className="new_password_row">
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      New Password
                    </label>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="new_password"
                    data-test-id="txtInputNewPassword"
                    placeholder={"New Password"}
                    fullWidth
                    variant="outlined"
                    type={
                      this.state.enablePasswordField ? "password" : "text"
                    }
                    value={this.state.new_password}
                    onChange={this.newPassword}
                    error={Boolean(this.state.new_password_error)}
                    helperText={this.state.new_password_error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            data-test-id="handleClickShowPassword_test_id"

                          >
                            {this.state.enablePasswordField ? (
                              <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />

                            ) : (
                              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <p style={{ color: 'gray', fontSize: '15px', marginTop: '7px' }}>Your new password must be more than 8 characters</p>
                </Grid>
              </Grid>
            </div>

            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
            <div className="confirm_password_row">
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={12} spacing={2}>
                  <Typography className="text-field">
                    <label style={{ fontWeight: 'bold' }}>
                      Confirm Password
                    </label>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} spacing={2}>
                  <TextField
                    name="confirm_password"
                    data-test-id="txtInputConfirmPassword"
                    placeholder={"Confirm Password"}
                    error={Boolean(this.state.confirm_password_error)}
                    helperText={this.state.confirm_password_error}
                    fullWidth
                    variant="outlined"
                    type={this.state.enableconfirmPasswordField ? "password" : "text"}
                    value={this.state.confirm_password}
                    onChange={this.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                            edge="end"
                            data-test-id="handleClickShowConfirmPassword_test_id"
                          >
                            {this.state.enableconfirmPasswordField ? (
                              <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
                            ) : (
                              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>


            <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '10px' }} />
            <Grid container>
              <Grid item sm={12} className="btn_row_save">
                <div>
                  <Button type="button"
                    data-test-id="btnCancel"
                    color="primary"
                    className="customCancel_btn">
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    data-test-id="updatePasswordTestIT"
                    color="primary"
                    className="customSave_btn"
                    onClick={this.handleUpdatePassword}
                  >
                    Update Password
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12} className="message_show_update_password" style={{width:'100%'}}>
                <div style={{ marginTop: '15px' }}>
                  <Collapse in={this.state.CollapseOpen_pass}>
                    <Alert
                      style={{ width: '100%' }}
                      severity={this.state.password_res_message === 'success' ? 'success' : "error"}
                      variant="filled"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          data-test-id="txtInputMessagePassword"
                          onClick={this.CollapseOpenClose_fun}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.password_response}
                    </Alert>
                  </Collapse>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    )
  }

  executive_settings() {
    return (
      <>
        <Grid item xs={12} sm={12} md={2} className="grid-container" style={{ backgroundColor: '#5370c2' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            className="tabs"
            indicatorColor="primary"
            style={{
              height: "899px",
              fontSize: 55,
              color: "White",
              fontWeight: "bold",
            }}
          >
            <Tab
              label="My Profile"
              {...this.a11yProps(0)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            <Tab
              label="My Activity"
              {...this.a11yProps(1)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            <Tab
              label="Password"
              {...this.a11yProps(2)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            <Tab
              label="Notifications"
              {...this.a11yProps(3)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            <Tab
              label="Appearance"
              {...this.a11yProps(4)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
            <Tab
              label="Applications"
              {...this.a11yProps(5)}
              style={{ marginTop: "20px", textTransform: "capitalize" }}
              className="varticalTabs_dev"
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={10} className="tabs-content-right">
          <TabPanel
            value={this.state.value}
            index={0}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            <>
              <div className="my_profile_section">
                <div className="settings_page">
                  <div className="settings_page_title" style={{ paddingBottom: '30px' }}>
                    <h2>Settings</h2>
                  </div>
                </div>
                <div className="my_profile_tab">
                  <div className="my_profile_tab_title">
                    <h3>My Profile</h3>
                    <p style={{ color: 'gray', fontSize: '15px' }}>Update your photo and personal details here</p>
                  </div>
                </div>
                <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                <form >
                  <div className="user_name_row">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Name
                          </label>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12} spacing={2}>
                        <TextField
                          name="first_name"
                          data-test-id="txtInputFirstName"
                          placeholder={"First Name"}
                          fullWidth
                          variant="outlined"
                          onChange={this.updateFirstname}
                          value={this.state.first_name}
                          defaultValue={this.state.first_name}
                        />
                      </Grid>
                      <Grid item md={4} xs={12} spacing={2}>
                        <TextField
                          name="last_name"
                          data-test-id="txtInputLastName"
                          placeholder={"Last Name"}
                          fullWidth
                          variant="outlined"
                          onChange={this.updateLastname}
                          value={this.state.last_name}
                          defaultValue={this.state.last_name}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                  <div className="email_row">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Email
                          </label>
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={12} spacing={2}>
                        <TextField
                          name="user_eamil"
                          data-test-id="txtInputEmail"
                          placeholder={"E-mail"}
                          fullWidth
                          variant="outlined"
                          value={this.state.email}
                          defaultValue={this.state.email}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                  <div className="photo_row">
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Your Photo
                          </label>
                        </Typography>
                        <p style={{ color: 'gray', fontSize: '15px' }}>This photo will be displayed on you profile</p>
                      </Grid>
                      <Grid item md={3} xs={12} spacing={2} style={{ textAlign: 'center' }}>
                        {this.state.Profilepic ? <img src={this.state.Profilepic} style={{ width: '100px', height: '100px', borderRadius: '59%' }} /> : <img src={no_user} style={{ width: '100px', height: '100px', borderRadius: '59%' }} />}
                      </Grid>
                      <Grid item md={5} xs={12} spacing={2} style={{ marginTop: '15px' }}>
                        <Button
                          onClick={() => {
                            this.inputRef.current?.click()
                          }}
                          data-test-id="txtinputRef"
                          className="Uploadbutton btn-block" variant="contained" color="primary"
                          style={{ width: "100%", textTransform: 'inherit' }} >
                          Upload new
                        </Button>
                        <input type="file" data-test-id="txtinputfile_upload" ref={this.inputRef} style={{ display: 'none' }} onChange={(e: { target: { files: any } }) => {
                          this.setState({
                            chooseFile: e.target.files[0],
                            Profilepic: URL.createObjectURL(e.target.files[0])
                          });
                        }} />
                        <Button data-test-id="removeimgBtn" onClick={this.remove_profile_ph} className="btn-block"
                          style={{ width: "100%", textTransform: 'inherit', color: '#3f51b5', }}>
                          Delete Avatar
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                  <div className="rol_row">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Role
                          </label>
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={12} spacing={2}>
                        <TextField
                          name="user_role"
                          data-test-id="txtInputRole"
                          placeholder={"Role"}
                          fullWidth
                          variant="outlined"
                          value={this.state.userRole}
                          defaultValue={this.state.userRole}
                          disabled
                          style={{ textTransform: 'capitalize' }}
                          className="user_role_class"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                  <div className="bio_row">
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Bio
                          </label>
                        </Typography>
                        <p style={{ color: 'gray', fontSize: '15px' }}>
                          Write a short introduction
                        </p>
                      </Grid>
                      <Grid item md={8} xs={12} spacing={2}>
                        <TextAreaWithButtons
                          value={this.state.bio}
                          onChange={this.handleTextAreaChange}
                          data-test-id="txtInputbio"
                          maxLength={MAX_CHARACTERS}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {this.state.bio ? MAX_CHARACTERS - this.state.bio.length : MAX_CHARACTERS} characters remaining
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
                  <div className="deactivate_row">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item md={4} xs={12} spacing={2}>
                        <Typography className="text-field">
                          <label style={{ fontWeight: 'bold' }}>
                            Deactivate Account
                          </label>
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={12} spacing={2}>
                        <div>
                          <CustomSwitch
                            checked={this.state.deactivated}
                            onChange={this.handleSwitchChange}
                            color="primary"
                            data-test-id="txtInputSwitch"
                          />
                        </div>

                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '10px' }} />
                  <Grid container>
                    <Grid item sm={12} className="btn_row_save">
                      <div>
                        <Button type="button"
                          data-test-id="btnCancel"
                          color="primary"
                          className="customCancel_btn">Cancel</Button>
                        <Button
                          type="button"
                          data-test-id="btnSave"
                          color="primary"
                          className="customSave_btn"
                          onClick={this.handleSubmit_settings}>Save Changes</Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item sm={12} className="message_show_settings_update" style={{width:'100%'}}>
                      <div style={{ marginTop: '15px' }}>
                        <Collapse in={this.state.CollapseOpen}>
                          <Alert
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                data-test-id="txtInputMessagePassword"
                                onClick={this.CollapseOpenClose_fun}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            variant="filled"
                            severity={this.state.password_res_message === 'success' ? 'success' : "error"}

                          >
                            {this.state.password_response}
                          </Alert>
                        </Collapse>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </>
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={1}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            {this.activityLog()}
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={2}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            {this.executive_password_update()}
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={3}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            {this.notification_settings()}
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={4}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            {this.appearance_page('executive')}
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={5}
            navigation={undefined}
            id={""}
            classes={undefined}
          >
            <ApplicationsSection data-test-id="application_section" status={this.state.status} checkFilterData={this.state.filterData} changeStatus={this.statusHandler} searchHandler={this.searchHandler} currentPage={this.state.currentPage} jobTitleSearchHandler={this.jobTitleHandler} totalApplications={this.state.totalApplications} lastpage={this.state.lastPage} handleForward={this.handleForward} handleRev={this.handleReverse} applications={this.state.applications} />
          </TabPanel>
        </Grid>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (

      <>
        {this.state.userRole !== null && this.state.userRole !== "" ? (
          <ThemeProvider theme={theme}>
            <div className="root">
              <Grid container style={{ boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)" }}>
                <Grid item md={8} xs={6}>
                  <div>
                    <Link to="/dashboard">
                      <img
                        src={navebarLogo}
                        style={{
                          width: "205px",
                          height: "45px",
                          margin: "23px"
                        }}
                        className="settings_page_logo"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </Grid>
                <Grid item md={4} xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      margin: "20px"
                    }}
                  >
                    <Typography style={{ color: "#000", paddingRight: '10px', textAlign: 'end' }}>
                      {this.state.userRole === "company"
                        ? this.state.company_name
                        : this.state.first_name + " " + this.state.last_name}
                    </Typography>
                    <div style={{ marginRight: "15px" }}>
                      {this.state.Profilepic ? (
                        <img
                          src={this.state.Profilepic}
                          alt="user"
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            objectFit: "cover"
                          }}
                        />
                      ) : (
                        <img
                          src={no_user}
                          alt="user"
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            objectFit: "cover"
                          }}
                        />
                      )}
                    </div>

                  </div>
                </Grid>
                {this.state.userRole === "company" ? (
                  this.company_settings()
                ) : (
                  this.executive_settings()
                )}
              </Grid>
            </div>
          </ThemeProvider>
        ) : (
          <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
            <CircularProgress />
          </div>
        )}
      </>

    );
  }
}

// Customizable Area Start
// Customizable Area End

// Customizable Area End
