export const userProfile = require("../assets/user-profile.png");
export const navebarLogo = require("../assets/boardsi_logoM.png");
export const contact_us = require("../assets/image_contactus.png");
export const droparrow = require("../assets/image_droparrow.png");
export const logout = require("../assets/image_logout.png");
export const msgicon = require("../assets/image_msg.png");
export const myactivity = require("../assets/image_myactivity.png");
export const profile = require("../assets/image_profile.png");
export const notifications = require("../assets/image_notifications.png");
export const settings = require("../assets/image_settings.png");
export const view_user = require("../assets/view_user.png");


