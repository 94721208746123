import React from "react";
import {
  Typography,
  Button,
  MobileStepper,
  Grid,
  Container,
  useMediaQuery
} from "@material-ui/core";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  client1,
  clientimg01,
  clientimg02,
  logo1,
  logo2,
  logo3,
  logo4
} from "../../blocks/landingpage/src/assets";
import "./slider.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const color = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
      contrastText: "#fff",
    },
  },
});
const tutorialSteps = [
  {
    id: 1,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
    path: "https://boardsi.com/wp-content/uploads/2021/03/clipart1503317.png",
  },
  {
    id: 2,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/58428cc1a6515b1e0ad75ab1-2.png",
  },
  {
    id: 3,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
    path: "https://boardsi.com/wp-content/uploads/2021/03/clipart1503317.png",
  },

  {
    id: 4,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/MT_Bank_logo_logotype.png",
  },
  {
    id: 5,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/58428cc1a6515b1e0ad75ab1-2.png",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
  },
  img: {
    height: "200px",
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "200px",
    borderRadius: "50px",
    marginTop: '0px',
    [theme.breakpoints.down("xs")]: {
      borderRadius: "10px",
      maxWidth: "auto",
      width: "100px",
      height: "100px"
    }
  },
  headding: {
    margin: "50px auto",
    textAlign: "center",
    fontSize: "70px",
    fontWeight: 700,
    color: '#100F0D',
    fontFamily: 'poppins',
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px"
    }
  },

  mainContainer: {},
  container: {
    display: "flex",
    width: "600px",
    alignItems: "center",
    justifyContent: " center",
    borderRadius: " 25px",
    margin: "auto",
    padding: "5px 20px 5px 70px",
    [theme.breakpoints.down("sm")]: {
      width: "96vw",
      borderRadius: 0,
      overflow: "hidden"
    }
  },
  content: {
    margin: "0 30px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    }
  },
  clientImg: {
    width: "100px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "autoclientContent",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  clientImg2: {
    width: "175px",
    height: "175px",
    marginTop: '100px',
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  clientImg3: {
    width: "175px",
    height: "175px",
    marginTop: '100px',
    marginLeft: '50px'
  },
  clientContent: {
    width: "600px",
    display: "flex",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 auto !imporatnt",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }
  },
  subHeadding: {
    fontWeight: 600,
    margin: "6px 0",
    fontSize: '19px',
    [theme.breakpoints.down("xs")]: {
      fontSize: '16px'
    }
  },
}));

const ClientWeb = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;
  const isMatch = useMediaQuery(theme.breakpoints.down("xs"));

  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
    
  };

  return (
    <ThemeProvider theme={color}>
      <div  id="ClientWeb">
        <Typography className="hedding" variant="h4">
          Many Companies
          <br />
          trust in Boardsi
        </Typography>
        <Grid className="ClientWeb-slider">
          {!isMatch && <Grid className="slider-client-img">
            <img src={clientimg01} className="client_img" />
          </Grid>}
          <Grid className="slider_grid">
            <div className='client_container'>
              <div style={{
                maxWidth: '100%',
                flexGrow: 1,

              }}>
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {tutorialSteps.map((step, index) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <div className="client_content" style={{ backgroundImage: `url(${props?.backImg})`, padding: "0px 20px" }}>
                          <div className="img_box_cont">
                            <img
                              className="client_img2"
                              src={client1}
                              alt={step.label}
                            />
                          </div>
                          <div className="client_sub_head">
                            <img src={step.path} className="client_img3" />
                            <Typography
                              variant="h4"
                              color="primary"
                              className="para_head"
                            >
                              {step.label}
                            </Typography>
                            <Typography style={{ fontSize: isMatch ? '12px' : '15px' }}>
                              “Please do keep sending. All conversations I have had
                              so far are valuable, it opened up thoughts of new opportunities on both sides,
                              on both sides, what your company does is a great service making
                              a great service making the world a connected enterprise. Both Rick and Michael are great
                              Michael are great introductions for me, can’t wait to speak to them.”
                            </Typography>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
              </div>
            </div>
          </Grid>
          {!isMatch && <Grid className="client_img4-grid">
            <img src={clientimg02} className="client_img4" />
          </Grid>}
        </Grid>
        <div>
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="dots"
            className="review_slide"
            activeStep={activeStep}
            nextButton={<Button size="small" hidden />}
            backButton={<Button size="small" hidden />}
          />
        </div>
      </div>

      <Container style={{ textAlign: 'center', margin: '50px auto' }}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <img src={logo1} style={{ width: isMatch ? '100px' : '230px', height: isMatch ? 'auto' : '80px', marginRight: '40px' }} />
            <img src={logo2} style={{ width: isMatch ? '100px' : '230px', height: isMatch ? 'auto' : '80px', marginRight: '40px', }} />
            <img src={logo3} style={{ width: isMatch ? '100px' : '230px', height: isMatch ? 'auto' : '80px', marginRight: '40px' }} />
            <img src={logo4} style={{ width: isMatch ? '100px' : '230px', height: isMatch ? 'auto' : '80px', marginRight: '40px' }} />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default ClientWeb;
