import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { ChangeEvent, MouseEvent } from "react";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: React.ReactNode;
  index: any;
  value: any;
  classes: any;
  // Customizable Area End
}

interface InviteRow {
  invite_email: string;
  invite_permission: string;
  isValidEmail: boolean;
}

interface S {
  // Customizable Area Start
  value: number;
  userRole: string;
  first_name: string;
  last_name: string;
  email: string;
  bio: string;
  deactivated: boolean;
  Profilepic: string;
  BackgroundPic: string;

  chooseFile: any;
  imageURL: string;
  current_password: string;
  new_password: string;
  confirm_password: string;
  new_password_error: string;
  current_password_error: string;
  confirm_password_error: string;
  CollapseOpen: boolean;
  CollapseOpen_pass: boolean;
  password_response: string;
  password_res_message: string;
  enablePasswordField: boolean;
  enableconfirmPasswordField: boolean;
  enableCurrentPasswordField: boolean;
  getNotifiData: {}[];
  anchorEl: HTMLElement | null;
  filterData: any;
  company_name: string;
  company_website: string;
  linkedin_url: string;
  twitter_url: string;
  facebook_url: string;
  activityLogs: any[];
  inviteRows: InviteRow[];
  CollapseOpen_invite_team: boolean;
  deleteEditPopUp: boolean;
  invite_team_response: string;
  editDeleteStatus: string;
  invite_team_message: string;
  applications: any[];
  currentPage: number;
  lastPage: number;
  currentPageActivityLog: number;
  totalPagesActivatyLog: number;
  status: string;
  search: string;
  totalApplications: number;
  jobTitle: string
  activityLogSorting: string;
  currentPageNotification: number;
  totalPagesNotification: number;
  currentPageTeams: number;
  totalPagesTeams: number;
  viewApplication: boolean;
  applicantQuestions: {}[];
  executiveDetails: {}[];
  anchorEl_notification_menu: { [key: number]: HTMLElement | null };
  notification_menu_id: number;
  teamMembers: any;
  showEditPopup: boolean;
  editItem: any;
  selectedEditPermission: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  applicationDetailsCase: string = "";
  teamMembersListingCase: string = "";
  deleteTeamMemberCase: string = "";
  updateTeamMemberCase: string = "";
  CompanyProfileApiCallId: any;
  apiUploadFile: string = "";
  apiGetUpdatePasswordCallId: string = "";
  apiGetDatagetNotification: string = "";
  apiGetreadSingleNoficationCallId: string = "";
  apiGetreadAllNoficationCallId: string = "";
  apiGetActivityLog: string = "";
  apiGetInviteTeamCallId: string = "";
  apiGetApplicationsCallId: string = "";
  apiGetDeleteSingleNoficationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSubmit_settings = this.handleSubmit_settings.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.statusHandler = this.statusHandler.bind(this);
    this.state = {
      value: parseInt(window.location.pathname.split('/')[2]) || 0,
      userRole: "",
      first_name: "",
      last_name: "",
      email: "",
      bio: "",
      deactivated: false,
      Profilepic: "",
      BackgroundPic: "",
      filterData: [],
      chooseFile: {},
      imageURL: '',
      current_password: "",
      new_password: "",
      confirm_password: "",
      new_password_error: "",
      current_password_error: "",
      confirm_password_error: "",
      CollapseOpen: false,
      CollapseOpen_pass: false,
      password_response: "",
      password_res_message: "",
      enablePasswordField: true,
      enableconfirmPasswordField: true,
      enableCurrentPasswordField: true,
      getNotifiData: [],
      anchorEl: null,
      company_name: "",
      company_website: "",
      linkedin_url: "",
      twitter_url: "",
      facebook_url: "",
      activityLogs: [],
      inviteRows: [{ invite_email: "", invite_permission: "Read Only", isValidEmail: false }],
      CollapseOpen_invite_team: false,
      deleteEditPopUp: false,
      viewApplication: false,
      invite_team_response: "",
      editDeleteStatus: "",
      invite_team_message: "",
      applications: [],
      currentPage: 1,
      lastPage: 0,
      currentPageActivityLog: 1,
      totalPagesActivatyLog: 0,
      status: "",
      search: "",
      totalApplications: 0,
      activityLogSorting: "sort",
      jobTitle: "",
      currentPageNotification: 1,
      totalPagesNotification: 0,
      currentPageTeams: 1,
      totalPagesTeams: 0,
      applicantQuestions: [],
      executiveDetails: [],
      anchorEl_notification_menu: {},
      notification_menu_id: 0,
      teamMembers: {},
      showEditPopup: false,
      editItem: {},
      selectedEditPermission: ''
    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const handleNotificationResponse = () => {
      this.get_notification(this.state.currentPageNotification);
    };

    if (responseJson) {
      switch (apiRequestCallId) {
        case this.apiGetDataCallId:
          this.apiGetDataCallIdFunc(responseJson)
          break;

        case this.apiGetUpdatePasswordCallId:
          this.handle_password_responseJson(responseJson)
          break;

        case this.apiGetDatagetNotification:
          this.setState({ getNotifiData: responseJson?.data, totalPagesNotification: responseJson?.meta?.last_page });
          break;

        case this.apiGetreadSingleNoficationCallId:
          this.setState((prevState) => ({
            getNotifiData: prevState.getNotifiData.map((item: any) => {
              return item.id === responseJson.data.id ? responseJson.data : item;
            }),
          }));
          this.setState({ anchorEl_notification_menu: {} });
          break;

        case this.apiGetreadAllNoficationCallId:
          handleNotificationResponse();
          break;
        case this.CompanyProfileApiCallId:
          this.handleCompanyProfileResponse(responseJson.data ? true : false);
          break;
        case this.apiGetInviteTeamCallId:
          this.handleInviteTeamResponse(responseJson);
          break;
        case this.apiGetActivityLog:
          this.setState({ activityLogs: responseJson?.data, totalPagesActivatyLog: responseJson?.meta?.last_page })
          break;
        case this.apiGetDeleteSingleNoficationCallId:
          this.setState({ anchorEl_notification_menu: {} });
          this.get_notification(this.state.currentPageNotification);
          break;
        case this.applicationDetailsCase:
          this.setState({ applicantQuestions: responseJson })
          break;
        case this.apiGetApplicationsCallId: this.setState({ applications: responseJson.data, totalApplications: responseJson.meta.my_applications_count, lastPage: responseJson.meta.last_page });
        case this.teamMembersListingCase:
          this.setState({ teamMembers: responseJson?.data?.attributes, totalPagesTeams: responseJson?.data?.attributes?.team_members?.meta?.last_page })
          break;
        case this.deleteTeamMemberCase:
          this.setState({ editDeleteStatus: responseJson.message, deleteEditPopUp: true })
          setTimeout(() => {
            this.setState({ editDeleteStatus: '', deleteEditPopUp: false })
          }, 4000);
          this.teamMemberDetails(1)
          break;
        case this.updateTeamMemberCase:
          this.setState({ editDeleteStatus: responseJson.message, deleteEditPopUp: true })
          setTimeout(() => {
            this.setState({ editDeleteStatus: '', deleteEditPopUp: false })
          }, 4000);
          this.teamMemberDetails(this.state.currentPageTeams)
          break;
      }
    }
  }
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getMenuItems(false);
    this.get_notification(this.state.currentPageNotification);
    this.getAllPosts(this.state.currentPage, this.state.activityLogSorting);
    this.getApplications();
    this.teamMemberDetails(this.state.currentPageTeams)
  }

  handle_password_responseJson(responseJson: any) {
    if (responseJson.message) {
      this.setState({
        password_response: responseJson.message,
        password_res_message: configJSON.res_message_success,
        CollapseOpen_pass: true,
      });
    } else {
      this.setState({
        password_response: responseJson.error,
        password_res_message: configJSON.res_message_error,
        CollapseOpen_pass: true,
      });
    }
    setTimeout(() => {
      this.setState({ CollapseOpen_pass: false });
    }, 3000);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.currentPage !== this.state.currentPage || prevState.jobTitle !== this.state.jobTitle || prevState.filterData !== this.state.filterData || prevState.search !== this.state.search || prevState.status !== this.state.status) {
      this.getApplications();
    }
    if (prevState.activityLogSorting !== this.state.activityLogSorting) {
      this.getAllPosts(this.state.currentPage, this.state.activityLogSorting);
    }
  }

  jobTitleHandler = (e: { target: { value: string } }) => {
    this.setState({ jobTitle: e.target.value })
  }

  statusHandler = (item: any) => {
    this.setState((prevState: any) => {
      const isSelected = prevState.filterData.includes(item);
      const updatedFilters = isSelected
        ? prevState.filterData.filter((filter: any) => filter !== item)
        : [...prevState.filterData, item];
      return { filterData: updatedFilters };
    });
  }

  searchHandler = (e: { target: { value: string } }) => {
    this.setState({ search: e.target.value })
  }

  getApplications = () => {
    const token = localStorage.getItem("authToken") as string;
    const endpoint = (this.state.filterData.length > 0) ?
      configJSON.getApplicationsCallId + `?job_title=${this.state.jobTitle}&page=${this.state.currentPage}&per_page=5&status=${JSON.stringify(this.state.filterData)}&company_name=${this.state.search}`

      :
      configJSON.getApplicationsCallId + `?job_title=${this.state.jobTitle}&page=${this.state.currentPage}&per_page=5&company_name=${this.state.search}`

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetApplicationsCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleForward = () => {
    if (this.state.currentPage !== this.state.lastPage) {
      this.setState({ currentPage: this.state.currentPage + 1 })
    }
  }

  handleReverse = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 })
    }
  }

  handleCompanyProfileResponse = (success: boolean) => {
    const successResponse = success ? configJSON.Companyprofile_success : configJSON.Companyprofile_error;
    const successMessage = success ? configJSON.res_message_success : configJSON.res_message_error;

    this.setState({
      password_response: successResponse,
      password_res_message: successMessage,
      CollapseOpen: true,
    });
    setTimeout(() => {
      this.setState({ CollapseOpen: false });
    }, 3000);
  };

  handleInviteTeamResponse = (responseJson: any) => {

    let completeEmail = ""
    let resMsg = ''
    let completeMsg = ''
    responseJson?.errors?.map((item: any, index: any) => {
      const inputString = String(item.email)
      const spaceIndex = inputString.indexOf(' ');

      if (spaceIndex !== -1) {
        const email = inputString.substring(0, spaceIndex);
        const restOfMessage = inputString.substring(spaceIndex + 1);
        if (index !== 0) {
          completeEmail = completeEmail + "," + " " + email
        } else {
          completeEmail = email
        }
        resMsg = restOfMessage
      }
    })
    completeMsg = completeEmail + " " + resMsg
    const errorMessage = responseJson.errors && (responseJson.errors[0].email ? completeMsg : configJSON.invite_team_error)
    const inviteTeamResponse = responseJson.errors ? errorMessage : configJSON.invite_team_success;
    const inviteTeamMessage = responseJson.errors ? configJSON.res_message_error : configJSON.res_message_success;

    this.setState({
      invite_team_response: inviteTeamResponse,
      invite_team_message: inviteTeamMessage,
      CollapseOpen_invite_team: true,
      inviteRows: [{ invite_email: "", invite_permission: "Read Only", isValidEmail: false }]
    });
    this.teamMemberDetails(this.state.currentPageTeams)
    setTimeout(() => {
      this.setState({ CollapseOpen_invite_team: false })
    }, 4000);
  };






  // Customizable Area Start

  handleIconDeleteButtonClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickShowCurrentPassword = () => {
    this.setState({
      enableCurrentPasswordField: !this.state.enableCurrentPasswordField,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };


  handleClickShowConfirmPassword = () => {
    this.setState({
      enableconfirmPasswordField: !this.state.enableconfirmPasswordField,
    });
  };



  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };


  updateFirstname = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ first_name: event.target.value });
  };

  updateLastname = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ last_name: event.target.value });
  };

  handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ bio: event.target.value });
  };

  handleSwitchChange = () => {
    this.setState((prevState) => ({
      deactivated: !prevState.deactivated,
    }));
  };


  update_company_name = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ company_name: event.target.value });
  };

  update_company_website = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ company_website: event.target.value });
  };

  update_company_linked_link = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ linkedin_url: event.target.value });
  };

  update_company_twitter_link = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ twitter_url: event.target.value });
  };


  update_company_fb_link = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ facebook_url: event.target.value });
  };

  a11yProps = (index: any) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  remove_profile_ph = () => {
    this.setState({ Profilepic: "" });
  }

  CollapseOpenClose_fun = () => {
    this.setState({ CollapseOpen: false })
    this.setState({ CollapseOpen_invite_team: false });
    this.setState({ CollapseOpen_pass: false, deleteEditPopUp: false });
  }

  currentPassword = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ current_password: event.target.value })
  };

  newPassword = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ new_password: event.target.value })
  };

  confirmPassword = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ confirm_password: event.target.value })
  };

  getMenuItems = async (id: any) => {

    const token = localStorage.getItem("authToken") as string;
    const user_id = id ? id : localStorage.getItem("user_id") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profilebios + '/' + user_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  uploadFile = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      token: token
    };

    const data = new FormData;
    data.append("profile_image", this.state.chooseFile)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUploadFile = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadFileMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSubmit_settings = () => {

    this.uploadFile();

    let formData = new FormData();
    if (this.state.userRole === 'company') {
      formData.append('company_name', this.state.company_name)
      formData.append('linkedin_url', this.state.linkedin_url)
      formData.append('twitter_url', this.state.twitter_url)
      formData.append('facebook_url', String(this.state.facebook_url))
      formData.append('website', this.state.company_website)
    } else {
      formData.append('first_name', this.state.first_name)
      formData.append('last_name', this.state.last_name)
      formData.append('deactivated', String(this.state.deactivated))
    }
    formData.append('bio', this.state.bio)

    const header = {
      "token": localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CompanyProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  };

  handleUpdatePassword = () => {

    const { current_password, new_password, confirm_password } = this.state;
    const uppercaseRegex = /[A-Z]/;

    this.setState({ current_password_error: '' });
    this.setState({ new_password_error: '' });
    this.setState({ confirm_password_error: '' });

    if (current_password.length <= 0) {
      this.setState({ current_password_error: configJSON.current_error });
      return;
    } else if (new_password.length < 8) {
      this.setState({ new_password_error: configJSON.new_error });
      return;
    } else if (!uppercaseRegex.test(new_password)) {
      this.setState({ new_password_error: configJSON.new_error_r });
      return;
    } else if (confirm_password.length <= 0) {
      this.setState({ confirm_password_error: configJSON.current_error });
      return;
    }

    const passwordData = {
      password: {
        current_password: current_password,
        new_password: new_password,
        password_confirmation: confirm_password
      }
    };
    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const httpBody = passwordData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUpdatePasswordCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePaaswordAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadFileMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  handlePageChange_notification = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPageNotification: page }, () => {
      this.get_notification(this.state.currentPageNotification);
    });
  };

  handlePageChange_teams = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPageTeams: page }, () => {
      this.teamMemberDetails(this.state.currentPageTeams)
    });
  };

  handleClick_show_menuoption = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({
      anchorEl_notification_menu: {
        ...this.state.anchorEl_notification_menu,
        [id]: event.currentTarget,
      },
      notification_menu_id: id
    });
  };


  handleClose_show_menuoption = (id: number) => {
    this.setState({
      anchorEl_notification_menu: {
        ...this.state.anchorEl_notification_menu,
        [id]: null,
      }
      , notification_menu_id: 0
    });
  };

  get_notification = async (pageNo_notification: number) => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDatagetNotification = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLNotification + `?page=${pageNo_notification}&per_page=5`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  readSingleNofication = (notificationId: number) => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const httpBody = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetreadSingleNoficationCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLReadSingleNotification + "/" + notificationId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  delete_SingleNofication = (notificationId: number) => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const httpBody = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDeleteSingleNoficationCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLNotification + "/" + notificationId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  readAllNofication = () => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetreadAllNoficationCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLReadAllNotification
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handlePageChange_ActivityLog = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPageActivityLog: page }, () => {
      this.getAllPosts(this.state.currentPageActivityLog, this.state.activityLogSorting);
    });
  };

  handleChange_activityLogSorting = (eeee: any) => {
    this.setState({ activityLogSorting: eeee.target.value });
  }

  getAllPosts = (pageNo: number, activityLogSorting: string) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityLog = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLActivityLog + `?page=${pageNo}&per_page=5&sort_by=${activityLogSorting}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }


  handleAddAnother = () => {
    this.setState((prevState) => ({
      inviteRows: [...prevState.inviteRows, { invite_email: "", invite_permission: "Read Only", isValidEmail: false }],
    }));
  }

  removeEmail = (index: number) => {
    this.state.inviteRows.splice(index, 1);
    this.setState({ inviteRows: this.state.inviteRows })
  }

  handleChangeInviteEmail(index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = event.target;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/
    let isValid = emailRegex.test(value);

    this.setState((prevState) => {
      const inviteRows = [...prevState.inviteRows];
      inviteRows[index].invite_email = value;
      inviteRows[index].isValidEmail = isValid;
      return { inviteRows };
    })
  }

  handleChang_invite_permission(index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = event.target;
    this.setState((prevState) => {
      const inviteRows = [...prevState.inviteRows];
      inviteRows[index].invite_permission = value;
      return { inviteRows };
    });
  }

  handleChang_edit_permission(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = event.target;
    this.setState({ selectedEditPermission: value })

  }


  handleSubmit_company_invite_team = () => {

    let isValidEmail = true
    let isEmailEmpty = false

    this.state.inviteRows.forEach((item) => {
      if (item.isValidEmail === false) {
        isValidEmail = false
      }
      if (item.invite_email === "") {
        isEmailEmpty = true
      }
    })


    if (isValidEmail) {

      const httpBody = {
        data: {
          attributes: {
            team: this.state.inviteRows.map((row) => ({
              email: (row.invite_email).toLowerCase(),
              permission: row.invite_permission,
            })),
          },
        },
      };

      const token = localStorage.getItem("authToken") as string;

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiGetInviteTeamCallId = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.APIURL_team_invite
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.uploadFileMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {

      let errorMsg = isEmailEmpty ? 'Please enter a email address' : 'Invalid email , Please enter a valid email address.'
      this.setState({
        invite_team_response: errorMsg,
        invite_team_message: errorMsg,
        CollapseOpen_invite_team: true,
      });

      setTimeout(() => {
        this.setState({ CollapseOpen_invite_team: false })
      }, 4000);

    }

    return true
  }


  onViewMoreCLick = (data: any) => {
    if (data.attributes.headings === 'Nudge Reminder' || data.attributes.headings === "Applied for Job") {
      this.setState({ viewApplication: true })
      this.applicationDetails(data.attributes.app_url.job_application_id)
      this.getMenuItems(data.attributes.app_url.from);
      this.getMenuItems(data.attributes.app_url.from);

    }
  }


  applicationDetails = async (id: any) => {

    const token = localStorage.getItem("authToken") as string;
    const header = { "Content-Type": configJSON.jsonApiContentType, token: token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.applicationDetailsCase = requestMessage.messageId

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_joblisting2/jobs/application_details/${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  teamMemberDetails = async (pageNo_notification: number) => {

    const token = localStorage.getItem("authToken") as string;
    const header = { "Content-Type": configJSON.jsonApiContentType, token: token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teamMembersListingCase = requestMessage.messageId

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_followers/teams?page=${pageNo_notification}&per_page=5`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteTeamMember = async (id: number) => {

    const token = localStorage.getItem("authToken") as string;
    const header = { "Content-Type": configJSON.jsonApiContentType, token: token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTeamMemberCase = requestMessage.messageId

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_followers/teams/remove_member?team_member_id=${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'DELETE');

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateTeamMember = async (id: number) => {

    let data = {
      "data": {
        "attributes": {
          "permission": this.state.selectedEditPermission
        }
      }
    }

    const header = {
      "token": localStorage.getItem('authToken'),
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTeamMemberCase = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_followers/teams/update_permission?team_member_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteMember = (id: number) => {
    this.deleteTeamMember(id)
  }

  apiGetDataCallIdFunc = (responseJson: any) => {
    if (!this.state.viewApplication) {
      this.setState({
        first_name: responseJson?.data?.attributes?.first_name,
        last_name: responseJson?.data?.attributes?.last_name,
        email: responseJson?.data?.attributes?.email,
        userRole: responseJson?.data?.attributes?.user_type,
        Profilepic: responseJson?.data?.attributes?.profile_image,
        BackgroundPic: responseJson?.data?.attributes?.background_image,
        bio: responseJson?.data?.attributes?.bio,
        deactivated: responseJson?.data?.attributes?.deactivated,
        company_name: responseJson?.data?.attributes?.company_name,
        company_website: responseJson?.data?.attributes?.website,
        linkedin_url: responseJson?.data?.attributes?.linkedin_url,
        twitter_url: responseJson?.data?.attributes?.twitter_url,
        facebook_url: responseJson?.data?.attributes?.facebook_url,
      });
    } else {
      this.setState({ executiveDetails: responseJson?.data?.attributes })
    }

  }


  editPermission = (item: any) => {
    this.setState({ showEditPopup: true, editItem: item, selectedEditPermission: item?.attributes?.permission })
  }

  onEditApprove = () => {

    this.updateTeamMember(this.state.editItem.id)
    this.setState({ showEditPopup: false })
  }

  // Customizable Area End
}


