import React from "react";

// Customizable Area Start

import AddContactus from "../../contactus/src/ContactusWeb.web";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import ContactusController, { Props, configJSON } from "./ContactusController";

// Customizable Area End

export default class ContactusPage extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        <AddContactus navigation={undefined} id={""} />
        <FooterWeb />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
