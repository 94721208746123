import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
// Customizable Area Start
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import "./share.css"

const CardNumberStyled = styled(CardNumberElement)({
  height: "54px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  width: "100%",
  padding: "17px 10px",
  borderRadius: "4px",
  boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
  marginBottom: "13px",
  position: "relative",
  background: "unset !important",
})
const CardCvcStyled = styled(CardCvcElement)({
  height: "54px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  width: "100%",
  padding: "17px 10px",
  boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
  borderRadius: "4px",
  background:"unset !important"

})
const CardExpiryStyled = styled(CardExpiryElement)({
  height: "54px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
  width: "100%",
  padding: "17px 10px",
  borderRadius: "4px",
  background:"unset !important"

})
// Customizable Area End

export default function CheckoutForm({ secretKey ,navigation,article,amount}: any) {
  // Customizable Area Start

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("")
  const [zip, setZip] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cvv, setCVV] = useState("")
  const [expiry, setexpiry] = useState("")
  const [cardNumberCheck, setCardNumberCheck] = useState("")
  const [cvvCheck, setCVVCheck] = useState("")
  const [expiryCheck, setexpiryCheck] = useState("")
  const [errorMessage, setErrorMessage] = useState('');
  const [errorNameMessage, setNameErrorMessage] = useState('');
  const [errorCountryMessage, setCountryErrorMessage] = useState('');
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    try {
      setMessage("Payment processing. Please wait.");
      setTimeout(() => {
        setMessage("")
      }, 2000);
      const paymentPayload = {
        card: elements.getElement(CardNumberElement),

      };
      const { error } = await stripe.confirmCardPayment(secretKey, {
        //@ts-ignore
        payment_method: paymentPayload,
      });

      if (error) {
      } else {
        setName("")
        setZip("")
        setCountry("");
        elements.getElement(CardNumberElement)?.clear();
        elements.getElement(CardCvcElement)?.clear();
        elements.getElement(CardExpiryElement)?.clear();
        setMessage("")
        alert("Payment successful. Thank you!");
        setTimeout(() => {
          navigation.navigate("Dashboard")
        }, 2000);
      }
      setIsLoading(false);
    }
    catch (error) {
      setMessage("An error occurred while processing your payment.");
      setIsLoading(false);
    }
  };

  const handleFieldChange = (field: any, value: any, filled: any) => {
    setCardNumberCheck(field)
    setCardNumber(value)
  };
  const handleFieldExpiry = (field: any, value: any, filled: any) => {
    setexpiryCheck(field)
    setexpiry(value)
  };
  const handleFieldChangeCvv = (field: any, value: any, filled: any) => {
    setCVVCheck(field)
    setCVV(value)
  }
  const countries: any = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  const handleBlur = () => {
    console.log("[blur]");
  };
  const handleFocus = () => {
    console.log("[focus]");
  };
  const handleReady = () => {
    console.log("[ready]");
  };
  const createOptions = (fontSize: any, padding: any) => {
    return {
      style: {
        base: {
          fontSize,
          color: "black",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          },
          padding
        },
        invalid: {
          color: "#9e2146"
        }
      },
      showIcon:true,
    };

  };

  const handleCountryChange = (e: any) => {
    if (e.target.value === '') {
      setCountry('');
      setCountryErrorMessage('*country name required!');
    } else {
      setCountryErrorMessage(''); // Clear error message if validation passes
      setCountry(e.target.value);
    }
  };
  const handleZipChange = (e: any) => {
    const enteredZip = e.target.value.trim();
    const reg = /^[0-9]+$/;

    if (enteredZip === '') {
      setErrorMessage('*Zipcode required!');
    } else if (enteredZip.length < 5) {
      setErrorMessage('*Zipcode should be 5 digits');
    } else if (!reg.test(enteredZip)) {
      setErrorMessage('*Zipcode should be numbers only');
    } else {
      setErrorMessage(''); // Clear error message if validation passes
    }

    setZip(enteredZip);
  };

 const handleValidation=(e:any)=>{
if(
  errorCountryMessage !== "" || errorNameMessage !== "" || errorMessage !== "" || message === "Payment successful. Thank you!" || cardNumber === "" || cvv === "" || expiry === "" || name === "" || country == "" || zip == "" || cvvCheck == "" || cardNumberCheck == "" || expiryCheck == ""
){
  setMessage(" Please fill all the fields")
  setTimeout(() => {
    setMessage("")
  }, 2000);
}
else{
  handleSubmit(e)
}
  }

  // Customizable Area End


  return (
    // Customizable Area Start

    // <form id="payment-form" onSubmit={handleSubmit}>
    <>
      {message && <Box>
        <Typography style={{ fontSize: "20px", color: "green", fontWeight: 600, marginTop: "20px", textAlign: "center" }}>
          {message}
        </Typography>
      </Box>}
      <Grid container className="mainBoxStyle">
        <Grid item xs={12} md={6} sm={6} className="mainGrid">
          <Typography style={{ fontSize: "20px", color: "#536fc2", fontWeight: 500, marginBottom: "15px", marginTop: "50px" }}>
            Add Card Details
          </Typography>
          <Typography style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
            Full Name
          </Typography>
          <TextField id="outlined-basic" inputProps={{
            autoComplete: 'off',
          }} className="textFieldstyle"  onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setName('');
              setNameErrorMessage('*Full name required!');
            }
            else if (/^[A-Za-z\s]*$/.test(inputValue) && /^(?!.* {2,}).*$/.test(inputValue) && inputValue[0] !==" "?inputValue[0]==inputValue[0].toUpperCase():"") {
              setName(inputValue);
              setNameErrorMessage(''); // Clear error message if validation passes
            }
            else {
              setNameErrorMessage('*Invalid name format');
            }
          }} value={name} placeholder="Full Name" variant="outlined" style={{ width: "100%",marginBottom:errorNameMessage !==''?"0px":"15px", boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }} />
          {errorNameMessage && <div style={{ color: 'red', marginBottom: "15px", }}>{errorNameMessage}</div>}
          <Box className="cardResponsivestyle">
            <Box className="gridStyle">

              <Typography style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
                Country
              </Typography>

              <FormControl variant="outlined" style={{ width: "100%" }} >

                <Select
                className="selectDrodown"
                IconComponent={ExpandMoreIcon}
                  style={{ width: "100%", height: "54px" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={country}
                  onChange={(e: any) => handleCountryChange(e)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    countries.map((item: any, index: any) => {

                      return (
                        <MenuItem key={index} value={item} >
                          {item}
                        </MenuItem>
                      );
                    })
                  }

                </Select>
              </FormControl>
              {errorCountryMessage && <div style={{ color: 'red' }}>{errorCountryMessage}</div>}
            </Box>
            <Box style={{ width: "100%" }} >

              <Typography className="textFieldstyle" style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
                ZIP
              </Typography>
              <TextField inputProps={{
                autoComplete: 'off', // This disables autocomplete
              }} onChange={(e) => handleZipChange(e)} value={zip} className="textFieldstyle" id="outlined-basic" placeholder="ZIP" variant="outlined" style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)", width: "100%" }} />
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </Box>

          </Box>

          <Typography style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
            Card Number
          </Typography>
          <CardNumberStyled
            onBlur={handleBlur}
            onChange={(event) =>
              handleFieldChange("cardNumber", event.complete ? "filled" : "", event.error ? "" : "filled")
            }
            onFocus={handleFocus}
            onReady={handleReady}
            options={createOptions("15px", "20px")}
          />
          <Box className="cardResponsivestyle">
            <Box className="gridStyle">

              <Typography style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
                Expiry
              </Typography>
              <CardExpiryStyled
                onBlur={handleBlur}
                onChange={(event) =>
                  handleFieldExpiry("expiry", event.complete ? "filled" : "", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={createOptions("15px", "20px")}
              />
            </Box>
            <Box style={{ width: "100%" }}>

              <Typography style={{ fontSize: "18px", color: "#6c6767", fontWeight: 500 }}>
                CVV
              </Typography>
              <CardCvcStyled
                onBlur={handleBlur}
                onChange={(event: any) =>
                  handleFieldChangeCvv("cvv", event.complete ? "filled" : "", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={createOptions("15px", "20px")}
                className="gridStyle"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sm={6} className="rightGridStyle">
          <Typography style={{ fontSize: "20px", color: "#536fc2", fontWeight: 500, marginBottom: "5px", marginTop: "50px" }}>
            Your Plan
          </Typography>
          <Box style={{ border: "2px solid #536fc2", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRadius: "6px", flexWrap: "wrap" }}>
            <Typography style={{ fontSize: "30px", color: "#536fc2", fontWeight: 600, marginBottom: "40px", marginTop: "50px" }}>
              Premium
            </Typography>
            <Box className="oneTimeplanStyle">
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>

                {amount}${" "}
              </span>
              One time setup fee
            </Box>
            <Typography className="textStyle">
              {article}
            </Typography>

            <Button id="submit"onClick={(e)=>{handleValidation(e)}} className="buttonStyle" >
              Subscribe
            </Button>
          </Box>
        </Grid>
      </Grid>
     
    </>
    
    // Customizable Area End

  );
}
