import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { CustomAccFormControl, CustomAccSelect } from "./CustomStyle.web";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  select: {
    fontSize: "40px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#100F0D",
    fontSize: "40px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      paddingTop: 8,
      paddingBottom: 8,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "33px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#5370C2",
    },
    "& li.Mui-selected:hover": {
      background: "#5370C2",
    },
  },
}));

const DropDown = ({
  name,
  value,
  onChange,
  items,
  InputProps,
  props,
  multiple,
  placeholder,
  IconComponent,
  error,
  helperText,
}: {
  name: string;
  value: any;
  onChange(e: any): void;
  items: any[];
  InputProps?: any;
  props?: any;
  multiple?: boolean;
  placeholder?: string;
  IconComponent?: any;
  error?: any;
  helperText?: any;
}) => {
  const classes = useStyles();
  const menuProps: Partial<MenuPropsType> = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  const icon_dropdown = () => {
    console.log('Button clicked');
  };

  return (
    <>
      <CustomAccFormControl variant="outlined" fullWidth>
        <CustomAccSelect
          placeholder={!placeholder ? "" : placeholder}
          name={name}
          error={error}
          // helperText={helperText}
          value={value}
          onChange={onChange}
          disableUnderline
          IconComponent={ExpandMoreIcon}
          MenuProps={menuProps}
          classes={{
            select: classes.select,
            //icon: classes.selectIcon,
          }}
          multiple={multiple}
        >
          {placeholder && (
            <MenuItem value="0" disabled>
              {placeholder}
            </MenuItem>
          )}

          {items.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.value}
            </MenuItem>
          ))}
        </CustomAccSelect>
      </CustomAccFormControl>
      {helperText && <Typography style={{ fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex", alignItems: "center" }}> {helperText}</Typography>
      }
    </>
  );
};

export default DropDown;
