Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.profilebios = "bx_block_profile_bio/profile_bios"
exports.dashboards="bx_block_dashboard/dashboards"
exports.activity_logs="bx_block_activitylog2/activity_logs"
exports.job_applied_executive="bx_block_joblisting2/jobs/my_applications"
exports.recent_applicants="bx_block_joblisting2/jobs/recent_applicants"
// Customizable Area End