import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WorkExperienceController from "./WorkExperienceController.web";
import CloseIcon from "@material-ui/icons/Close";
import "./accountCreation.css";

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#4e5da7",
      contrastText: "#fff",
    },
  },
});

export default class WorkExperience extends WorkExperienceController {
  constructor(props: Props) {
    super(props);
  }

  renderDeleteDialog() {
    const { deleteId } = this.state;
    return (
      <>
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              No
            </Button>
            <Button
              data-test-id="btnYes"
              onClick={() => {
                this.delete(deleteId);
                this.handleClose();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  renderAlert() {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {this.state.deleteMessage}
      </Alert>
    );
  }

  renderEditDialog() {
    return (
      <>
        <Dialog open={this.state.openEditDialog} onClose={this.handleEditClose}>
          <div className="container">
            <DialogTitle>Edit Work Experience</DialogTitle>
            <CloseIcon onClick={this.handleEditClose} />
          </div>
          <DialogContent>
            <Grid item xs={8} sm={8}>
              <TextField
                style={webStyle.textfield}
                name="position"
                data-test-id="txtInputEditPosition"
                placeholder={"Position"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.position}
                onChange={(e) => this.onHandleEditChange(e)}
              />
              <TextField
                style={webStyle.textfield}
                name="company"
                data-test-id="txtInputCompanyEdit"
                placeholder={"Company"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.company}
                onChange={(e) => this.onHandleEditChange(e)}
              />
              <TextField
                style={webStyle.textfield}
                name="industry"
                data-test-id="txtInputIndustryEdit"
                placeholder={"Industry"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.industry}
                onChange={(e) => this.onHandleEditChange(e)}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={webStyle.textfield}
                  name="from"
                  data-test-id="txtInputFromEdit"
                  placeholder={"From"}
                  type="date"
                  fullWidth={true}
                  variant="outlined"
                  value={moment(this.state.editFormData.from).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => this.onHandleEditChange(e)}
                />
                <TextField
                  style={webStyle.textfield}
                  name="to"
                  data-test-id="txtInputToEdit"
                  placeholder={"To"}
                  type="date"
                  fullWidth={true}
                  variant="outlined"
                  value={moment(this.state.editFormData.to).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => this.onHandleEditChange(e)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  data-test-id="currentlyWorkingEdit"
                  color="primary"
                  onClick={() =>
                    this.setCurrentlyEditWorking(
                      !this.state.currently_work_here
                    )
                  }
                  checked={this.state.currently_work_here}
                />
                <span className="label-wrap">Currently working here</span>
              </Box>
              <TextField
                style={webStyle.textfield}
                name="location"
                data-test-id="txtInputLocationEdit"
                placeholder={"Location"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.location}
                onChange={(e) => this.onHandleEditChange(e)}
              />

              <div>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <TextField
                    name="skills"
                    data-test-id="txtInputSkillsTextEdit"
                    placeholder={"Add Skills"}
                    variant="outlined"
                    fullWidth={true}
                    value={this.state.skills}
                    onChange={(e) => this.setSkills(e.target.value)}
                  />
                  <Button
                    style={webStyle.btnAddSkills}
                    data-test-id="btnAddSkillsEdit"
                    variant="contained"
                    color="primary"
                    onClick={this.onChangeEditHandler}
                  >
                    Add
                  </Button>
                </Box>
                <Box>
                  {this.state.editSkills.map((item: any) => (
                    <div style={webStyle.addSkills} key={item.id}>
                      <TextField
                        name="skills"
                        data-test-id="txtInputSkillsText"
                        placeholder={"Skills"}
                        variant="outlined"
                        value={item.name}
                      />
                      <Button onClick={() => this.removeSkills(item.id)}>
                        x
                      </Button>
                    </div>
                  ))}
                </Box>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              data-test-id="btnEditWokExperience"
              onClick={() => {
                this.updateWorkData(this.state.editId);
                this.handleEditClose();
              }}
              color="primary"
              autoFocus
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  render() {
    const { workExperience, deleteMessage } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.mainContainer}>
          <Box style={webStyle.accountBox}>
            <Typography variant="h3" color="primary" className="headding">
              Work Experience
            </Typography>
            {this.renderDeleteDialog()}
            {deleteMessage ? this.renderAlert() : ""}
            {this.renderEditDialog()}
            <Grid container spacing={2}>
              {workExperience.map((data: any) => {
                const { id, attributes } = data;
                return (
                  <Grid item xs={4} sm={4} key={id}>
                    <div className="content">
                      <div className="typography">
                        <Typography>Position:&nbsp;</Typography>
                        <Typography>{attributes.position}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>Company:&nbsp;</Typography>
                        <Typography>{attributes.company}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>Industry:&nbsp;</Typography>
                        <Typography>{attributes.industry}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>From:&nbsp;</Typography>
                        <Typography>
                          {attributes.from}&nbsp;
                          {attributes.to}
                        </Typography>
                      </div>
                      <div className="typography">
                        <Typography>Location:&nbsp;</Typography>
                        <Typography>{attributes.location}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>Skills:&nbsp;</Typography>
                        <Typography>
                          {attributes.skills.map((item: any) => (
                            <span key={item.id}>&nbsp;{item.name}</span>
                          ))}
                        </Typography>
                      </div>
                      <Box className="buttons">
                        <Button
                          style={webStyle.cancleBtn}
                          data-test-id="btnDelete"
                          variant="contained"
                          onClick={() => {
                            this.deleteRecord(id);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          style={webStyle.nextBtn}
                          data-test-id="btnEdit"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.editNavigation(id, workExperience);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={3} style={webStyle.container}>
              <Grid item xs={4} sm={4}>
                <ul className="ulList">
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnBiography"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/BoardBiography"}
                    >
                      Board Biography
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnWorkExperience"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/WorkExperience"}
                    >
                      Work expierence
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnEducation"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/Education"}
                    >
                      Education
                    </Button>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div>
                  <TextField
                    style={webStyle.textfield}
                    name="position"
                    data-test-id="txtInputPosition"
                    placeholder={"Position"}
                    fullWidth={true}
                    variant="outlined"
                    required
                    value={this.state.formData.position}
                    onChange={(e) => this.onHandleChange(e)}
                  />
                  <TextField
                    style={webStyle.textfield}
                    name="company"
                    data-test-id="txtInputCompany"
                    placeholder={"Company"}
                    fullWidth={true}
                    variant="outlined"
                    required
                    value={this.state.formData.company}
                    onChange={(e) => this.onHandleChange(e)}
                  />
                  <TextField
                    style={webStyle.textfield}
                    name="industry"
                    data-test-id="txtInputIndustry"
                    placeholder={"Industry"}
                    fullWidth={true}
                    variant="outlined"
                    required
                    value={this.state.formData.industry}
                    onChange={(e) => this.onHandleChange(e)}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      style={webStyle.textfield}
                      name="startDate"
                      data-test-id="txtInputFrom"
                      placeholder={"From"}
                      type="date"
                      fullWidth={true}
                      defaultValue={new Date()}
                      variant="outlined"
                      required
                      value={this.state.formData.startDate}
                      onChange={(e) => this.onHandleChange(e)}
                    />
                    <TextField
                      style={webStyle.textfield}
                      name="endDate"
                      data-test-id="txtInputTo"
                      placeholder={"To"}
                      type="date"
                      fullWidth={true}
                      defaultValue={new Date()}
                      variant="outlined"
                      required
                      value={this.state.formData.endDate}
                      onChange={(e) => this.onHandleChange(e)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      data-test-id="currentlyWorking"
                      color="primary"
                      onClick={() =>
                        this.setCurrentlyWorking(!this.state.checkedWorking)
                      }
                      checked={this.state.checkedWorking}
                    />
                    <span className="label-wrap">Currently working here</span>
                  </Box>
                  <TextField
                    style={webStyle.textfield}
                    name="location"
                    data-test-id="txtInputLocation"
                    placeholder={"Location"}
                    fullWidth={true}
                    variant="outlined"
                    value={this.state.formData.location}
                    onChange={(e) => this.onHandleChange(e)}
                  />

                  <div>
                    <Box sx={{ display: "flex", marginBottom: "20px" }}>
                      <TextField
                        name="skills"
                        data-test-id="txtInputSkills"
                        placeholder={"Add Skills"}
                        variant="outlined"
                        required
                        fullWidth={true}
                        value={this.state.skills}
                        onChange={(e) => this.setSkills(e.target.value)}
                      />
                      <Button
                        style={webStyle.btnAddSkills}
                        data-test-id="btnAddSkills"
                        variant="contained"
                        color="primary"
                        onClick={this.onChangeHandler}
                      >
                        Add
                      </Button>
                    </Box>
                    <Box>
                      {this.state.addSkills.map((item, index) => (
                        <div style={webStyle.addSkills} key={item.id}>
                          <TextField
                            name="skills"
                            data-test-id="txtInputSkills"
                            placeholder={"Skills"}
                            variant="outlined"
                            value={item.name}
                          />
                          <Button
                            data-test-id="onDeleteSkills"
                            onClick={() => this.onDeleteSkills(index)}
                          >
                            x
                          </Button>
                        </div>
                      ))}
                    </Box>
                  </div>
                </div>
                <Box
                  sx={{
                    padding: "25px 0px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    style={webStyle.nextBtn}
                    data-test-id="btnNext"
                    variant="contained"
                    color="primary"
                    onClick={() => this.AddWorkExperience()}
                  >
                    Save
                  </Button>
                  <Box
                    sx={{
                      padding: "25px 0px",
                      textAlign: "end",
                    }}
                  >
                    <Button
                      style={webStyle.nextBtn}
                      data-test-id="btnNavigation"
                      variant="contained"
                      color="primary"
                      onClick={() => (window.location.href = "/Education")}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
  },
  btn: {
    background: "#536fc2",
    marginBottom: "15px",
    width: "180px",
  },
  nextBtn: {
    background: "#536fc2",
  },
  accountBox: {
    backgroundColor: "#ffff",
    margin: "80px 0",
    padding: "30px",
    width: "80%",
    borderRadius: "20px",
    boxShadow: "0 0px 10px 6px rgb(0 0 0 / 26%)",
  },
  container: {
    marginTop: "20px",
  },
  backgroundImg: {
    width: "100%",
    border: "1px solid gray",
    height: "150px",
    marginBottom: "70px",
    borderRadius: "4px",
  },
  backgroundImgUpload: {
    display: "inline-block",
    padding: "12px 12px",
    cursor: "pointer",
    background: "#536fc2",
  },
  profileImg: {
    width: "150px",
    height: "150px",
    borderRadius: "100px",
  },
  textfield: {
    marginBottom: "20px",
    marginLeft: "10px",
  },
  btnAddSkills: {
    background: "#536fc2",
    marginLeft: "10px",
    width: "30%",
  },
  addSkills: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "10px",
  },
  cancleBtn: {
    background: "#ef5151",
    color: "#fff",
    margin: "6px",
  },
};
// Customizable Area End
