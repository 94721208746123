import styled from "@emotion/styled";
import {
  Checkbox,
  Select,
  FormControl,
  TextField,
  AppBar,
} from "@material-ui/core";
export const CustomTextField = styled(TextField)({
  '& input:-internal-autofill-selected': {
    background: 'unset',
  },
  "& input": {
    "&::placeholder": {
      opacity: 1,
      color: '#ADADAD',
    },
  },
  "& .MuiInputBase-input": {
    color: "#100F0D !important",
    paddingTop: "19px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    background: "unset !important",
    lineHeight: "32px",
  },
 
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2 ",
      borderRadius: "8px",
    },
  },
  "& .MuiFormHelperText-root": {
    "&:hover fieldset": {
      borderRadius: "8px",
    },
    "&.Mui-error": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fonwteight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#CD4C39",
    },
  },
});
export const CustomFormControl = styled(FormControl)({
  "& input": {
    top: "0",
    bottom: "0",
    border: "none",
    opacity: "1",
    padding: "15px",
    paddingTop: "19px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
    width: "90%",
  },
  "& input::placeholder": {
    color: "100F0D !important",
  },

  "& .MuiInputBase-input": {
    color: "#100F0D !important",
    paddingTop: "19px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2 ",
      borderRadius: "8px",
    },
  },
  "& .MuiFormHelperText-root": {
    "&:hover fieldset": {
      borderRadius: "8px",
    },
    "&.Mui-error": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fonwteight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#CD4C39",
    },
  },
});

export const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2 ",
      borderRadius: "8px",
    },
  },
  "& input": {
    top: "0",
    bottom: "0",
    border: "none",
    opacity: "1",
    padding: "15px",
    paddingTop: "19px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "21px",
    lineHeight: "32px",
    width: "85%",
  },
  "& input::placeholder": {
    color: "#a0a0a0 !important",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0,0,0,0.4)",
  },
  "@media(max-width:576px)": {
    "& input": {
      width: "90%",
    },
  },
  "& .MuiSelect-root": {
    borderRadius: "8px",
    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2 ",
      borderRadius: "8px",
    },
  },
});

export const CustomCheckBox = styled(Checkbox)({
  color: "#536fc2",
  "&.Mui-checked": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(164, 164, 164, 0.48)",
    width: "2rem",
    height: "2rem",
  },
  "&.Mui-checked .MuiSvgIcon-root, & input:focus~.MuiSvgIcon-root": {
    color: "#536fc2",
  },
});
export const CustomAppBar = styled(AppBar)({
  "&.MuiAppBar-colorPrimary": {
    backgroundColor: "transparent",
  },
  "&.MuiPaper-elevation4": {
    boxShadow: "none",
  },
  "& .MuiToolbar-regular": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "50px",
  },
});
export const CustomAccTextField = styled(TextField)({
  "& input": {
    "&::placeholder": {
      opacity: 1,
      color: "grey !important",
    },
  },
  "&.MuiFormControl-fullWidth": {
    // marginBottom: "25px",
  },
  "& .MuiInputBase-input": {
    "&::placeholder":{
      color: 'Grey',
    },
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "33px",
    height: "40px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",

    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#5370C2 ",
  },
  "& .MuiFormHelperText-root": {
    display:"flex",
    alignItems:"Center",
    "&:hover fieldset": {
      borderRadius: "8px",
    },
    "&.Mui-error": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fonwteight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#CD4C39",
    },
    "::placeholder": {
      color: "red"
    }
  },
});
export const CustomAccFormControl = styled(FormControl)({
  "& input": {
    "&::placeholder": {
      opacity: 1,
    },
  },
  "&.MuiFormControl-fullWidth": {
    // marginBottom: "25px",
  },
  "& .MuiInputBase-input": {
    color: "#100F0D !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "33px",
    height: "40px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",

    "&:hover fieldset": {
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "#5370C2",
      borderRadius: "8px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#5370C2 ",
  },
  "& .MuiFormHelperText-root": {
    "&:hover fieldset": {
      borderRadius: "8px",
    },
    "&.Mui-error": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fonwteight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#CD4C39",
    },
  },
});

export const CustomAccSelect = styled(Select)({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
 
});
