export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const navebarLogo = require("../assets/boardsi_logoM.png");
export const profilepic = require("../assets/view_profile.png");
export const no_user = require("../assets/no_user.png");

export const AccSuccess = require("../assets/view_tick.png");
export const view_Rectangle = require("../assets/view_Rectangle.jpg");
export const view_profile = require("../assets/view_profile.png");
export const Eyeopen = require("../assets/Eyeopen.png");
export const EyeSlash = require("../assets/EyeSlash.png");