export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const view_Rectangle = require("../assets/view_Rectangle.png");
export const view_profile = require("../assets/view_profile.png");
export const image_View_edit = require("../assets/image_View_edit.png");
export const worklogo1 = require("../assets/worklogo1.png");
export const worklogo2 = require("../assets/worklogo2.png");
export const view_com_profile = require("../assets/view_com_profile.png");
export const view_comp_Rectangle = require("../assets/view_comp_Rectangle.png");
export const add_icon_img = require("../assets/add_icon_img.png");
export const edit_icon_img = require("../assets/edit_icon_img.png");
export const image_Building = require("../assets/image_Building.png");
export const image_Globe = require("../assets/image_Globe.png");
export const image_Marker = require("../assets/image_Marker.png");
export const CalenderImg = require("../assets/NOUN.png");
export const image_Phone_call = require("../assets/image_Phone-call.png");
export const image_Users = require("../assets/image_Users.png");
export const deleteIcon = require("../assets/deletee.png");
export const DefaultBackground = require("../assets/Default_background.jpg");
