import React, { useState } from "react";
// Customizable Area Start
import {
   Typography,
   Box,
   Button,
   Grid,
   Collapse,
   IconButton,
   Checkbox
} from "@material-ui/core";
import{no_user} from "../../blocks/Settings5/src/assets"
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import "./share.css"
import { Cross, facebook, insta, linked, twitter } from "../../blocks/JobListing2/src/assets";
export const ViewApplication = (props: any) => {
   const [recentAppIndex, setRecentAppIndex] = useState(0);
   const [appStatus, setStatus] = useState('');
   const {  closeModal ,executiveDetails,applicantQuestions,onApplicationCLick,selectedExecutiveId,recentApplicants,isDashboard} = props;
   const isMobile = window.matchMedia('(max-width:1024px)').matches;

   React.useEffect(() => {
    if(recentApplicants){
        recentApplicants.map((item: any, index: any) => {
            if (Number(item.id) === Number(selectedExecutiveId)) {
                setRecentAppIndex(index)
            }
        })
    }

       if (applicantQuestions && applicantQuestions?.data && applicantQuestions?.data?.attributes?.status) {
           setStatus(applicantQuestions?.data?.attributes?.status)
}

       return () => {
           setStatus('')
       }
   }, [applicantQuestions]);


   const isMultiCheck=(item:any,description:any)=>{
       let isExist = false
       item?.attributes?.answers?.map((item: any, ind: any) => {
           if (item.includes(description)) {
               isExist = true
           }
       })
       return isExist
   }

    return <>
        <Box>
            <Box className="cross_icon_share_popup" style={{ textAlign: 'right', position: 'absolute', right: '36px', top: '26px' }}>
                <img src={Cross} onClick={closeModal} className="right_arrow_new" style={{ width: '17px',cursor:'pointer' }} /></Box>

            <div className="modalWrapper">


                <div className="modalLeftContent">
                    <h2 className='colorWhite mobileHeading'>Contact:</h2>
                    <p  className="mobileContent colorWhite smallFont">{executiveDetails?.email}</p>
                    <br />
                    <br />

                    <h2 className='colorWhite mobileHeading'>Certification:</h2>
                  
                    {
                        executiveDetails?.certificates?.data?.map((item: any, index: any) => {
                            return (
                                <div className={executiveDetails.certificates.data.length-1===index?"noBorder": "certificates"}>
                                    <h3 className="colorWhite font16 ">{item?.attributes?.name}</h3>
                                    <p className='colorWhite smallFont mobileContent'>{item?.attributes?.issuing_organization}</p>
                                    <p className='colorWhite smallFont mobileContent'>Issued {item?.attributes?.issue_date} {item?.attributes?.issue_year}</p>
                                    <p className='colorWhite smallFont mobileContent'>{item?.attributes?.location}</p>
                                </div>
                            )
                        })
                    }
                    <br />
                    <br />

                    <h2 className='colorWhite mobileHeading'>Matching Skills:</h2>
                    {executiveDetails?.skills?.map((item: any, index: any) => {
                        return (
                            <p className="mobileContent colorWhite smallFont">{item}</p>
                        )
                    })
                    }
                </div>

                <div className="modalRightContent">
                    <div className="modalRightContentTwo">

                        <div style={{ display: 'flex' }}>
                            <Box>
                                <img src={executiveDetails?.profile_image?executiveDetails.profile_image: no_user} className="profileImg" />
                            </Box>
                            <Box style={{ marginLeft: '15px', marginTop: '10px' }}>
                                <p style={{ fontWeight: 'bold' }} className="mobileFont12">{executiveDetails?.first_name} {executiveDetails?.last_name}</p>
                                <p className="font12">{executiveDetails?.bio}</p>
                            </Box>
                        </div>

                        <br />
                        <br />

                        <p style={{ fontWeight: 'bold' }} className="font17">Work Experience</p>
                        <div className="workExp">
                            {executiveDetails?.work_experiences?.data.map((item:any, index:any) => {
                                return (
                                    <div  className={executiveDetails.work_experiences.data.length-1===index? 'noBorder':'newBox'}>
                                            <h2 className="fontLarge">{item?.attributes?.title}</h2>
                                            <p style={{ marginTop: '-3px' }} className="font14">{item?.attributes?.company_name} - {item?.attributes?.employment_type?.employment_type_name}</p>
                                            <p style={{  color: '#8F8F8F' }} className="font11">{item?.attributes?.start_date} {item?.attributes?.start_year} - {item?.attributes?.end_date} {item?.attributes?.end_year} {index===0?'- Present':(item.attributes.total_years>0?" - " +item.attributes.total_years + ' years' :'')}</p>
                                            <p style={{  color: '#8F8F8F' }} className="font11">{item?.attributes?.location}</p>
                                            <p style={{  color: '#8F8F8F' }} className="font11">Skills : {item?.attributes?.skill_set}</p>
                                    </div>
                                )
                            })
                            }
                            <br />
                        </div>

                        <br />
               
                        <p style={{ fontWeight: 'bold'}} className="font17">Education</p>

                        <div className="education">
                            {executiveDetails?.educations?.data.map((item: any, index: any) => {
                                return (
                                    <div className={executiveDetails.educations.data.length-1===index? 'noBorder':'newBox'}>
                                        <h2 className="fontLarge">{item?.attributes?.school_name}</h2>
                                        <p style={{  marginTop: '-3px' }}className="font14">{item?.attributes?.degree?.degree_name} - {item?.attributes?.grade} Grade</p>
                                        <p style={{  color: '#8F8F8F' }} className="font11">{item?.attributes?.start_date} {item?.attributes?.start_year} - {item?.attributes?.end_date} {item?.attributes?.end_year}</p>
                                        <p style={{  color: '#8F8F8F' }} className="font11">{item?.attributes?.location}</p>
                                        <p style={{  color: '#8F8F8F' }} className="font11">{item?.attributes?.skill_set}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>


                    <div className="question">
                        <p className='colorWhite assesmentSeparation'> Assesment Questions</p>
                    </div>


                    <div className='belowQuestion' >

                        {applicantQuestions?.data?.attributes?.questions?.data.map((item: any, index: any) => {
                            return (
                                <div className="assesmentQuestions">
                                    {item.attributes.selection_type === "Single Selection" && <Box>
                                        <h4 className="font16"> {index+1}. {item.attributes.description}</h4>
                                        {item?.attributes?.options?.map((ele: any, ind: any) => {
                                            return (
                                                <Box className="singleSelection" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                    <Checkbox
                                                        edge="start"
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        disableRipple
                                                        disabled={true}
                                                        checked={item.attributes.answers.description===ele.description}
                                                        checkedIcon={<RadioButtonCheckedIcon fontSize={isMobile? "medium":'large'} />}
                                                        icon={<RadioButtonUncheckedIcon fontSize={isMobile? "medium":'large'} />}
                                                        color="primary"
                                                    />
                                                    <label>{ele.description}</label>
                                                </Box>
                                            )
                                        })
                                        }
                                    </Box>}

                                    {item.attributes.selection_type === "Multi Selection" && <Box>
                                        <h4 className="font16"> {index+1}. {item.attributes.description}</h4>
                                        {item?.attributes?.options?.map((ele: any, ind: any) => {
                                            return (
                                                <Box className="singleSelection" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                    <Checkbox
                                                        edge="start"
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        disableRipple
                                                        disabled={true}
                                                        checked={isMultiCheck(item,ele.description)}
                                                        color="primary"
                                                    />
                                                    <label>{ele.description}</label>
                                                </Box>
                                            )
                                        })
                                        }
                                    </Box>}

                                    {item.attributes.question_type === 'Single line text' && <Box>
                                        <h4 className="font16"> {index+1}. {item.attributes.description}</h4>
                                        <p style={{ color: "#8F8F8F"}} className="font12">{item.attributes.answers}</p>
                                    </Box>}
                                </div>
                            )
                    })
                        }

                        {!isDashboard && appStatus &&

                            <div className={`${appStatus} ${isMobile?'status-btnn-mobile':'status-btnn'}`} style={{marginTop:'20px'}}>
                                {appStatus !== 'Answered' && appStatus}</div>
                        }

                        {localStorage.getItem("teamMember_ReadOnly") !== 'true' &&isDashboard&&<Box display={"flex"} justifyContent="flex-end" style={{paddingBlock:"30px",marginTop:'40px'}} className="belowBtn">
                        <div className={`${recentApplicants[recentAppIndex].attributes.status} status-btnn`}>
                                    {recentApplicants[recentAppIndex].attributes.status!=='Answered'&&recentApplicants[recentAppIndex].attributes.status}</div>
                            <Button variant="outlined" className="btn-font" style={{width:"140px",marginRight:isMobile?"26px":"30px",border:'1px solid #486ad0',borderRadius:'7px',marginLeft:isMobile?'30px':'45px',fontSize:isMobile?'10px':'14px',textTransform: 'none'}} onClick={()=>onApplicationCLick('Rejected',{id:selectedExecutiveId})}>Reject</Button>
                            <Button variant="contained" className="btn-font" style={{width:"140px",color:'white',background:'#486ad0',borderRadius:'7px',fontSize:isMobile?'10px':'14px',textTransform: 'none',marginRight:isMobile?'14px':'0px',marginLeft:isMobile?'-11px':'0px'}} onClick={()=>onApplicationCLick('Hired',{id:selectedExecutiveId})}>Approve</Button>
                        </Box>}
                    </div>
                    
                </div>

            </div>
        </Box>
    </>
}