import React from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    FormGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    Dialog,
    IconButton,
    styled,
    CircularProgress,

} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import "./job.css"
import JobListing2Controller, {
    Props,
} from "./jobListing2Controller.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FooterWeb from "../../../components/src/FooterWeb";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import { ViewApplication } from "../../../components/src/ViewApplication";
import { CompanyPopUp } from "../../../components/src/CompanyPopUp.web";
const FormGroupGrid = styled(FormGroup)({
    "@media (max-width:960px )": {
        flexDirection: "row"
    }
})
const ButtonStyle = styled(Button)({
    backgroundColor: "#536fc2",
    color: "#fff",
    fontWeight: 700,
    marginRight: "10px",
    textTransform: "none",
    "@media (max-width:310px )": {
        marginBottom: "10px"
    },
    "&:hover": {
        backgroundColor: "#536fc2",
    }
})
const CloseIconStyle = styled(IconButton)({

    "&:hover": {
        backgroundColor: "unset",
    }

})
const FormControlLabelGrid = styled(FormControlLabel)({
    marginLeft: 0,
    color: "grey",
    justifyContent: "space-between",
    "@media (max-width:960px )": {
        marginRight: "10px"
    }
})
const DialogBox = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        width: "40%"
    },
    "& .MuiDialog-paper": {
        overflowY: "unset"
    },
    "@media (max-width: 900px)": {
        "& .MuiDialog-paperWidthSm": {
            width: "100%"
        }
    }
})
const MainGrid = styled(Grid)({
    padding: "0px 0px 20px 20px",
    "@media (max-width:960px )": {
        padding: "0px 60px 10px 60px",
    },
    "@media (max-width:675px )": {
        padding: "0px 30px 10px 30px",
    },

    "@media (max-width:480px)": {
        padding: "0px 10px 10px 10px",
    },
    "@media (max-width:340px )": {
        padding: "0px 5px 10px 5px",
    },
})
const MainGrid1 = styled(Grid)({
    padding: "25px",
    "@media (max-width:960px)": {
        padding: "10px 0 10px 10px"
    }
})
const MainDialog = styled(`div`)({
    padding: "25px 40px 50px 40px",
    "@media (max-width:600px)": {
        padding: "20px"
    },
})
const MainDialog2 = styled(`div`)({
    padding: "20px 40px 20px 40px",
    "@media (max-width:600px)": {
        padding: "20px"
    },
})
const MainGrid2 = styled(Grid)({
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    "@media (max-width:960px)": {
        padding: "10px 10px 10px 0px"
    },
    "@media (max-width:600px  )": {
        padding: "10px 10px 10px 10px",
        alignItems: "start",
    },

})
export default class JobsApplied extends JobListing2Controller {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const filterData = ["Answered", "Hold", "Interviewed", "Hired", "Rejected"]

        return <>
            <NavigationMenunew navigation={undefined} id={""} />
            {
 this.state.userType==""? <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
                    <CircularProgress />
                </div> :
                    <Box className="RecentJobSection">
                        <Box className="container">
                            <Grid className="JobAppliedContainer">
                                <Grid style={{ marginBottom: "40px", marginTop: "50px" }}>
                                    <Typography style={{ fontSize: '33px', fontWeight: 'bold', marginBottom: "10px", letterSpacing: "0.06em " }}>
                                        {this.state.userType !== "company" ? "My Applications" : "My Applicants"}
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: 500, color: "grey" }}>
                                        Sorted by newest
                                    </Typography>
                                </Grid>
                                {
                                    this.state.get_jobAppliedData  ?
                                        <Grid container>
                                            <Grid xs={12} md={3}>
                                                <Typography style={{ fontSize: "16px", fontWeight: 600, borderBottom: "1px solid grey", maxWidth: "143px", marginBottom: "12px" }}>
                                                    Filter by Status
                                                </Typography>
                                                <FormControl component="fieldset" style={{ marginBottom: "25px" }}>
                                                    <FormGroupGrid>
                                                        {filterData.map((item: string, id: number) => (
                                                            <FormControlLabelGrid
                                                                key={`key_${id}`}
                                                                value={item}
                                                                onClick={() => { this.handleFilterToggle(item) }}
                                                                control={<Checkbox color="primary" checked={this.state.filterData.includes(item)} />}
                                                                label={item}
                                                                labelPlacement="start"
                                                                data-test-id="filterDataTest"
                                                            />
                                                        ))}
                                                    </FormGroupGrid>
                                                </FormControl>
                                            </Grid>
                                            <MainGrid xs={12} md={9}>
                                                {this.state.get_jobAppliedData.length > 0 && this.state.get_jobAppliedData.map((item: any, id: number) => {
                                                    return <Grid container style={{ border: "1px solid #879cdb", borderRadius: "8px", marginBottom: "20px" }} key={`key_${id}`}>
                                                        <MainGrid1 sm={6} xs={12} >
                                                            <Typography style={{ fontSize: '25px', fontWeight: 'bold' }}>
                                                                {this.handleTitle(item)}
                                                            </Typography>
                                                            <Typography style={{ fontSize: "16px", fontWeight: 500, marginBottom: "10px" }}>
                                                                {this.handleCompanyName(item)}
                                                            </Typography>
                                                            <Typography style={{ fontSize: "14px", fontWeight: 500, color: "grey" }}>
                                                                {this.dateFunction(item.attributes.job.data.attributes.created_at)}
                                                            </Typography>
                                                        </MainGrid1>
                                                        <MainGrid2 sm={6} xs={12} >
                                                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', marginBottom: "12px" }}>
                                                                {item.attributes.status}
                                                            </Typography>
                                                            <Box>
                                                                <ButtonStyle data-test-id='viewApplication-btn' onClick={() => this.viewApplication(item)}>
                                                                    Details
                                                                </ButtonStyle>

                                                                {localStorage.getItem("teamMember_ReadOnly") !== 'true'&&<Button style={{ backgroundColor: "#536fc2", color: "#fff", fontWeight: 700, textTransform: "none", }} data-test-id="nudgeTestId" onClick={() => this.postNudgeApi(item)}>
                                                                    Nudge
                                                                </Button>}
                                                            </Box>
                                                        </MainGrid2>
                                                    </Grid>

                                                })}
                                                {this.state.get_jobAppliedData.length === 0  && <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "10px", textAlign: "center" }}>
                                                    Data not found.
                                                </Typography>
                                                }
                                                {this.state.get_jobAppliedData.length > 0 && <Box className="container pagination_wrp" style={{ marginBottom: '30px', padding: 0, display: "flex", justifyContent: "end" }}>
                                                    <Typography>page no. {this.state.currentJobApplied}/{this.state.last_page_JobApplied}

                                                    </Typography>
                                                    <Box className="box_Style">
                                                        <ExpandMoreIcon data-test-id="currentpageid" className="left_arrow" onClick={() => { this.state.currentJobApplied > 1 && this.setState({ currentJobApplied: this.state.currentJobApplied - 1 }) }} />
                                                        <ExpandMoreIcon data-test-id="previouspageid" className="right_arrow" onClick={() => { this.state.currentJobApplied < this.state.last_page_JobApplied && this.setState({ currentJobApplied: this.state.currentJobApplied + 1 }) }} />
                                                    </Box>
                                                </Box>
                                                }

                                                <Dialog fullWidth className="model_popup" scroll="body" open={this.state.viewApplication}  >

                                                    <div className="">
                                                    { this.state.userType=="company" ?  <ViewApplication data-test-id="viewPopup" applicantQuestions={this.state.applicantQuestions} executiveDetails={this.state.executiveDetails} closeModal={() => { this.setState({ viewApplication: false }) }} selectedExecutiveId={this.state.selectedExecutiveId} onApplicationCLick={() => { }} recentApplicants={this.state.get_jobAppliedData} isDashboard={false} />
                                                      
                                                       : <CompanyPopUp data-test-id="companypopup" applicantQuestions={this.state.applicantQuestions} companyDetails={this.state.executiveDetails} closeModal={() => { this.setState({ viewApplication: false }) }} selectedExecutiveId={this.state.selectedExecutiveId} onApplicationCLick={() => { }} recentApplicants={this.state.get_jobAppliedData} isDashboard={false} />
                                              }  </div>

                                                </Dialog>
                                                
                                            </MainGrid>
                                        </Grid>
                                        :
                                        <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "10px", textAlign: "center" }}>
                                            {this.checkDataFound()}
                                        </Typography>
                                }
                            </Grid>
                        </Box>
                    </Box>
            }
            <DialogBox
                open={this.state.showNudgePopup}
                onClose={this.hideNudgePopup}
            >
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '10px 40px' }}>
                    <CloseIconStyle edge="end" color="inherit" onClick={this.hideNudgePopup}>
                        <CloseIcon />
                    </CloseIconStyle>
                </div>
                <MainDialog>

                    <Grid container style={{ border: "1px solid #879cdb", borderRadius: "8px", marginBottom: "10px" }}>
                        <Grid sm={6} xs={12} style={{ padding: "10px" }}>
                            <Typography style={{ fontSize: '25px', fontWeight: "bold" }}>
                                {this.handleTitlePopUp()}
                            </Typography>
                            <Typography style={{ fontSize: "16px", fontWeight: 500, marginBottom: "10px" }}>
                                {this.handleCompanyPopUp()}
                            </Typography>
                            <Typography style={{ fontSize: "14px", fontWeight: 500, color: "grey" }}>
                                {this.dateFunction(this.state.userData?.attributes?.job?.data?.attributes?.created_at)}
                            </Typography>
                        </Grid>
                        <Grid sm={6} xs={12} style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "end" }}>
                            <Typography style={{ fontSize: '25px', fontWeight: "bold", marginBottom: "12px" }}>
                                {this.state.userData?.attributes?.status}
                            </Typography>
                            <Box>
                                    <Button data-test-id="viewApplication-nudge" onClick={()=>this.viewApplication(this.state.userData)} style={{ backgroundColor: "#536fc2", color: "#fff", fontWeight: 700, textTransform: "none" }}>
                                        Details
                                    </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "10px", textAlign: "center" }}>
                        Your premium status alert has been sent!
                    </Typography>
                </MainDialog>
            </DialogBox>
            <DialogBox
                open={this.state.showMessage}
                onClose={this.hideMessagePopup}
            >
               
                <MainDialog2>

                    <Grid container style={{  padding:"10px"}}>
                    <Typography style={{  textAlign: "center",fontSize:"18px" ,marginBottom:"12px"}}>
                    To utilize this functionality, please upgrade to our premium plan.
                    </Typography>
                    <Box style={{display:"flex",justifyContent:"end",width:"100%"}}>

                    <Button data-test-id="viewApplication-message" onClick={()=>{this.navigateNudge()}} style={{ backgroundColor: "#536fc2", color: "#fff", fontWeight: 700, textTransform: "none" }}>
                                        Upgrade
                                    </Button>
                    </Box>
                    </Grid>
                   
                </MainDialog2>
            </DialogBox>
            <FooterWeb />
        </>
    }
}