import React from "react";
import { Checkbox } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    Checkbox: {
      color: "#536fc2",
     "&.Mui-checked": {
        border: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "rgba(164, 164, 164, 0.48)",
        width: "2rem",
        height: "2rem",
      },
      "&.Mui-checked .MuiSvgIcon-root, & input:focus~.MuiSvgIcon-root": {
        color: "#536fc2",
      },
    },
  })
);

const CustomCheckBox = (props: any) => {
  const classes = useStyles();

  return <Checkbox color="primary" className={classes.Checkbox} {...props} />;
};
export default CustomCheckBox;
