import {Dialog,DialogContent} from "@material-ui/core";
import "./logout.css"
import React from 'react';
import { Box, Button, Typography } from "@material-ui/core";
import { Logout } from "../../blocks/JobListing2/src/assets";
import {Link} from 'react-router-dom'


export const LogoutModal = (props: {open: boolean,closeModal: VoidFunction}) => {
  return(
        <Dialog className="model_popup" scroll="paper" open={props.open}>
          <DialogContent>
            <div className="logout_box">
                <img src={Logout} alt="" />
                <Typography>Oh no! you are leaving..<br></br>Are you sure?</Typography>
                <span className="come_soon">Come back soon.</span>
                <Box style={{margin:'25px 0 0',display:'flex', alignItems:'center',justifyContent:'center',gap:'20px'}}>
                    <Button className="cancel" onClick={props.closeModal}>cancel</Button>
                    <Button className="logout" onClick={() => {
                      localStorage.removeItem("authToken");
                      localStorage.removeItem("user_id");
                    }}><Link style={{color: "white"}} to="/LandingPage">Logout</Link></Button>
                </Box>
            </div>
          </DialogContent>
        </Dialog>
        )
     }