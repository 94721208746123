export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const boardsiLogo = require("../assets/boardsi_logo.png");
export const boardsiBackgroundImg = require("../assets/boardsi_login_background.png");
export const BackgroundImage = require("../assets/view_background.png");
export const SideImage = require("../assets/view_formbackground.png");
export const BoardsiLogo = require("../assets/view_logo.png");
export const Executive = require("../assets/view_placeholder_Ex.png");
export const Company = require("../assets/view_placeholder.png");
export const BordsiLogoWhite = require("../assets/view_logowhite.png");
export const dark1 = require("../assets/11.png");
export const dark2 = require("../assets/11111.png");
export const Eyeopen = require("../assets/Eyeopen.png");
export const EyeSlash = require("../assets/EyeSlash.png");





