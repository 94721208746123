import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formData: any;
  companyFormData: any;
  enablePasswordField: boolean;
  isExicutive: boolean;
  labelHeader: string;
  labelHeaderSignUpFree: string;
  error: string;
  companyError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      formData: {
        email: "",
        password: "",
      },
      companyFormData: {
        cemail: "",
        cpassword: "",
      },
      enablePasswordField: true,
      isExicutive: false,
      labelHeader: configJSON.labelHeader,
      labelHeaderSignUpFree: configJSON.labelHeaderSignUpFree,
      error: "",
      companyError: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setExicutive = (value: boolean) => {
    this.setState({ isExicutive: value });
  };

  checkResponse=(responseJson:any)=>{
    if(responseJson.meta.team_member){
      localStorage.setItem("authToken", responseJson.meta.team_member.company_token);
      localStorage.setItem("user_id", responseJson.meta.team_member.company_id);
      localStorage.setItem("teamMember_ReadOnly",(responseJson.meta.team_member.permission==='Read Only').toString());
    }else{
      localStorage.setItem("authToken", responseJson.meta.token);
      localStorage.setItem("user_id", responseJson.meta.id);
      localStorage.setItem("teamMember_ReadOnly",(false).toString());
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      

      if (apiRequestCallId === this.apiEmailLoginCallId) {
        if (!responseJson.errors) {
      
          this.checkResponse(responseJson)    
this.props.navigation.navigate("Dashboard")
        } else {
          Object.entries(responseJson).forEach((item: any) => {
            item[1].forEach((item: any) => {
              this.state.isExicutive === false
                ? this.setState({ error: item.failed_login })
                : this.setState({ companyError: item.failed_login });
            });
          });
        }
      }
    }
    // Customizable Area End
  }



  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (this.state.isExicutive === false) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        companyFormData: {
          ...prevState.companyFormData,
          [name]: value,
        },
      }));
    }
  };

  handleLogin = () => {
    if (!this.state.formData.email || !this.state.formData.password) {
      this.setState({ error: "Please enter both email and password." });
      return;
    }
    this.doEmailLogIn({ email : this.state.formData.email, password : this.state.formData.password });
  };

  handleCompanyLogin = () => {
    if (!this.state.companyFormData.cemail || !this.state.companyFormData.cpassword) {
      this.setState({ companyError: "Please enter both email and password." });
      return;
    }
    this.doEmailLogIn({ email: this.state.companyFormData.cemail, password: this.state.companyFormData.cpassword });
  };

  doEmailLogIn(values: any) {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: this.state.isExicutive === false ? "executive" : "company",
      attributes: values,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
