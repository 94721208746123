Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions";
exports.labelBodyText = "TermsAndConditions Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiTermsAndCondition = "/get_page_content?slug=term_and_conditions"
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";


// Customizable Area End