Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Sign up";
exports.alreadyRegisterdText = " Already registered?",
exports.loginNowText = "Login now!",
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.getDegreeEndPoint = "bx_block_profile/degrees";
exports.validationApiContentType = "application/json";

exports.saveAccountApiEndpoint = "account_block/accounts";
exports.getEmploymentType ="bx_block_profile/employment_types"
exports.GetApiMethodType = "GET";
exports.GetProfile="/account_block/accounts";
exports.getSkills="/bx_block_profile/skills";
exports.getIndustries="/bx_block_profile/industries";
exports.getDegrees="/bx_block_profile/degrees";
exports.AddBigraphy ="/account_block/account_creations/board_biography";
exports.CreateAccount ="/account_block/account_creations"
exports.PostAPiMethod = "POST";
exports.PutAPiMethod = "PUT";
exports.accountCreateEndPoint = "/account_block/account_creations/update"
exports.companySize =  ["1-50","51-200","201-500","501-1000"]
exports.passwordRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
exports.urlRegex = /^(http(s)?:\/\/)?([\w-]+\.)?linkedin\.com\/(pub|in|profile)/;
exports.month = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];

// Customizable Area End
