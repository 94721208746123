import React from "react";

// Customizable Area Start
import Snackbar from '@material-ui/core/Snackbar';
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
  Grid,
  styled
} from "@material-ui/core";
import { createTheme, } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';


import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

// Customizable Area End

import SignupController, {
  configJSON,
  Props
} from "./CommonSignupController.web";
import {
  BackgroundImage,
  BoardsiLogo,
  BordsiLogoWhite,
  Checkbox,
  Company,
  DownArrow,
  Executive,
  SideImage,
  SideImageCompany,
  dark1,
  dark2,
  Eyeopen,
  EyeSlash
} from "./assets";
import {
  CustomTextField,
  CustomFormControl,
  CustomCheckBox,
} from "../../../components/src/CustomStyle.web";
import "./signup.css";
import CustomSelect from "../../../components/src/CustomSelect.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
      contrastText: "#fff"
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});
// Customizable Area Start
const Customdiv=styled('div')({
  '& .btn_select_user_type:hover': {
    background: 'unset'
  }
})
    const CustomBox = styled(Box)({
      '& fieldset': {
        top: -11
      },
      "@media(max-width:600px)":{
        '& fieldset': {
          top: -9
        },

      }
    })
// Customizable Area End
export default class SignupWeb extends SignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  validationSchema = Yup.object().shape({

    last_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .required("Please enter Last name"),
    first_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .required("Please enter First name"),
    email: Yup.string()
      .email("Please enter valid email address")
      .required("Please Enter your email address"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        configJSON.passwordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
    linkedin_url: Yup.string()
      .matches(configJSON.urlRegex, "please enter valid url")
      .required("Please enter Linkedin url"),
    checkedAgreeWith: Yup.bool().oneOf(
      [true],
      "Please Accept Terms & Conditions"
    )
  });

  validationSchemaForCompany = Yup.object().shape({
    first_name: Yup.string()
      .required("Please enter First name")
      .trim()
      .min(3, "Minimum 3 characters required"),
    last_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .required("Please enter Last name"),
    linkedin_url: Yup.string()
      .matches(configJSON.urlRegex, "please enter valid url")
      .required("Please enter Linkedin url"),
    email: Yup.string()
      .required("Please Enter your email address")
      .email("Please enter valid email address"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        configJSON.passwordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),

    checkedAgreeWith: Yup.bool().oneOf(
      [true],
      "Please Accept Terms & Conditions"
    ),
    company_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .required("Please enter Company name"),
    company_size: Yup.string().required("Please select Company size"),
    industry: Yup.string().required("Please select Insustry name"),
    location: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .required("Please enter Location")
  });

  IconCheckbox = () => (
    <img src={Checkbox} alt="checkbox" className="checkbox-icon" />
  );

  selectCompanyORExecutiveRow() {
    // Customizable Area Start
    return (
      <>
        <img src={BoardsiLogo} alt="logo" className="logo" />

        <h1>Sign Up</h1>
        <div className="login-free">
          <span>Already Registered?</span>
          <Link to="/EmailAccountLoginBlock">LogIn Now!</Link>
          <Customdiv className="executive select_user_signup">
            <Button
              onClick={() => this.setExicutive(false)}
              data-test-id="btnExecutiveLogIn"
              className="btn-executiveclass btn_select_user_type"
            >
              <div
                className={
                  this.state.isExicutive === true
                    ? "executive-button"
                    : "executive-button-active"
                }
              >
                {this.state.isExicutive === true ? <img src={Executive} /> : <img src={dark1} />}
                <p
                  style={{
                    color:
                      this.state.isExicutive === true ? "black" : "#5370c2"
                  }}
                >
                  I'm an
                  <br /> Executive
                </p>
              </div>
            </Button>
            <Button
              onClick={() => this.setExicutive(true)}
              className="btn-executiveclass btn_select_user_type"
              data-test-id="btnComponyLogIn"
            >
              <div
                className={
                  this.state.isExicutive === false
                    ? "executive-button"
                    : "executive-button-active"
                }
              >
                {this.state.isExicutive === false ? <img src={Company} /> : <img src={dark2} />}
                <p
                  style={{
                    color:
                      this.state.isExicutive === false ? "black" : "#5370c2"
                  }}
                >
                  I'm a <br />
                  Company
                </p>
              </div>
            </Button>
          </Customdiv>
        </div>
      </>
    )
    // Customizable Area End
  }

  isExicutiveFalse_password() {
    return (
      <>
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            className="signup_eye_icon"
            edge="end"
            onClick={this.handleClickShowPassword}
          >
            {this.state.enablePasswordField ? (
              <img style={{ marginBottom: '9px', width: '40%' }} src={Eyeopen} />
            ) : (
              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
            )}
          </IconButton>
        </InputAdornment>
      </>
    )
  }

  isExicutiveFalse_confirm_password() {
    return (
      <>
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle confirm password visibility"
            className="signup_eye_icon"
            onClick={this.handleClickShowConfirmPassword}
            edge="end"

          >
            {this.state.enableconfirmPasswordField ? (
              <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
            ) : (
              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
            )}
          </IconButton>
        </InputAdornment>
      </>
    )
  }


  isExicutiveFalse_submit_btn() {
    return (
      <>
        <Typography className="error-message">
          {this.state.error ? this.state.error : ""}
        </Typography>
        <div className="login-button">
          <Button
            type="submit"
            data-test-id="btnSignupe"
            variant="contained"
            color="primary"
          >
            Sign Up
          </Button>
        </div>
      </>
    )
  }

  isExicutiveFalse_fileds_flname_email(values: any, errors: any, touched: any, handleChange: any) {
    return (
      // Customizable Area Start
      <CustomBox>
        <Typography className="text-field">First Name</Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            data-test-id="txtInputFirstName"
            fullWidth={true}
            variant="outlined"
            placeholder={"First Name"}
            value={values.first_name}
            onChange={handleChange}
            name="first_name"
            error={
              touched.first_name && Boolean(errors.first_name)
            }
            helperText={touched.first_name && errors.first_name}
          />
        </Box>
        <Typography className="text-field">Last Name</Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            name="last_name"
            data-test-id="txtInputLastName"
            placeholder={"Last Name"}
            value={values.last_name}
            onChange={handleChange}
            error={touched.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            fullWidth={true}
            variant="outlined"
          />
        </Box>
        <Typography className="text-field">Email</Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            placeholder={"Email"}
            fullWidth={true}
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            name="email"
            data-test-id="txtInputEmail"
            helperText={touched.email && errors.email}
          />
        </Box>
      </CustomBox>
      // Customizable Area End
    )
  }

  isExicutiveFalse_form(values: any, errors: any, touched: any, handleChange: any, handleSubmit: any) {
    return (
      // Customizable Area Start
      <>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <>
            {this.isExicutiveFalse_fileds_flname_email(values, errors, touched, handleChange)}

            <Typography className="text-field">Password</Typography>
            <CustomBox sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="password"
                data-test-id="txtInputPassword"
                variant="outlined"
                value={values.password}
                type={
                  this.state.enablePasswordField ? "password" : "text"
                }
                placeholder={"Password"}
                fullWidth={true}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{ endAdornment: (this.isExicutiveFalse_password()) }}
              />
            </CustomBox>
            <Typography className="text-field">Confirm Password</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="confirm_password"
                type={this.state.enableconfirmPasswordField ? "password" : "text"}
                placeholder={"Confirm Password"}
                data-test-id="txtInputConfirmPassword"
                InputProps={{ endAdornment: (this.isExicutiveFalse_confirm_password()) }}
                fullWidth={true}
                variant="outlined"
                value={values.confirm_password}
                onChange={handleChange}
                helperText={touched.confirm_password && errors.confirm_password}
                error={touched.confirm_password && Boolean(errors.confirm_password)}
              />
            </Box>
            <Typography className="text-field">
              LinkedIn URL
            </Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                placeholder={"LinkedIn URL"}
                name="linkedin_url"
                data-test-id="txtInputLinkedInUrl"
                fullWidth={true}
                variant="outlined"
                value={values.linkedin_url}
                onChange={handleChange}
                helperText={
                  touched.linkedin_url && errors.linkedin_url
                }
                error={
                  touched.linkedin_url && Boolean(errors.linkedin_url)
                }
              />
            </Box>

            <div className="terms-condition">
              <CustomCheckBox
                data-test-id="agreeWith"
                value={values.checkedAgreeWith}
                checked={values.checkedAgreeWith}
                name="checkedAgreeWith"
                color="primary"
                onChange={handleChange}
                icon={this.IconCheckbox()}
              />

              <span className="label-wrap">Agree with</span>
              <Link to={{
                pathname: "/TermsAndConditions",
                state: { checkedAgreeWith: values.checkedAgreeWith } // Pass the value as state
              }}>
                Terms and conditions
              </Link>
            </div>

            <Typography className="error-message">
              <ErrorMessage name="checkedAgreeWith" />
            </Typography>

            {this.isExicutiveFalse_submit_btn()}

          </>
        </form>
      </>
      // Customizable Area End
    )
  }

  isExicutiveFalse() {
    return (
      // Customizable Area Start
      <>
        <Formik
          initialValues={this.state.formData}
          validationSchema={this.validationSchema}
          onSubmit={values => {
            this.createAccount({
              email: values.email.toLowerCase(),
              password: values.password,
              first_name: values.first_name,
              last_name: values.last_name,
              linkedin_url: values.linkedin_url,
              user_type: "executive"
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            this.isExicutiveFalse_form(values, errors, touched, handleChange, handleSubmit)
          )}
        </Formik>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_password() {
    return (
      // Customizable Area Start
      <>
        <InputAdornment position="end">
          <IconButton
            className="signup_eye_icon"
            onClick={this.handleClickShowPassword}
            aria-label="toggle password visibility"
            edge="end"
          >
            {this.state.enablePasswordField ? (
              <img src={Eyeopen} style={{ marginBottom: '9px', width: '40%' }} />
            ) : (
              <img style={{ width: '40%', marginBottom: '9px' }} src={EyeSlash} />
            )}
          </IconButton>
        </InputAdornment>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_confirm_password() {
    return (
      // Customizable Area Start
      <>
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle confirm password visibility"
            onClick={this.handleClickShowConfirmPassword}
            edge="end"
            className="signup_eye_icon"
          >
            {this.state.enableconfirmPasswordField ? (
              <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
            ) : (
              <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
            )}
          </IconButton>
        </InputAdornment>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_submit_btn() {
    return (
      // Customizable Area Start
      <>
        <Typography className="error-message">
          {this.state.companyError ? this.state.companyError : ""}
        </Typography>
        <div className="login-button">
          <Button
            type="submit"
            data-test-id="btnSignup"
            variant="contained"
            color="primary"
          >
            Sign Up
          </Button>
        </div>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_fileds_flname_email(values: any, errors: any, touched: any, handleChange: any) {
    return (
      // Customizable Area Start
      <>
        <Typography className="text-field">First Name</Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            name="first_name"
            data-test-id="txtInputEmail"
            placeholder={"First Name"}
            fullWidth={true}
            variant="outlined"
            value={values.first_name}
            onChange={handleChange}
            helperText={touched.first_name && errors.first_name}
            error={
              touched.first_name && Boolean(errors.first_name)
            }
          />
        </Box>
        <Typography className="text-field">Last Name</Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            name="last_name"
            data-test-id="txtInputEmail"
            placeholder={"Last Name"}
            fullWidth={true}
            variant="outlined"
            value={values.last_name}
            onChange={handleChange}
            helperText={touched.last_name && errors.last_name}
            error={touched.last_name && Boolean(errors.last_name)}
          />
        </Box>
        <Typography className="text-field">
          Corporate Email
        </Typography>
        <CustomBox sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            name="email"
            fullWidth={true}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            data-test-id="txtInputEmail"
            placeholder={"Corporate Email"}
          />
        </CustomBox>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_company_name_filed(values: any, errors: any, touched: any, handleChange: any) {
    return (
      // Customizable Area Start
      <>
        <Typography className="text-field">
          Company Name
        </Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomTextField
            name="company_name"
            fullWidth
            variant="outlined"
            value={values.company_name}
            data-test-id="txtInputCopmpanyName"
            placeholder={"Company Name"}
            onChange={handleChange}
            error={
              touched.company_name && Boolean(errors.company_name)
            }
            helperText={
              touched.company_name && errors.company_name
            }
          />
        </Box>
        <Typography className="text-field">
          Company Size
        </Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomFormControl variant="outlined" fullWidth>

            <CustomSelect
              name="company_size"
              data-test-id="txtInputCompanySize"
              placeholder={"Company Size"}
              value={values.company_size}
              onChange={handleChange}
              error={
                touched.company_size &&
                Boolean(errors.company_size)
              }
              helperText={
                touched.company_size && errors.company_size
              }

            >
              <MenuItem value="1-50">1-50</MenuItem>
              <MenuItem value="51-200">51-200</MenuItem>
              <MenuItem value="201-500">201-500</MenuItem>
              <MenuItem value="501-1000">501-1000</MenuItem>
            </CustomSelect>
          </CustomFormControl>

          <Typography className="error-message">
            <ErrorMessage name="company_size" />
          </Typography>
        </Box>
        <Typography className="text-field">
          Industry
        </Typography>
        <Box sx={{ paddingBottom: "12px" }}>
          <CustomFormControl variant="outlined" fullWidth>
            <CustomSelect
              name="industry"
              data-test-id="txtInputCompanySize"
              placeholder={"Industry"}
              value={values.industry}
              onChange={handleChange}
              error={touched.industry && Boolean(errors.industry)}
              helperText={touched.industry && errors.industry}

            >
             {
              this.state.industryData.map((item:any,index:any)=>{
              return <MenuItem value={item.value}>{item.value}</MenuItem>

              })
             }
            </CustomSelect>
          </CustomFormControl>

          <Typography className="error-message">
            <ErrorMessage name="industry" />
          </Typography>
        </Box>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue_form(values: any, errors: any, touched: any, handleChange: any, handleSubmit: any) {
    return (
      // Customizable Area Start
      <>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Box>
            {this.isExicutiveTrue_fileds_flname_email(values, errors, touched, handleChange)}

            <Typography className="text-field">Password</Typography>
            <CustomBox sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="password"
                variant="outlined"
                value={values.password}
                data-test-id="txtInputPassword"
                type={
                  this.state.enablePasswordField ? "password" : "text"
                }
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                placeholder={"Password"}
                fullWidth={true}
                onChange={handleChange}
                InputProps={{ endAdornment: (this.isExicutiveTrue_password()) }}

              />
            </CustomBox>
            <Typography className="text-field">Confirm Password</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="confirm_password"
                data-test-id="txtInputConfirmPassword"
                type={this.state.enableconfirmPasswordField ? "password" : "text"}
                placeholder={"Confirm Password"}
                fullWidth={true}
                variant="outlined"
                value={values.confirm_password}
                onChange={handleChange}
                error={touched.confirm_password && Boolean(errors.confirm_password)}
                helperText={touched.confirm_password && errors.confirm_password}
                InputProps={{ endAdornment: (this.isExicutiveTrue_confirm_password()) }}
              />
            </Box>

            {this.isExicutiveTrue_company_name_filed(values, errors, touched, handleChange)}

            <Typography className="text-field">Location</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="location"
                data-test-id="txtInputEmail"
                placeholder={"Location"}
                fullWidth={true}
                variant="outlined"
                value={values.location}
                onChange={handleChange}
                error={touched.location && Boolean(errors.location)}
                helperText={touched.location && errors.location}
              />
            </Box>
            <Typography className="text-field">
              LinkedIn URL
            </Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomTextField
                name="linkedin_url"
                data-test-id="txtInputEmail"
                placeholder={"LinkedIn URL"}
                fullWidth={true}
                onChange={handleChange}
                error={
                  touched.linkedin_url && Boolean(errors.linkedin_url)
                }
                variant="outlined"
                value={values.linkedin_url}
                helperText={
                  touched.linkedin_url && errors.linkedin_url
                }
              />
            </Box>

            <div className="terms-condition">
              <CustomCheckBox
                data-test-id="agreeWith"
                name="checkedAgreeWith"
                color="primary"
                value={values.checkedAgreeWith}
                checked={values.checkedAgreeWith}
                onChange={handleChange}
                icon={this.IconCheckbox()}
              />

              <span className="label-wrap">Agree with</span>
              <Link to="/TermsAndConditions">
                Terms and conditions
              </Link>
            </div>

            <Typography className="error-message">
              <ErrorMessage name="checkedAgreeWith" />
            </Typography>

            {this.isExicutiveTrue_submit_btn()}

          </Box>
        </form>
      </>
      // Customizable Area End
    )
  }

  isExicutiveTrue() {
    return (
      // Customizable Area Start
      <>
        <Formik
          initialValues={this.state.companyFormData}
          validationSchema={this.validationSchemaForCompany}
          onSubmit={values => {
            this.createAccount({
              email: values.email.toLowerCase(),
              password: values.password,
              first_name: values.first_name,
              last_name: values.last_name,
              linkedin_url: values.linkedin_url,
              location: values.location,
              company_name: values.company_name,
              company_size: values.company_size,
              industry: values.industry,
              user_type: "company"
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            this.isExicutiveTrue_form(values, errors, touched, handleChange, handleSubmit)
          )}
        </Formik>
      </>
      // Customizable Area End
    )
  }




  leftSideBannerImage() {
    return (
      // Customizable Area Start
      <>
        <img
          src={BackgroundImage}
          className="background-image"
          alt="background-image"
        />
        <img src={BordsiLogoWhite} alt="logo-white" className="logo-white" />
        {this.state.isExicutive === false ? (
          <img src={SideImage} className="side-image" alt="side-image" />
        ) : (
          <img src={SideImageCompany} className="side-image-company" alt="side-image" />
        )}
      </>
      // Customizable Area End
    )
  }


  render() {
    return (
      // Customizable Area Start
      <>
        <div className="signup_page_div">
          <Grid container>
            <Grid item md={8} xs={12} sm={12} className="left_side_img">
              <div className="forgot_right_col">
                <img
                  src={BackgroundImage}
                  className="forgot_image_bg"
                  alt="BackgroundImage"
                  style={{ width: '100%', objectFit: 'cover', height: '100vh' }}
                />
                <img src={BordsiLogoWhite} alt="logo-white" className="logo-white" />

              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={12} className="scrollable-content">
              <div className="signup-container">
                <div className="signup-sub-container">
                  {this.selectCompanyORExecutiveRow()}
                  {this.state.isExicutive === false ? (
                    
                    this.isExicutiveFalse()
                  ) : (
                    this.isExicutiveTrue()
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <div style={{  display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "4px",
    textAlign:"center",
    justifyContent:"center",
    width: "362px",
    height: "42px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "10px",
    paddingRight: "10px"}}>
           {this.state.message}
          </div>
        </Snackbar>
        </div>
      </>
      // Customizable Area End
    );
  }
}
