import React, { useState } from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Checkbox
} from "@material-ui/core";
import { no_user } from "../../blocks/Settings5/src/assets"
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import "./share.css"
import "../../blocks/email-account-registration/src/signup.css"
import { Cross } from "../../blocks/JobListing2/src/assets";
import  {  useRef } from 'react';
const playIcon=require("./playIcon.png")

export const VideoPlayer = ({ videoSource }: any) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
  
  
    const handlePlayButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
        // Stop the propagation of the click event to prevent it from reaching the container
        event.stopPropagation();
        
        // Handle play/pause logic
        handleVideoClick();
      };
      const handleVideoClick = () => {
        const video = videoRef.current;
        if (video) {
          if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true)
          } else {
            video.pause();
            setIsPlaying(false)

          }
        }
      };
    return (
      <div className="video-container"    onClick={()=>handleVideoClick()}>
        <video
          ref={videoRef}
          style={{ height: '400px' }}
          src={videoSource}
          className="profile_cover_img"
        ></video>
        <div
          className={`play-button ${isPlaying ? 'hidden' : ''}`}
          onClick={(e)=>handlePlayButtonClick(e)}
        ></div>
      </div>
    );
  };
  
  export const VideoPlayer2= ({ videoSource,bgImageController }: any) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
  
  
    const handlePlayButtonClick = ( event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        // Stop the propagation of the click event to prevent it from reaching the container
        event.stopPropagation();
        
        // Handle play/pause logic
        handleVideoClick();
      };
      const handleVideoClick = () => {
        const video = videoRef.current;
        if (video) {
          if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true)
          } else {
            video.pause();
            setIsPlaying(false)

          }
        }
      };
    return (
        <div style={{position: 'relative', width: "620px",
        height: "420px"}} className="acccount-default ">
          { !isPlaying ? <img src={ playIcon} onClick={(e)=>handlePlayButtonClick(e)} alt="" style={{position:'absolute', cursor:"pointer",top: '50%', left: '50%', zIndex: 111, transform: 'translate(-50%, -50%)',width:"60px",height:"60px",objectFit: "contain"}}/>
           :<div onClick={(e)=>handlePlayButtonClick(e)}  style={{position:'absolute', cursor:"pointer",top: '50%', left: '50%', zIndex: 111, transform: 'translate(-50%, -50%)',width:"60px",height:"60px",objectFit: "contain"}}></div>
            } <video
              ref={videoRef}
              style={{right:0,width:"unset",left:"unset"}}
              src={videoSource}
              onClick={bgImageController}
              className="acccount-default"
              onTouchStart={bgImageController} 
            ></video>
        </div>
    );
  };

// Customizable Area End

export const CompanyPopUp = (props: any) => {
    // Customizable Area Start

    const [recentAppIndex, setRecentAppIndex] = useState(0);
    const { closeModal, companyDetails, applicantQuestions, onApplicationCLick, selectedExecutiveId, recentApplicants, isDashboard } = props;
    const isMobile = window.matchMedia('(max-width:1024px)').matches;
    React.useEffect(() => {
        if (recentApplicants) {
            recentApplicants.map((item: any, index: any) => {
                if (Number(item.id) === Number(selectedExecutiveId)) {
                    setRecentAppIndex(index)
                }
            })
        }
    }, []);

    const isMultiCheck = (item: any, description: any) => {
        let isExist = false
        item?.attributes?.answers?.map((item: any, ind: any) => {
            if (item.includes(description)) {
                isExist = true
            }
        })
        return isExist
    }
    // Customizable Area End

    return (
        // Customizable Area Start
        <>
            <Box>
                <Box className="cross_icon_share_popup" style={{ textAlign: 'right', position: 'absolute', right: '36px', top: '26px', cursor: 'pointer' }}>
                    <img src={Cross} onClick={closeModal} className="right_arrow_new" style={{ width: '17px' }} /></Box>

                <div className="modalWrapper">


                    <div className="modalLeftContent">
                        <h2 className='colorWhite mobileHeading'>Comapny name:</h2>

                        <p className="mobileContent colorWhite smallFont">{companyDetails?.company_name}</p>
                        <br />
                        <br />
                        <h2 className='colorWhite mobileHeading'>Comapny location:</h2>

                        <p className="mobileContent colorWhite smallFont">{companyDetails?.location}</p>
                        <br />
                        <br />
                        <h2 className='colorWhite mobileHeading'>Contact:</h2>
                        <p className="mobileContent colorWhite smallFont">{companyDetails?.email}</p>
                        <br />
                        <br />
                        <h2 className='colorWhite mobileHeading'>Contact number:</h2>
                        <p className="mobileContent colorWhite smallFont">{companyDetails?.full_phone_number}</p>
                        <br />
                        <br />
                        <h2 className='colorWhite mobileHeading'>Comapny size:</h2>

                        <p className="mobileContent colorWhite smallFont">{companyDetails?.company_size}</p>
                        <br />
                        <br />

                        <h2 className='colorWhite mobileHeading'>Specialities:</h2>

                        <p className="mobileContent colorWhite smallFont">{companyDetails?.specialities}</p>
                    </div>

                    <div className="modalRightContent" >
                        <div className="modalRightContentTwo">

                            <div style={{ display: 'flex', maxWidth: "400px", overflow: "hidden" }}>
                                <Box>
                                    <img src={companyDetails?.profile_image ? companyDetails.profile_image : no_user} className="profileImg" />
                                </Box>
                                <Box style={{ marginLeft: '15px', marginTop: '10px' }}>
                                    <p style={{ fontWeight: 'bold' }} className="mobileFont12">{companyDetails?.first_name} {companyDetails?.last_name}</p>
                                    <p className="font12">{companyDetails?.bio}</p>
                                </Box>
                            </div>

                            <br />
                            <br />
                        </div>


                        <div className="question">
                            <p className='colorWhite assesmentSeparation'> Assesment Questions</p>
                        </div>


                        <div className='belowQuestion' >

                            {applicantQuestions?.data?.attributes?.questions?.data.map((item: any, index: any) => {
                                return (
                                    <div className="assesmentQuestions">
                                        {item.attributes.selection_type === "Single Selection" && <Box>
                                            <h4 className="font16"> {index + 1}. {item.attributes.description}</h4>
                                            {item?.attributes?.options?.map((ele: any, ind: any) => {
                                                return (
                                                    <Box className="singleSelection" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                        <Checkbox
                                                            edge="start"
                                                            disableFocusRipple
                                                            disableTouchRipple
                                                            disableRipple
                                                            disabled={true}
                                                            checked={item.attributes.answers.description === ele.description}
                                                            checkedIcon={<RadioButtonCheckedIcon fontSize={isMobile ? "medium" : 'large'} />}
                                                            icon={<RadioButtonUncheckedIcon fontSize={isMobile ? "medium" : 'large'} />}
                                                            color="primary"
                                                        />
                                                        <label>{ele?.description}</label>
                                                    </Box>
                                                )
                                            })
                                            }
                                        </Box>}

                                        {item.attributes.selection_type === "Multi Selection" && <Box>
                                            <h4 className="font16"> {index + 1}. {item.attributes.description}</h4>
                                            {item?.attributes?.options?.map((ele: any, ind: any) => {
                                                return (
                                                    <Box className="singleSelection" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                        <Checkbox
                                                            edge="start"
                                                            disableFocusRipple
                                                            disableTouchRipple
                                                            disableRipple
                                                            disabled={true}
                                                            checked={isMultiCheck(item, ele.description)}
                                                            color="primary"
                                                        />
                                                        <label>{ele?.description}</label>
                                                    </Box>
                                                )
                                            })
                                            }
                                        </Box>}

                                        {item.attributes.question_type === 'Single line text' && <Box>
                                            <h4 className="font16"> {index + 1}. {item.attributes.description}</h4>
                                            <p style={{ color: "#8F8F8F" }} className="font12">{item?.attributes?.answers}</p>
                                        </Box>}
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>

                </div>
            </Box>
        </>
        // Customizable Area End

    )
}