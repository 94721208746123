Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.httpPutMethod = "PUT";
exports.updateProfileAPiEndPoint = 'bx_block_profile_bio/profile_bios';
exports.btnExampleTitle = "CLICK ME";

exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.profilebios = "bx_block_profile_bio/profile_bios"

exports.uploadFileEndPoint = "account_block/account_creations/board_biography";
exports.uploadFileMethodType = "POST"

exports.updatePaaswordAPI = "account_block/accounts/password";

exports.getAPIURLNotification = "bx_block_notifications/notifications";

exports.getAPIURLReadSingleNotification = "bx_block_notifications/notifications";
exports.getAPIURLReadAllNotification = "bx_block_notifications/notifications/clear_all";

exports.validationApiMethodTypeDelete = "DELETE";

exports.res_message_success = "success"
exports.res_message_error = "error"
exports.new_error = "Your new password must be at least 8 characters long.";
exports.current_error = "Field is required and cannot be empty";

exports.new_error_r = "New password must contain at least 1 uppercase character";


exports.Companyprofile_success = "Profile Updated Successfully"
exports.Companyprofile_error = "Profile Not Updated"
exports.getAPIURLActivityLog = "bx_block_activitylog2/activity_logs";

exports.APIURL_team_invite = "bx_block_followers/teams/add_member";

exports.invite_team_success = "Invite Team Successfully"
exports.invite_team_error = "Invite Team Some records might not be saved due to an error.";

exports.getApplicationsCallId = "bx_block_joblisting2/jobs/my_applications"

// Customizable Area End