import React, { BaseSyntheticEvent } from "react";
import { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Checkbox,
  Dialog,
  DialogContent,
  styled
} from "@material-ui/core";
import "./ApplicationsSection.css"
import "../../blocks/JobListing2/src/job.css"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Cross, block } from "../../blocks/JobListing2/src/assets";
import {placeholder_logo} from "../../blocks/JobListing2/src/assets"
const FormGroupLabelStyle=styled(FormControlLabel)({
 " &.MuiFormControlLabel-labelPlacementStart":{
  marginLeft:-11
 }
})

const rows = [
  { id: 'Id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'Title', numeric: true, disablePadding: false, label: 'Title' },
  { id: 'Date Applied', numeric: true, disablePadding: false, label: 'Date Applied' },
  { id: 'Company', numeric: true, disablePadding: false, label: 'Company' },
  { id: 'Status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'Action', numeric: true, disablePadding: false, label: '' },
];

const getFullDate = (created_at: string) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  return `${months[new Date(created_at).getMonth() - 1]} ${new Date(created_at).getDate()},${new Date(created_at).getFullYear()}`

}

const ApplicationsSection = (props: any) => {
  const { applications, changeStatus, searchHandler, totalApplications,checkFilterData } = props;
  const [open, setOpen] = useState(false);
  const [isEmployment, setIsEmmployment] = useState(false);
  const [details, setDetails] = useState({ company_name: "", createdAt: "", status: "", profile_image: "", id: "", job_title: "", skills: "", description: "", experience_level: "" ,placeholder_logo:""});
  const filterData = ["Answered", "Hold", "Interviewed", "Hired", "Rejected"]

  return (
    <>
      <div className="my_profile_section">
        <div className="settings_page">
          <div className="settings_page_title" style={{ paddingBottom: '30px' }}>
            <h2>Settings</h2>
          </div>
        </div>
        <div className="my_profile_tab">
          <div className="my_profile_tab_title">
            <h3>Applications</h3>
            <p style={{ color: 'gray', fontSize: '15px' }}>You can see the all Submitted aplication here</p>
          </div>
        </div>
        <Box style={{ position: "relative" }}>
          <Typography className="total_applications" style={{ borderBottom: "2px solid #537fc2", position: 'absolute', bottom: 29, color: "#537fc2", fontWeight: "bold", right: 10 }}>Application Submitted ({totalApplications})</Typography>
          <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
        </Box>
        <Box className="">
          <Grid container className="setting_aplication_grid">
            <Grid xs={12} md={3}>
              <Box className="FilterBox">
                <Typography className="FilterBox-title">Filter</Typography>
                <input type="text" className="filterInput" onChange={(e) => props.searchHandler(e)} placeholder="Company" />
              </Box>
              <Box className="FilterBox">
                <Typography className="FilterBox-title">Job Title</Typography>
                <input type="text" className="filterInput" onChange={(e) => props.jobTitleSearchHandler(e)} placeholder="Job Title" />
              </Box>
              <Box className="EmployeementBox">
                <Box className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <Typography className="EmployeementBox-title">Status</Typography> <ExpandMoreIcon onClick={() => setIsEmmployment(!isEmployment)} style={{ cursor: "pointer" }} />
                </Box>
                {isEmployment &&
                  filterData.map((item:string,id:number)=>{
                      return <FormGroup style={{flexDirection:"row"}}>
                     <FormGroupLabelStyle
                      key={`key_${id}`}
                      value={item}
                      style={{flexDirection:"row"}}
                      onClick={() => {changeStatus(item) }}
                      control={<Checkbox color="primary" checked={checkFilterData.includes(item)} />}
                      label={item}
                      labelPlacement="start"
                      data-test-id="filterDataTest"
                  />
              </FormGroup>
                })
              
                }
              </Box>
            </Grid>
            <Grid xs={12} md={9} >
              <Table className="setting_aplication_table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                      />
                    </TableCell>
                    {rows.map(
                      row => (
                        <TableCell
                          key={row.id}
                          align={row.numeric ? 'left' : 'left'}
                          padding={row.disablePadding ? 'none' : 'default'}
                        >
                          <Tooltip
                            title="Sort"
                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                          >
                            <TableSortLabel
                            >
                              {row.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applications.length > 0 && applications.map(({ attributes: { created_at, status, job: { data: { attributes: { skills, description, experience_level, job_title, id, company: { company_name, profile_image } } } } } }: any) => {

                    return (
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                          />
                        </TableCell>
                        <TableCell padding="none">
                          {id}
                        </TableCell>
                        <TableCell padding="default">
                          {job_title}
                        </TableCell>
                        <TableCell padding="default">
                          {getFullDate(created_at)}
                          {/* {new Date(created_at).getDate()+ new Date(created_at).getMonth()+new Date(created_at).getFullYear()} */}
                        </TableCell>
                        <TableCell padding="default">
                          {profile_image ? <img style={{ height: '46px' }} src={profile_image} alt="image" /> : <img style={{ height: '50px', width: '75px'}} src={placeholder_logo} alt="image" />}
                        </TableCell>
                        <TableCell padding="default">
                          {status}
                        </TableCell>
                        <TableCell padding="default">
                          <Button onClick={() => {
                            setDetails({ id: id, status: status, createdAt: created_at, profile_image: profile_image,placeholder_logo:placeholder_logo, company_name: company_name, skills: skills, description: description, experience_level, job_title: job_title })
                            setOpen(true)
                          }}>View Details</Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

              <Box style={{ marginBottom: '30px', padding: 0, display: "flex", justifyContent: "end",marginTop:"30px" }}>
                <Typography>page no. {props.currentPage}/{props.lastpage}
                </Typography>
                <Box className="box_Style">

                  <ExpandMoreIcon className="left_arrow" onClick={props.handleRev} />
                  <ExpandMoreIcon className="right_arrow" onClick={props.handleForward} />

                </Box>
              </Box>
            </Grid>
            
          </Grid>
        </Box>
        <Dialog className="model_popup job_details_modal_popup" scroll="paper" open={open}>
          <DialogContent>
            <img src={Cross} className="close_ic" onClick={() => setOpen(false)} />
            <Box className="job_details_modal">
              <Box className="job_details_modal_head">
                <Grid container spacing={2}   alignItems="center">
                <Grid item md={3} sm={12} xs={12} className="imgstyle">
                <div className= {details.profile_image?"img_bx":"img_bx_new"} ><img style={{objectFit:"contain"}} src={details.profile_image?details.profile_image:details.placeholder_logo} /></div>
                </Grid>
                <Grid item sm ={8} md={7} xs={6} className="headingstyle">
                <h4>{details.job_title}</h4>
                <p><img src={block} />{details.company_name}</p>
                        <p><img src={block} />{getFullDate(details.createdAt)}</p>
                </Grid>
                <Grid item  sm ={4} md={2} xs={6} className="hold_state" >
                <h5>{details.id}</h5>
                      <Button>{details.status}</Button>
                </Grid>
              </Grid>
              </Box>
              <Divider style={{ height: '2px', marginTop: '30px', marginBottom: '30px' }} />
              <div className="detils_mdl">
                <h5>Description </h5>
                <p>{details.description}</p>
                <h5>Experience,Skills and Qualifications</h5>
                <p>Experience : {details.experience_level}</p>
                <p>Skills: {details.skills}</p>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export { ApplicationsSection }


