import React from "react";
import { NavLink } from "react-router-dom";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Tooltip
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BoardBiographyController from "./BoardBiographyController.web";
import CreateSharpIcon from "@material-ui/icons/CreateSharp";
import "./accountCreation.css";

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#4e5da7",
      contrastText: "#fff"
    }
  }
});

export default class BoardBiography extends BoardBiographyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      backgroundImage,
      profileImage,
      aboutText,
      profileData
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.mainContainer}>
          <Box style={webStyle.accountBox}>
            <Typography variant="h3" color="primary" className="headding">
              Board Biography
            </Typography>

            <Grid container spacing={3} style={webStyle.container}>
              <Grid item xs={4} sm={4}>
                <ul className="ulList">
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnBiography"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/BoardBiography"}
                    >
                      Board Biography
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnWorkExperience"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/WorkExperience"}
                    >
                      Work expierence
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id={"btnEducation"}
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/Education"}
                    >
                      Education
                    </Button>
                  </li>
                </ul>
              </Grid>

              <Grid item xs={8} sm={8}>
                <Typography variant="h6">{profileData}</Typography>
                <form>
                  <div className="main-image-box">
                    {backgroundImage && !this.state.backgroundImageData  ? (
                      <img
                        style={webStyle.backgroundImg}
                        src={backgroundImage}
                      />
                    ) : (
                      <img
                        style={webStyle.backgroundImg}
                        src={this.state.backgroundImageData}
                      />
                    )}

                    <Tooltip title="Add Background Image">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          onChange={this.onImageChange}
                          data-test-id="backgroundImage"
                        />
                        <CreateSharpIcon color="inherit" />
                      </label>
                    </Tooltip>
                    <div>
                      <Tooltip title="Add Profile Image">
                        <label className="profile-image-box">
                          {backgroundImage && !this.state.profileImageData ? (
                            <img
                              style={webStyle.profileImg}
                              src={profileImage}
                            />
                          ) : (
                            <img
                              style={webStyle.profileImg}
                              src={this.state.profileImageData}
                            />
                          )}

                          <input
                            type="file"
                            onChange={this.onProfileImageChange}
                            data-test-id="profileImage"
                          />
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                  <Box>
                    <TextField
                      name="aboutYourself"
                      data-test-id="txtInputAboutYourself"
                      placeholder={"About Yourself"}
                      multiline
                      fullWidth={true}
                      variant="outlined"
                      value={this.state.aboutYourself || aboutText}
                      onChange={e => this.setAboutYourself(e.target.value)}
                    />
                  </Box>
                </form>
              </Grid>
            </Grid>
            <Box
              sx={{
                padding: "25px 0px",
                textAlign: "end"
              }}
            >
              <Button
                style={webStyle.nextBtn}
                data-test-id="btnNext"
                variant="contained"
                color="primary"
                onClick={() => {
                  this.AddBiography();
                }}
              >
                Save
              </Button>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                textAlign: "end"
              }}
            >
              <Button
                style={webStyle.nextBtn}
                data-test-id="btnNavigation"
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = "/WorkExperience")}
              >
                Next
              </Button>
            </Box>
          </Box>
        </div>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0"
  },
  btn: {
    background: "#536fc2",
    marginBottom: "15px",
    width: "180px"
  },
  nextBtn: {
    background: "#536fc2"
  },
  accountBox: {
    backgroundColor: "#ffff",
    margin: "80px 0",
    padding: "30px",
    width: "80%",
    borderRadius: "20px",
    boxShadow: "0 0px 10px 6px rgb(0 0 0 / 26%)"
  },
  container: {
    marginTop: "20px"
  },
  backgroundImg: {
    display: "inline-block",
    maxHeight: "144px",
    width: "100%",
    border: "1px solid gray",
    height: "150px",
    marginBottom: "70px",
    borderRadius: "4px"
  },
  profileImg: {
    width: "120px",
    height: "120px",
    borderRadius: "100px"
  }
};
// Customizable Area End
