import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  // Customizable Area Start
  Dialog,
  DialogContent,
  FormControl,
  // Customizable Area End
} from "@material-ui/core";

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, InstapaperShareButton } from 'react-share'


import "./job.css"
// Customizable Area Start
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Customizable Area End

import JobListing2Controller, {
  Props,
} from "./JobDetailsController.web";
import { Searchsvg, block, boardsiIntro, facebook, groupshape, insta, linked, location, twitter, } from "./assets";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import CustomCheckBox from "../../../components/src/CustomCheckBox.web";
import { SharePopUp } from "../../../components/src/sharePopUp";

export default class JobListing2 extends JobListing2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  statusBar = (status: string) => {
    console.log(status, "statuuss")
    switch (status) {
      case "Hired": return <Typography className="box-detail-Content-subtitle job_status_wrp_green">Hired</Typography>
      case "Answered": return <Typography className="box-detail-Content-subtitle job_status_wrp_blue">Answered</Typography>
    }
  }

  navigator = (id: number) => {
    localStorage.setItem("applicant-id", JSON.stringify(id));
    this.props.navigation.navigate("ApplicantProfile")
  }

  date = (date: string) => {
    if (new Date().getDate() !== new Date(date).getDate()) {
      return `${new Date().getMonth() - new Date(date).getMonth()} month ago`
    } else {
      return `${new Date().getDate() - new Date(date).getDate()} day ago`
    }
  }

  executive_applications() {
    const checkVal = ["Answered", "Hold", "Interviewed", "Hired", "Rejected"]
    return (
      <>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box mb={3} style={{ borderBottom: '1px solid gray', width: '90%' }}></Box>
        </Box>
        <Box className="RecentJobSection">
          <Box className="container">
            <Typography style={{ fontSize: '25px', fontWeight: 600, marginBottom: '30px' }}>Executive Applications</Typography>
            <Grid className="recentJobContainer">
              <Grid xs={12} md={3}>
                <Box className="FilterBox" style={{ margin: '0' }}>
                  <Typography className="FilterBox-title">Filter by status</Typography>
                </Box>
                <Box className="EmployeementBox">
                  <FormControl component="fieldset" style={{ marginBottom: "25px" }}>
                    <FormGroup>
                      {checkVal.map((item: string, id: number) => (
                        <FormControlLabel
                          key={`key_${id}`}
                          value={item}
                          onClick={() => { this.handleFilterToggle_executive_status(item) }}
                          control={<Checkbox color="primary" checked={this.state.checkVal.includes(item)} />}
                          label={item}
                          labelPlacement="end"
                          data-test-id="filterDataTest"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid xs={12} md={9} className="executive_search_grid">
                <Box style={{ position: "relative", margin: "0 0 40px" }} className="executive_search">
                  <input type="search" className="filterInputSearch" onChange={this.searchHandler} value={this.state.executiveSearch} placeholder="Search Executive"></input>
                  <img src={Searchsvg} />
                </Box>
                {this.state.jobApplications.length > 0 && this.state.jobApplications.map(({ attributes: { executive: { first_name, last_name, id, profile_image }, status, created_at } }: any) => {
                  return (
                    <Box className="card-box">
                      <img src={profile_image} className=" job_card_img"></img>
                      <Box className="box-detail job-box-detail_wrap">
                        <Box className="box-detail-Content">
                          <Box>
                            <Typography className="box-detail-Content-title job_details_list_title">
                              {first_name + " " + last_name}
                            </Typography>
                            {this.statusBar(status)}
                            <Typography className="job_timing_status job_detail_page_date">{this.date(created_at)}</Typography>
                          </Box>
                          <Chip style={{ borderRadius: '9px' }} label={"Full time"} />
                          <Chip style={{ borderRadius: '9px' }} label={"Senior Level"} />
                          <Chip style={{ borderRadius: '9px' }} label={"Remote"} />
                        </Box>
                      </Box>
                      <Box className="btn-group job_status_btn">
                        <Button className="CardBTN" style={{ textTransform: 'capitalize', height: "38px" }} data-test-id="see-profile-btn" onClick={() => { this.navigator(id) }}>See Profile</Button>
                      </Box>
                    </Box>
                  )
                })}

                {this.state.jobApplications.length === 0 && <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "10px", textAlign: "center" }}>
                  Data not found.
                </Typography>
                }

              </Grid>

            </Grid>
          </Box>
          <Box className="container pagination_wrp" style={{ marginBottom: '30px' }}>
            <Typography>page no. {this.state.currentPage}/{this.state.lastPage}
              <ExpandMoreIcon className="left_arrow job_detail_page_pagegination" data-test-id="go_prev_btn" onClick={this.goPrevious} />
              <ExpandMoreIcon className="right_arrow" data-test-id="go_fwd_btn" onClick={this.goForward} />
            </Typography>
          </Box>
        </Box>
      </>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        <Box style={{ display: 'flex', height: "427px", width: '100%', backgroundColor: '#d3d9e7', position: "relative" }}>
          <Box className="container new-job-bnr" style={{ maxWidth: '1280px', margin: '0 auto', position: 'relative', width: '100%', }}>
            <img className="new-job-bnr-img" style={{ height: "351px", padding: '24px', marginLeft: '110px' }} src={boardsiIntro} />
            <Typography style={{ bottom: 168, right: 106, position: "absolute", color: 'black', fontSize: '72px', fontWeight: 'bold' }}>
              Job Details
            </Typography>
            <img className="new-job-bnr-img1" src={groupshape} style={{ bottom: 61, right: 20, position: "absolute", height: "58px" }} />
          </Box>
        </Box>
        <Box className="RecentJobSection" mb={3} >
          <Box className="container">
            <Grid className="recentJobContainer">
              <Grid xs={12} md={9}>
                <Box className="developheaderCard">
                  <Box className="developheaderTitleCard">
                    <Typography className="developheaderTitle"><strong>{this.state.job_title}</strong></Typography>
                    <Box className="developheaderprofileBox">
                      <Box style={{ marginRight: '16px', display: 'flex' }}>
                        <img src={location} style={{ height: '22px' }} />
                        <Typography>{this.state.location}</Typography>
                      </Box>
                      <Box style={{ marginRight: '16px', display: 'flex' }}>
                        <img style={{ height: '22px' }} src={block} />
                        <Typography>{this.state.company.company_name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Button variant="outlined" style={{ textTransform: 'capitalize' }} onClick={this.shareModalHandler} className="sharebtn">share</Button>
                </Box>
                <Box mt={3} mb={3} style={{ borderBottom: '1px solid gray', width: '100%' }}></Box>
                <Box className="job_card">
                  <Typography className="title">Description</Typography>
                  <Typography>{this.state.description}</Typography>
                </Box>
                <Box className="job_card">
                  <Typography className="title">Experience,Skills and Qualifications</Typography>
                  <Typography className="job_card_detail">Skills: {this.state.skills.join(",")}.</Typography>
                  <Typography className="job_card_detail">Experience: {this.state.experience_level}</Typography>
                  <Typography className="job_card_detail"></Typography>
                </Box>
              </Grid>
              <Grid xs={12} className="recent_job_3" md={3} style={{ marginTop: '52px' }}>
                <Box className="AppliedJobCard">
                  <Box className="card-inner">
                    <img src={this.state.company.profile_image} className="logoImg"></img>
                    <Typography className="title">Executive Applied</Typography>
                    <Button className="cardBTN">{this.state.executive_have_applied} Executives</Button>
                  </Box>
                </Box>
                <Box className="Jobinfocard">
                  <Typography className="Jobinfocard-title"><strong>Job Information</strong></Typography>
                  <div className="Jobinfocard-deatil">
                    <ul>
                      <li>
                        <Typography>Employeement type</Typography>
                        <Typography className="Jobinfocard-deatil-data">{this.state.employment_type.employment_type_name}</Typography>
                      </li>
                      <li>
                        <Typography>Job type</Typography>
                        <Typography className="Jobinfocard-deatil-data">{this.state.job_type}</Typography>
                      </li>
                      <li>
                        <Typography>Qualification</Typography>
                        <Typography className="Jobinfocard-deatil-data">{this.state.qualifications.degree_name}</Typography>
                      </li>
                    </ul>
                  </div>
                </Box>
                <Box style={{ marginTop: '15px', display: "inline-block" }}>
                  <Typography>Share this:</Typography>
                  <Box>
                    <FacebookShareButton url={window.location.href}> <img src={facebook} style={{ height: '32px', width: '32px', margin: '4px 4px 4px 10px' }} /></FacebookShareButton>
                    <TwitterShareButton url={window.location.href}> <img src={twitter} style={{ height: '32px', width: '32px', margin: '4px 4px 4px 10px' }} /></TwitterShareButton>
                    <LinkedinShareButton url={window.location.href}><img src={linked} style={{ height: '32px', width: '32px', margin: '4px 4px 4px 10px' }} /></LinkedinShareButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {this.state.userType_role === "company" ? this.executive_applications() : ""}
        <Dialog className="model_popup" scroll="paper" open={this.state.shareModal}>
          <DialogContent>
            <div className="">
              <SharePopUp data-test-id="modal_share" location={window.location} id={this.props.navigation.getParam("id")} data={this.state.jobData} type="single" closeModal={() => { this.setState({ shareModal: false }) }} />
            </div>
          </DialogContent>
        </Dialog>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
