import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface StaticAnalytics {
  total_static_analytics_applications: number;
  accepted_applications: number;
  rejected_applications: number;
  pending_applications: number;
}


interface S {
  // Customizable Area Start
  token: any;
  userRole: string;
  first_name: string;
  applied_jobs: string;
  job_alerts: string;
  interviews: string;
  shortlisted: string;
  recenty_activity_log: {}[];
  exe_job_applied: {}[];
  job_posted: string;
  applications: string;
  month_views: string;
  company_shortlisted: string;
  recent_applicants: {}[];
  static_analytics: StaticAnalytics;
  profileViews: { count: number, month?: string, date?: string, year?: string, [key: string]: number | string | undefined }[];
  selectedFilter: string;
  url: string;
  jobID: any;
  isShare: boolean;
  viewApplication: boolean;
  selectedExecutiveId: number;
  executiveDetails: {}[];
  applicantQuestions: {}[];
  shareJob: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiGetDashboardCallId: string = "";
  apiGetActivityLogsCallId: string = "";
  apiGetJobAppliedCallId: string = "";
  apiGetRecentApplicantsCallID: string = "";
  applicationStatusCase: string = "";
  applicationDetailsCase: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      userRole: "",
      first_name: "",
      applied_jobs: "",
      job_alerts: "",
      interviews: "",
      shortlisted: "",
      recenty_activity_log: [],
      exe_job_applied: [],
      job_posted: "",
      applications: "",
      month_views: "",
      company_shortlisted: "",
      recent_applicants: [],
      executiveDetails: [],
      applicantQuestions: [],
      static_analytics: {
        "total_static_analytics_applications": 0,
        "accepted_applications": 0,
        "rejected_applications": 0,
        "pending_applications": 0
      },
      profileViews: [],
      selectedFilter: "Monthly",
      url: "",
      jobID: "",
      shareJob: [],
      isShare: false,
      viewApplication: false,
      selectedExecutiveId: 0,
    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start   
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.apiGetDataCallId) {
        this.apiGetDataCallIdFunc(responseJson)
      }
      if (apiRequestCallId === this.apiGetDashboardCallId) {
        this.setState({ applied_jobs: responseJson?.data?.applied_jobs })
        this.setState({ job_alerts: responseJson?.data?.job_alerts })
        this.setState({ interviews: responseJson?.data?.interviews })
        this.setState({ shortlisted: responseJson?.data?.shortlisted })

        this.setState({ job_posted: responseJson?.data?.job_posted })
        this.setState({ applications: responseJson?.data?.applications })
        this.setState({ month_views: responseJson?.data?.month_views })
        this.setState({ company_shortlisted: responseJson?.data?.shortlisted })
        this.setState({ static_analytics: responseJson?.data?.static_analytics })

        this.setState({ profileViews: responseJson?.data?.profile_view })
      }
      if (apiRequestCallId === this.apiGetActivityLogsCallId) {
        this.setState({ recenty_activity_log: responseJson?.data })
      }

      if (apiRequestCallId === this.apiGetJobAppliedCallId) {
        this.setState({ exe_job_applied: responseJson?.data })
      }

      if (apiRequestCallId === this.apiGetRecentApplicantsCallID) {
        this.setState({ recent_applicants: responseJson?.data })
      }

      this.applicationStatusHandler(apiRequestCallId, responseJson)
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getMenuItems_dashboard(false);
    this.get_Dashboards();
    this.get_activity_logs();
    this.get_job_applied();
    this.get_recent_applicants();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.selectedFilter !== this.state.selectedFilter) {
      this.get_Dashboards();
    }
  }


  shareJobPopUp = (data: any) => {
    this.setState({ shareJob: data, url: window.location.href, jobID: data.id }, () => {
      this.setState({ isShare: true })
    })
  }


  viewApplication = (data: any) => {
    this.applicationDetails(data.id)
    this.getMenuItems_dashboard(data.attributes.executive.id);
    this.setState({ viewApplication: true, selectedExecutiveId: data.id })
  }

  handleChange = (eeee: any) => {
    this.setState({ selectedFilter: eeee.target.value });
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems_dashboard = async (id: any) => {

    const userid = id ? id : localStorage.getItem("user_id") as string;
    const Usertoken = localStorage.getItem("authToken") as string;
    const APIheader = {
      token: Usertoken,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profilebios + '/' + userid
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(APIheader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  get_Dashboards = async () => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDashboardCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboards + '?profile_view_filter=' + this.state.selectedFilter
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  get_activity_logs = async () => {

    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityLogsCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activity_logs + '?page=1&per_page=4'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  get_job_applied = async () => {

    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetJobAppliedCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.job_applied_executive + '?page=1&per_page=2'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  get_recent_applicants = async () => {

    const token = localStorage.getItem("authToken") as string;


    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRecentApplicantsCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recent_applicants + '?page=1&per_page=2'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  applicationStatus = async (status: string, id: number) => {

    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applicationStatusCase = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting2/jobs/application_status/${id}?status=${status}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  applicationDetails = async (id: number) => {

    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applicationDetailsCase = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting2/jobs/application_details/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  apiGetDataCallIdFunc = (responseJson: any) => {
    this.setState({ first_name: responseJson?.data?.attributes?.first_name })
    if (responseJson?.data?.type === 'company') {
      this.setState({ userRole: responseJson?.data?.attributes?.user_type })
    } else {
      if (!this.state.viewApplication) {
        this.setState({ userRole: responseJson?.data?.attributes?.user_type })
      }
      this.setState({ executiveDetails: responseJson?.data?.attributes })
    }
  }

  applicationStatusHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.applicationStatusCase) {
      this.get_recent_applicants();
    }

    if (apiRequestCallId === this.applicationDetailsCase) {
      this.setState({ applicantQuestions: responseJson })
    }
  }

  // Customizable Area End

}