import React from "react";

import {
  Box,
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./job.css"
// Customizable Area End

import JobListing2Controller, {
  Props,
} from "./ApplicationProfileController.web";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import { ApplicationHeader } from "../../../components/src/ApplicationHeader";

export default class JobListing2 extends JobListing2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        <ApplicationHeader
          user_name={this.state.user_name}
          profile_image={this.state.profile_image}
          background_image={this.state.background_image}
          about_yourself={this.state.about_yourself}
          user_role={this.state.user_role}
          tagline={this.state.tagline}
        />
        <Box style={{ maxWidth: '1200px', margin: '0px auto', width: '100%' }}>
          <Box style={{ padding: '30px', background: '#f2f2f2', margin: '50px auto', }}>
            <Typography variant="h4" style={{ marginBottom: '20px' }}><strong>Work Experience</strong></Typography>
            
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
            <Grid container spacing={5} style={{ maxHeight:"500px", overflow:"auto"}}>
              {this.state.workExperiences.data && this.state.workExperiences.data.map(({ attributes: { title, company_name, location, skill_set, employment_type: { employment_type_name }, start_date, start_year, end_date, end_year } }: any) => {
                return (<>
                  <Grid item md={12} xs={12} >
                    <Box style={{ width: '100%', padding: '15px', border: '1px solid #c6c7e8', borderRadius: '5px' }}>
                      <Box style={{ display: 'flex' }}>
                        <Box style={{ flex: 1 }}>
                          <Typography variant="h5"><strong>{title}</strong></Typography>
                          <Typography variant="h6" style={{ margin: '10px 0' }}>{company_name} - {employment_type_name}</Typography>
                          <Typography style={{ color: 'gray', fontSize: '14px' }}>{start_date + " " + start_year} - {end_date + " " + end_year}</Typography>
                          <Typography style={{ color: 'gray', fontSize: '14px' }}>{location}</Typography>
                          <Typography style={{ color: 'gray', fontSize: '14px' }}><strong>Skill : </strong>{skill_set}</Typography>
                        </Box>
                       </Box>
                    </Box>
                  </Grid>
                </>)
              })}
            </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
            <Grid container spacing={5}>
              <Grid item md={12} xs={12}>
                <Box style={{ width: '100%', padding: '15px', border: '1px solid #c6c7e8', borderRadius: '5px' }}>
                  <Typography variant="h5" style={{ marginBottom: '30px' }}><strong>Applied Positions</strong></Typography>
                  {this.state.appliedJobs.map(({attributes: {job: {data:{attributes: {job_title,id}}}}}:any) => {
                    return<Typography onClick={()=>this.navigator(id)} style={{ fontSize: '16px', padding: '15px', border: '1px solid #c6c7e8', marginBottom: '10px', borderRadius: '5px' }}>{job_title}</Typography>
                  })} 
                 </Box>
              </Grid>
              </Grid>
              </Grid>
              </Grid>
          </Box>
          <Box style={{ padding: '30px', background: '#f2f2f2', margin: '50px auto', }}>
            <Typography variant="h4" style={{ marginBottom: '20px' }}><strong>Education</strong></Typography>
            {this.state.educations.data && this.state.educations.data.map(({ attributes: { school_name, start_date, start_year, end_date, end_year, degree: { degree_name }, location } }: any) => {
              return (<Box style={{ width: '100%',marginBottom:"25px", padding: '15px', border: '1px solid #c6c7e8', borderRadius: '5px' }}>
                <Box>
                  <Box>
                    <Typography variant="h5"><strong>{school_name}</strong></Typography>
                    <Typography variant="h6" style={{ margin: '10px 0' }}>{degree_name}</Typography>
                    <Typography style={{ color: 'gray', fontSize: '14px' }}>{start_date+" "+start_year} - {end_date+" "+end_year}</Typography>
                    <Typography style={{ color: 'gray', fontSize: '14px' }}>{location}</Typography>
                  </Box>
                  <Box>
                  </Box>
                </Box>
              </Box>
              )
            })}
          </Box>
        </Box>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
