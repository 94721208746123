import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EducationController from "./EducationController.web";
import CloseIcon from "@material-ui/icons/Close";
import "./accountCreation.css";

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#4e5da7",
      contrastText: "#fff",
    },
  },
});

export default class Education extends EducationController {
  constructor(props: Props) {
    super(props);
  }

  renderDeleteDialog() {
    const { deleteId } = this.state;
    return (
      <>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              data-test-id="btnNo"
              onClick={this.handleClose}
              color="primary"
            >
              No
            </Button>
            <Button
              data-test-id="btnYes"
              onClick={() => {
                this.delete(deleteId);
                this.handleClose();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  renderAlert() {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {this.state.deleteMessage}
      </Alert>
    );
  }

  renderEditDialog() {
    return (
      <>
        <Dialog open={this.state.openEditDialog} onClose={this.handleEditClose}>
          <div className="container">
            <DialogTitle>Edit Education</DialogTitle>
            <CloseIcon onClick={this.handleEditClose} />
          </div>
          <DialogContent>
            <Grid item xs={8} sm={8}>
              <TextField
                style={webStyle.textfield}
                name="degree"
                data-test-id="txtInputEditDegree"
                placeholder={"Degree"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.degree}
                onChange={(e) => this.onHandleEditChange(e)}
              />
              <TextField
                style={webStyle.textfield}
                name="institute"
                data-test-id="txtInputInstituteEdit"
                placeholder={"Institute"}
                fullWidth={true}
                variant="outlined"
                value={this.state.editFormData.institute}
                onChange={(e) => this.onHandleEditChange(e)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={webStyle.textfield}
                  name="from"
                  data-test-id="txtInputFromEdit"
                  placeholder={"From"}
                  type="date"
                  fullWidth={true}
                  variant="outlined"
                  value={moment(this.state.editFormData.from).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => this.onHandleEditChange(e)}
                />
                <TextField
                  style={webStyle.textfield}
                  name="to"
                  data-test-id="txtInputToEdit"
                  placeholder={"To"}
                  type="date"
                  fullWidth={true}
                  variant="outlined"
                  value={moment(this.state.editFormData.to).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => this.onHandleEditChange(e)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  data-test-id="currentlyWorkingEdit"
                  color="primary"
                  onClick={() =>
                    this.setCurrentlyEditWorking(
                      !this.state.currently_studying_here
                    )
                  }
                  checked={this.state.currently_studying_here}
                />
                <span className="label-wrap">Currently studying here</span>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              data-test-id="btnEditEducation"
              onClick={() => {
                this.updateEducationData(this.state.editId);
                this.handleEditClose();
              }}
              color="primary"
              autoFocus
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  render() {
    const { education, deleteMessage } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.mainContainer}>
          <Box style={webStyle.accountBox}>
            <Typography variant="h3" color="primary" className="headding">
              Education
            </Typography>
            {this.renderDeleteDialog()}
            {this.renderEditDialog()}
            {deleteMessage ? this.renderAlert() : ""}
            <Grid container spacing={3}>
              {education.map((data: any) => {
                const { id, attributes } = data;
                return (
                  <Grid item xs={4} sm={4} key={id}>
                    <div className="content">
                      <div className="typography">
                        <Typography>Degree:&nbsp;</Typography>
                        <Typography>{attributes.degree}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>Institute:&nbsp;</Typography>
                        <Typography>{attributes.institute}</Typography>
                      </div>
                      <div className="typography">
                        <Typography>From:&nbsp;</Typography>
                        <Typography>
                          {attributes.from}&nbsp;
                          {attributes.to}
                        </Typography>
                      </div>
                      <div className="buttons">
                        <Button
                          style={webStyle.cancleBtn}
                          data-test-id="btnDelete"
                          variant="contained"
                          onClick={() => {
                            this.deleteRecord(id);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          style={webStyle.nextBtn}
                          data-test-id="btnEdit"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.editNavigation(id, education);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={3} style={webStyle.container}>
              <Grid item xs={4} sm={4}>
                <ul className="ulList">
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id="btnBiography"
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/BoardBiography"}
                    >
                      Board Biography
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id="btnWorkExperience"
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/WorkExperience"}
                    >
                      Work expierence
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={webStyle.btn}
                      data-test-id="btnEducation"
                      variant="contained"
                      color="primary"
                      component={NavLink}
                      to={"/Education"}
                    >
                      Education
                    </Button>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  style={webStyle.textfield}
                  name="degree"
                  data-test-id="txtInputDegree"
                  placeholder={"Degree"}
                  fullWidth={true}
                  variant="outlined"
                  value={this.state.formData.degree}
                  onChange={(e) => this.onHandleChange(e)}
                />
                <TextField
                  style={webStyle.textfield}
                  name="institute"
                  data-test-id="txtInputInstitute"
                  placeholder={"Institute"}
                  fullWidth={true}
                  variant="outlined"
                  value={this.state.formData.institute}
                  onChange={(e) => this.onHandleChange(e)}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={webStyle.textfield}
                    name="from"
                    data-test-id="txtInputFrom"
                    placeholder={"From"}
                    type="date"
                    fullWidth={true}
                    defaultValue={new Date()}
                    variant="outlined"
                    value={this.state.formData.from}
                    onChange={(e) => this.onHandleChange(e)}
                  />
                  <TextField
                    style={webStyle.textfield}
                    name="to"
                    data-test-id="txtInputTo"
                    placeholder={"To"}
                    type="date"
                    fullWidth={true}
                    defaultValue={new Date()}
                    variant="outlined"
                    value={this.state.formData.to}
                    onChange={(e) => this.onHandleChange(e)}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    data-test-id="currentlyWorking"
                    color="primary"
                    onClick={() =>
                      this.setCurrentlyWorking(!this.state.checkedStudying)
                    }
                    checked={this.state.checkedStudying}
                  />
                  <span className="label-wrap">Currently studying here</span>
                </Box>
                <Box
                  sx={{
                    padding: "25px 0px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    style={webStyle.nextBtn}
                    data-test-id="btnNext"
                    variant="contained"
                    color="primary"
                    onClick={() => this.AddEducation()}
                  >
                    Done
                  </Button>
                </Box>
                <Box
                  sx={{
                    padding: "25px 0px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    style={webStyle.nextBtn}
                    data-test-id="btnNavigation"
                    variant="contained"
                    color="primary"
                    onClick={() => (window.location.href = "/")}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
  },
  btn: {
    background: "#536fc2",
    marginBottom: "15px",
    width: "180px",
  },
  nextBtn: {
    background: "#536fc2",
  },
  accountBox: {
    backgroundColor: "#ffff",
    margin: "80px 0",
    padding: "30px",
    width: "80%",
    borderRadius: "20px",
    boxShadow: "0 0px 10px 6px rgb(0 0 0 / 26%)",
  },
  container: {
    marginTop: "20px",
  },
  backgroundImg: {
    width: "100%",
    border: "1px solid gray",
    height: "150px",
    marginBottom: "70px",
    borderRadius: "4px",
  },
  backgroundImgUpload: {
    display: "inline-block",
    padding: "12px 12px",
    cursor: "pointer",
    background: "#536fc2",
  },
  profileImg: {
    width: "150px",
    height: "150px",
    borderRadius: "100px",
  },
  textfield: {
    marginBottom: "20px",
    marginLeft: "10px",
  },
  btnAddSkills: {
    background: "#536fc2",
    marginLeft: "10px",
    width: "30%",
  },
  addSkills: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "10px",
  },
  cancleBtn: {
    background: "#ef5151",
    color: "#fff",
    margin: "6px",
  },
};
// Customizable Area End
