import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import * as Yup from "yup";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import JobListing2Controller, {
  Props,
} from "./NewJobController.web";
import { deleteIcon, boardsiIntro, groupshape, Plus } from "./assets";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import { Formik } from "formik";
import { CustomAccSelect, CustomAccTextField } from "../../../components/src/CustomStyle.web";
import { QuestionsCard } from "../../../components/src/QuestionsCard";
import { ButtonSection, NewJobForm } from "../../../components/src/NewJobForm";
import "./job.css"
// Customizable Area End

export default class JobListing2 extends JobListing2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (e: any, value: string) => {
    this.setState({ chooseVal: value })
  }

  QuestionSection = (chooseBoxes: any, i: number, data: any) => {
    return <QuestionsCard
      data-test-id="Question-Card"
      data={data}
      key_i={i}
      addNewOptionBox={() => this.addNewOptionBox(i, chooseBoxes)}
      editOption={(e: any, index: any) => this.editOption(i, index, e, chooseBoxes)}
      addBox={() => chooseBoxes[0].selection_type === "Single Selection" ? this.addSingleBox() : this.addMultiChoiceBox()}
      manageOption={(optionIndex: number) => { this.manageOption(i, chooseBoxes, optionIndex) }}
      updateQuestion={(e: React.BaseSyntheticEvent) => this.updateMultipleChoiceQuestion(e, i, chooseBoxes)}
      deleteBox={() => { this.deleteBox(i, chooseBoxes) }}
    />
  }

  saveButton = () => {
    return (
      <Grid item sm={12} style={{ textAlign: "center" }}>
        <Button
          type="submit"
          style={{
            letterSpacing: "2px",
            width: "200px",
            textTransform: "capitalize",
            marginTop: "20px"
          }}
          data-test-id={"btnContactUs"}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Grid>
    )
  }

  chooseBoxes = (state: any) => {
    if (state === "chooseOne") {
      return (
        <>
          {this.state.chooseOneBoxes.map((data: { question_type: string, selection_type: string, number_of_options: number, description: string, options: any }, i: number) => {
            return (
              <>
                {this.QuestionSection(this.state.chooseOneBoxes, i, data)}
              </>
            )
          })}

        </>
      )
    } else if (state === "text") {
      return (
        <>
          {this.textBoxes_fun()}
        </>
      )
    } else if (state === "chooseMultiple") {
      return <>
        {this.state.chooseMultipleBoxes.map((data: { question_type: string, selection_type: string, number_of_options: number, description: string, options: any }, i: number) => {
          return (
            <>
              {this.QuestionSection(this.state.chooseMultipleBoxes, i, data)}
            </>
          )
        })}
      </>
    } else if (state === "Multi_text") {
      return (
        <>
          {this.MultitextBoxes_fun()}
        </>
      )
    }
  }

  textBoxes_fun() {
    return (
      <>
        {
          this.state.textBoxes.map((data: { question_type: string, description: string }, i: number) => {
            if (data.question_type === "Single line text") {
              return (
                <Box style={{ width: '100%', position: 'relative' }} className="tab_details_box one_choicebox">
                  <Box>
                    <textarea
                      style={{ resize: 'none', padding: "12px", borderRadius: "10px" }}
                      data-test-id="text_area_new_job"
                      placeholder="Start your question"
                      value={data.description}
                      onChange={(event) => this.updateTextQuestion(event, i)}
                    />
                  </Box>
                  <Grid xs={12}>
                    {i !== 0 ? (
                      <Box className="question_text_footer" onClick={() => this.deleteTextBox(i)}>
                        <img src={deleteIcon} alt="" style={{cursor: 'pointer'}} />
                      </Box>
                    ) : <Box className="question_text_footer">
                      <img src={deleteIcon} alt="" style={{cursor: 'pointer'}} />
                    </Box>}
                  </Grid>
                  <img src={Plus} onClick={this.addNewTextBox} style={{ height: '20px', position: 'absolute', top: '12px', right: '15px', cursor: 'pointer' }} />
                </Box>
              );
            }
          })
        }
      </>
    )
  }

  MultitextBoxes_fun() {
    return (
      <>
        {this.state.MultitextBoxes.map((data: { question_type: string, description: string }, i: number) => {
          if (data.question_type === "Multi line text") {
            return (
              <Box style={{ width: '100%', position: 'relative' }} className="tab_details_box one_choicebox">
                <Box>
                  <textarea
                    style={{ resize: 'none', padding: "12px", borderRadius: "10px" }}
                    data-test-id="Multitext-area"
                    value={data.description}
                    placeholder="Start your question"
                    onChange={(event) => this.updateMultiTextQuestion(event, i)}
                  />
                </Box>
                <Grid xs={12}>
                  {i !== 0 ? (
                    <Box className="question_text_footer" onClick={() => this.deleteMultiTextBox(i)}>
                      <img src={deleteIcon} alt="" style={{cursor: 'pointer'}} />
                    </Box>
                  ) : <Box className="question_text_footer">
                    <img src={deleteIcon} alt="" style={{cursor: 'pointer'}} />
                  </Box>}
                </Grid>
                <img src={Plus} onClick={this.addNewMultiTextBox} style={{cursor: 'pointer', height: '20px', position: 'absolute', top: '12px', right: '15px' }} />
              </Box>
            );
          }
        })}
      </>
    );
  }

  jobCreationSchema = Yup.object().shape({
    job_title: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    employment_type_id: Yup.string().required("This field is required"),
    industry_id: Yup.string().required("This field is required"),
    experience_level: Yup.string().required("This field is required"),
    job_type: Yup.string().required("This field is required"),
    qualification_id: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    skills: Yup.string().required("This field is required"),
    salary_range_start: Yup.string().required("This field is required"),
    salary_range_end: Yup.string().required("This field is required")
  })
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        <Box style={{ display: 'flex', height: "427px", width: '100%', backgroundColor: '#d3d9e7', position: "relative" }}>
          <Box className="container new-job-bnr" style={{ maxWidth: '1280px', margin: '0 auto', position: 'relative', width: '100%', }}>
            <img className="new-job-bnr-img" style={{ height: "351px", padding: '24px', marginLeft: '110px' }} src={boardsiIntro} />
            <Typography style={{ bottom: 168, right: 106, position: "absolute", color: 'black', fontSize: '72px', fontWeight: 'bold' }}>
              New Job
            </Typography>
            <img className="new-job-bnr-img1" src={groupshape} style={{ bottom: 61, right: 20, position: "absolute", height: "58px" }} />
          </Box>
        </Box>
        <Formik
          initialValues={this.state.job}
          validationSchema={this.jobCreationSchema}
          data-test-id="formik-test-id-new_job"
          onSubmit={values => {
            this.createJob(values)
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box>
              <NewJobForm
                touched={touched}
                errors={errors}
                designation={this.state.designation_data}
                employmentIds={this.state.employmentIds}
                job_Type={this.state.job_Type}
                degreeIds={this.state.degreeIds}
                values={values}
                entry_level={this.state.entry_level}
                industryIds={this.state.industryIds}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                this_current={this}
              />
            </Box>
            <div className="new_job_box">
              <Box className="container" style={{ display: 'block', marginBottom: '50px' }}>
                <Box className="question_tabs" style={{ width: '100%', borderBottom: "2px solid #3f51b5" }}>
                  <Tabs
                    value={this.state.chooseVal}
                    onChange={this.handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    className="tabs_main_div"
                  >
                    <Tab className="Tab-btn question_tab_btn" value="text" label="Single line text" />
                    <Tab className="Tab-btn question_tab_btn" value="Multi_text" label="Multi line text" />
                    <Tab className="Tab-btn question_tab_btn" value="chooseOne" label="Choose One" />
                    <Tab className="Tab-btn question_tab_btn" value="chooseMultiple" label="Choose Multiple" />
                  </Tabs>
                </Box>
                {this.chooseBoxes(this.state.chooseVal)}
              </Box>
            </div>
            <ButtonSection chooseVal={this.state.chooseVal} />
          </form>
        )}
        </Formik>

        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
