import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { navebarLogo, contact_us, droparrow, logout, msgicon, myactivity, profile, notifications, settings, view_user } from "../../blocks/navigationmenu/src/assets";
import { AppBar,Dialog, Toolbar, Container, Grid, Button, Typography, IconButton, Menu, MenuItem, Drawer, List, Badge, Divider, ListItemText, ListItem, ListItemIcon, Popover, Collapse } from "@material-ui/core";
import { Menu as MenuIcon } from '@material-ui/icons';
import {
    makeStyles,
    createTheme
} from "@material-ui/core/styles";
import "./navbarweb.css";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LogoutModal } from "./LogoutModal";
import { ViewApplication } from "./ViewApplication";
import { CompanyPopUp } from "./CompanyPopUp.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#536fc2",
        },
    },
});

const useStyles = makeStyles({
    appBar: {
        backgroundColor: "#d3d9e7",
        padding: "15px 0px",
    },
    container: {
        placeItems: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tabContainer: {
        textAlign: "center",
    },

    tabRoot: {
        textTransform: "inherit",
        fontSize: "13px",
        margin: "0px 17px",
        color: "#536fc2",
        borderBottom: '2px solid #d3d9e7',
        borderRadius: "0px",
        "&:hover": {
            borderBottom: '2px solid #536fc2',
            borderRadius: "0px"
        },
        '@media (max-width: 1080px)': {
            margin: "0px 3px",
        },
        '@media (max-width: 1180px)': {
            margin: "0px 12px",
        }
    },
    activeTab: {
        borderBottom: '2px solid #536fc2',
        borderRadius: "0px",
        fontWeight: "bold"
    },
    btn: {
        whiteSpace: "nowrap",
        // textTransform: "capitalize",
        background: "linear-gradient(-40deg, #8199e1, #536fc2 100%) no-repeat",
        color: "#fff",
        "&:hover": {
            background: '#8199e1'
        }
    },
    submenuicon: {
        padding: "2px 10px 3px 0px",
        color: "#536fc2",
    },

    notificationCount: {
        background: "#5e2ee3",
        width: "20px",
        borderRadius: "50%",
        fontSize: "10px",
        color: "white",
        padding: "3px",
        position: "absolute",
        right: "0px",
        top: "14px",
        "font-weight": "800",
    },

    notificationCountMobile: {
        top: "-16px",
        color: "white",
        left: "10px",
        width: "20px",
        padding: "3px",
        position: "absolute",
        fontSize: "10px",
        background: "#5e2ee3",
        "font-weight": "800",
        borderRadius: "100%",
        textAlign: "center",
    },


    notificationList:{background:"#5370C2",
    color:"white",
    opacity:"0.9",
    borderBottom:"2px solid white",
    borderRadius:"7px",
    "&:hover": {
        cursor: "pointer",
    }
}
});




const Navbarweb = (props: any) => {
    const { items, first_name, role, notification_data, company_name,unReadNotificationCount,readSingleNotification,onViewPopUp ,viewApplication,closeModal,applicantQuestions,executiveDetails} = props
    const classes = useStyles();
    const [activeUrl, setActiveUrl] = useState('');
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isOpen,setIsOpen] = useState(false)
    const [anchorEl_notifications, setAnchorEl_notifications] = useState<HTMLElement | null>(null);
    const isMobile = window.matchMedia('(max-width:1024px)').matches;

    const [SubmenuOpen, setSubMenuOpen] = useState(false); // To control the sub-menu

    // Get the current location object
    const location = props.location;

    // Extract the pathname from the location object
    const currentPathname = location.pathname;

    // Update the activeUrl state with the current URL slug
    React.useEffect(() => {
        setActiveUrl(currentPathname);
    }, [currentPathname]);

    const handleMenuOpen = (event: React.MouseEvent<Element>) => {
        if (isMobile) {
            setMobileMenuOpen(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleSubMenuOpen = () => {
        setSubMenuOpen(!SubmenuOpen); // Toggle the sub-menu state
    };


    const handleOpen_notifications = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl_notifications(event.currentTarget);
    };

    const handleClose_notifications = () => {
        setAnchorEl_notifications(null);
    };
    const open = Boolean(anchorEl_notifications);

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const onNotificationClick=(item:any)=>{
        readSingleNotification(item.id)
              if (item.attributes.headings === "Nudge Reminder"||item.attributes.headings === "Applied for Job") { 
                  onViewPopUp(item.attributes.app_url)
              } else {
                  setTimeout(() => {
                      if (item.attributes.app_url.type === 'job_details') {
                          window.location.href = "JobDetails/" + item.attributes.app_url.job_id
                      }
                  }, 500);
              }
      
          }


    return (
        <div className="header_row_dev">
            <Container style={{ backgroundColor: '#d3d9e2' }} >
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        <Grid container className={classes.container}>
                            <Grid item xs={2}>
                                <img src={navebarLogo} alt="Logo" height="32" />
                            </Grid>
                            {isMobile ? (
                                <>
                                    <IconButton onClick={toggleMobileMenu} color="inherit">
                                        <MenuIcon className="menuicon_drawer_mobile" />
                                    </IconButton>
                                    <Drawer anchor="right" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>

                                        <List>
                                            {items?.map((item: any, index: any) => (
                                                <ListItem button component={Link} to={item.url} key={index}>
                                                    <ListItemText primary={item.name} />
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Divider />


                                        <List>
                                            <ListItem button onClick={handleOpen_notifications}>
                                                <ListItemIcon style={{position:"relative"}}>
                                                    <Badge color="secondary">
                                                        <img src={notifications} alt="notifications" height="20" />
                                                    </Badge>
                                                    {unReadNotificationCount?<span className={classes.notificationCountMobile}>{unReadNotificationCount}</span>:''}
                                                </ListItemIcon>
                                                <ListItemText primary="Notifications" />
                                            </ListItem>

                                            <ListItem button onClick={handleSubMenuOpen}>
                                                <img src={props.profileImage ? props.profileImage : view_user} alt="user" style={{ width: '40px', height: '40px', borderRadius: '30px', marginRight: "5px", objectFit:"cover" }} />
                                                {role === "company" ? company_name : first_name}
                                                {SubmenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </ListItem>
                                            <Collapse in={SubmenuOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        role != 'company' ? <ListItem button onClick={handleMenuOpen}>
                                                            <ListItemText primary="Profile" />
                                                        </ListItem> : ''
                                                    }
                                                    <ListItem button component={Link} to="/Settings5/1">
                                                        <ListItemText primary=" My Activity" />
                                                    </ListItem>
                                                    <ListItem button component={Link} to="/Settings5/0">
                                                        <ListItemText primary="Settings" />
                                                    </ListItem>
                                                    <ListItem button component={Link} to="/Contactus">
                                                        <ListItemText primary="Contact Us" />
                                                    </ListItem>
                                                    <MenuItem onClick={handleOpen}>
                                                        Logout
                                                    </MenuItem>
                                                </List>
                                            </Collapse>
                                        </List>
                                    </Drawer>
                                    <Popover
                                        open={open}
                                        anchorEl={anchorEl_notifications}
                                        onClose={handleClose_notifications}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <List>
                                            {notification_data?.map((itemi: any, index: number) => (
                                                <ListItem key={itemi.id} className={itemi.attributes.is_read? '':classes.notificationList} style={{cursor:'pointer'}} onClick={()=>onNotificationClick(itemi)}>
                                                    <img src={notifications} style={{ paddingRight: '15px' }} alt="notifications" height="20" />
                                                    <ListItemText primary={itemi.attributes.contents} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Popover>
                                    <Dialog fullWidth className="model_popup" scroll="body" open={viewApplication}  >
                                        <div className="">
                                            {role === "company" ? <ViewApplication data-test-id="viewPopup" applicantQuestions={applicantQuestions} executiveDetails={executiveDetails} closeModal={() => { closeModal() }} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />
                                                : <CompanyPopUp data-test-id="companyPopup" applicantQuestions={applicantQuestions} companyDetails={executiveDetails} closeModal={() => { closeModal() }} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />

                                            }
                                        </div>
                                    </Dialog>
                                </>
                            ) : (
                                <>
                                    <Grid item md={7} className={classes.tabContainer}>
                                        {items?.map((item: any, index: any) => (
                                            <Button component={Link} className={`${classes.tabRoot} ${item.url === activeUrl ? classes.activeTab : ''}`}
                                                to={item.url} key={index} startIcon={item.icon}
                                                onClick={() => setActiveUrl(item.url)}>
                                                {item.name}

                                            </Button>
                                        ))}
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className="nav_icon_btn_class">
                                            <IconButton onClick={handleOpen_notifications}>

                                                <Badge color="primary">
                                                    <img src={notifications} alt="notifications" width="20" />
                                                </Badge>
                                                {unReadNotificationCount?<span className={classes.notificationCount}>{unReadNotificationCount}</span>:''}
                                            </IconButton>
                                            <Popover
                                                open={open}
                                                anchorEl={anchorEl_notifications}
                                                onClose={handleClose_notifications}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                {notification_data?<List>
                                                    {notification_data?.map((itemi: any, index: number) => (
                                                     <ListItem key={itemi.id} className={itemi.attributes.is_read? '':classes.notificationList} onClick={()=>onNotificationClick(itemi)} >
                                                            <img src={notifications} style={{ paddingRight: '15px' }} alt="notifications" height="20" />
                                                            <ListItemText  primary={itemi.attributes.contents} />
                                                        </ListItem>
                                                    ))}
                                                </List>:
                                                <List>
                                                    <ListItem>
                                                        <img src={notifications} style={{ paddingRight: '15px' }} alt="notifications" height="20" />
                                                        <ListItemText  primary={"No notifications found"} />
                                                    </ListItem>
                                            </List>
                                                }
                                            </Popover>
                                            <div className="vertical-line"></div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ fontSize:'15px', color: '#000', paddingLeft: '15px' }}>
                                                    {role === "company" ? company_name : first_name}
                                                </Typography>
                                                <IconButton onClick={handleMenuOpen} >
                                                    <img src={props.profileImage ? props.profileImage : view_user} alt="user" style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                                                    <img src={droparrow} alt="user" height="5" style={{ paddingLeft: '10px' }} />
                                                </IconButton>
                                            </div>


                                            <Menu
                                                className="header_dropdown_profile"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                keepMounted
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                open={Boolean(anchorEl || mobileMenuOpen)}
                                                onClose={handleMenuClose}
                                            >
                                                {
                                                    role != 'company' ? <MenuItem onClick={handleMenuClose}><Link className="menu_drop_down_prfile" to="/Settings5/0"><img src={profile} className={classes.submenuicon} alt="profile" height="20" /> Profile</Link></MenuItem> : ''
                                                }

                                                <MenuItem onClick={handleMenuClose}><Link className="menu_drop_down_prfile" to="/Settings5/1"><img src={myactivity} className={classes.submenuicon} alt="myactivity" height="20" /> My Activity</Link></MenuItem>
                                                <MenuItem onClick={handleMenuClose}><Link className="menu_drop_down_prfile" to="/Settings5/0"><img src={settings} className={classes.submenuicon} alt="settings" height="20" /> Settings</Link></MenuItem>
                                                <MenuItem onClick={handleMenuClose}><Link className="menu_drop_down_prfile" to="/Contactus"><img src={contact_us} className={classes.submenuicon} alt="contact_us" height="20" /> Contact Us</Link></MenuItem>
                                                <MenuItem onClick={handleOpen}><div className="menu_drop_down_prfile"><img src={logout} className={classes.submenuicon} alt="logout" height="20" /> Logout</div></MenuItem>
                                            </Menu>
                                        </div>
                                        </Grid>
                                        <Dialog fullWidth className="model_popup" scroll="body" open={viewApplication}  >
                                            <div className="">
                                              {role==="company"  ?<ViewApplication data-test-id="viewPopup" applicantQuestions={applicantQuestions} executiveDetails={executiveDetails} closeModal={() => { closeModal()}} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />
                                                       : <CompanyPopUp data-test-id="companyPopup" applicantQuestions={applicantQuestions} companyDetails={executiveDetails} closeModal={() => { closeModal()}} selectedExecutiveId={0} onApplicationCLick={() => { }} recentApplicants={false} isDashboard={false} />

}
</div>
                                        </Dialog>
                                    </>
                            )}
                        </Grid>
                    </Toolbar>
                </AppBar >
                <LogoutModal open={isOpen} closeModal={handleOpen} />
            </Container>
        </div>
    );
}


export default withRouter(Navbarweb);
