import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: any;
  checkedStudying: boolean;
  education: any[];
  openDialog: boolean;
  deleteId: any;
  deleteMessage: any;
  editFormData: any;
  openEditDialog: boolean;
  editId: any;
  currently_studying_here: boolean;
  errorMessage: any;
  openAddDialog: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EducationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addEducationApiCallId: any;
  getEducationApiCallId: any;
  DeleteApiCallId: any;
  updateApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formData: {
        degree: "",
        institute: "",
        from: "",
        to: "",
      },
      editFormData: {
        degree: "",
        institute: "",
        from: "",
        to: "",
      },
      education: [],
      token: "",
      checkedStudying: false,
      openDialog: false,
      deleteId: "",
      deleteMessage: "",
      openEditDialog: false,
      editId: "",
      currently_studying_here: false,
      errorMessage: "",
      openAddDialog: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const authTokenReq = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(authTokenReq);
  }

  onHandleChange = (e: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onHandleEditChange = (e: any) => {
    this.setState({
      editFormData: {
        ...this.state.editFormData,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleEditClose = () => {
    this.setState({ openEditDialog: false });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      const token = localStorage.getItem("authToken") as string;
      this.setState({ token: token }, () => {
        this.getAllEducationData();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.getEducationApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data = item[1];
              this.setState({ education: data });
            });
          }
        }
        if (apiRequestCallId === this.addEducationApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data = item[1];
              this.setState({
                education: [...this.state.education, data],
              });
              this.getAllEducationData();
              this.setState({
                formData: {
                  degree: "",
                  institute: "",
                  from: "",
                  to: "",
                },
              });
              this.setState({ checkedStudying: false });
            });
          }
        }
        if (apiRequestCallId === this.DeleteApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              this.setState({ deleteMessage: item[1] });
            });
          }
          this.getAllEducationData();
        }
        if (apiRequestCallId === this.updateApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data = item[1];
              this.setState({
                education: [...this.state.education, data],
              });
              this.getAllEducationData();
            });
          }
        }
      }

      runEngine.debugLog("API Message Recived", message);

      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  editNavigation = (id: any, item: any) => {
    const array = item.filter((obj: any) => obj.id === id);
    const data = array[0].attributes;
    this.setState({
      editFormData: {
        degree: data.degree,
        institute: data.institute,
        from: data.from,
        to: data.to,
        currently_studying_here: data.currently_studying_here,
      },
    });
    this.setState({ currently_studying_here: data.currently_studying_here });
    this.setState({ editId: id });
    this.setState({ openEditDialog: true });
  };

  setCurrentlyWorking = (value: boolean) => {
    this.setState({ checkedStudying: value });
  };

  setCurrentlyEditWorking = (value: boolean) => {
    this.setState({ currently_studying_here: value });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  AddEducation(): boolean {
    if (
      this.isStringNullOrBlank(this.state.formData.degree) ||
      this.isStringNullOrBlank(this.state.formData.institute) ||
      this.isStringNullOrBlank(this.state.formData.from) ||
      this.isStringNullOrBlank(this.state.formData.to)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token,
      };

      const attrs = {
        data: {
          attributes: {
            degree: this.state.formData.degree,
            institute: this.state.formData.institute,
            from: this.state.formData.from,
            to: this.state.formData.to,
            currently_studying_here: this.state.checkedStudying,
          },
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addEducationApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.AddEducation
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  getAllEducationData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddEducation
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateEducationData(Id: any) {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const attrs = {
      data: {
        attributes: {
          degree: this.state.editFormData.degree,
          institute: this.state.editFormData.institute,
          from: this.state.editFormData.from,
          to: this.state.editFormData.to,
          currently_studying_here: this.state.currently_studying_here,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddEducation + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putPostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteRecord(Id: any) {
    this.setState({ deleteId: Id });
    this.setState({ openDialog: true });
  }

  delete(Id: any) {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddEducation + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getAllEducationData();
    return true;
  }

  // Customizable Area End
}
