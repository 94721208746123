import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}


interface S {
    // Customizable Area Start
    tearm_data: string;
    forget_email: string;
    CollapseOpen: boolean;
    response_message: string;
    res_message: string;
    enablePasswordField: boolean;
    enableconfirmPasswordField: boolean;
    new_password: string;
    confirm_password: string;
    confirm_error: string;
    new_error: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetForgotEmailCallId: string = "";
    apiGetRestPasswordCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            tearm_data: "",
            forget_email: "",
            CollapseOpen: false,
            response_message: "",
            res_message: "",
            enablePasswordField: true,
            enableconfirmPasswordField: true,
            new_password: "",
            confirm_password: "",
            new_error: "",
            confirm_error: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);


        // Customizable Area Start   

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );



        if (apiRequestCallId === this.apiGetForgotEmailCallId) {

            if (responseJson.message) {
                this.setState({ res_message: 'success' })
                this.setState({ response_message: responseJson.message })

            } else {
                this.setState({ res_message: 'error' })
                this.setState({ response_message: responseJson.errors[0].otp })
            }
            this.setState({ CollapseOpen: true })
        }

        if (apiRequestCallId === this.apiGetRestPasswordCallId) {
            if (responseJson.message) {
                this.setState({ res_message: 'success' })
                this.setState({ response_message: responseJson.message })
                setTimeout(() => {
                    window.location.href = "/EmailAccountLoginBlock";
                }, 4000);
            } else {
                this.setState({ res_message: 'error' })
                this.setState({ response_message: responseJson.errors[0].token })
            }
            this.setState({ CollapseOpen: true })
        }



        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

    }


    CollapseOpenClose_fun = () => {
        this.setState({ CollapseOpen: false })
    }


    forget_email_change = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ forget_email: event.target.value })
    };

    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };

    handleClickShowConfirmPassword = () => {
        this.setState({
            enableconfirmPasswordField: !this.state.enableconfirmPasswordField,
        });
    };

    newPassword = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ new_password: event.target.value })
    };

    confirmPassword = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ confirm_password: event.target.value })
    };


    forgot_pass_update = () => {

        const { forget_email } = this.state;

        const passwordData = {
            data: {
                attributes: {
                    email: forget_email
                }
            }
        };
        const token = localStorage.getItem("authToken") as string;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const httpBody = passwordData;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetForgotEmailCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ForgotEmailAPI
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.uploadFileMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;


    }

    resetPassword = () => {

        const { new_password, confirm_password } = this.state;
        if (new_password.length < 8) {
            this.setState({ new_error: 'Your new password must be at least 8 characters long.' });
            return;
        }
        this.setState({ new_error: '' });

        if (new_password !== confirm_password) {
            this.setState({ confirm_error: "Passwords must match" });
            return;
        }

        this.setState({ confirm_error: '' });

        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentUrl).search);
        const parameterValue = urlParams.get('token');
    

        const passwordData = {
            data: {
                new_password: new_password,
                token: parameterValue,
            }
        };
        const tokenns = localStorage.getItem("authToken") as string;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: tokenns
        };

        const httpBody = passwordData;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetRestPasswordCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RestPasswordAPI
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.uploadFileMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;


    }
    // Customizable Area End



}
