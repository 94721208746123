import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  Chip,
  FormGroup,
  FormControlLabel,
  // Customizable Area Start
  Dialog,
  DialogContent,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./job.css"
import {
  placeholder_logo, sampleImg
} from "./assets";
import { Link } from 'react-router-dom';

// Customizable Area End

import JobListing2Controller, {
  Props,
} from "./jobListing2Controller.web";
import { boardsiIntro, unnamed, view_profilepic } from "./assets";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import CustomCheckBox from "../../../components/src/CustomCheckBox.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SharePopUp } from "../../../components/src/sharePopUp";
import MatchAlgorithm2 from "../../MatchAlgorithm2/src/MatchAlgorithm2.web"

export default class JobListing2 extends JobListing2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  Header = () => {
    return (
      <Box className="container new-job-bnr" style={{ height: "427px", width: '100%', backgroundColor: '#d3d9e7', display: 'flex', position: "relative" }}>
        <img className="new-job-bnr-img" style={{ height: "351px", padding: '24px', marginLeft: " 136px" }} src={boardsiIntro} />
        {localStorage.getItem("teamMember_ReadOnly") !== 'true'&&<div className="btn_box" style={{ width: '200px', height: "50px", top: 164, right: 340, position: "absolute", display: 'flex', borderLeft: "2px solid #536fc2" }}>
          <Button style={{ textTransform: "capitalize", backgroundColor: '#536fc2', borderRadius: '25px', color: 'white', marginLeft: '13px', padding: '20px 35px' }} onClick={this.navigator} >New Job</Button>
        </div>}
      </Box>
    )
  }

  HeaderExecutive = () => {
    return (
      <Box style={{ height: "427px", width: '100%', backgroundColor: '#d3d9e7', justifyContent: 'center', alignItems: 'center', display: 'flex', position: "relative" }}>
        <img className="bnr_mbl_fix" style={{ height: "351px", padding: '24px' }} src={boardsiIntro} />
      </Box>
    )
  }

  daysAgoCreated = (date: string) => {
    if (new Date().getDate() === new Date(date).getDate()) {
      return `${new Date().getDate() - new Date(date).getDate()} day ago`
    } else {
      return `${new Date().getMonth() - new Date(date).getMonth()} month ago`
    }
  }

  createdAt = (date: string) => {
    return `${String(new Date(date).getDate()).padStart(2, '0')}/${new Date(date).getMonth()}/${new Date(date).getFullYear()}`
  }

  navigator = () => {
    this.props.navigation.navigate("NewJob")
  }

  cardSection = () => {
    return (
      <Box className="card-inner">
        <img src={view_profilepic} className="logoImg"></img>
        <Typography className="title">Boardsi Job Portal </Typography>
        <Typography className="subtitle"> Search for your right</Typography>
        <Button onClick={this.navigateToProfile} className="cardBTN">See Complete Account</Button>
      </Box>
    )
  }

  messageOrApply = (id: any) => {
    if (this.state.userType === "executive") {
      return <Link to={`/JobsAppliedExecutive/${id}`} onClick={() => window.scrollTo(0, 0)}>
        <Button className="CardBTN executive_apply_btn" style={{ textTransform: 'capitalize' }}>apply</Button>
      </Link>
    }
  }

  executive_artical() {
    return (
      <div className="executive_arical_div">
        <Grid container spacing={2}>
          <Grid item md={10} sm={10} xs={9} spacing={2} className="executive_arical_title">
            <h2 className="executive_arical_title_h2">Articles</h2>
          </Grid>
          <Grid item md={2} sm={2} xs={3} spacing={2} className="executive_arical_view_all_btn">
            <Link className="artical_link_to" to="/BlogPost">
              View All
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {this.state.get_artical.length > 0 && this.state.get_artical.map((itemi: any, index: number) =>
          (
            <Grid item md={4} sm={4} xs={12} spacing={2} className="executive_arical_grid" key={index}>
              <div className="artical_grid ">
                <div className="artical_img_div artical-grid-container">
                  <img src={itemi.attributes.thumbnail === null ? sampleImg : itemi.attributes.thumbnail} className="artical_img" alt="Article" />
                </div>
                <div className="artical_title_div">
                  <h3 className="artical_title">{itemi.attributes.name ? itemi.attributes.name : "--"}</h3>
                </div>
                <div className="artical_date_div">
                  <p className="artical_date"> {itemi.attributes.publish_date ? itemi.attributes.publish_date : "--"}</p>
                </div>
                <div className="artical_title_dis">
                  <p className="artical_dis">{itemi.attributes.description ? itemi.attributes.description : "--"}</p>
                </div>
                <div className="artical_link">
                  <Link className="artical_link_to" to={`/BlogPost/${itemi.id}`}>
                    Read More
                  </Link>
                </div>

              </div>
            </Grid>
          )
          )}

        </Grid>
      </div>
    )
  }

  matchAlgorithm() {
    return (
      <>
        {this.state.userType === "executive" &&
          <Grid className="recentJobContainer">
            <Grid xs={12} md={3}></Grid>
            <Grid xs={12} md={9} >
              <MatchAlgorithm2 data-test-id="match-algo" navigation={this.props.navigation} messageOrApply={(id: string) => this.messageOrApply(id)} shareJobPopUp={(data: any) => this.shareJobPopUp(data)}  id={""} />
            </Grid>
          </Grid>
        }
      </>
    )
  }


  job_data() {
    return (
      <>
        <Typography className="recent_job_title_hedaing">Recent Jobs</Typography>
        {this.state.jobs.length > 0 && this.state.jobs.map((data: any, index: number) => {
          return <>  <Box className="card-box">
            <Box className="card_box_inner"
              data-test-id={`job_detail_navigate${index}`}
              onClick={() => {
                this.props.navigation.navigate("JobDetails", { id: data.id })
                window.scrollTo(0, 0)
              }}
            >
              <img src={data.attributes.company.profile_image === null ? placeholder_logo : data.attributes.company.profile_image} className="card-logo"></img>
              <Box className="box-detail">
                <Box className="box-detail-Content">
                  <Box>
                    <Typography className="box-detail-Content-title">
                      {data.attributes.job_title}
                    </Typography>
                    {this.state.userType === "company" ?
                      <Typography className="box-detail-Content-subtitle">
                        {data.attributes.executive_have_appiled} executives applied
                      </Typography> : <Typography className="box-detail-Content-subtitle">{data.attributes.company.company_name}</Typography>
                    }
                  </Box>
                  {this.state.userType === "executive" && <Chip style={{ borderRadius: '9px', width: "93px" }} label={this.createdAt(data.attributes.created_at)} />}
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.employment_type.employment_type_name} />
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.experience_level} />
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.job_type} />
                  <Typography className="location_width">{data.attributes.location}</Typography>
                </Box>
                {this.state.userType === "executive" ? <Typography style={{ fontSize: '13px', marginLeft: '2px', color: 'grey', marginBottom: '10px' }}>{data.attributes.description.substring(0, 25)}</Typography> :
                  <Typography style={{ fontSize: '13px', marginLeft: '2px', color: 'grey', marginBottom: '10px' }} >{this.daysAgoCreated(data.attributes.created_at)}</Typography>
                }
              </Box>
            </Box>
            <Box className="btn-group">
              <Button data-test-id="share_job_btn0" className="CardBTN" onClick={() => { this.shareJobPopUp(data) }} style={{ textTransform: 'capitalize', height: "28px" }}>share</Button>
              {this.messageOrApply(data.id)}
            </Box>
          </Box>
          </>
        })}
      </>
    )
  }

  job_data_not_found() {
    return (
      <>
        {this.state.jobs.length === 0 && <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "10px", textAlign: "center" }}>
          Data not found.
        </Typography>
        }
      </>
    )
  }

  recent_job_grid() {
    return (
      <>
        <Grid className="recentJobContainer">
          <Grid xs={12} md={3} >
            <Box className="JobPortalCard">
              <img src={unnamed} className="JobPortalCardCoverImg"></img>
              {this.cardSection()}
            </Box>
            <Box className="FilterBox">
              <Typography className="FilterBox-title" style={{ marginTop: "15px" }}>Filter</Typography>
              <input type="text" className="filterInput" onChange={this.searchFilter} placeholder="Company,Skills"></input>
            </Box>
            <Box className="FilterBox">
              <Box className="FilterBox-title" style={{ marginTop: "15px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.empFilter}>
                <Typography className="FilterBox-title">Employement</Typography> <ExpandMoreIcon style={{ cursor: "pointer" }} />
              </Box>
              {this.state.isEmp && <>
                <FormGroup>
                  <FormControlLabel control={<CustomCheckBox data-test-id="checkFull" onChange={this.handleFullTIme} value={"1"} checked={this.state.isFullTIme} />} label="Fulltime" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel control={<CustomCheckBox data-test-id="checkRemote" onChange={this.handleRemote} value={"2"} checked={this.state.isRemote} />} label="Remote" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel control={<CustomCheckBox data-test-id="checkContract" onChange={this.handleContract} value={"3"} checked={this.state.isContract} />} label="Contract" />
                </FormGroup>
              </>
              }
            </Box>
            <Box className="FilterBox">
              <Box className="" onClick={this.salaryFilter} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography className="FilterBox-title">Salary </Typography>  <ExpandMoreIcon style={{ cursor: "pointer" }} />
              </Box>
              <div className="salary_filter_class">
                {this.state.isSalary && <> <input type="number" value={this.state.salary_start > 0 ? this.state.salary_start : ""} className="filterInput" onChange={this.startChange} placeholder="Salary start" />
                  <Typography style={{ textAlign: "center" }}>To</Typography>
                  <input type="number" className="filterInput" value={this.state.salary_end > 0 ? this.state.salary_end : ""} onChange={this.endChange} placeholder="Salary end" />
                </>}
              </div>
            </Box>
          </Grid>
          <Grid xs={12} md={9}>
            {this.job_data()}
            {this.job_data_not_found()}
            {this.state.last_page > 1 && (
              <Box className="container pagination_wrp" style={{ marginBottom: '30px' }}>
                <Typography>page no. {this.state.currentPage}/{this.state.last_page}
                  <ExpandMoreIcon className="left_arrow left_arrow_recent_job_list" onClick={() => { this.state.currentPage > 1 && this.setState({ currentPage: this.state.currentPage - 1 }) }} />
                  <ExpandMoreIcon data-test-id="pagination_btn" className="right_arrow" onClick={() => this.state.currentPage !== this.state.last_page && this.setState({ currentPage: this.state.currentPage + 1 })} />
                </Typography>
              </Box>
            )}
            {this.state.userType === "company" ? "" : this.executive_artical()}
          </Grid>
        </Grid>
      </>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        {this.state.userType !== null && this.state.userType !== "" ? (
          <>
            {this.state.userType === "company" ? this.Header() : this.HeaderExecutive()}

            <Box className="RecentJobSection">
              <Box className="container">
                {this.matchAlgorithm()}
                {this.recent_job_grid()}
              </Box>
            </Box>
            <Dialog className="model_popup" scroll="paper" open={this.state.isShare}>
              <DialogContent>
                <div className="">
                  <SharePopUp data-test-id="share_modal" location={window.location} id={this.state.jobID} data={this.state.shareJob} closeModal={() => { this.setState({ isShare: false }) }} />
                </div>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
            <CircularProgress />
          </div>
        )}

        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
