import React  from "react";

import {
  CircularProgress,
  Container,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FooterWeb from "../../../components/src/FooterWeb";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import { Elements } from '@stripe/react-stripe-js';
import CheckoutFormStrip from "../../SubscriptionBilling2/src/CheckoutFormStrip.web";


// Customizable Area End

import StripeIntegrationController, {
  Props,
} from "./StripeIntegrationController.web";

export default class StripeIntegration extends StripeIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
      { this.state.secretKey=="" ?<div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
       <CircularProgress />
   </div>:
        <div className="stripe_integration_body">
          <Container>
        {  this.state.secretKey !==""&&<Elements stripe={this.state.secretKey} >
              <CheckoutFormStrip navigation={this.props.navigation} id={""} loadStripe={this.state.secretKey}/>
              </Elements>}
          </Container>
        </div>
   }
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
