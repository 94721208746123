import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Container,
    AppBar,
    Toolbar,
    Paper,

  } from "@material-ui/core";

import {sample,navebarLogo,placeHolder} from "./assets"
import { Link } from "react-router-dom"
import BlogPostController from "./BlogPostController.web"
import { ViewBlogPost } from "../../../components/src/ViewBlogPost";
import "./accountCreation.css";

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlogPost extends BlogPostController {
  
  constructor(props: Props) {
    super(props);
  }
  
  
  render() {

    return (
        <div className="main-root">
 
            <AppBar position="static" style={{ background: 'white' }}>
                <Toolbar>
                    <Grid container alignItems="center">

                        <Grid item xs={2}>
                        <Link to={"/Dashboard"} data-test-id="scroll-top" onClick={() => window.scrollTo(0, 0)}>
                            <img src={navebarLogo} alt="Logo" height="32" className="header-img" />
                            </Link>
                        </Grid>

                        <Grid item xs={9} style={{ textAlign: 'right', padding: '5px' }}>
                            <Typography variant="h6" className="header-profile-name">
                                {this.state.profileData.first_name} {this.state.profileData.last_name}
                            </Typography>
                            <Typography variant="subtitle1" className="executive">
                                {this.state.profileData.user_type}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} style={{ textAlign: 'right' }}>
                            <img
                                src={this.state.profileData.profile_image}
                                alt="Logo"
                               className="header-right-img"
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

          <hr style={{color:'black',margin:'2px'}}/>

            {!this.state.viewBlogPost?<Container style={webStyle.container}>
          <div className="center" style={{ marginTop: '15px', marginLeft: '3px', marginBottom: '25px', cursor: 'pointer' }}>
            <Link data-test-id="scroll-next" className="navigation"  to={"/Jobs"} onClick={() => window.scrollTo(0, 0)}> <p > Home   &nbsp;    &gt;  &nbsp;   Blog Posts </p></Link>
          </div>

                <Grid container spacing={2}>
                    <Grid container item xs={12} md={6} lg={6} sm={6}>
                        <Box data-test-id="on-post-click" height="100%" width="100%" onClick={()=>this.onPostClick(this.state.posts[0])}>
                            {this.state.posts.length>0&&<Paper className={`${webStyle.largeBlog}`} style={{height:'100%'}}>
                                <img src={this.state.posts[0].attributes.thumbnail?this.state.posts[0].attributes.thumbnail:placeHolder} alt="Large Blog Post" style={{ width: '100%', height: '450px',objectFit:'cover' }} />
                                <div style={{padding:'10px'}}>
                                <Typography variant="body1" paragraph className="large-description">
                                  {this.state.posts[0].attributes.description}
                                </Typography>
                               
                                <p className="date-area"> {this.state.posts[0].attributes.publish_date} {"///"} {this.state.posts[0].attributes.comment_count} Comments {"///"} {this.state.posts[0].attributes.share_count} Shared</p>
                                    <div className="category">{this.state.posts[0].attributes.category.name}</div>
                                </div>
                            </Paper>}
                        </Box>
                    </Grid>


                    <Grid container item xs={12} md={6} lg={6} sm={6} spacing={2}>

                        {this.state.posts.map((item:any,index:any) => (

                            index>0&&index<5&&<Grid item xs={6} style={{paddingRight:index%2===0?'0px':'8px'}}>
                                <Box data-test-id="on-post-clickNew" onClick={()=>this.onPostClick(item)} >
                                <Paper 
                                 className={`${webStyle.smallBlog} mobile-height`}>
                                    <img src={this.getImg(item,placeHolder)} alt={`Small Blog`} 
                                    style={{ width: '100%', height: '185px',objectFit:'cover' }} />
                               <div style={{padding:'10px'}}>
                               <h4 className="block-description">
                                       {item.attributes.description}
                                    </h4>
                                  
                                    <p className="date-area-small"> {item.attributes.publish_date} {"///"} {item.attributes.comment_count} Comments {"///"} {item.attributes.comment_count} Shared</p>
                                    <div 
                                    className="category-small">{item.attributes.category.name}</div>
                               </div>
                                </Paper>
                                </Box>
                            </Grid>
                        ))}
                    
                    </Grid>

                </Grid>

          <Box style={{ display: 'flex', marginTop: '40px' ,justifyContent:'space-between'}}>
            <h3 className="category-section">
              Category Sections
            </h3>

            <p data-test-id="on-post-count" className="view-all" onClick={() => {
              this.setState({ categoryPostCount: this.state.categoryPostCount === 4 ? 100 : 4 })
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              })
            }}>
              View All
            </p>
          </Box>

                <br />
                <br />

                <Grid container spacing={2}>
                    {this.state.postsCategory.map((item:any,index:any) => (

                       index < this.state.categoryPostCount && <Grid item xs={6} lg={3} sm={3}>
                        <Box 
                        data-test-id="on-post-click-next"
                         onClick={() => this.onPostClick(item)}>
                          <Paper style={{height:'100%'}}
                          className={`${webStyle.smallBlog}`}>
                            <img src={this.getImg(item,placeHolder)}
                            alt={`Small Blog`} style={{ width: '100%', height: '160px' ,objectFit:'cover'}} />
                            <div style={{ padding: '10px' }}>
                              <h4 className="block-description">
                                {item.attributes.description}
                              </h4>
                              <p 
                              className="date-area-small"> {item.attributes.publish_date} {"///"} {item.attributes.comment_count} Comments {"///"} {item.attributes.share_count} Shared</p>
                              <div 
                             className="category-small">{item.attributes.category.name}</div>
                            </div>
                          </Paper>
                        </Box>
                      </Grid>
                    ))}

                    </Grid>

            </Container>: <ViewBlogPost placeHolder={placeHolder} image={sample} viewPostData={this.state.viewPostData} posts={this.state.posts} viewPostComments={this.state.viewPostComments} onComment={this.onComment} onCommentReply={this.onCommentReply} onCommentDelete={this.onCommentDelete} onCommentDeleteTheReply={this.onDeleteCommentReply} onLikePostOrTheComment={this.onLikePostOrComment} onDeletePostOrTheCommentLikes={this.onDeletePostOrComment} onBackBtn={this.onBackButton} onPostClick={this.onPostClick} onSharePost={this.sharePost} />}
        </div>
      );
    }
  }


// Customizable Area Start
const webStyle = {
    root: {
       margin:0,
      },
      container: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      largeBlog: {
        padding: 24,
      },
      smallBlog: {
        padding: 16,
        marginBottom: 16,
      },
      blogImage: {
        width: '100%',
        height: 'auto',
        marginBottom: 16,
      },
  };
// Customizable Area End