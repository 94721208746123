export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const BackgroundImage = require("../assets/view_background.png");
export const boardsiLogo = require("../assets/boardsi_logo.png");

export const Eyeopen = require("../assets/Eyeopen.png");
export const EyeSlash = require("../assets/EyeSlash.png");




