import React from "react";

// Customizable Area Start
import TopNavWeb from "../../../components/src/TopNav.web";
import AboutUsWeb from "../../../components/src/AboutUsWeb.web";
import AddContactus from "../../contactus/src/ContactusWeb.web";
import { sliderBackground } from './assets'
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController.web";
import ClientWeb from "../../../components/src/ClientWeb.web";
import FooterWeb from "../../../components/src/FooterWeb";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <>
        <TopNavWeb />
        <AboutUsWeb />
        <ClientWeb backImg={sliderBackground} />
        <AddContactus navigation={undefined} id={""} />
        <FooterWeb />
      </>
    );
  }
}
// Customizable Area Start

// Customizable Area End