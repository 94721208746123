import React from "react";

// Customizable Area Start
import "./navigation.css";
// Customizable Area End

import NavigationMenuWebController, {
  Props,
} from "./NavigationMenuWebController.web";
import Navbarweb from "../../../components/src/Navbarweb.web";

export default class NavigationMenunew extends NavigationMenuWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {
          this.state.userRole &&
          <Navbarweb  data-test-id = "nav-bar" company_name={this.state.companyName} notification_data={this.state.getNotifi_data} role={this.state.userRole} profileImage={this.state.profile_image} items={this.state.userRole === 'company' ? this.state.company : this.state.executive} first_name={this.state.first_name} unReadNotificationCount={this.state.unReadNotificationCount} readSingleNotification={this.readSingleNotification} onViewPopUp={this.onViewPopUp} viewApplication={this.state.viewApplication} closeModal={() => { this.setState({ viewApplication: false }) }} applicantQuestions={this.state.applicantQuestions} executiveDetails={this.state.executiveDetails} />
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  muiDrawer: {
    width: 250,
  },
  listItem: {
    color: "#536fc2",
    marginTop: 15,
    marginBottom: 5,
  },
};
// Customizable Area End
