// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Collapse,
  IconButton,
} from "@material-ui/core";
import "../../../components/src/Customform.css";
import DropDown from "../../../components/src/AccDropdown.web";
import {
  CustomAccTextField
} from "../../../components/src/CustomStyle.web";
import InputAdornment from '@material-ui/core/InputAdornment';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CustomformController from "./CustomformController.web";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End



export default class Customformweb extends CustomformController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { number, fields, quetion_id, dropdowns, designation, selectType, numOption, option_id, numTextFields, custom_form_id } = this.state;
    const type_of_quetion = [
      {
        "id": 'Multiple Choice',
        "value": "Multiple Choice"
      },
      {
        "id": 'Single line text',
        "value": "Single Line Text"
      },
      {
        "id": 'Multi line text',
        "value": "Multi Line Text"
      },
      {
        "id": 'Dropdown',
        "value": "Dropdown"
      },
    ];
    const number_of_quetion = [
      {
        "id": '1',
        "value": "1"
      },
      {
        "id": '2',
        "value": "2"
      },
      {
        "id": '3',
        "value": "3"
      },
      {
        "id": '4',
        "value": "4"
      },
      {
        "id": '5',
        "value": "5"
      }
    ];
    const SelectTypeOptions = [
      {
        "id": 'Single Selection',
        "value": "Single Selection"
      },
      {
        "id": 'Multi Selection',
        "value": "Multi Selection"
      },
    ];
    const numOptionsOptions = [
      {
        "id": '1',
        "value": "1"
      },
      {
        "id": '2',
        "value": "2"
      },
      {
        "id": '3',
        "value": "3"
      },
      {
        "id": '4',
        "value": "4"
      },
      {
        "id": '5',
        "value": "5"
      },
      {
        "id": '6',
        "value": "6"
      },
      {
        "id": '7',
        "value": "7"
      },
      {
        "id": '8',
        "value": "8"
      },
      {
        "id": '9',
        "value": "9"
      },
      {
        "id": '10',
        "value": "10"
      },
    ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div className="custom_from_tab">
          <div className="custom_from_tab_title">
            <h3>Custom Form</h3>
          </div>
          <div className="custom_from_select_option_row">
            <form ref={this.formRef}>
              <Card className="custom_from_card_select_option">
                <CardContent>
                  <div>
                    <Grid container>
                      <Grid item sm={4}>
                        <Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Designation</label></Typography>
                        <Box>
                          <DropDown
                            data-test-id="designation_test_id"
                            name="designation"
                            value={designation}
                            onChange={this.handleDesignationChange}
                            items={this.state.designation_data}
                            placeholder="Select Role"
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={4} className="no_of_question">
                        <Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Number of Questions</label></Typography>
                        <Box>
                          <DropDown
                            data-test-id="Questions_test_id"
                            name="number"
                            value={number}
                            onChange={this.handleNumberChange}
                            items={number_of_quetion}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>

              {fields.map((field, index) => (
                <Card className="custom_from_card_select_option" key={index}>
                  <CardContent>
                    <div >
                      <Grid container>
                        <Grid item sm={12}>
                          <input
                            type="hidden"
                            name={`question_id-${index}`}
                            value={quetion_id[index]}
                          />
                          <Typography className="text-field" ><label style={{ fontWeight: 'bold' }} htmlFor={`field-${index}`}>Question {index + 1}</label></Typography>
                          <Box>
                            <CustomAccTextField
                              name={`field-${index}`}
                              data-test-id="txtInputProfileHeadline"

                              fullWidth={true}
                              variant="outlined"

                              id={`field-${index}`}
                              value={field}
                              onChange={(event) => this.handleInputChange(event, index)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={4} style={{ marginTop: '17px' }}>
                          <Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Type of Question</label></Typography>
                          <Box>
                            <DropDown
                              data-test-id="txtInputTypeOfQuestion"
                              name={`dropdown-${index}`}
                              value={dropdowns[index]}
                              onChange={(event) => this.handleDropdownChange(event, index)}
                              items={type_of_quetion}
                            />
                          </Box>
                        </Grid>
                        {(dropdowns[index] == "Multiple Choice" || dropdowns[index] == "Dropdown") && (
                          <>
                            <Grid item sm={4} style={{ marginTop: '17px' }}>
                              <Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Type of Select</label></Typography>
                              <Box>
                                <DropDown
                                  data-test-id="txtInputTypeOfSelectss"
                                  name={`select-type-${index}`}
                                  value={selectType[index]}
                                  onChange={(event) => this.handleSelectTypeChange(event, index)}
                                  items={SelectTypeOptions}
                                />
                              </Box>
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: '17px' }}>
                              <Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Type of Options</label></Typography>
                              <Box>
                                <DropDown
                                  data-test-id="txtInputTypeOfOptionss"
                                  name={`num-option-${index}`}
                                  value={numOption[index]}
                                  onChange={(event) => this.handleNumOptionChange(event, index)}
                                  items={numOptionsOptions}
                                />
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {(dropdowns[index] == "Multiple Choice" || dropdowns[index] == "Dropdown") && (
                        <>
                          {numTextFields[index]?.length === 0 ? "" : <Grid item sm={12} style={{ marginTop: '17px' }}><Typography className="text-field"><label style={{ fontWeight: 'bold' }}>Provide Options Below</label></Typography></Grid>}

                          {numTextFields[index] && numTextFields[index].map((_, subIndex) => (
                            <Grid item sm={12} key={subIndex} style={{ marginTop: '17px' }}>
                              <>
                                <input
                                  type="hidden"
                                  name={`option_id-${index}-${subIndex}`}
                                  value={(option_id[index] && option_id[index][subIndex]) || ""}
                                />
                                <Box>
                                  <CustomAccTextField
                                    name={`text-field-${index}-${subIndex}`}
                                    value={this.state.textFields[index][subIndex] || ''}
                                    onChange={(event) => this.handleTextFieldChange(event, index, subIndex)}
                                    fullWidth={true}
                                    variant="outlined"
                                    data-test-id="handleTextFieldChange_test_id"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start" className="icon_input_div">
                                          <DragIndicatorIcon className="textField_icon" />
                                        </InputAdornment>
                                      ),
                                    }}
                                    className="textFieldsWithIcon"
                                  />
                                </Box>
                              </>
                            </Grid>
                          ))}
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              ))}
              <Grid container>
                <Grid item sm={12} className="btn_row_save">
                  <div>
                    <Button type="button"
                      data-test-id="btnCancel_customform"
                      color="primary"
                      className="customCancel_btn"
                      onClick={this.handleCancel_CustomeForm_Click}
                    >Cancel</Button>
                    {custom_form_id !== 0 ? <Button
                      type="button"
                      data-test-id="btnSave_custom_form_test_id_update"
                      color="primary"
                      className="customSave_btn" onClick={this.handleSubmit_update}>Update</Button> : <Button
                        type="button"
                        data-test-id="btnSave_custom_form_test_id"
                        color="primary"
                        className="customSave_btn" onClick={this.handleSubmit}>Save</Button>}

                  </div>

                </Grid>
                <Grid container>
                  <Grid item sm={12} className="message_show" style={{width:'100%'}}>
                    <div style={{ marginTop: '15px' }}>
                      <Collapse in={this.state.CollapseOpen_customform}>
                        <Alert
                          variant="filled"
                          severity={this.state.customform_res_message === 'success' ? 'success' : "error"}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              data-test-id="CollapseOpenClose_fun_test_id"
                              onClick={this.CollapseOpenClose_fun}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.customform_response}
                        </Alert>
                      </Collapse>
                    </div>
                  </Grid>
                </Grid>
              </Grid>


            </form>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}



// Customizable Area Start
// Customizable Area End

