import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPlan_id: any;
  one_tim_fee: boolean
  plans_data: any;
  planResponseData: any,
  freePlan: boolean,
  indexState: any;
  activePlan: any;
  activePlanId: any;
  Activeplans_data:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionBilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subScriptionPlansAPI: string = "";
  ScriptionPlansAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPlan_id: 4,
      one_tim_fee: false,
      plans_data: [],
      Activeplans_data: [],
      planResponseData: {},
      freePlan: false,
      indexState: null,
      activePlan: false,
      activePlanId: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.subScriptionPlansAPI) {
        this.handlePlanResponse(responseJson)
        
      }
      if (this.ScriptionPlansAPI == apiRequestCallId) {
        if (responseJson?.error) {
          alert(responseJson.error)
        }
        else {
          if (responseJson?.clientSecret) {
            this.props.navigation.navigate("StripeIntegration", { id: `${this.state.selectedPlan_id}` })
            window.scrollTo(0, 0)
          }
          else {
            window.open(responseJson?.subscription?.url, "_blank")
          }
        }

      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  handlePlanResponse(responseJson:any){
   if(responseJson?.active_plan!=null){
    if(Object.keys(responseJson?.active_plan).length>0){
      this.setState({activePlan:true,activePlanId:responseJson?.active_plan})
      const Data=responseJson?.plans.filter((item:any,index:number)=>{
            return item?.id==responseJson?.active_plan?.plan_id
      })
      this.setState({ Activeplans_data: Data })
      this.setState({ plans_data: responseJson?.plans })
    }}
    else{
      this.setState({ plans_data: responseJson?.plans })
      this.handleReponse(responseJson?.plans)
    }
    
  }
  handleActivePlanHeading(){
    return this.state.activePlanId.set_up_type !== "one_time" ? "/Month" : ""
  }
  handleHeading(){
return this.state.activePlan ? "Active Plan!" : "Join Now!"
  }
  handleName(itemi:any){
    return itemi.name ? itemi.name : "--"
  }
  handleDetails(itemi:any){
    return itemi.details ? itemi.details : "--"
  }
  handlePrice(){
   return  this.state.activePlanId.set_up_type === "one_time"
    ? this.state.Activeplans_data[0]?.one_time_set_up_fee
    : this.state.Activeplans_data[0]?.price
  }
  handleReponse(responseJson: any) {
    this.setState({ selectedPlan_id: responseJson[0].id })
    responseJson.map((item: any, index: any) => {
      if (this.state.selectedPlan_id == item.id) {
        if (item.one_time_set_up_fee === 0) {
          this.setState({ freePlan: true })
        }
      }
    })
  }

  async componentDidMount() {
    super.componentDidMount();
    this.subScriptionPlans();
  }

  one_tim_fee_fun = (id: any,) => {
    this.setState({ selectedPlan_id: id, freePlan: false })
    this.setState((prevState) => ({
      one_tim_fee: !prevState.one_tim_fee,
    }));
  };

  handleChange_selectedPlan = (id: any, indexId: any, index: any) => {
    this.setState({ indexState: index })
    if (indexId?.one_time_set_up_fee == 0) {
      this.setState({ freePlan: true })
    }
    else {
      this.setState({ freePlan: false })
    }
    this.setState({
      selectedPlan_id: Number(id),
      one_tim_fee: false
    });
  };
  handlePlan() {
    if (this.state.freePlan === true) {
      this.props.navigation.navigate("Dashboard")
    window.scrollTo(0,0)

    }
    else {
      this.SubscriptionFunctionality(this.state.selectedPlan_id, `${this.state.one_tim_fee === true ? 'one_time' : 'auto_renewal'}`)

    }
  }
  SubscriptionFunctionality = (id: any, type: any) => {
    localStorage.setItem("planId", this.state.selectedPlan_id)
    const token = localStorage.getItem("authToken") as string;
    const header = {

      "Content-Type": "application/json",
      token: token
    };
    const url = type == "one_time" ? "/bx_block_plan/subscriptions" : "bx_block_plan/subscriptions/create_subscription";
    const body = type == "one_time" ? { "plan_id": id, "set_up_type": type } : {
      "plan_id": this.state.selectedPlan_id,
      "success_url": "/SuccessfullSubscription",
      "fail_url": "/FailSubscription"
    }
    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ScriptionPlansAPI = requestMessage2.messageId
    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);
    return true;

  };
  subScriptionPlans = async () => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subScriptionPlansAPI = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subScriptionPlansAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  // Customizable Area End
}
