import React, { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { navebarLogo } from "../../blocks/navigationmenu/src/assets";
import { AppBar, Toolbar, Grid, Tabs, Tab, Button, IconButton, Link } from "@material-ui/core";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { HashLink } from 'react-router-hash-link';
import { Menu, Close } from "@material-ui/icons";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
    },
  },
});


const useStyles = makeStyles({
  appBar: { backgroundColor: "#fff", minHeight: "70px", paddingTop: '10px' },
  navbarTop: {
    width: "150px",
    marginLeft: "25px",
  },
  container: {
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "end",
    marginLeft: '-20px',

  },
  tabRoot: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "#536fc2",
    "&:hover": {
      borderBottom: '2px solid #536fc2'
    }
  },
  btn: {
    whiteSpace: "nowrap",
    textTransform: "none",
    background: "linear-gradient(-40deg, #8199e1, #536fc2 100%) no-repeat",
    color: "#fff",
    "&:hover": {
      background: '#8199e1'
    }
  },
  gridXs: {
    maxWidth: "50%"
  },
  navList: {
    position: "absolute",
    background: "#fff",
    right: 0,
    boxShadow: "rgb(0,0,0,0.35) 0px 5px 15px",
    width: "100%",
    height: "100vh"
  }
});

const TopNavWeb = () => {
  const classes = useStyles();
  const navTheme = useTheme();
  const isMatch = useMediaQuery(navTheme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [openNav, setOpenNav] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const tempData = [
    { id: 0, name: "About", link: "#AboutUsWeb" },
    { id: 1, name: "Clients", link: "#ClientWeb" },
    { id: 2, name: "Contact Us", link: "#ContactUs" },
    { id: 3, name: "Login", link: "/EmailAccountLoginBlock" }
  ];

  return (
    <ThemeProvider theme={theme}>
      <div>
        <AppBar style={{ backgroundColor: "#fff", minHeight: "70px", paddingTop: '10px' }} color="primary">
          <Toolbar>
            <Grid container style={{
              placeItems: "center",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Grid item xs={2}>
                <img style={{
                  width: "150px",
                  marginLeft: "25px",
                }} src={navebarLogo} />
              </Grid>
              {isMatch === false && (
                <Grid item xs={8} style={{
                  display: "flex",
                  justifyContent: "end",
                  marginLeft: '-20px',
                }}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                  // value={value}
                  // onChange={handleChange}
                  >
                    {tempData?.map((item) => {
                      return (
                        <Tab style={{
                          textTransform: "capitalize",
                          fontSize: "18px",
                          color: "#536fc2",
                        }} key={item.id} label={item.name} to={item.link} component={HashLink} />
                      )
                    })}

                  </Tabs>
                </Grid>
              )}
              {
                isMatch === false && (
                  <Grid item xs={2} style={{ maxWidth: '50%' }}>
                    <Button style={{
                      whiteSpace: "nowrap",
                      textTransform: "none",
                      background: "linear-gradient(-40deg, #8199e1, #536fc2 100%) no-repeat",
                      color: "#fff",
                    }}
                      variant="contained" component={NavLink} to="/EmailAccountRegistration">
                      Get Started for free!
                    </Button>
                  </Grid>
                )
              }
              {
                isMatch && (
                  <Grid>
                    <IconButton onClick={() => setOpenNav(!openNav)} edge="end" color="primary" aria-label="navigation">
                      {!openNav ? <Menu color="primary" fontSize="large" /> : <Close color="primary" fontSize="large" />}
                    </IconButton>

                    <List style={openNav ? {
                      position: "absolute",
                      background: "#fff",
                      right: 0,
                      boxShadow: "rgb(0,0,0,0.35) 0px 5px 15px",
                      width: "100%",
                      height: "100vh"
                    } : {}} disablePadding>
                      {
                        openNav && tempData.map(item => (
                          <ListItem key={item.id}>
                            <Link onClick={() => setOpenNav(false)} component={HashLink} to={item.link} style={{ width: "100%" }}>
                              <ListItemText style={{ textAlign: "center" }}>{item.name}</ListItemText>
                            </Link>
                          </ListItem>
                        ))
                      }
                    </List>
                  </Grid>
                )
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
};

export default withRouter(TopNavWeb);
