import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Link,
    Grid,
    InputAdornment,
    Collapse,
    IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { CustomTextField } from "../../../components/src/CustomStyle.web";
import "./forgotpassword.css";


// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import {
    BackgroundImage,
    boardsiLogo,
    Eyeopen,
    EyeSlash
} from "./assets";


export default class ResetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            <>
                <div className="forgot_password_page">
                    <Grid container alignItems="center">
                        <Grid item md={8} sm={12} xs={12} style={{ order: 2 }}>
                            <div className="forgot_right_col">
                                <img
                                    src={BackgroundImage}
                                    className="forgot_image_bg"
                                    alt="BackgroundImage"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} style={{ order: 1 }}>
                            <div className="forgot_left_col" style={{}}>
                               
                                <div style={{ paddingBottom: '50px', textAlign: 'center' }}>
                                    <img src={boardsiLogo} alt="logo" className="logo" />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 style={{ paddingBottom: '20px' }}>Reset Password</h1>
                                  
                                </div>

                                <form>
                                    <div style={{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '30px' }}>
                                        <Box>
                                            <Typography className="text-field">New Password</Typography>
                                            <Box sx={{ paddingBottom: "12px" }}>
                                                <CustomTextField
                                                    name="password"
                                                    data-test-id="txtInputResetNewPassword"
                                                    type={
                                                        this.state.enablePasswordField ? "password" : "text"
                                                    }
                                                    placeholder={"Password"}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={this.state.new_password}
                                                    onChange={this.newPassword}
                                                    error={Boolean(this.state.new_error)}
                                                    helperText={this.state.new_error}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowPassword}
                                                                    edge="end"
                                                                    className="ResetNewPassword_eye"
                                                                >
                                                                    {this.state.enablePasswordField ? (
                                                                        <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />

                                                                    ) : (
                                                                        <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Box>
                                            <Typography className="text-field">Confirm Password</Typography>
                                            <Box sx={{ paddingBottom: "12px" }}>
                                                <CustomTextField
                                                    name="confirm_password"
                                                    data-test-id="txtInputResetConfirmPassword"
                                                    type={this.state.enableconfirmPasswordField ? "password" : "text"}
                                                    placeholder={"Confirm Password"}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={this.state.confirm_password}
                                                    onChange={this.confirmPassword}
                                                    error={Boolean(this.state.confirm_error)}
                                                    helperText={this.state.confirm_error}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle confirm password visibility"
                                                                    className="ResetConfirmPassword_eye"
                                                                    onClick={this.handleClickShowConfirmPassword}
                                                                    edge="end"
                                                                >
                                                                    {this.state.enableconfirmPasswordField ? (
                                                                        <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
                                                                    ) : (
                                                                        <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            <div className="login-butto" style={{ padding: '25px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={this.resetPassword}
                                                    style={{textTransform:'capitalize'}}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <Collapse in={this.state.CollapseOpen}>
                                                    <Alert
                                                        variant="filled"
                                                        severity={this.state.res_message === 'success' ? 'success' : "error"}
                                                        action={
                                                            <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                size="small"
                                                                data-test-id=""
                                                                onClick={this.CollapseOpenClose_fun}
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        }
                                                    >
                                                        {this.state.response_message}
                                                    </Alert>
                                                </Collapse>
                                            </div>
                                        </Box>
                                    </div>
                                </form>
                                <div style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: 'center' }}>
                                    <Link href="/EmailAccountLoginBlock">
                                        Back to Sign in
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }
}
