import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputAdornment,
} from "@material-ui/core";

// Customisable Area Start
// Customizable Area End

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ContactusControllerWeb, { Props } from "./ContactusControllerWeb.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { newBack } from "./assets";
import { rawCountries } from "../../../components/src/rawCountryData";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./ContactusWeb.css"

// Customisable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#4e5da7",
      contrastText: "#fff",
    },
    secondary: {
      main: "#68B984",
    },
  },
});
// Customizable Area End

export default class AddContactus extends ContactusControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchemaAdminobj = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .matches(
        /^[A-Z][a-z]*( [A-Z][a-z]*)*$/,
        "First letter should be capitalized and multiple spaces are not allowed"
      )
      .required("Please enter First name"),
    last_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters required")
      .matches(
        /^[A-Z][a-z]*( [A-Z][a-z]*)*$/,
        "First letter should be capitalized and multiple spaces are not allowed"
      )
      .required("Please enter Last name"),
    phone_number: Yup.string()
      .matches(
        /^((0091)|(\+91)|0?[6789]\d{9})$/,
        "Please enter valid mobile number"
      )
      .required("Please enter mobile number "),
    email: Yup.string()
      .email("Please enter valid email address")
      .required("Please enter email address"),
    message: Yup.string()
      .trim()
      .matches(
        /^\S+$/,
        "Please enter a valid message"
      )
      .required("Please enter a Message")
  });
  // Customizable Area End

  renderSuccessDialog() {
    return (
      // Customizable Area Start
      <div>
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <div
            style={{ textAlign: "center", maxWidth: "350px", padding: " 20px" }}
          >
            <DialogTitle>
              <Typography
                variant="h4"
                color="secondary"
                style={{ color: "#68B984" }}
              >
                Thank You
              </Typography>
              <CheckCircleOutlineIcon color="secondary" fontSize="large" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography>{this.state.successMsg}</Typography>
              </DialogContentText>
            </DialogContent>
            <Button
              data-test-id="btnDone"
              onClick={() => this.handleClose()}
              color="primary"
              variant="contained"
              style={{ textTransform: "none" }}

            >
              Done
            </Button>
          </div>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.successMsg ? this.renderSuccessDialog() : ""}
        <div style={webStyle.mainContainer} id="ContactUs">
          <Box className="contactBox" style={webStyle.contactBox}>
            <div
              style={{ textAlign: "center" }}><h1 className="mainHeading" style={webStyle.mainHeading}>Contact Us!</h1></div>
            <Formik
              initialValues={this.state.formData}
              validationSchema={this.validationSchemaAdminobj}
              onSubmit={(values) => {
                
                const modifiedData = {
                  ...values,
                  phone_number: this.state.countryCode + values.phone_number,
                  email:values.email.toLowerCase()
                };
                // istanbul ignore next
                this.contactusApi(modifiedData);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form style={{ marginTop: '8rem' }} onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography className="textField" style={webStyle.textField}>
                        First Name
                      </Typography>
                      <Box sx={{ paddingBottom: "12px" }}>
                        <TextField
                          name="first_name"
                          data-test-id="txtInputFirstName"
                          placeholder={"First Name"}
                          fullWidth={true}
                          variant="outlined"
                          value={values.first_name}
                          onChange={handleChange}
                          error={
                            touched.first_name && Boolean(errors.first_name)
                          }
                          helperText={touched.first_name && errors.first_name}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className="textField" style={webStyle.textField}>
                        Last Name
                      </Typography>
                      <Box sx={{ paddingBottom: "12px" }}>
                        <TextField
                          name="last_name"
                          data-test-id="txtInputLastName"
                          placeholder={"Last Name"}
                          fullWidth={true}
                          variant="outlined"
                          value={values.last_name}
                          onChange={handleChange}
                          error={touched.last_name && Boolean(errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className="textField" style={webStyle.textField}>Email</Typography>
                      <Box sx={{ paddingBottom: "12px" }}>
                        <TextField
                          name="email"
                          data-test-id="txtInputEmail"
                          placeholder={"Email"}
                          fullWidth={true}
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className="textField" style={webStyle.textField}>Phone</Typography>
                      <Box sx={{ paddingBottom: "12px" }}>
                        <TextField
                          name="phone_number"
                          type="number"
                          data-test-id="txtInputPhone"
                          placeholder={"Phone"}
                          fullWidth={true}
                          variant="outlined"
                          value={values.phone_number}
                          onChange={handleChange}
                          error={
                            touched.phone_number && Boolean(errors.phone_number)
                          }
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" style={{ position: 'relative' }}>
                                <select style={{
                                  appearance: 'none',
                                  border: 0,
                                  background: 'transparent',
                                  width: '60px',
                                  position: 'relative',
                                  zIndex: 1,
                                  cursor: 'pointer'
                                }}
                                  onChange={(e: { target: { value: string } }) => {
                                    this.setState({ countryCode: e.target.value })
                                  }}
                                >
                                  {rawCountries.map(country => {
                                    return (
                                      <option value={`+${country[3]}`}>{`+${country[3]}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${country[0]}`}</option>
                                    )
                                  })}
                                </select>
                                <ExpandMoreIcon style={{
                                  position: 'absolute',
                                  right: '0px',
                                  cursor: 'pointer',
                                  zIndex: 0
                                }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography className="textField" style={webStyle.textField}>
                        Message
                      </Typography>
                      <Box sx={{ paddingBottom: "12px" }}>
                        <TextField
                          name="message"
                          data-test-id="txtInputMessage"
                          placeholder={"Message"}
                          multiline
                          minRows={3}
                          fullWidth={true}
                          variant="outlined"
                          value={values.message}
                          onChange={handleChange}
                          error={touched.message && Boolean(errors.message)}
                          helperText={touched.message && errors.message}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      padding: "15px 0px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{
                        marginTop: "15px",
                        letterSpacing: "2px",
                        width: "200px",
                        textTransform: "capitalize",
                      }}
                      data-test-id={"btnContactUs"}
                      variant="contained"
                      color="primary"
                    >
                      Send!
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    alignItems: " center",
    justifyContent: "center ",
    background: "linear-gradient(0deg, white, #d3d9e7 100%) no-repeat",
  },
  contactBox: {
    backgroundColor: "#ffff",
    marginTop: "100px",
    padding: "50px",
    maxWidth: "800px",
    borderRadius: "20px 20px 0 0",
    boxShadow: "0 0px 10px 6px rgb(0 0 0 / 26%)",
    backgroundImage: `url(${newBack})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  mainHeading: {
    // textAlign: "center",
    color: '#5370c2',
    fontSize: '70px',
    // fontWeight: 'bold',
    [theme.breakpoints.down("sm")]: {
      fontSize: '45px',
    },
  },
  heading: {
    margin: "20px 0px 30px 0px",
    letterSpacing: "1px",
    fontSize: "50px",
  },
  textField: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '33px',
    color: '#100F0D'
  },
  dropdownspan: {
    width: "25px",
    borderBottom: '5px solid #ebeff8',
    height: '1px',
    position: "absolute",
    top: '60%',
    zIndex: "9999",
    transform: 'translate(90%,-50%)',
    right: '35px',
    bottom: '-10px'
  },
  countryselect: {
    appearance: 'none',
    border: 0,
    background: 'transparent',
    width: '50px'
  },
  textFieldInput: {
    outline: 'none !important',
    border: 'none !important'
  }
};
// Customizable Area End