import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  file: any;
  profileImageData: any;
  backgroundImageData: any;
  aboutYourself: string;
  profile: any;
  boardBiography: any[];
  backgroundImage: any;
  profileImage: any;
  aboutText: any;
  profileData: any;
  viewBlogPost:boolean;
  posts:any;
  postsCategory:any,
  viewPostData:any;
  viewPostComments:any;
  post_id:any;
  categoryPostCount:any;
  urlEvent:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoardBiographyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPostApiCallId: any;
  getPostCategoryApiCallId:any;
  showProfileApiCallId: any;
  viewPostDataApiCallId:any;
  viewPostCommentsApiCallId:any;
  sharePostApiId:any;
  addCommentsApiId:any;
  addCommentsReplyApiId:any;
  deleteCommentsApiCallId:any;
  deleteCommentsReplyApiCallId:any;
  deleteLikesApiCallId:any;
  likePostOrCommentApiId:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      file: "",
      profileImageData: "",
      viewBlogPost:false,
      backgroundImageData: "",
      aboutYourself: "",
      boardBiography: [],
      posts:[],
      postsCategory:[],
      token: "",
      profile: "",
      backgroundImage: "",
      profileImage: "",
      aboutText: "",
      profileData: "",
      viewPostData:{},
      viewPostComments:[],
      post_id:'',
      categoryPostCount:4,
      urlEvent:window.location.pathname.split('/').pop(),
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    const authTokenReq = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(authTokenReq);
    this.getProfileData();
    this.getAllPostData();
    this.getAllPostDataCategory();
    this.checkPostRedirection()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = localStorage.getItem("authToken") as string;
      this.setState({ token: token }, () => {
        this.getProfileData();
        this.getAllPostData();
        this.getAllPostDataCategory();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.getPostApiCallId) {
          this.setState({ posts: responseJson.data });
        }

        if (apiRequestCallId === this.getPostCategoryApiCallId) {
          let temp:any= []
          responseJson.data.map((item: any, index: any) => {
            item.attributes.posts.data.map((ele: any, ind: any) => {
              temp.push(ele)
            })

          })


          this.setState({ postsCategory: temp });
        }

   this.apiCallFunc(responseJson,apiRequestCallId)

      }

      runEngine.debugLog("API Message Recived", message);

      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }


  getAllPostData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/posts?page=1&per_page=10"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getAllPostDataCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/posts/category_post?limit=4"
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getProfileData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.showProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"/account_block/accounts"
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onPostClick = (data: any) => {

    this.getPostData(data.id)
    this.getPostComments(data.id)
    this.setState({ viewBlogPost: true })
  }

  onComment = (data: any, postId: any) => {

    this.addCommentsToPost(data, postId)
  }


  onCommentReply = (data: any, id: any) => {

    this.addReplyToComment(data, id)
  }


  onCommentDelete = (id: any,postId:any) => {
    this.setState({post_id:postId})
   this.deleteComment(id)

  }


  onDeleteCommentReply = (id: number) => {
    this.deleteReply(id)

  }


  onLikePostOrComment=(post:any,id:any)=>{
    this.likePostOrComment(post,id)
  }

  onDeletePostOrComment = (id: any) => {
    this.deletePostOrCommentLikes(id)
  }


  likePostOrComment = (post:any,id:any)=>{

    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    
    const body = {
      data: {
       attributes:{
        likeable_id : id, 
        likeable_type : post?"BxBlockPosts::PostNew":"BxBlockPosts::Comment"
       }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likePostOrCommentApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_like/likes",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deletePostOrCommentLikes=(id:any)=> {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteLikesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_like/likes/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getPostData(id:any) {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewPostDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostComments=(id:any)=> {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewPostCommentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/comments?post_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  addReplyToComment = (eventData:any,Id:any)=>{

    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    
    const body = {
      data: {
        attributes: {
          content: eventData,
          replable_id: Id,
          replable_type: "BxBlockPosts::Comment"
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCommentsReplyApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/replies",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  addCommentsToPost = (eventData:any,postId:any)=>{

    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    
    const body = {
      data: {
       attributes:{
        post_id:postId,
        content:eventData
       }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCommentsApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/comments",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteReply=(id:any)=> {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentsReplyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/replies/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  deleteComment=(id:any)=> {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/comments/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  onBackButton=()=>{

    this.setState({viewBlogPost:false})
  }


  apiCallFunc(responseJson:any,apiRequestCallId:any){

    if (apiRequestCallId === this.showProfileApiCallId) {
      {
        Object.entries(responseJson).forEach((item: any) => {
          this.setState({ profileData: item[1].attributes });
        });
      }
    }


    if (apiRequestCallId === this.viewPostDataApiCallId) {
      this.setState({ viewPostData: responseJson.data });
    }


    if (apiRequestCallId === this.viewPostCommentsApiCallId) {
      this.setState({ viewPostComments: responseJson.data })
    }

    if (apiRequestCallId === this.addCommentsApiId) {
      this.getPostData(responseJson.data.attributes.post_id)
      this.getPostComments(responseJson.data.attributes.post_id)
    }

    if (apiRequestCallId === this.deleteCommentsApiCallId||apiRequestCallId===this.deleteCommentsReplyApiCallId||apiRequestCallId===this.likePostOrCommentApiId||apiRequestCallId===this.deleteLikesApiCallId||apiRequestCallId===this.addCommentsReplyApiId||apiRequestCallId===this.sharePostApiId) {
      this.getPostData(this.state.viewPostData.id)
      this.getPostComments(this.state.viewPostData.id)
    }
  }

  checkPostRedirection=()=>{

    if(this.state.urlEvent!=='BlogPost'){
      setTimeout(() => {
        this.onPostClick({id:this.state.urlEvent})
     }, 600)

    }
  }


  sharePost = (postId:any)=>{

    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    
    const body = {
      data:{
        shared_to_id: localStorage.getItem("user_id"),
        post_id:postId
    }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_share/share",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getImg(item: any, placeHolder: any) {

    let src = item.attributes.thumbnail ? item.attributes.thumbnail : placeHolder
    return src
  }
  

  // Customizable Area End
}
