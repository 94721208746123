import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Grid,
  Typography,
  Chip,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  placeholder_logo,
} from "./assets";
import { createTheme } from "@material-ui/core/styles";
import "../../JobListing2/src/job.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import MatchAlgorithm2Controller, {
  Props,
  configJSON,
} from "./MatchAlgorithm2Controller";

export default class MatchAlgorithm2 extends MatchAlgorithm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  createdAt = (date: string) => {
    return `${String(new Date(date).getDate()).padStart(2, '0')}/${new Date(date).getMonth()}/${new Date(date).getFullYear()}`
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Grid xs={12}>
        <Typography style={{ fontSize: '23px', fontWeight: 'inherit' }}>Matched Jobs</Typography>
        {this.state.matchedJobs && this.state.matchedJobs.map((data: any, index: number) => {
          return <>  <Box className="card-box">
            <Box className="card_box_inner" data-test-id="jobDetailsBtn" onClick={() => { this.handleNavigate(data.id)
             window.scrollTo(0, 0) }}>
              <img src={data.attributes.company.profile_image === null ? placeholder_logo : data.attributes.company.profile_image} className="card-logo"></img>
              <Box className="box-detail">
                <Box className="box-detail-Content">
                  <Box>
                    <Typography className="box-detail-Content-title">
                      {data.attributes.job_title}
                    </Typography>
                    <Typography className="box-detail-Content-subtitle">{data.attributes.company.company_name}</Typography>
                  </Box>
                  <Chip style={{ borderRadius: '9px', width: "93px" }} label={this.createdAt(data.attributes.created_at)} />
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.employment_type.employment_type_name} />
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.experience_level} />
                  <Chip style={{ borderRadius: '9px', width: "93px", textOverflow: "ellipsis" }} label={data.attributes.job_type} />
                  <Typography className="location_width">{data.attributes.location}</Typography>
                </Box>
                {<Typography style={{ fontSize: '13px', marginLeft: '2px', color: 'grey', marginBottom: '10px' }}>{data.attributes.description.substring(0, 25)}</Typography>}
              </Box>
            </Box>
            <Box className="btn-group">
              <Button data-test-id="share_job_btn0" className="CardBTN"
                onClick={() => { this.props.shareJobPopUp(data) }} style={{ textTransform: 'capitalize', height: "28px" }}>
                share</Button>
              {this.props.messageOrApply(data.id)}
            </Box>
          </Box>
          </>
        })}
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  cardBoxInner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  cardBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
    width: '100%'
  },
  cardLogo: {
    width: "120px",
    height: "78px",
    objectFit: "cover" as const,
  },

};
// Customizable Area End
