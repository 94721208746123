import React from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
    Dialog,
    IconButton,
    styled,
    TextField,
    Radio,
    CircularProgress,
} from "@material-ui/core";
import "./job.css";
import CloseIcon from '@material-ui/icons/Close';
import FooterWeb from "../../../components/src/FooterWeb";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import { Link } from 'react-router-dom';
import JobsAppliedController, { Props } from "./JobsAppliedController.web";
import { placeholder_logo } from "./assets";
const CloseIconStyle = styled(IconButton)({

    "&:hover": {
        backgroundColor: "unset",
    }

})
const DialogBox = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        width: "40%"
    },
    "& .MuiDialog-paper": {
        padding: "10px 30px 10px 30px",
        margin: 0
    },
    "@media (max-width: 900px)": {

        "& .MuiDialog-paperWidthSm": {
            width: "100%"
        },
        "& .MuiDialog-paper": {
            padding: "10px",
        }
    }
})
const DialogMessgaeBox = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        width: "40%"
    },

    "@media (max-width: 900px)": {
        "& .MuiDialog-paperWidthSm": {
            width: "100%"
        }
    }
})
const ButtonStyle = styled(Button)({
    backgroundColor: "#536fc2",
    color: "#fff",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: 700,
    marginRight: "12px",
    textTransform: "none",
    width: "140px",
    "&:hover": {
        backgroundColor: "#536fc2",
    },

    "& .MuiButton-root.Mui-disabled": {
        color: "#fff"
    },
    "@media (max-width:450px)": {
        marginBottom: "10px"
    },
})

const MainDialog = styled(`div`)({
    padding: "40px",
    "@media (max-width:600px)": {
        padding: "20px"
    },
})
const MainGrid = styled(Grid)({
    marginBottom: "40px",
    "@media (max-width:450px)": {
        marginBottom: "0px"
    },
})
const ChildGrid = styled(Grid)({
    paddingRight: "40px",
    "@media (max-width:450px)": {
        paddingRight: "0px"
    },
})
const ChildGrid2 = styled(Grid)({
    paddingLeft: "40px",
    "@media (max-width:450px)": {
        paddingLeft: "0px"
    },
})
const ParentBox = styled(`div`)({
    display: 'flex',
    gap: 20,
    marginBottom: "40px",
    "@media (max-width:960px)": {
        flexDirection: 'column',
    },
})
const ChildBox = styled(`div`)({
    border: "1px solid #536fc2",
    borderRadius: "8px",
    padding: "40px 30px",
    flex: 1,
})
const ChildBox2 = styled(Box)({
    display: "flex",
    marginBottom: "30px",
    "@media (max-width:450px)": {
        display: "unset",
    },
})
const TypographyStyle = styled(Typography)({
    ontSize: '15px',
    fontWeight: 500,
    marginRight: "50px",
    flex: 1,
    "@media (max-width:450px)": {
        marginRight: "0px",
    },
})

const ChildBox3 = styled(Box)({
    display: "flex",
    alignItems: "center",
    "@media (max-width:450px)": {
        display: "unset",
    },
})
const stylesForWeb = {
    mainBox: {
        margin: "20px 0"
    },

}

export default class JobsAppliedExecutive extends JobsAppliedController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { questionIndex, qusetions_data } = this.state;
        const currentQuestion = qusetions_data[questionIndex];
        let inputElement = null;

        if (currentQuestion) {
            const { attributes } = currentQuestion;

            if (attributes) {
                const { selection_type, id, options } = attributes;

                switch (selection_type) {
                    case null:
                        inputElement = (
                            <TextField
                                key={`${id}`}
                                id={`answer-${questionIndex}`}
                                placeholder="Your answer is here..."
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%", padding: "2px" }}
                                onChange={this.handleAnswerChange}
                            />
                        );
                        break;
                    case "Multi Selection":
                        inputElement = (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {options.map((selecHeading: any) => (
                                    <FormControlLabel
                                        key={`${selecHeading.id}`}
                                        value={selecHeading.id}
                                        control={<Checkbox color="primary" />}
                                        label={selecHeading?.description}
                                        labelPlacement="start"
                                        style={{ flexDirection: "row" }}
                                        onChange={this.handleAnswerChange}
                                    />
                                ))}
                            </div>
                        );
                        break;
                    case "Single Selection":
                        inputElement = (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {options.map((selecHeading: any) => (
                                    <FormControlLabel
                                        key={`${selecHeading?.id}`}
                                        value={selecHeading?.id}
                                        control={<Radio color="primary" />}
                                        label={selecHeading?.description}
                                        labelPlacement="start"
                                        style={{ flexDirection: "row" }}
                                        onChange={this.handleAnswerChange}
                                    />
                                ))}
                            </div>
                        );
                        break;
                    default:
                        break;
                }
            }
        }
        return <>
            <NavigationMenunew navigation={undefined} id={""} />
            {
                Object.keys(this.state.jobsDetailsData).length === 0 ?
                    <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
                        <CircularProgress />
                    </div>
                    :
                    <Box className="RecentJobSection">
                        <Box className="container">
                            <Grid className="JobAppliedContainer">
                                <Box style={stylesForWeb.mainBox}>
                                    <Link to="/Jobs">
                                        <ButtonStyle>
                                            Search Jobs
                                        </ButtonStyle>
                                    </Link>
                                </Box>
                                <MainGrid xs={12} container >
                                    <ChildGrid md={6} item >
                                        <Typography style={{ fontSize: '33px', fontWeight: 600, marginBottom: "10px" }}>
                                            {this.state.jobsDetailsData.data.attributes.job_title}
                                        </Typography>
                                        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                            <Box>
                                                <Typography style={{ fontSize: '20px', fontWeight: 600, marginBottom: "5px" }}>
                                                    {this.state.jobsDetailsData.data.attributes.company.company_name}
                                                </Typography>
                                                <Typography style={{ fontSize: '15px', fontWeight: 500, marginBottom: "5px" }}>
                                                    {this.handledateFunction(this.state.jobsDetailsData.data.attributes.created_at)}

                                                </Typography>
                                                <Typography style={{ fontSize: '15px', fontWeight: 500, marginBottom: "5px", color: "gray" }}>
                                                    {this.state.jobsDetailsData.data.attributes.location}

                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography style={{ fontSize: '20px', fontWeight: 600, marginBottom: "5px" }}>
                                                    Skills
                                                </Typography>
                                                {
                                                    this.state.jobsDetailsData?.data?.attributes?.skills.map((item: any, id: any) => {
                                                        return <Typography style={{ fontSize: '15px', fontWeight: 500, marginBottom: "5px", color: "gray" }} key={`${id}`}>
                                                            {item}
                                                        </Typography>
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                        <ButtonStyle
                                            style={{ marginBottom: "20px", backgroundColor: this.handleStyle(), color: "white" }}
                                            onClick={this.openDialog}
                                            disabled={this.state.jobsApplied === true}
                                        >
                                            {this.handleApplyButtonName()}
                                        </ButtonStyle>


                                        <Typography className="text-ellipsis2" style={{
                                            fontSize: '15px',
                                            fontWeight: 500,
                                            marginBottom: "5px",
                                        }}
                                        >
                                            {this.state.jobsDetailsData?.data?.attributes?.description}
                                        </Typography>


                                    </ChildGrid>
                                    <ChildGrid2 md={6} item >
                                        <ChildBox2>
                                            <img src={this.state.jobsDetailsData?.data?.attributes?.company?.profile_image === null ? placeholder_logo : this.state.jobsDetailsData?.data?.attributes?.company?.profile_image} style={{ width: "180px", height: "60px", marginRight: "40px", objectFit: "cover" }} />

                                            <Box>
                                                <Typography style={{ fontSize: '15px', fontWeight: 600, marginBottom: "10px" }} className="text-ellipsis">
                                                    {this.state.jobsDetailsData?.data?.attributes?.description}
                                                </Typography>
                                                <Link to={`/UserProfileBasicBlockweb/${this.state.jobsDetailsData?.data?.attributes?.company?.id}`} className="text-style">
                                                    See the Company
                                                </Link>
                                            </Box>
                                        </ChildBox2>
                                    </ChildGrid2>
                                </MainGrid>
                                <Typography style={{ fontSize: '33px', fontWeight: 600, marginBottom: "10px" }}>
                                    {this.state.relatedJobs?.data && this.state.relatedJobs?.data.length > 0 && "Related Jobs"}
                                </Typography>
                                <ParentBox>
                                    {this.state.relatedJobs?.data && this.state.relatedJobs?.data.slice(0, 2).map((item: any, id: any) => {
                                        return <ChildBox key={`${id}`}>
                                            <Box style={{ display: "flex", marginBottom: "30px" }}>
                                                <img src={item.attributes.company.profile_image===null? placeholder_logo :item.attributes.company.profile_image} style={{ width: "150px", height: "60px", marginRight: "18px", objectFit: "cover" }} />
                                                <Box>
                                                    <Typography style={{ fontSize: '15px', fontWeight: 600, marginBottom: "10px" }}>
                                                        {item.attributes.job_title}
                                                    </Typography>
                                                    <Typography style={{ fontSize: '15px', fontWeight: 500, marginBottom: "5px" }}>
                                                        {item.attributes.company.company_name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <ChildBox3 >

                                                <TypographyStyle className="text-ellipsis2">
                                                    {item.attributes.description}

                                                </TypographyStyle>
                                                <ButtonStyle style={{ marginRight: "0px" }} data-test-id="navigate" onClick={() => this.navigateToDetail(item.attributes.id)}>
                                                    Apply
                                                </ButtonStyle>
                                            </ChildBox3>

                                        </ChildBox>
                                    })}

                                </ParentBox>
                            </Grid>
                            <DialogMessgaeBox open={this.state.messageshow}
                                onClose={this.handleMessageAlert}>
                                <div style={{ display: "flex", justifyContent: "Center", padding: "30px" }}>
                                    <Typography>
                                        {this.state.message}
                                    </Typography>
                                </div>
                            </DialogMessgaeBox>
                            <DialogBox
                                open={this.state.isDialogOpen}
                                onClose={this.closeDialog}
                            >

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '40px 40px 10px 40px' }}>
                                    <Typography style={{ fontSize: "33px", fontWeight: "bold" }}>
                                        Questions
                                    </Typography>
                                    <CloseIconStyle edge="end" color="inherit" onClick={this.closeDialog}>
                                        <CloseIcon />
                                    </CloseIconStyle>
                                </div>
                                <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px 30px", alignItems: "center" }}>
                                    {this.state.qusetions_data.map((question: any, index: any) => {
                                        return (
                                            <Box
                                                key={index}
                                                style={{
                                                    height: this.handleCurrentQuestion(question.attributes.id, currentQuestion.attributes.id),
                                                    width: this.handleCurrentQuestion(question.attributes.id, currentQuestion.attributes.id),
                                                    border: this.handleCurrentIndex(questionIndex, qusetions_data.length),
                                                    borderRadius: "50%",
                                                    margin: "10px",
                                                }}
                                            ></Box>
                                        );
                                    })}

                                </Box>
                                <MainDialog>
                                    <Typography style={{ fontSize: "20px", fontWeight: 500, marginBottom: "20px" }}>
                                        {`${questionIndex + 1}. ${currentQuestion?.attributes?.description}`}
                                    </Typography>

                                    {inputElement}

                                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                                        <ButtonStyle onClick={this.handleContinue}>
                                            {this.handleButtonName()}
                                        </ButtonStyle>
                                    </Box>
                                </MainDialog>


                            </DialogBox>
                        </Box>
                    </Box>
            }
            <FooterWeb />
        </>
    }
}