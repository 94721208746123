import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  CardHeader,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import {
  image_view,
  image_applications,
  image_jobs,
  image_shortlisted
} from "./assets";
import "../../../components/src/dashboard.css";
import DashboardController, { Props } from "./DashboardController.web";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DropDown from "../../../components/src/AccDropdown.web";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import moment from "moment";
import { SharePopUp } from "../../../components/src/sharePopUp";
import{ViewApplication} from"../../../components/src/ViewApplication"
import { Link } from "react-router-dom"

type FilterOptions = {
  [key: string]: string;
};
// Customizable Area End

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  company_dashboard() {
    const profile_view_type: FilterOptions = { "Daily": "date", "Monthly": "month", "Yearly": "year" }
    const xaxis: string = profile_view_type[this.state.selectedFilter];
    const linechartData1 = {
      series1: [
        {
          name: "Series 1",
          data: this.state.profileViews.map((item) => item.count)
        }
      ],
      options1: {
        chart: {
          id: "line-chart",
          toolbar: {
            show: true, // Show the custom toolbar
            tools: {
              download: false, // Remove the download option
              selection: false, // Enable the selection/home option
              zoom: false, // Enable the zoom option
              zoomin: false, // Enable the zoom in option
              zoomout: false, // Enable the zoom out option
              pan: false, // Disable the pan option
              reset: false // Enable the reset option
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.state.profileViews.map((item) => item[xaxis])
        }
      }
    };
    const profileViewData1 = [
      {
        "id": 'Daily',
        "value": "last 6 days"
      },
      {
        "id": 'Monthly',
        "value": "last 6 month"
      },
      {
        "id": 'Yearly',
        "value": "last 6 year"
      },
    ];
    const chartOptions1: ApexOptions = {
      chart: {
        type: "donut",
        height: "200px",
        width: "200px"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      labels: [
        "Accepted applications",
        "Rejected application",
        "Pending applications"
      ],
      colors: ["#2E93fA", "#66DA26", "#546E7A"],
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center"
      }
    };
    const chartData1 = [
      this.state.static_analytics?.accepted_applications,
      this.state.static_analytics?.rejected_applications,
      this.state.static_analytics?.pending_applications,
    ];

    const onApplicationCLick = (status: string, item: any) => {
      this.applicationStatus(status, item.id)
    }

    return (
      <div className="company_dashboard">
        <Container>
          <Grid container id="company_dashboard_msg_grid">
            <Grid item sm={12} className="company_dashboard_welcome_msg">
              <h2 className="welcome_heading">Welcome, {this.state.first_name ? this.state.first_name : "-"}!!</h2>
            </Grid>
            <Grid item sm={12} className="company_dashboard_goodtosee_msg">
              <h3 className="good_to_see">Good to see you back</h3>
            </Grid>
          </Grid>
          <div className="card_count_grid">
            <Grid container spacing={3} id="company_dashboard_four_grid">
              <Grid item sm={6} md={3} xs={12}>
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row">
                      <div className="dashboard_col_1_icon">
                        <img
                          src={image_jobs}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.job_posted ? this.state.job_posted : "0"}
                        </Typography>
                        <Typography color="textSecondary">
                          Jobs Posted
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12}>
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_two">
                      <div className="dashboard_col_1_icon card_icon_two">
                        <img
                          src={image_applications}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.applications ? this.state.applications : "0"}
                        </Typography>
                        <Typography color="textSecondary">
                          Applications
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12}>
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_three">
                      <div className="dashboard_col_1_icon card_icon_three">
                        <img
                          src={image_view}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.month_views ? this.state.month_views : "0"}
                        </Typography>
                        <Typography color="textSecondary">
                          Month Views
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12}>
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_four">
                      <div className="dashboard_col_1_icon card_icon_four">
                        <img
                          src={image_shortlisted}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.company_shortlisted ? this.state.company_shortlisted : "0"}
                        </Typography>
                        <Typography color="textSecondary">
                          Shortlisted
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="line_graph_row">
            <Grid container spacing={3}>
              <Grid item md={8} sm={6} xs={12}>
                <div className="">
                  <Card className="analy_dashboard">
                    <CardHeader
                      title="Your Profile Views"
                      className="card_heading_dashboard"
                    />
                    <CardContent>
                      <div className="line_chart_dropdown">
                        <DropDown
                          name='profile_view_data'
                          value={
                            this.state.selectedFilter
                          }
                          onChange={this.handleChange}
                          items={profileViewData1}
                        />
                      </div>
                      <div className="line_chart_line_graph_row">

                        <ReactApexChart
                          options={linechartData1.options1}
                          series={linechartData1.series1}
                          type="line"
                          height={300}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="">
                  <Card className="analy_dashboard">
                    <CardHeader
                      title="Recent Activity"
                      className="card_heading_dashboard"
                    />
                    <CardContent className="recent_activity_div_content">
                      {this.state.recenty_activity_log?.map((itemi: any, index: number) =>
                      (
                        <div className="recent_activity_div" id={itemi.id}>
                          <Typography
                            variant="h5"
                            component="h2"
                            className="recent_activity_title"
                          >
                            {itemi.attributes.description}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            className="recent_activity_disc"
                          >
                            {moment.unix(itemi.attributes.timestamp).from(moment())}
                          </Typography>
                        </div>
                      )
                      )}

                      <div className="recent_activity_load_more">
                        <Link to={"/Settings5/1/"} onClick={() => window.scrollTo(0, 0)}>
                          <Button
                            size="small"
                            className="recent_activity_load_more_btn"
                          >
                            Load More
                          </Button>
                        </Link>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="pie_chart_row">
            <Grid container spacing={3}>
              <Grid item md={12} sm={12} xs={12} lg={3}>
                <div className="">
                  <Card className="analy_dashboard analy_dashboard_donut">
                    <CardHeader
                      title="Static Analytics"
                      className="card_heading_dashboard"
                    />
                    <CardContent className="chart_donut_CardContent">
                      <div className="chart_donut" id="chart">
                        <ReactApexChart
                          options={chartOptions1}
                          series={chartData1}
                          type="donut"
                          height={300}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12} lg={9}>
                <div className="">
                  <Card className="analy_dashboard analy_dashboard_donut">
                    <CardHeader
                      title="Recent Applicants"
                      className="card_heading_dashboard"
                    />
                    <CardContent className="recent_application_content">
                      <div className="recent_application_div">

                        {this.state.recent_applicants?.map((itemi: any, index: number) =>
                        (
                          <Card className="recent_application_div_card_row">
                            <CardContent>
                              <Grid
                                container
                                spacing={3}
                                className="recent_application_div_grid"
                              >
                                <Grid item md={12} sm={12} xs={12} lg={2}>
                                  {itemi.attributes.executive.profile_image ? (
                                    <img
                                      src={itemi.attributes.executive.profile_image}
                                      alt="Edit"
                                      className="recent_application_img"
                                    />
                                  ) : (
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_title_for_executive"
                                    >
                                      {itemi.attributes.executive.first_name} {itemi.attributes.executive.last_name}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item md={12} sm={12} xs={12} lg={6}>
                                  <Typography
                                    variant="h5"
                                    component="h2"
                                    className="recent_application_title"
                                  >
                                    <b>{itemi.attributes.executive.first_name} {itemi.attributes.executive.last_name}</b>
                                  </Typography>
                                  <div className="recent_application_dis_div">
                                    <Grid
                                      container
                                      spacing={1}
                                      className="title_date_location_grid"
                                    >
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_dis"
                                        >
                                          {itemi.attributes.job.data.attributes.job_title}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_dis"
                                        >
                                          {moment(itemi.attributes.created_at).from(moment())}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_city"
                                        >
                                          <b>{itemi.attributes.job.data.attributes.location}</b>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="recent_application_batch">
                                    <Grid
                                      container
                                      spacing={1}
                                      className="title_date_location_grid"
                                    >
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_batch_text"
                                        >
                                          {itemi.attributes.job.data.attributes.employment_type.employment_type_name}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_batch_text"
                                        >
                                          {itemi.attributes.job.data.attributes.experience_level}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={3} xs={4}>
                                        <Typography
                                          variant="h5"
                                          component="h2"
                                          className="recent_application_batch_text"
                                        >
                                          {itemi.attributes.job.data.attributes.job_type}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12} lg={4} className="btn-wrapper" >
                                  <div data-test-id="test-btn" className="btn-wrapper">
                                    <Tooltip title="View Application" arrow placement="top">
                                    <Button
                                      variant="outlined"
                                      data-test-id="btn"
                                      size="small"
                                      color="primary"
                                      className="recent_application_btn_icon"
                                      onClick={()=>this.viewApplication(itemi)}
                                    >
                                      <VisibilityOutlinedIcon className='iconSize' />
                                    </Button>
                                    </Tooltip>

                                    {localStorage.getItem("teamMember_ReadOnly") !== 'true' &&<Tooltip title="Accept Application" arrow placement="top">
                                      <Button
                                        data-test-id="btn"
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="recent_application_btn_icon"
                                        onClick={() => onApplicationCLick('Hired', itemi)}
                                      >
                                        <CheckOutlinedIcon className='iconSize'/>
                                      </Button>
                                    </Tooltip>}

                                    {localStorage.getItem("teamMember_ReadOnly") !== 'true' &&<Tooltip title="Reject Application" arrow placement="top">
                                      <Button
                                        data-test-id="btn"
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="recent_application_btn_icon"
                                        onClick={() => onApplicationCLick('Rejected', itemi)}
                                      >
                                        <CancelOutlinedIcon className='iconSize'/>
                                      </Button>
                                    </Tooltip>}

                                    {localStorage.getItem("teamMember_ReadOnly") !== 'true' &&<Tooltip title="Delete Application" arrow placement="top">
                                      <Button
                                        data-test-id="btn"
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="recent_application_btn_icon"
                                        onClick={() => onApplicationCLick('delete', itemi)}
                                      >
                                        <DeleteOutlineOutlinedIcon className='iconSize' />
                                      </Button>
                                    </Tooltip>}
                                    <span className={`${itemi.attributes.status} status-btn`}>
                                      {itemi.attributes.status !== 'Answered' && itemi.attributes.status}</span>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Dialog fullWidth className="model_popup" scroll="body" open={this.state.viewApplication}  >
          
            <div className="">
             <ViewApplication data-test-id="viewPopup"  applicantQuestions={this.state.applicantQuestions} executiveDetails={this.state.executiveDetails} closeModal={() => { this.setState({ viewApplication: false })}}   selectedExecutiveId={this.state.selectedExecutiveId } onApplicationCLick={onApplicationCLick} recentApplicants={this.state.recent_applicants} isDashboard={true} />
            </div>
         
        </Dialog>
      </div>
    )
  }

  executive_dashboard() {
    const profile_view_type: FilterOptions = { "Daily": "date", "Monthly": "month", "Yearly": "year" }
    const xaxis: string = profile_view_type[this.state.selectedFilter];
    const linechartData = {
      series: [
        {
          name: "Series 1",
          data: this.state.profileViews.map((item) => item.count)
        }
      ],
      options: {
        chart: {
          id: "line-chart",
          toolbar: {
            show: true, // Show the custom toolbar
            tools: {
              download: false, // Remove the download option
              selection: false, // Enable the selection/home option
              zoom: false, // Enable the zoom option
              zoomin: false, // Enable the zoom in option
              zoomout: false, // Enable the zoom out option
              pan: false, // Disable the pan option
              reset: false // Enable the reset option
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.state.profileViews.map((item) => item[xaxis])
        }
      }
    };
    const profileViewData = [
      {
        "id": 'Daily',
        "value": "last 6 days"
      },
      {
        "id": 'Monthly',
        "value": "last 6 month"
      },
      {
        "id": 'Yearly',
        "value": "last 6 year"
      },
    ];
    return (
      <div className="executive_dashboard">
        <Container>
          <Grid container>
            <Grid item sm={12} className="executive_dashboard_goodtosee_msg">
              <h2 className="welcome_heading">Welcome, {this.state.first_name ? this.state.first_name : "-"}!!</h2>
            </Grid>
            <Grid item sm={12} className="executive_dashboard_goodtosee_msg">
              <h3 className="good_to_see">Good to see you back</h3>
            </Grid>
          </Grid>
          <div className="card_count_grid">
            <Grid container spacing={3} id="executive_dashboard_four_grid">
              <Grid item sm={6} md={3} xs={12} className="executive_dashboard_appliedjobs_grid">
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row">
                      <div className="dashboard_col_1_icon">
                        <img
                          src={image_jobs}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.applied_jobs ? this.state.applied_jobs : '0'}
                        </Typography>
                        <Typography color="textSecondary">
                          Applied Jobs
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12} className="executive_dashboard_appliedjobs_grid">
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_two">
                      <div className="dashboard_col_1_icon card_icon_two">
                        <img
                          src={image_applications}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.job_alerts ? this.state.job_alerts : '0'}
                        </Typography>
                        <Typography color="textSecondary">
                          Job Alerts
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12} className="executive_dashboard_appliedjobs_grid">
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_three">
                      <div className="dashboard_col_1_icon card_icon_three">
                        <img
                          src={image_view}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.interviews ? this.state.interviews : '0'}
                        </Typography>
                        <Typography color="textSecondary">
                          Interviews
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={6} md={3} xs={12} className="executive_dashboard_appliedjobs_grid">
                <div className="div_dashboard_card">
                  <Card>
                    <CardContent className="card_row_four">
                      <div className="dashboard_col_1_icon card_icon_four">
                        <img
                          src={image_shortlisted}
                          alt="Edit"
                          className="dashboard_icon_card"
                        />
                      </div>
                      <div className="dashboard_col_2_icon">
                        <Typography variant="h5" component="h2">
                          {this.state.shortlisted ? this.state.shortlisted : '0'}
                        </Typography>
                        <Typography color="textSecondary">
                          Shortlisted
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="line_graph_row">
            <Grid container spacing={3}>
              <Grid item sm={8}>
                <div className="">
                  <Card className="analy_dashboard">
                    <CardHeader
                      title="Your Profile Views"
                      className="card_heading_dashboard"
                    />
                    <CardContent>
                      <div className="line_chart_dropdown">
                        <DropDown
                          name='profile_view_data'
                          value={
                            this.state.selectedFilter
                          }
                          onChange={this.handleChange}
                          items={profileViewData}
                        />
                      </div>
                      <div className="line_chart_line_graph_row">
                        <ReactApexChart
                          options={linechartData.options}
                          series={linechartData.series}
                          type="line"
                          height={300}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="">
                  <Card className="analy_dashboard">
                    <CardHeader
                      title="Recent Activity"
                      className="card_heading_dashboard"
                    />
                    <CardContent className="recent_activity_div_content">


                      {this.state.recenty_activity_log?.map((itemi: any, index: number) =>
                      (
                        <div className="recent_activity_div" id={itemi.id}>
                          <Typography
                            variant="h5"
                            component="h2"
                            className="recent_activity_title"
                          >
                            {itemi.attributes.description}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            className="recent_activity_disc"
                          >
                            {moment.unix(itemi.attributes.timestamp).from(moment())}
                          </Typography>
                        </div>
                      )
                      )}
                      <div className="recent_activity_load_more">
                        <Link
                          onClick={() => window.scrollTo(0, 0)}
                          to={"/Settings5/1/"}
                        >
                          <Button className="recent_activity_load_more_btn" size="small">Load More</Button>
                        </Link>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="pie_chart_row">
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <div className="">
                  <Card className="analy_dashboard analy_dashboard_job_applied_recently">
                    <CardHeader
                      title="Jobs Applied Recently"
                      className="card_heading_dashboard"
                    />
                    <CardContent className="recent_application_content">
                      {this.state.exe_job_applied?.map((itemi: any, index: number) =>
                      (
                        <div className="recent_application_div" id={itemi.id}>
                          <Card className="recent_application_div_card_row">
                            <CardContent>
                              <Grid
                                container
                                spacing={3}
                                className="recent_application_div_grid"
                              >
                                <Grid item xs={12} sm={4} md={2}>
                                  {itemi.attributes.job.data.attributes.company.profile_image ? (
                                    <img
                                      src={itemi.attributes.job.data.attributes.company.profile_image}
                                      alt="Edit"
                                      className="recent_application_img"
                                    />
                                  ) : (
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_title_for_executive"
                                    >
                                      {itemi.attributes.job.data.attributes.company.company_name}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={3} md={2}>
                                  <Typography
                                    variant="h5"
                                    component="h2"
                                    className="recent_application_title"
                                  >
                                    {itemi.attributes.job.data.attributes.job_title}
                                  </Typography>
                                  <div className="recent_application_dis_div_executive">
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_dis"
                                    >
                                      {itemi.attributes.job.data.attributes.executive_have_appiled} executives applied
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_dis"
                                    >
                                      {moment(itemi.attributes.job.data.attributes.created_at).from(moment())}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={5} md={5}>
                                  <div className="recent_application_batch">
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_batch_text_exe"
                                    >
                                      {itemi.attributes.job.data.attributes.employment_type.employment_type_name}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_batch_text_exe"
                                    >
                                      {itemi.attributes.job.data.attributes.experience_level}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className="recent_application_batch_text_exe"
                                    >
                                      {itemi.attributes.job.data.attributes.job_type}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={1}>
                                  <Typography
                                    variant="h5"
                                    component="h2"
                                    className="recent_application_title_for_executive"
                                  >
                                    {itemi.attributes.job.data.attributes.location}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                  <Button
                                    variant="contained"
                                    className="recent_application_title_for_executive_btn"
                                    data-test-id="test_job_popup"
                                    onClick={() => { this.shareJobPopUp(itemi.attributes.job.data) }}
                                  >
                                    Share
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </div>
                      )
                      )}
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Dialog className="model_popup" scroll="paper" open={this.state.isShare}>
          <DialogContent>
            <div className="">
              <SharePopUp data-test-id="share_modal" location={window.location} id={this.state.jobID} data={this.state.shareJob} closeModal={() => { this.setState({ isShare: false }) }} />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    let content;
    if (this.state.userRole) {
      content = this.state.userRole === "company" ? (
        this.company_dashboard()
      ) : (
        this.executive_dashboard()
      );
    } else {
      content = (
        <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        <div>
          {content}
        </div>
        <FooterWeb />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
