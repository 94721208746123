import React, { useState,useEffect } from "react";
// Customizable Area Start
import {
   Typography,
   Box,
   Button,
   Grid,
   Collapse,
   IconButton,
   Checkbox,
   Paper,
   Dialog,
   DialogContent
} from "@material-ui/core";

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ShareIcon from '@material-ui/icons/Share';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CommentIcon from '@material-ui/icons/Comment';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { SharePopUpBlog } from './SharePopUpBlog'

import "./share.css"
import { Cross, facebook, insta, linked, twitter } from "../../blocks/JobListing2/src/assets";
export const ViewBlogPost = (props: any) => {
   const [recentAppIndex, setRecentAppIndex] = useState(0);
   const [appStatus, setStatus] = useState('');
   const [isShare, setIsShare] = useState(false);
   const [viewComments, setViewComments] = useState(false);
   const [videoUrl, setVideoUrl] = useState('');
   const [textAreaValue, setTextAreaValue] = useState('');
   const [textAreaValueReply, setTextAreaValueReply] = useState('');
   const [replyIndex, setReplyIndex] = useState(-1);
   const {image,placeHolder,viewPostData,posts,viewPostComments,onComment,onCommentDelete,onBackBtn,onLikePostOrTheComment,onDeletePostOrTheCommentLikes,onPostClick,onCommentReply,onCommentDeleteTheReply,onSharePost}= props
   const isMobile = window.matchMedia('(max-width:1024px)').matches;



    useEffect(() => {
        if (viewPostData?.attributes?.media_file) {
            if (viewPostData?.attributes?.media_file.type === 'video') {
                setVideoUrl(viewPostData?.attributes?.media_file.url)
            }
        }

    }, [viewPostData])


   function getFormattedCustomDate(date:any) {
    const currentDate : any = new Date();
    const commentDate : any = new Date(date);
  
    const timeDifference : any= currentDate - commentDate;
  
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
  
    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
  }


   const handleChange = (event:any) => {
    setTextAreaValue(event.target.value);
  };

  const handleChangeReply = (event:any) => {
    setTextAreaValueReply(event.target.value);
  };

   const webStyle = {
    root: {
        flexGrow: 1,
      },
      container: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      largeBlog: {
        padding: 24,
      },
      smallBlog: {
        padding: 16,
        marginBottom: 16,
      }
  };

  const onCommentPost=(e:any)=>{
    if(e.key==='Enter'){
        e.preventDefault();
        onComment(e.target.value,viewPostData.id)
        setTextAreaValue('');
    }
  }


  const onCommentPostReply=(e:any,item:any)=>{
    if(e.key==='Enter'){
        e.preventDefault();
        onCommentReply(e.target.value,item.id)
        setTextAreaValueReply('');
        setReplyIndex(-1)
    }
  }

  const onCommentDeletePost=(item:any)=>{
    onCommentDelete(item.attributes.id,item.attributes.post_id)

  }

    const onCommentDeleteReply = (item: any) => {
        onCommentDeleteTheReply(item.id)

    }


    const onLikePostOrComment = (post: boolean, item: any) => {
        if (post) {
    
            if (viewPostData.attributes.already_liked === null) {
                onLikePostOrTheComment(post, viewPostData.id)
            } else {
                onDeletePostOrTheCommentLikes(viewPostData.attributes.already_liked.id)
            }
        } else {
          
            if (item.attributes.already_liked === null) {
                onLikePostOrTheComment(post, item.id)
            } else {
                onDeletePostOrTheCommentLikes(item.attributes.already_liked.id)
            }
        }
    }

    return <>

        <div className="back-btn" onClick={() => onBackBtn()}>
            <IconButton className={''} size="small">
                < KeyboardBackspaceOutlinedIcon
                    fontSize="small" />
            </IconButton>
            Back
        </div>


        <div className="contentWrapper">
            <Box>

        

            <div  className="center top-heading" >
                <p style={{cursor:'pointer'}} onClick={()=>onBackBtn()}> Library   &nbsp;    &gt;  &nbsp;   Blog Posts  &nbsp;  &gt;  &nbsp;  {viewPostData?.attributes?.description}.. </p>
                </div>

            <div className='center'>

                <div className='main-head'>
                    <h4 className='post-head'> {viewPostData?.attributes?.description}</h4>
                    <p className='date-area'> {viewPostData?.attributes?.publish_date} /// {viewPostData?.attributes?.comment_count} Comments /// {viewPostData?.attributes?.share_count} Shared</p>
                </div>
                    {viewPostData?.attributes?.media_file.type==='video'&&videoUrl&&String(videoUrl)==String(viewPostData?.attributes?.media_file.url)&&<video controls style={{ width: '100%', height: '400px',objectFit:'cover' }}>
                        <source src={viewPostData?.attributes?.media_file.url} type="video/mp4" key={videoUrl} />
                        Your browser does not support the video tag.
                    </video>}
                    {viewPostData?.attributes?.media_file.type==='application'&& 
                      <iframe
                      src={viewPostData?.attributes?.media_file.url}
                      width="100%"
                      height="400px"
                      title="Document Preview"
                    />}
                {viewPostData?.attributes?.media_file.type==='image'&&<img src={viewPostData?.attributes?.media_file.url} alt="Large Blog Post" style={{ width: '100%', height: '400px',objectFit:'cover' }} />}

                <div  className='para-data' dangerouslySetInnerHTML={{ __html: viewPostData?.attributes?.body }} />

            </div>


            <div className='center socialIcons' style={{ marginTop: '20px',marginBottom:'20px' }}>
                <Paper  elevation={3} className={'padd'} style={{ display: 'flex',justifyContent:'flex-start' }}>
                    <div className="mr-font">
                        <IconButton className={''} size="small" onClick={()=>onLikePostOrComment(true,0)}>
                            {viewPostData?.attributes?.like_count!==0?<ThumbUpIcon fontSize="small" style={{color:'#5470c3'}} />:
                             <ThumbUpAltOutlinedIcon fontSize="small" />}
                        </IconButton>
                        {(viewPostData?.attributes?.like_count===0)?'Like':`${viewPostData?.attributes?.like_count} Likes`}
                    </div>
                    <div className="mr-font">
                        <IconButton className={''} size="small" onClick={()=> setViewComments(!viewComments)}>
                        {(viewPostData?.attributes?.comment_count===0)?<InsertCommentOutlinedIcon fontSize="small" />:<InsertCommentOutlinedIcon fontSize="small" style={{color:'#5470c3'}} />}
                        </IconButton>
                        {(viewPostData?.attributes?.comment_count===0)?'Comment':`${viewPostData?.attributes?.comment_count} Comments`}
                    </div>
                    <div className="mr-font">
                        <IconButton className={''} size="small" onClick={()=>{setIsShare(true)
                       onSharePost(viewPostData.id) }}>
                         {(viewPostData?.attributes?.share_count===0)?<ShareOutlinedIcon  fontSize="small" />:<ShareOutlinedIcon  fontSize="small" style={{color:'#5470c3'}} />}
                        </IconButton>
                        {(viewPostData?.attributes?.share_count===0)?'Share':`${viewPostData?.attributes?.share_count} Shares`}
                    </div>
                </Paper>
            </div>


            {viewComments&& <Box className="center comment-box">
                <Box>
                    <img
                        src={image}
                        alt="Logo"
                        className='comment-img'
                    />
                </Box>

                <Box className="comment-border">
                    <textarea value={textAreaValue}
                        onChange={handleChange} onKeyDown={(e) => onCommentPost(e)} id="commentInput" name="commentInput" rows={1} cols={48} placeholder="Add a Comment" className='text-area'></textarea>
                </Box>
            </Box>}
               
            {viewComments&&viewPostComments?.map((item:any, index:any) => {

                return (
                    <>
                        <Box style={{ display: 'flex', gap: '15px', marginTop: '20px' }} className="center">
                            <Box>
                                <img
                                    src={image}
                                    alt="Logo"
                                    className='comment-img'
                                />
                            </Box>
                            <Box className="comment-border" style={{ background: '#ededed' }}>
                                <div >
                                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: '5px 0' }}>
                                        <h4 className='comment-name'>{item.attributes.account.first_name} {item.attributes.account.last_name}</h4>
                                        <span className='update-time'>{getFormattedCustomDate(item.attributes.updated_at)}
                                            <span style={{ marginLeft: '10px' }}>
                                                {<IconButton className={(item.attributes.account.id)==localStorage.getItem("user_id")?'':'hiddenBlock'} size="small" >
                                                    <DeleteOutlineOutlinedIcon fontSize="small" onClick={() => onCommentDeletePost(item)} />
                                                </IconButton>}
                                            </span>
                                        </span>
                                    </div>
                                    <p className='comment-content'>{item.attributes.content}</p>
                                </div>
                            </Box>
                        </Box>
                        <div className='like-reply-wrapper'>
                     <div className="center like-btn-reply" >
                     <IconButton className={''} size="small" onClick={()=>onLikePostOrComment(false,item)}>
                                {item?.attributes?.likes !== 0 ? <ThumbUpIcon fontSize="small" style={{ color: '#5470c3' }} /> :
                                    <ThumbUpAltOutlinedIcon fontSize="small" />}
                            </IconButton>
                            {(item?.attributes?.likes === 0) ? 'Like' : `${item?.attributes?.likes} Likes`}


                 </div>
                 <div onClick={()=>setReplyIndex(index)} className="center reply-btn" >Reply</div>
                 </div>
               

                        {replyIndex===index&&<Box className="comment-border-reply center mbottom mobileTextAreaReply">
                            <textarea value={textAreaValueReply}
                                onChange={handleChangeReply} onKeyDown={(e) => onCommentPostReply(e,item)} id="commentInput" name="commentInput" rows={1} cols={25} placeholder="Add a Reply" className="reply-textarea" ></textarea>
                        </Box>}


                        {item.attributes.replies.data.map((ele:any, ind:any) => {

                            return (
                                <Box className="center comment-border-reply reply-bubble" style={{ background: '#ededed' }} >
                                    <div >
                                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: '5px 0' }}>
                                            <h4 className='comment-name'>{ele.attributes.account.first_name} {ele.attributes.account.last_name}</h4>
                                            <span style={{ fontSize: '11px', color: 'grey' }}>
                                                <span style={{ marginLeft: '10px' }}>
                                                    {<IconButton className={(ele.attributes.account.id)==localStorage.getItem("user_id")?'':'hiddenBlock'} size="small" >
                                                        <DeleteOutlineOutlinedIcon fontSize="small" onClick={() => onCommentDeleteReply(ele)} />
                                                    </IconButton>}
                                                </span>
                                            </span>
                                        </div>
                                        <p className='comment-content max-width-mobile'>{ele.attributes.content}</p>
                                    </div>
                                </Box>
                            )
                        })
                        }
                    </>
                )
            })
            }

            <Dialog className="model_popup" scroll="paper" open={isShare}>
                <DialogContent>
                    <div className="">
                        <SharePopUpBlog data-test-id="share_modal" location={window.location} id={25} data={{}} closeModal={() => setIsShare(false)} />
                    </div>
                </DialogContent>
            </Dialog>



            <div className="center" style={{marginTop:'65px'}}>
                <h3 className="readMore">
                 Read More Blogs
                </h3>

                <br />
                <br />

                <Grid container spacing={2}>

                    {posts.map((item:any, index:any) => (

                        index < 3 && <Grid item xs={6} md={4} lg={4} sm={4}>
                            <Box onClick={()=>{onPostClick(item)
                            window.scrollTo(0,0)}}>
                                <Paper className={`${webStyle.smallBlog}`} style={{height:'100%'}}>
                                    <img src={item.attributes.thumbnail?item.attributes.thumbnail:placeHolder} alt={`Small Blog`} style={{ width: '100%', height: '160px',objectFit:'cover' }} />
                                    <div style={{ padding: '10px' }}>
                                        <h4 className="block-description" style={{ marginTop: '10px' }}>
                                           {item.attributes.description}
                                        </h4>
                                        <p className="date-area-small"> {item.attributes.publish_date} /// {item.attributes.comment_count} Comments /// {item.attributes.share_count} Shared</p>
                                        <div className="category-small">{item.attributes.category.name}</div>
                                    </div>
                                </Paper>
                            </Box>
                        </Grid>
                    ))}

                </Grid>
            </div>

        </Box>
        </div>
    </>
}