import React from "react";

// Customizable Area Start
import {
  Grid,
  Card,
  Container,
  Button,
  Radio,
  CircularProgress,

} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import "../../../components/src/subscriptionBilling.css";

// Customizable Area End

import SubscriptionBilling2Controller, { Props } from "./SubscriptionBilling2Controller.web";

import FooterWeb from "../../../components/src/FooterWeb";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import moment from "moment";

export default class SubscriptionBilling2 extends SubscriptionBilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenunew navigation={undefined} id={""} />
{
  this.state.plans_data.length==0?<div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
  <CircularProgress />
</div>:
        <div className="subsciption_body">
          <Container>
            <div className="subsciption_title_row">
              <h1>{this.handleHeading()}</h1>
            </div>
            <div className="subsciption_plan_row">

              <div className="subsciption_plan_type">

                <div>
                  {this.state.activePlan !== true ? this.state.plans_data?.map((itemi: any, index: number) =>
                  (
                    <Card className="subsciption_plan_type_card_row" data-id={itemi.id} key={index}>
                      <Grid container spacing={3} className="subsciption_plan_type_container">
                        <Grid item xs={12} sm={1} md={1}>
                          <Radio
                            checked={this.state.selectedPlan_id === itemi.id}
                            onChange={() => this.handleChange_selectedPlan(itemi.id, itemi, index)}
                            value={itemi.id ? itemi.id : "--"}
                            name="radio-button-demo"
                            className="plan_select_ready_btn"
                            data-test-id="test_id_subsciption_plan_type_container"
                          />
                        </Grid>
                        <Grid item xs={12} sm={11} md={9} className="plain_title_dis">
                          <h3>{this.handleName(itemi)}</h3>
                          <p>{this.handleDetails(itemi)}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <div className="plan_price_text">
                            {itemi.price === 0.0 ?
                              <div>
                                <h1>Free!</h1>
                              </div>
                              :
                              <div>
                                <h1>${itemi.price}<small className="price_month">/Month</small></h1>
                                <ToggleButton
                                  value={itemi.id}
                                  selected={this.state.one_tim_fee && this.state.selectedPlan_id === itemi.id}
                                  onChange={() => this.one_tim_fee_fun(itemi.id)}
                                  className="price_once_tim_setup_fee"
                                >
                                  <div >
                                    <h1>${itemi.one_time_set_up_fee}</h1>
                                    <span>One time setup fee</span>
                                  </div>
                                </ToggleButton>

                              </div>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  )
                  ) : <Card className="subsciption_plan_type_card_row"  >
                    <Grid container spacing={3} className="subsciption_plan_type_container">
                      <Grid item xs={12} sm={1} md={1}>
                      </Grid>
                      <Grid item xs={12} sm={11} md={9} className="plain_title_dis">
                        <h3>{this.state.Activeplans_data[0]?.name}</h3>
                        <p>{this.state.Activeplans_data[0]?.details}</p>
                        <p style={{fontWeight:700}}>Expiry date : <span>
                          {moment(this.state.activePlanId.next_billing_date).format("DD MMM YYYY")}
                          </span>
                          </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2}>
                        <div className="plan_price_text">

                          <div>
                            <h1>
                              ${
                                this.handlePrice()
                              }
                             <span style={{fontSize:"20px"}}>
                              {this.handleActivePlanHeading()}
                              </span> 
                            </h1>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>}
                </div>
              </div>

                <Grid item sm={12} className="get_start_btn_grid" spacing={2}>
            { this.state.activePlan !==true&& <Grid container style={{justifyContent:"end"}} >
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="button"
                      data-test-id="btnGetSatrt_subcription"
                      color="primary"
                      className="customSave_btn" onClick={() => { this.handlePlan() }}>Get Started</Button>
                  </div>
                </Grid>}
              </Grid>

            </div>
          </Container >
        </div >
}
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
