import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start 
  loadStripe:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPlan_id: any;
  one_tim_fee: boolean
  plans_data: {}[];
  planResponseData: any
  auto_renewal: boolean
  subscriptionType: any;
  viewPage: any;
  Data:any;
  article:any;
  urlId:any
  number:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CheckoutFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ScriptionPlansAPI: string = "";
  subScriptionPlansStripeAPI:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPlan_id: null,
      one_tim_fee: false,
      plans_data: [],
      planResponseData: {},
      auto_renewal: false,
      subscriptionType: "",
      viewPage: false,
      Data:[],
      article:"",
      number:null,
      urlId:window.location.pathname.split('/').pop()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {

      if (this.ScriptionPlansAPI == apiRequestCallId) {
        if (responseJson?.error) {
          alert(responseJson?.error)
          this.props.navigation.navigate("SubscriptionBilling2")
        }
        else {


  this.setState({ planResponseData: responseJson })
  this.setState({ viewPage: true })


        }
      }
      else if(this.subScriptionPlansStripeAPI===apiRequestCallId){
this.handleResponse(responseJson)
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start


  async componentDidMount() {
    
    super.componentDidMount();
    this.subScriptionPlansForStripe()
      this.ScriptionPlansAPI_fun(localStorage.getItem("planId"), 'one_time');
  }

  handleResponse(responseJson:any){
    
    responseJson.plans.map((item:any,index:any)=>{
         if(item.id==this.state.urlId){
          this.setState({article:item?.details,number:item?.one_time_set_up_fee})
          
         }
    })
  }
  
  ScriptionPlansAPI_fun = (id: any, type: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {

      "Content-Type": "application/json",
      token: token
    };
    const url = type == "one_time" ? "/bx_block_plan/subscriptions" : "bx_block_plan/subscriptions/create_subscription";
    const body = type == "one_time" ? { "plan_id": id, "set_up_type": type } : {
      "plan_id": 6,
      "success_url": "/SuccessfullSubscription",
      "fail_url": "/FailSubscription"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ScriptionPlansAPI = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };
  subScriptionPlansForStripe = async () => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subScriptionPlansStripeAPI = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subScriptionPlansAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}
