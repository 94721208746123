// Customizable Area End
import React from "react";

// Customizable Area Start
import "./user_profile_block.css";

import {
  Box,
  Typography,
  Grid,
  Button,
  MenuItem
} from "@material-ui/core";
import { getIn } from "formik";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../blocks/email-account-registration/src/account.css"
import {
  CustomAccTextField,
} from "./CustomStyle.web";
import "../../blocks/email-account-registration/src/signup.css";
import CustomDatePicker from "./CustomDatePicker.web";
import DropDown from "../../components/src/AccDropdown.web";
import { rawCountries } from "./rawCountryData";
import { CollapseBar } from "./CollapseBar";
const ErrorIcon = require("../src/ErrorIcon.png");


export const CompanyAccountCreation = (props: any) => {
  const { values, handleChange, errors, setFieldValue, touched, countryCodeHandler, dateSetter, collapse_open, yearSetter } = props;
  const companySize = [
    {
      "id": '1-50',
      "value": "1-50"
    },
    {
      "id": '51-200',
      "value": "51-200"
    },
    {
      "id": '201-500',
      "value": "201-500"
    },
    {
      "id": '501-1000',
      "value": "501-1000"
    }
  ];
  const countries = [...rawCountries];

  return (
    <Box>
      <div className="account-creation-sections">
        <div className="account-content">
          <Grid container>
            <Grid item sm={12}>
              <h4>Company Details </h4>
            </Grid>
          </Grid>
          <Grid container spacing={4} className="new_job_input_wrap">
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Company Size*</Typography>
              <Box sx={{ paddingBottom: '12px', height: '60px' }} className="company_degree_select_field">
                <DropDown
                  name="company_size"
                  value={values.company_size || ''}
                  onChange={handleChange}
                  items={companySize}
                />
                {errors.company_size && touched.company_size && <span className="err_msg_account_creation">{errors.company_size}</span>}
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Industry Type*</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="industry"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"Industry type"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.industry}
                  onChange={handleChange}
                />
                {errors.industry && touched.industry && <span className="err_msg_account_creation">{errors.industry}</span>}
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Started On Date</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomDatePicker
                  name="start_date"
                  views={["date", "month"]}
                  onChange={(val) => {
                    setFieldValue("start_date", val)
                    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
                    const formattedDate = val.toLocaleDateString("en-US", options);
                    const parts = formattedDate.split("/");
                    const rearrangedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
                    dateSetter(rearrangedDate)
                  }}
                  data-test-id="test_id_user_work_experiences_date"
                  value={values.start_date}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Year</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomDatePicker
                  name="year"
                  views={["year"]}
                  data-test-id="user_work_experiences_start_year_test_id"
                  value={values.year} onChange={(val: any) => {
                    setFieldValue('year', val)
                    yearSetter(val.getFullYear)
                  }} />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Phone Number</Typography>
              <Box sx={{
                height: '63px',
                paddingBottom: '12px',
                border: '1px solid #536fc2',
                borderRadius: '6px',
                display: 'flex',
                position: 'relative',
                width: '100%'
              }}>
                <select className="drop-down"
                  style={{
                    backgroundColor: 'transparent',
                    height: '60px',
                    border: '0px',
                    borderRadius: '10px',
                    appearance: 'none',
                    width: '54.5px',
                    position: 'relative',
                    zIndex: 9,
                    cursor: 'pointer',
                    marginLeft: "8px"
                  }}
                  onChange={countryCodeHandler}
                >
                  {countries.map((country: any) => {
                    return (
                      <option value={`+${country[3]}`}>{`+${country[3]}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${country[0]}`}</option>
                    )
                  })}
                </select>
                <input type="text"
                  name="full_phone_number"
                  style={{ width: '100%',overflow:'hidden', outline: 'none', border: '0px', marginTop: '3px', marginLeft: '17px', height: '56px', backgroundColor: 'transparent' }}
                  value={values.full_phone_number}
                  onChange={handleChange}
                />
                <div style={{
                  display: 'flex',
                  position: 'absolute',
                  top: '17px',
                  left: '43px',
                  cursor: 'pointer',
                  marginLeft: "3px"
                }}
                >
                  <ExpandMoreIcon
                    style={{
                      color: 'grey',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              </Box>
              
              {errors.full_phone_number && touched.full_phone_number && <span className="err_msg_account_creation"> {errors.full_phone_number}</span>}

            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Location</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="location"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"location"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.location}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Website</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="website"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"website"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.website}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
              <Typography className="text-field">Specialities</Typography>
              <Box sx={{ paddingBottom: "12px" }}>
                <CustomAccTextField
                  name="specialities"
                  data-test-id="txtInputOrganizationType"
                  placeholder={"specialities"}
                  fullWidth={true}
                  variant="outlined"
                  value={values.specialities}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item sm={12} style={{ textAlign: "center", margin: '0 auto' }}>
              <Button
                type="submit"
                style={{
                  letterSpacing: "2px",
                  width: "200px",
                  textTransform: "capitalize",
                  marginBottom: "20px"
                }}
                data-test-id={"btnContactUs"}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <CollapseBar
                collapse_open={collapse_open}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  )
}

export const month = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
