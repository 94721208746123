import React from "react"
import { Collapse, IconButton } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert";
import CloseIcon from '@material-ui/icons/Close';
import {useState} from "react"

export const CollapseBar = (props: any) => {
 const {collapse_open} = props
 const [open,setOpen] = useState(collapse_open)
 return(
  <Collapse in={open}>
                <Alert
                  variant="filled"
                  severity={'success'}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      data-test-id="txtInputMessagePassword"
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {"Success"}
                </Alert>
              </Collapse>

    )
}