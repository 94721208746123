import React, { useRef } from "react";
import { Select } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";


const DownArrow = require("../src/view_arrow.png");
const UpArrow = require("../src/view_arrow_up.png");


const useStyles = makeStyles(() =>
  createStyles({
    select: {
      "& input": {
        top: "0",
        bottom: "0",
        border: "none",
        opacity: "1",
        padding: "15px",
        paddingTop: "19px !important",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "21px",
        lineHeight: "32px",
        width: "80%",
      },
      "& input::placeholder": {
        color: "rgb(179 179 179) !important",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0,0,0,0.4)",
      },
      "@media(max-width:576px)": {
        "& input": {
          width: "90%",
        },
      },
      "& .MuiSelect-root": {
        borderRadius: "8px",
        "&:hover fieldset": {
          borderColor: "#5370C2",
          borderRadius: "8px",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid",
          borderColor: "#5370C2 ",
          borderRadius: "8px",
        },
      },
    },
    icon: {
      marginRight: "15px",
      marginLeft: "15px", 
    },
    customArrow: {
      cursor: "pointer",
    },
  })
);



const CustomSelect = (props: any) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  React.useEffect(() => {
    if (menuOpen && selectRef.current) {
      selectRef.current.focus();
    }
  }, [menuOpen]);


  return (
    <Select
      className={classes.select}
      open={menuOpen}
      onClose={handleMenuClose}
      IconComponent={() => (
        <img
          src={menuOpen ? UpArrow : DownArrow}
          alt="arrow"
          className={`${classes.icon} ${classes.customArrow}`}
          onClick={handleToggleMenu}
        />
      )}
      renderValue={(selected) => (
        <div>
          <img
            src={DownArrow}
            alt="arrow"
            className={`${classes.icon} ${classes.customArrow}`}
            onClick={handleToggleMenu}
          />
          {selected}
        </div>
      )}
      ref={selectRef}
      {...props}
    >
      {/* Menu items... */}
    </Select>
  );
};

export default CustomSelect;
