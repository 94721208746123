import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  company: any[];
  executive: any[];
  userRole: string;
  first_name: string;
  profile_image: string;
  getNotifi_data: {}[];
  unReadNotificationCount: number;
  companyName: string;
  viewApplication: boolean;
  applicantQuestions: {}[];
  executiveDetails: {}[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiGetDatagetNotification1: string = "";
  apiGetUnreadNotificationCount: string = "";
  applicationDetailsCase: string = "";
  apiGetProfileData: string = "";
  readSingleNotificationCase: string = "";
  apiGetMenudata: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      getNotifi_data: [],
      unReadNotificationCount: 0,
      webDrawer: false,
      token: "",
      drawerItems: [
        { id: 0, name: "About", url: "/About" },
        { id: 1, name: "Client", url: "/Client" },
        { id: 2, name: "Contact Us", url: "/ContactUs" },
        { id: 3, name: "Login", url: "/EmailAccountLoginBlock" },
      ],
      company: [],
      executive: [],
      userRole: "",
      first_name: "",
      profile_image: "",
      companyName: "",
      viewApplication: false,
      executiveDetails: [],
      applicantQuestions: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (responseJson) {
      switch (apiRequestCallId) {
        case this.apiGetMenudata:
          this.nev_menu_data(responseJson);
          break;

        case this.apiGetDataCallId:
          this.setState({
            first_name: responseJson?.data?.attributes?.first_name,
            userRole: responseJson?.data?.attributes?.user_type,
            profile_image: responseJson?.data?.attributes?.profile_image,
            companyName: responseJson?.data?.attributes?.company_name
          });
          break;

        case this.apiGetDatagetNotification1:
          this.setState({ getNotifi_data: responseJson?.data });
          break;

        case this.apiGetUnreadNotificationCount:
          this.setState({ unReadNotificationCount: responseJson?.count });
          break;

        case this.readSingleNotificationCase:
          this.getNotification();
          this.getUnreadNotificationCOunt();
          break;

        case this.applicationDetailsCase:
          this.setState({ applicantQuestions: responseJson });
          break;

        case this.apiGetProfileData:
          this.setState({ executiveDetails: responseJson?.data?.attributes });
          break;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  nev_menu_data(responseJson: any) {
    const isExecutiveMenu = responseJson.some((item: { menu_type: string; }) => item.menu_type === "executive");
    let transformedResponse;
    if (isExecutiveMenu) {
      transformedResponse = responseJson
        .filter((item: { is_active: any; }) => item.is_active)
        .sort((a: { priority: number; }, b: { priority: number; }) => a.priority - b.priority)
        .map((item: { slug: string; title: any; }, index: any) => {
          let url = item.slug;
          if (item.slug === "/Settings5/") {
            url = url + "5";
          }
          return { id: index, name: item.title, url: url };
        });
      this.setState({ executive: transformedResponse });
    } else {
      const transformedResponse = responseJson
        .filter((item: { is_active: any; }) => item.is_active)
        .sort((a: { priority: number; }, b: { priority: number; }) => a.priority - b.priority)
        .map((item: { slug: string; title: any; }, index: any) => {
          let url = item.slug;
          if (localStorage.getItem("teamMember_ReadOnly") === 'true' && item.slug === "/NewJob") {
            return null;
          }
          if (item.slug === "/UserProfileBasicBlockweb/") {
            url = url + localStorage.getItem("user_id");
          }
          return { id: index, name: item.title, url: url };
        })
        .filter(Boolean);

      this.setState({ company: transformedResponse });
    }
  }

  toggleDrawer = (event: any) => {
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;
    window.open(path);
  };

  async componentDidMount() {
    super.componentDidMount();
    this.get_menu_data_fun();
    this.getToken();
    this.getNotification();
    this.getUnreadNotificationCOunt();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getNotification = async () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDatagetNotification1 = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLNotification + '?page=1&per_page=10&only_unread=true'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUnreadNotificationCOunt = async () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUnreadNotificationCount = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_notifications/notifications/unread_count"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  readSingleNotification = async (id: number) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.readSingleNotificationCase = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onViewPopUp = (data: any) => {
    this.getProfileData(data.from)
    this.applicationDetails(data.job_application_id)
    this.setState({ viewApplication: true })
  }


  applicationDetails = async (id: any) => {

    const token = localStorage.getItem("authToken") as string;
    const header = { "Content-Type": configJSON.jsonApiContentType, token: token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.applicationDetailsCase = requestMessage.messageId

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage)
      , `bx_block_joblisting2/jobs/application_details/${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage)
      , configJSON.getApiMethodType);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage)
      , JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProfileData = async (id: any) => {

    const token = localStorage.getItem("authToken") as string;
    const user_id = id ? id : localStorage.getItem("user_id") as string;

    const header = {
      "Content-Type": configJSON.jsonApiContentType, token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileData = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_profile_bio/profile_bios' + '/' + user_id);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  get_menu_data_fun = async () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetMenudata = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAPIURLMenuData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
