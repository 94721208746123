import React from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Cross, Plus } from "../../blocks/JobListing2/src/assets";
import { deleteIcon } from "../../blocks/user-profile-basic/src/assets";
import "../../blocks/JobListing2/src/job.css"



export const QuestionsCard = (props: any) => {
  const { data, addNewOptionBox, editOption, key_i, addBox, manageOption, updateQuestion, deleteBox } = props
  return (
    <>
      <Box style={{ width: '100%', padding: "18px" }} className="tab_details_box" >
        <Box>
          <Grid xs={12}>
            <FormControl style={{ width: '100%' }}>
              <input className="tab_details_box_des" value={data.description} onChange={(e) => { updateQuestion(e) }} placeholder="Please enter question here" style={{ appearance: 'none', padding: '5px', border: 0, borderBottom: '1px solid #536fc2', outline: 'none', marginBottom: '10px', width: '90%' }} />
              <img src={Plus} onClick={() => { addBox() }} style={{ cursor: 'pointer', height: '20px', position: 'absolute', right: '0px' }} />
              <Box >
                {data.options && data.options.map((option: any, index: number) => {
                  return (<Box style={{
                    display: 'flex',
                    gap: '10px',
                    margin: '10px 0'
                  }}>
                    <FormControlLabel value="disabled" disabled control={<Radio />} label="" style={{marginRight: '-5px',height:'30px'}} />
                    <input style={{height:'20px'}} value={option.description} type="text" onChange={(e) => { editOption(e, index) }} />  <img onClick={() => { manageOption(index) }} style={{ cursor: 'pointer', height: '17px', marginTop: '2px' }} src={Cross} /><br />
                  </Box>
                  )
                })}
                <Typography onClick={() => addNewOptionBox()} >add option or <span style={{ color: "#536fc2", cursor: 'pointer' }}>add new</span></Typography>
              </Box>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            {key_i !== 0 ? (
              <Box className="question_footer" onClick={() => { deleteBox() }} >
                <img src={deleteIcon} alt="" style={{ cursor: 'pointer' }} />
              </Box>
            ) : <Box className="question_footer_disable" style={{ color: 'gray' }}>
              <img src={deleteIcon} alt="" style={{ cursor: 'pointer' }} />
            </Box>}
          </Grid>
        </Box>
      </Box>
    </> 
  )
}