import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import React, { ChangeEvent, FormEvent } from "react";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    token: any;
    userRole: string;
    number: number;
    fields: string[];
    quetion_id: number[];
    dropdowns: string[];
    designation: string;
    selectType: string[];
    numOption: string[];
    option_id: number[][];
    numTextFields: number[][];
    textFields: string[][];
    successMsg: string;
    CollapseOpen_customform: boolean;
    customform_response: string;
    customform_res_message: string;
    designation_data: {}[];
    designation_customform_data: {}[];
    custom_form_id: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CustomformController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId_customFome: string = "";
    apiGetDataCallId_customForm_update: string = "";
    formRef: any;
    api_get_designation_data: string = "";
    api_get_designation_customform: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: "",
            userRole: "",
            number: 1,
            fields: [''],
            quetion_id: [],
            dropdowns: [''],
            designation: "0",
            selectType: [''],
            numOption: [''],
            option_id: [],
            numTextFields: [[]],
            textFields: [[]],
            successMsg: "",
            CollapseOpen_customform: false,
            customform_response: "",
            customform_res_message: "",
            designation_data: [],
            designation_customform_data: [],
            custom_form_id: 0,
        };

        this.formRef = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start   
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch (apiRequestCallId) {
            case this.apiGetDataCallId_customFome:
            case this.apiGetDataCallId_customForm_update:
                this.get_designation_customform();
                this.show_success_msg("CustomForm Updated Successfully", "CustomForm Not Updated", responseJson)
                break;


            case this.api_get_designation_data:
                this.setState({ designation_data: responseJson.data.slice().reverse() })
                break;

            case this.api_get_designation_customform:
                this.setState({ designation_customform_data: responseJson.data })
                if (responseJson.data === null) {
                    this.setState({ number: 1 });
                    this.setState({ custom_form_id: 0 });
                    this.setState({
                        fields: Array(1).fill(''),
                        dropdowns: Array(1).fill(''),
                        selectType: Array(1).fill(''),
                        numOption: Array(1).fill(''),
                        numTextFields: Array(1).fill([]),
                        textFields: Array(1).fill([]),
                    });
                } else {
                    const numberOfQuestions = responseJson.data.attributes.number_of_questions;
                    this.setState({ number: numberOfQuestions });
                    this.setState({ custom_form_id: responseJson.data.attributes.id });

                    const fields = Array(numberOfQuestions).fill('');
                    const quetion_id = Array(numberOfQuestions).fill(0);
                    const dropdowns = Array(numberOfQuestions).fill('');
                    const selectType = Array(numberOfQuestions).fill('');
                    const numOption = Array(numberOfQuestions).fill('');
                    const numTextFields = Array(numberOfQuestions).fill([]);
                    const textFields = Array(numberOfQuestions).fill([]);
                    const option_id = Array(numberOfQuestions).fill(0);
                    responseJson.data.attributes.questions.data.forEach((question: any, index: number) => {
                        fields[index] = question.attributes.description || '';
                        quetion_id[index] = question.attributes.id || 0;
                        dropdowns[index] = question.attributes.question_type || '';
                        selectType[index] = question.attributes.selection_type || '';
                        numOption[index] = question.attributes.number_of_options || '';

                        const options = question.attributes.options || [];
                        numTextFields[index] = options.map((option: any) => option.description) || [];
                        textFields[index] = options.map((option: any) => option.description) || [];
                        option_id[index] = options.map((option: any) => option.id || 0) || 0;
                    });
                    this.setState({
                        fields,
                        quetion_id,
                        dropdowns,
                        selectType,
                        numOption,
                        numTextFields,
                        textFields,
                        option_id
                    });
                }
                break;
        }



        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.get_designation_data();
    }

    componentDidUpdate(prevProps: Props, prevState: S) {
        if (prevState.designation !== this.state.designation) {
            this.get_designation_customform();
        }
    }

    show_success_msg(successMessage: string, errorMessage: string, responseJson: any) {
        if (responseJson.data) {
            this.setState({ customform_response: successMessage });
            this.setState({ customform_res_message: configJSON.res_message_success });
        } else {
            this.setState({ customform_response: errorMessage });
            this.setState({ customform_res_message: configJSON.res_message_error });
        }

        this.setState({ CollapseOpen_customform: true });
        setTimeout(() => {
            this.setState({ CollapseOpen_customform: false });
        }, 3000);
    }

    CollapseOpenClose_fun = () => {
        this.setState({ CollapseOpen_customform: false })
    }

    handleCancel_CustomeForm_Click = () => {
        if (this.formRef.current) {
            this.formRef.current.reset();
        }
        this.setState({
            designation: '',
            number: 1,
            fields: [''],
            dropdowns: [''],
            selectType: [''],
            numOption: [''],
            numTextFields: [[]],
            textFields: [[]],
            successMsg: "",
            CollapseOpen_customform: false,
            customform_response: "",
            customform_res_message: "",
        });
    };

    handleDesignationChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedDesignation = e.target.value;
        this.setState({
            designation: selectedDesignation,
        });
    };

    handleNumberChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedNumber = parseInt(e.target.value, 10);

        const fields = Array(selectedNumber).fill('');
        const dropdowns = Array(selectedNumber).fill('');
        const selectType = Array(selectedNumber).fill('');
        const numOption = Array(selectedNumber).fill('');
        const numTextFields = Array(selectedNumber).fill([]);
        const textFields = Array(selectedNumber).fill([]);
        const option_id = Array(selectedNumber).fill([]);


        const designationCustomFormData: any = this.state.designation_customform_data;

        if (designationCustomFormData && designationCustomFormData.attributes && designationCustomFormData.attributes.questions) {
            const questionsData = designationCustomFormData.attributes.questions.data || [];

            questionsData.slice(0, selectedNumber).forEach((question: any, innerIndex: number) => {
                fields[innerIndex] = question.attributes.description || "";
                dropdowns[innerIndex] = question.attributes.question_type || "";
                selectType[innerIndex] = question.attributes.selection_type || "";
                numOption[innerIndex] = question.attributes.number_of_options || "";

                const options = question.attributes.options || [];
                numTextFields[innerIndex] = options.map((option: any) => option.description) || "";
                textFields[innerIndex] = options.map((option: any) => option.description) || "";
                option_id[innerIndex] = options.map((option: any) => option.id) || "";



            });
        }

        this.setState({
            number: selectedNumber,
            fields,
            dropdowns,
            selectType,
            numOption,
            numTextFields,
            textFields,
        });
    };

    handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatedFields = [...prevState.fields];
            updatedFields[index] = value;
            return { fields: updatedFields };
        });
    };

    handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatedDropdowns = [...prevState.dropdowns];
            updatedDropdowns[index] = value;
            return { dropdowns: updatedDropdowns };
        });
    };


    handleSelectTypeChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatedSelectTypeChange = [...prevState.selectType];
            updatedSelectTypeChange[index] = value;
            return { selectType: updatedSelectTypeChange };
        });
    };




    handleNumOptionChange(event: ChangeEvent<HTMLSelectElement>, index: number) {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatednumOption = [...(prevState.numOption || [])];
            updatednumOption[index] = value;
            return { numOption: updatednumOption };
        });
        const numTextFields = Array.from({ length: parseInt(value) }, (_, i) => i);

        this.setState(prevState => {
            const updatedNumTextFields = [...(prevState.numTextFields || [])];
            updatedNumTextFields[index] = numTextFields;
            return { numTextFields: updatedNumTextFields };
        });
    }

    handleTextFieldChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number, subIndex: number) {
        const { value } = event.target;
        this.setState(prevState => {
            const updatedTextFields = [...prevState.textFields];
            updatedTextFields[index] = [...updatedTextFields[index]];
            updatedTextFields[index][subIndex] = value;
            return { textFields: updatedTextFields };
        });
    }



    handleSubmit_update = (event: FormEvent) => {
        event.preventDefault();

        const token = localStorage.getItem("authToken") as string;
        const { custom_form_id, quetion_id, option_id, designation, number, fields, dropdowns, selectType, numOption, numTextFields, textFields } = this.state;

        const questions = fields.map((field, index) => {
            const questionType = dropdowns[index];
            let question: any = {}
            if (quetion_id[index] !== null && quetion_id[index] !== undefined) {
                question = {
                    id: quetion_id[index],
                    question_type: questionType,
                    description: field,
                };
            } else {
                question = {
                    question_type: questionType,
                    description: field,
                };
            }

            if (questionType === "Multiple Choice" || questionType === "Dropdown") {

                question.selection_type = selectType[index];
                question.number_of_options = parseInt(numOption[index]);

                const options = (numTextFields[index] || []).map((_, subIndex) => {
                    const optionId = option_id[index]?.[subIndex];
                    return optionId !== null && optionId !== undefined
                        ? { id: optionId, description: textFields[index][subIndex] || '' }
                        : { description: textFields[index][subIndex] || '' };
                });
                if (options.length > 0) {
                    question.options = options;
                }
            }
            return question;

        });

        const formData = {
            custom_form: {
                id: custom_form_id,
                designation_id: designation,
                number_of_questions: number.toString(),
                questions: questions,
            },
        };

        this.sendFormData_update(formData, token, custom_form_id);
    };

    sendFormData_update = (formData: any, token: string, custom_form_id: number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const httpBody = formData;

        const requestMessage_update = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetDataCallId_customForm_update = requestMessage_update.messageId
        requestMessage_update.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveCustomFormApiEndpoint + '/' + custom_form_id
        );

        requestMessage_update.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage_update.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage_update.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sellerDetailsAPIMethodPUT
        );

        runEngine.sendMessage(requestMessage_update.id, requestMessage_update);
        return true;

    }


    handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const token = localStorage.getItem("authToken") as string;

        const { designation, number, fields, dropdowns, selectType, numOption, numTextFields, textFields } = this.state;

        const questions = fields.map((field, index) => {
            const questionType = dropdowns[index];
            const question: any = {
                question_type: questionType,
                description: field,
            };
            if (questionType === "Multiple Choice" || questionType === "Dropdown") {
                question.selection_type = selectType[index];
                question.number_of_options = parseInt(numOption[index]);

                const options = numTextFields[index].map((_, subIndex) => ({
                    description: textFields[index][subIndex] || '',
                }));

                question.options = options;
            }
            return question;
        });
        if (designation && number && questions.length > 0) {
            const formData = {
                custom_form: {
                    designation_id: designation,
                    number_of_questions: number.toString(), // Convert number to string
                    questions: questions,
                },
            };
            this.sendFormData(formData, token);
        } else {
            const formData = {};
            this.sendFormData(formData, token);
        }
        return true;
    };


    sendFormData = (formData: any, token: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const httpBody = formData;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetDataCallId_customFome = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveCustomFormApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }


    get_designation_data = async () => {

        const token = localStorage.getItem("authToken") as string;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const requestMessage_designation_data_endpoint = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.api_get_designation_data = requestMessage_designation_data_endpoint.messageId

        requestMessage_designation_data_endpoint.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.api_get_designation_data_endpoint
        );

        requestMessage_designation_data_endpoint.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage_designation_data_endpoint.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage_designation_data_endpoint.id, requestMessage_designation_data_endpoint);
        return true;
    };


    get_designation_customform = async () => {

        const token = localStorage.getItem("authToken") as string;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const requestMessage_api_designation_customform = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.api_get_designation_customform = requestMessage_api_designation_customform.messageId

        requestMessage_api_designation_customform.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.api_api_get_designation_customform_endpoint + '?designation_id=' + this.state.designation
        );

        requestMessage_api_designation_customform.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage_api_designation_customform.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage_api_designation_customform.id, requestMessage_api_designation_customform);
        return true;
    };



}
