// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import SubscriptionBilling2 from '../../blocks/SubscriptionBilling2/src/SubscriptionBilling2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import CfReminderNudge from '../../blocks/CfReminderNudge/src/CfReminderNudge';
import JobListing2 from '../../blocks/JobListing2/src/JobListing2';
import JobsApplied from '../../blocks/JobListing2/src/JobsApplied.web';
import JobsAppliedExecutive from '../../blocks/JobListing2/src/JobsAppliedExecutive.web';
import JobDetails from '../../blocks/JobListing2/src/JobDetails.web';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import BlogPost from '../../blocks/postcreation/src/BlogPost.web';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import VerticalTabs from '../../blocks/Settings5/src/Settings5.web';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import MatchAlgorithm2 from '../../blocks/MatchAlgorithm2/src/MatchAlgorithm2';
import CfResponsiveWebDesignAndDevEfforts from '../../blocks/CfResponsiveWebDesignAndDevEfforts/src/CfResponsiveWebDesignAndDevEfforts';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import UserStatus from '../../blocks/userstatus/src/UserStatus';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import Share from '../../blocks/share/src/Share';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import ContactsList from '../../blocks/ContactsList/src/ContactsList';
import VerticalTabs2 from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Contactus from '../../blocks/contactus/src/ContactusWeb.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import LandingPage from '../../blocks/landingpage/src/LandingPageWeb.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/CommonLoginWeb.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/CommonSignup.web';
import AlertBlock from '../../blocks/alert/src/AlertBlockWeb.web';
import WorkExperience from '../../blocks/postcreation/src/WorkExperience.web';
import Education from '../../blocks/postcreation/src/Education.web';
import BoardBiography from '../../blocks/postcreation/src/BoardBiography.web' ;
import AccountCreation from '../../blocks/email-account-registration/src/AccountCreation.web';
import NavigationMenunew from '../../blocks/navigationmenu/src/NavigationMenunew.web';
import UserProfileBasicBlockweb from '../../blocks/user-profile-basic/src/UserProfileBasicBlockweb.web';
import Dashboardweb from '../../blocks/dashboard/src/Dashboard.web';
import ContactusPage from '../../blocks/contactus/src/ContactusPage.web';
import Customformweb from '../../blocks/customform/src/Customform.web';
import ForgotPasswordweb from '../../blocks/forgot-password/src/ForgotPasswordweb.web';
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import NewJob from '../../blocks/JobListing2/src/NewJob.web';
import ApplicantProfile from '../../blocks/JobListing2/src/ApplicationsProfile.web';
import SuccessFull from "../../blocks/SubscriptionBilling2/src/Successfull.web"
import FailSubscription from "../../blocks/SubscriptionBilling2/src/FailSubscription.web"
const routeMap = {
  UserProfileBasicBlockweb: {
    component: UserProfileBasicBlockweb,
    path: '/UserProfileBasicBlockweb/:id'
  },
  ForgotPasswordweb: {
    component: ForgotPasswordweb,
    path: '/ForgotPasswordweb'
  },
  SuccessFul: {
    component: SuccessFull,
    path: '/SuccessfullSubscription'
  },
  FailSubscription: {
    component: FailSubscription,
    path: '/FailSubscription'
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/reset-password'
  },
  Dashboardweb: {
    component: Dashboardweb,
    path: '/Dashboardweb'
  },
  JobDetails: {
    component: JobDetails,
    path: '/JobDetails/:id'
  },
  ApplicantProfile: {
    component: ApplicantProfile,
    path: '/ApplicantProfile'
  },
  NewJob: {
    component: NewJob,
    path: '/NewJob'
  },
  
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration/:id'
  },
  SubscriptionBilling2: {
    component: SubscriptionBilling2,
    path: '/SubscriptionBilling2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Customform: {
    component: Customformweb,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenunew,
    path: '/NavigationMenunew'
  },
  CfReminderNudge: {
    component: CfReminderNudge,
    path: '/CfReminderNudge'
  },
  JobListing2: {
    component: JobListing2,
    path: '/Jobs'
  },
  jobApplied:{
    component:JobsApplied,
    path:"/JobsApplied"
  },
  applicants:{
    component:JobsApplied,
    path:"/Applicants"
  },
  JobsAppliedExecutive:{
    component:JobsAppliedExecutive,
    path:`/JobsAppliedExecutive/:id`
  },
  RecentJob:{
    component:JobListing2,
    path:"/JobListing"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  BlogPost: {
    component: BlogPost,
    path: '/BlogPost'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  Settings5: {
    component: VerticalTabs,
    path: '/Settings5/:id'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  MatchAlgorithm2: {
    component: MatchAlgorithm2,
    path: '/MatchAlgorithm2'
  },
  CfResponsiveWebDesignAndDevEfforts: {
    component: CfResponsiveWebDesignAndDevEfforts,
    path: '/CfResponsiveWebDesignAndDevEfforts'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  UserStatus: {
    component: UserStatus,
    path: '/UserStatus'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  ContactsList: {
    component: ContactsList,
    path: '/ContactsList'
  },
  TermsAndConditions: {
    component: VerticalTabs2,
    path: '/TermsAndConditions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },

  Contactus: {
    component: ContactusPage,
    path: '/Contactus'
  },
  ContactusPage: {
    component: Contactus,
    path: '/ContactusPage'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  WorkExperience: {
    component: WorkExperience,
    path: '/WorkExperience'
  },

  Education: {
    component: Education,
    path: '/Education'
  },
  BoardBiography: {
    component: BoardBiography,
    path: '/BoardBiography'
  },
  AccountCreation: {
    component: AccountCreation,
    path: '/AccountCreation'
  },
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
