import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {  styled } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import { CustomAccTextField } from "./CustomStyle.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getIn } from "formik";

const ExpandMoreIconStyle = styled(DatePicker)({
 "& .MuiOutlinedInput-adornedEnd":{
  paddingRight:"5px"
 }
})

function CustomDatePicker({
  name,
  value,
  onChange,
  views,
  minDate,
  maxDate,
}: {
  name: string;
  value: any;
  onChange(e: any): void;
  views: any[];
  minDate?: any;
  maxDate?: any;

}) {
  const renderInput = (params: any) => (
    <CustomAccTextField
      variant="outlined"
      {...params}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            <ExpandMoreIcon  />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ExpandMoreIconStyle
        inputVariant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        TextFieldComponent={renderInput}
        views={views}
        minDate={minDate}
        maxDate={maxDate}
        fullWidth
        
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomDatePicker;
