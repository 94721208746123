import React, { Component } from "react";
import {successSubsription} from "./assets"
export default class Successfull extends Component{
    handleSuccess(){
        alert("Payment successful. Thank you!")
    }
   
    render(){
        return <>
       <img src={successSubsription} style={{margin:"auto",maxWidth:"300px"}}/>
       {this.handleSuccess()}
        </>
    }
}