import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  JobsAppliedId: number;
  jobsDetailsData: any;
  relatedJobs: any;
  question_type: boolean;
  isDialogOpen: boolean;
  qusetions_data: any;
  questionIndex: number;
  answers: any;
  message: string;
  jobsApplied: boolean
  messageshow: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobsAppliedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  JobsAppliedApiExecutiveId: string = "";
  getDetailsApiId: string = "";
  getRelatedJobsDetailsApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      jobsDetailsData: {},
      JobsAppliedId: 0,
      relatedJobs: {},
      question_type: false,
      isDialogOpen: false,
      qusetions_data: [],
      questionIndex: 0,
      answers: [],
      message: "",
      jobsApplied: false,
      messageshow: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getDetailsApiId) {
      this.setState({ jobsDetailsData: responseJson })
      this.setState({ jobsApplied: responseJson?.data?.attributes?.job_application?.is_applied })
      if (this.state.jobsDetailsData?.data?.attributes?.questions?.data.length > 0) {
        this.setState({ question_type: true })
        this.setState({ qusetions_data: this.state.jobsDetailsData.data.attributes.questions.data })
      }
    }
    else if (apiRequestCallId === this.getRelatedJobsDetailsApiId) {
      this.setState({ relatedJobs: responseJson })
    }
    else if (apiRequestCallId === this.JobsAppliedApiExecutiveId) {
      if (responseJson?.message === "Please answer all the questions!") {
        this.setState({ questionIndex: 0 })
        this.setState({ message: responseJson?.message })
        this.setState({ messageshow: true })
      }
      else if (responseJson?.message === "Applied successfully.") {
        this.setState({ message:`Congrats! You have been applied to "${this.state.jobsDetailsData?.data?.attributes?.job_title}"` })
        this.setState({jobsApplied:true})
        this.setState({ messageshow: true })
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  handleMessageAlert = () => {
    this.setState({ messageshow: false })
  }
  handleAnswerChange = (event: any) => {
    const { value, type } = event.target;
    const currentQuestion = this.state.qusetions_data[this.state.questionIndex];
    const questionId = currentQuestion?.attributes?.id;
    let updatedAnswers = [...this.state.answers];

    const answerIndex = updatedAnswers.findIndex((answer) => answer.question_id === questionId);

    if (type === "checkbox") {
      if (answerIndex !== -1) {
        updatedAnswers[answerIndex].options.push({ option_id: value });
      } else {
        updatedAnswers.push({ question_id: questionId, options: [{ option_id: value }] });
      }
    } else if (type === "radio") {
      if (answerIndex !== -1) {
        updatedAnswers[answerIndex].option_id = value;
      } else {
        updatedAnswers.push({ question_id: questionId, option_id: value });
      }
    } else {
      if (answerIndex !== -1) {
        updatedAnswers[answerIndex].answer = value;
      } else {
        updatedAnswers.push({ question_id: questionId, answer: value });
      }
    }

    this.setState({ answers: updatedAnswers });
  };
  navigateToDetail(id: any) {
    if (this.state.JobsAppliedId !== id) {
      const updatedPathname = window.location.pathname.replace(/\/\d+$/, `/${id}`);
      window.history.pushState({}, '', updatedPathname);
      this.setState({ JobsAppliedId: Number(id) })
      this.getRelatedJobsApplied(id)
      this.getDeatilsJobsApplied(id)
      this.setState({ questionIndex: 0 })
      this.setState({ message: "" })
      this.setState({ answers: [] })
      window.scrollTo(0,0)
    }
  }


  handleContinue = () => {
    if (this.state.questionIndex < this.state.qusetions_data.length - 1) {
      this.setState((prevState) => ({
        questionIndex: prevState.questionIndex + 1,
      }));
    } else {
      this.setState({ isDialogOpen: false });
      this.getJobsAppliedExecutiveData(this.state.JobsAppliedId)
    }
  };
  openDialog = () => {
    if (this.state.qusetions_data.length > 0) {

      this.setState({ isDialogOpen: true });
    }
    else {
      this.getJobsAppliedExecutiveData(this.state.JobsAppliedId)
    }
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  async componentDidMount() {
    super.componentDidMount();
    const pathname = window.location.pathname;
    const id = pathname.split('/').pop();
    this.getRelatedJobsApplied(id)
    this.getDeatilsJobsApplied(id)
  }

  handledateFunction = (date: any) => {
    const datenew = new Date(date);
    const daynew = datenew.getDate();
    const monthnew = datenew.toLocaleString('en-US', { month: 'long' });
    const yearnew = datenew.getFullYear();

    const dayWithSuffix = (day: any) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${dayWithSuffix(daynew)} ${monthnew} ${yearnew}`;
  };

  getJobsAppliedExecutiveData = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const body = {
      "job": {
        questions: this.state.answers
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.JobsAppliedApiExecutiveId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting2/jobs/${id}/apply`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  getDeatilsJobsApplied = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailsApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting2/jobs/${id}/details`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getRelatedJobsApplied = async (id: any) => {
    this.setState({ JobsAppliedId: Number(id) })

    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRelatedJobsDetailsApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting2/jobs/${id}/related_jobs`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCurrentQuestion(id: any, currentId: any) {
    return id === currentId ? "70px" : "50px"
  }
  handleCurrentIndex(questionIndex: any, total_count: any) {
    return questionIndex === total_count - 1 ? "1px solid #58aa41" : "1px solid #536fc2"
  }

  handleButtonName() {
    return this.state.questionIndex === this.state.qusetions_data.length - 1 ? "Done!" : "Continue"
  }
  handleStyle() {
    return  this.state.jobsApplied ? "#58aa41" : "#536fc2"
  }
  handleApplyButtonName() {
    return  this.state.jobsApplied ? "Applied" : "Apply";
  }
  // Customizable Area End
}
