import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  MobileStepper,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./landingpage.css";
import Divider from '@material-ui/core/Divider';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  boardsiIntroImgM,
  boardsiLogoM,
  imgshape1,
  imgshape2,
  imgshape3,
  aboutimg,
  circle
} from "../../blocks/landingpage/src/assets";
import { Link } from "react-router-dom";
import zIndex from "@material-ui/core/styles/zIndex";




const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const color = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
      contrastText: "#fff",
    },
  },
});
const tutorialSteps = [
  {
    id: 1,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
    path: "https://boardsi.com/wp-content/uploads/2021/03/clipart1503317.png",
  },
  {
    id: 2,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/58428cc1a6515b1e0ad75ab1-2.png",
  },
  {
    id: 3,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
    path: "https://boardsi.com/wp-content/uploads/2021/03/clipart1503317.png",
  },

  {
    id: 4,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/MT_Bank_logo_logotype.png",
  },
  {
    id: 5,
    label: "Avatar Healthcare INC",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    path:
      "https://boardsi.com/wp-content/uploads/2021/03/58428cc1a6515b1e0ad75ab1-2.png",
  },
];

const useStyles = makeStyles((theme) => ({
  boardsiintroimg: {
    width: "100%",
    height: "610px",
    marginTop: "130px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      objectFit: "contain"
    }
  },
  root: {
    flexGrow: 1,
    marginTop: "80px",
  },
  img: {
    height: 255,
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    marginLeft: '200px'

  },
  text: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
    fontFamily: 'Poppins',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px"
    }
  },
  paratext: {
    fontFamily: 'Poppins',
  },
  textpara2: {
    fontFamily: 'Poppins',
    fontWeight: 100,
    fontSize: '17px',
    lineHeight: '22px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  headding: {
    fontSize: "50px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: '26px',
      marginBottom: '10px'
    }
  },
  subHeadding: {
    fontSize: "25px",
    fontWeight: 600,
  },
  about: {
    display: "flex",
    alignItems: "center",
  },
  circle: {
    width: "300px",
    height: "250px",
    borderRadius: "100%",
    border: "50px solid #536fc2",
    position: "absolute",
    left: "65px",
    marginTop: "50px",
  },
  mainContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "20px 100px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0
    }
  },
  aboutBox: {
    padding: "60px",
    paddingRight: "0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
      marginTop: "15%"
    }
  },
  btn: {
    letterSpacing: "1.5px",
    background: "linear-gradient(-40deg, #8199e1, #536fc2 100%) no-repeat",
    textTransform: "capitalize",
    marginTop: "10px",
  },
  mainBox: {
    // margin: "0 100px",
  },
  mainimg: {
    // textAlign: "center",
  },
  imglogo: {
    width: '500px',
    height: '100px',
    marginLeft: '200px',
    [theme.breakpoints.down("sm")]: {
      marginLeft: '20px',
      width: '150px',
      height: 'auto',
      marginBottom: "10px"
    }
  },
  bordsipara: {
    width: "500px",
    marginLeft: '200px',
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      width: "100%",
      padding: "0 14px"
    }
  },
  // mainContainer: {},
  container: {
    width: '100%',
    height: '250px',
    marginTop: "-505px",
    marginLeft: '-88px'
  },
  content: {
    margin: "0 30px",
    textAlign: "center",
  },
  clientImg: {
    width: "100px",
    height: "50px",
  },
  clientContent: { width: "100%", display: "flex" },
  imgswipe: {
    height: "380px",
    display: "block",
    overflow: "hidden",
    width: "100%",
    marginTop: '0px',
    marginBottom: '20px',
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      marginTop: "34%"
    }
  },
  slidertabs: {
    marginTop: '38px',
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '100px',
    zIndex: 2,
    [theme.breakpoints.down("xs")]: {
      width: '80px',
      marginTop: '95px',
      marginLeft: "90px"
    },
  },
}));

const AboutUsWeb = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
  };

  return (
    <ThemeProvider theme={color}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box className={classes.mainBox}>
            <div className={classes.mainimg}>
              <img src={boardsiIntroImgM} className="boardsi_intro_img" />
            </div>
            <div style={{ marginTop: isMatch ? "0" : '-80px' }}>
              <img src={boardsiLogoM} className='img_logo' />

              <Box className="boardsi_para">
                <Typography className="text_area">
                  “The Corporate Matchmaker”
                </Typography>
                <Typography style={{
                  fontFamily: 'Poppins',
                }}>
                  Boardsi is a cutting-edge executive recruiting firm that connects
                  executives with advisory positions and companies with exceptional
                  talent. Our unique combination of advanced technology, personalized
                  connections, and exclusive network helps to transform businesses and
                  advance careers.
                </Typography>
                <Button
                  style={{
                    letterSpacing: "1.5px",
                    background: "linear-gradient(-40deg, #8199e1, #536fc2 100%) no-repeat",
                    textTransform: "none",
                    marginTop: "20px",
                    zIndex: 1
                  }}
                  variant="contained"
                  component={Link}
                  color="primary"
                  to="/EmailAccountRegistration"
                >
                  Get Started for free!
                </Button>
              </Box>
            </div>
          </Box>
          <Divider style={{ height: '5px', marginTop: '30px' }} />
        </Grid>
      </Grid>
      <Grid
        container
        className="main_Container"
        id="AboutUsWeb"
      >
        <Grid item xs={12} md={5} sm={6}>
          <div className="back_img_icon_circle">
            <img className="back_icon_img" src={circle} style={{ width: 'auto', height: isMatch ? '300px' : '500px', marginLeft: isMatch ? "13%" : "0", marginTop: isMatch ? "2%" : "0" }} />
            <div style={{
              width: '118%',
              height: '250px',
              marginTop: "-505px",
              marginLeft: '-84px'
            }}>
              <div className="web-auto-slider">
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {tutorialSteps.map((step, index) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          <div className="user_bx_img">
                            <img
                              className="img_swipe"
                              src={aboutimg}
                              alt={step.label}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
              </div>
            </div>
          </div>
          <div className="about_slide">
            <MobileStepper
              className="slider_tabs"
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={<Button size="small" hidden />}
              backButton={<Button size="small" hidden />}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7} sm={6} style={{
          display: "flex",
          alignItems: "center"
        }}>
          <Box className="about_box">
            <Typography variant="h4" className="Heading_main">
              About {!isMatch && <br></br>}Boardsi
            </Typography>
            <Typography className="text_para_2">
              At Boardsi, our driving force is our desire to revolutionize the traditional
              recruiting landscape and support the growth of businesses and executives.
              Established in 2016, our primary objective has been to serve as a comprehensive
              solution for organizations of all sizes and industries. Whether companies are
              searching for investment opportunities or seeking to assemble a board of directors
              or advisors, our goal is to help them succeed. We strive to break away from conventional
              business networking methods and pioneer new and effective approaches that simplify the
              relationship between businesses and executives.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}  sm={12}>
          <div className="bnr_fotr_img">
            <img src={imgshape1} style={{ width: isMatch ? '130px' : '230px', height: isMatch ? '30px' : '50px', marginRight: '40px' }} />
            <img src={imgshape2} style={{ width: isMatch ? '50px' : '130px', height: isMatch ? '30px' : '50px' }} />
            <img src={imgshape3} style={{ width: isMatch ? '150px' : '250px', height: isMatch ? '30px' : '50px' }} />
          </div>
        </Grid>
      </Grid>

      <Divider style={{ height: '5px', marginTop: '80px' }} />

    </ThemeProvider>
  );
};

export default AboutUsWeb;
