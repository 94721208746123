export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BackgroundImage = require("../assets/image_back.png");
export const SideImage = require("../assets/view_formbackground.png");
export const SideImageCompany = require("../assets/view_formbackgroundCompany.png");
export const BoardsiLogo = require("../assets/view_logo.png");
export const Executive = require("../assets/view_placeholder_Ex.png");
export const Company = require("../assets/view_placeholder.png");
export const BordsiLogoWhite = require("../assets/view_logowhite.png");
export const DownArrow = require("../assets/view_arrow.png");
export const Checkbox = require("../assets/view_rectangle.png");
export const AccBackgroundImage = require("../assets/view_backg.png");
export const AccBoardsiLogo = require("../assets/image_logo.png");
export const AccUserLogo = require("../assets/view_user.png");
export const AccCircle = require("../assets/view_imagebgCircle.png");
export const AccDefault = require("../assets/view_imagebg_default.png");
export const AccShapes = require("../assets/view_shapes.png");
export const AccWhiteCircle = require("../assets/view_imagebg.png");
export const AccCamera = require("../assets/view_image.png");
export const AccEditIcon = require("../assets/view_edit_icon.png");
export const AccInfoBackground = require("../assets/view_acc_rectangle.png");
export const AccSkillsBackground = require("../assets/view_rectangle_skills.png");
export const AccEdit = require("../assets/view_edit.png");
export const AccSearch = require("../assets/view_imagesearch.png");
export const AccHelp = require("../assets/view_help.png");
export const AccCheck = require("../assets/view_check.png");
export const cross = require("../assets/view_cross.png");
export const AccDropdown = require("../assets/image_drop.png");
export const AccSuccess = require("../assets/view_tick.png");
export const add_icon_img = require("../assets/textlabel_plus.png");
export const dark1 = require("../assets/11.png");
export const dark2 = require("../assets/11111.png");
export const Eyeopen = require("../assets/Eyeopen.png");
export const EyeSlash = require("../assets/EyeSlash.png");
export const Shape = require('../assets/shape.png');
