import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  description: string,
  company: any,
  job_title: string,
  location: string,
  executive_have_applied: number
  skills: any,
  experience_level: string,
  qualifications: any,
  employment_type: any,
  job_type: string,
  job_id: string | null,
  jobApplications: any,
  executiveSearch: string;
  isSubmitted: boolean,
  isViewed: boolean,
  isHold: boolean,
  isRejected: boolean,
  status: string,
  jobData: any,
  shareModal: boolean,
  currentPage: number,
  searchVal: string,
  checkVal: string[],
  lastPage: number;
  userType_role: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jobDetailsApiCallID: string = "";
  jobApplicationsApiCallId: string = "";
  apiGetUserTypeDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      description: "",
      company: {},
      job_title: "",
      location: "",
      executive_have_applied: 0,
      skills: [],
      experience_level: "",
      qualifications: {},
      employment_type: {},
      job_type: "",
      job_id: "",
      jobApplications: [],
      executiveSearch: "",
      isSubmitted: false,
      isViewed: false,
      isHold: false,
      isRejected: false,
      status: "",
      jobData: "",
      shareModal: false,
      currentPage: 1,
      searchVal: "",
      checkVal: [],
      lastPage: 0,
      userType_role: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      switch (apiRequestCallId) {
        case this.apiGetUserTypeDataCallId: this.setState({ userType_role: responseJson.data.type });
          break;
        case this.jobDetailsApiCallID: this.setJobListData(responseJson);
          break;
        case this.jobApplicationsApiCallId: this.setJobApplications(responseJson)
      }
    }
    // Customizable Area End
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start




  handleFilterToggle_executive_status = (item: any) => {
    this.setState((prevState: any) => {
      const isSelected = prevState.checkVal.includes(item);
      const updatedFilters = isSelected
        ? prevState.checkVal.filter((filter: any) => filter !== item)
        : [...prevState.checkVal, item];
      return { checkVal: updatedFilters };
    });
  };

  shareModalHandler = () => {
    this.setState({ shareModal: !this.state.shareModal })
  }

  goForward = () => {
    if (this.state.currentPage !== this.state.lastPage) {
      this.setState({ currentPage: this.state.currentPage + 1 })
    }
  }

  goPrevious = () => {
    if (this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 })
    }
  }

  searchHandler = (e: { target: { value: string } }) => {
    this.setState({ executiveSearch: e.target.value })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.checkVal !== this.state.checkVal || prevState.executiveSearch !== this.state.executiveSearch) {
      this.getJobApplications();
    }
  }
  async componentDidMount() {
    this.setState({ job_id: localStorage.getItem("jobID") }, () => {
      this.getUserType_data();
      this.getJobDetails();
      this.getJobApplications();
    })
  }

  setJobApplications = (responseJson: any) => {
      this.setState({ jobApplications: responseJson.data , lastPage: responseJson?.meta?.last_page })
  }

  handleHold = (e: { target: { value: string } }) => {
    this.setState({
      status: e.target.value,
    }, () => {
      this.setState({
        isHold: true,
        isRejected: false,
      })
    })
  }

  handleRejected = (e: { target: { value: string } }) => {
    this.setState({
      status: e.target.value,
    }, () => {
      this.setState({
        isHold: false,
        isRejected: true,
      })
    })
  }



  setJobListData = (responseJson: any) => {
    const { description, company, job_title, location, executive_have_appiled,
      skills, experience_level, qualification, employment_type, job_type } = responseJson?.data?.attributes;
    this.setState({
      description: description,
      executive_have_applied: executive_have_appiled,
      skills: skills,
      location: location,
      experience_level: experience_level,
      job_type: job_type,
      qualifications: qualification,
      company: company,
      job_title: job_title,
      employment_type: employment_type,
      jobData: responseJson.data
    }, () => {
      const jobdata = {
        attributes: {
          job_title: job_title,
          company: {
            company_name: company.company_name,
            profile_image: company.profile_image
          },
          location: location
        }
      }
      this.setState({ jobData: jobdata })
    })
  }

  getJobDetails = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jobListingContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobDetailsApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobListingEndPoint + `/${this.props.navigation.getParam("id")}/details`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getJobApplications = () => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.jobListingContentType,
      token: token,
    };
    let endpointURL = configJSON.getJobApplicationsEndPoint + `/${this.props.navigation.getParam("id")}/executives?page=${this.state.currentPage}&per_page=5&search=${this.state.executiveSearch}`
    if (this.state.checkVal.length > 0) {
      endpointURL = configJSON.getJobApplicationsEndPoint + `/${this.props.navigation.getParam("id")}/executives?page=${this.state.currentPage}&per_page=5&search=${this.state.executiveSearch}&status=${JSON.stringify(this.state.checkVal)}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobApplicationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobApplicationsMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  getUserType_data = async () => {
    const userId = localStorage.getItem("user_id") as string;
    const User_token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: User_token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserTypeDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPointURL + '/' + userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
