import React from "react";
// Customizable Area Start
import "../../../components/src/user_profile_block.css";
import "../../email-account-registration/src/signup.css"
import { image_Building, image_Globe, image_Marker, image_Phone_call, image_Users, image_View_edit, worklogo1, add_icon_img, edit_icon_img, deleteIcon, DefaultBackground, CalenderImg } from "./assets";
import {
  Container,
  Grid,
  Badge,
  Avatar,
  IconButton,
  Card,
  CardContent,
  Typography,
  Dialog,
  Box,
  DialogContent,
  Button,
  Select,
  MenuItem,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import {
  CustomCheckBox,
  CustomAccTextField,
} from "../../../components/src/CustomStyle.web";
import DropDown from "../../../components/src/AccDropdown.web";
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/core/styles";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import * as Yup from "yup";
import { validationHandle } from "../../../components/src/WorkExperienceFile";
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 45,
  height: 45,
  border: `2px solid ${theme.palette.background.paper}`,
}));
import { background_logo, placeholder_logo } from "../../JobListing2/src/assets";

import { Model_company_details_add_fun } from "../../../components/src/CompanyModal";
import {VideoPlayer} from "../../../components/src/CompanyPopUp.web";

// Customizable Area End

import UserProfileBasicController, { Props } from "./UserProfileBasicController.web";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import FooterWeb from "../../../components/src/FooterWeb";
import { Formik, getIn } from "formik";
import Alert from "@material-ui/lab/Alert";
import { month } from "../../../components/src/CompanyAccountCreation";
import { CertificateFunc, EducationFunc, workExperienceFunc } from "../../../components/src/WorkExperienceFile";

export default class UserProfileBasicBlockweb extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.selectRef = React.createRef<HTMLSelectElement>();
    this.profileRef = React.createRef<HTMLInputElement>();
    this.inputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End
  }

  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;
  selectRef: React.RefObject<HTMLSelectElement>
  profileRef: React.RefObject<HTMLInputElement>;


  CompanyvalidationSchema = Yup.object().shape({
    company_size: Yup.string().required(),
    industry: Yup.string().required(),
    full_phone_number: Yup.string().required().min(10, "Invalid Phone no.").max(10, "Invalid Phone no")
  });
  experienceSchema = Yup.object().shape({
    title: Yup.string().required("This field is required.....")
      .test(
        'no-leading-space',
        'Invalid input',
        (value) => {
          if (!value) return true;
          return validationHandle.test(value);
        }
      ),
    employment_type_id: Yup.string().required("This field is required.....")
      .test('no-empty-spaces', 'Invalid input', value => value && !/^\s+$/.test(value))
    ,
    company_name: Yup.string().required("This field is required.....")
      .test(
        'no-leading-space',
        'Invalid input',
        (value) => {
          if (!value) return true;
          return validationHandle.test(value);
        }
      ),
    location: Yup.string().required("This field is required.....")
      .test(
        'no-leading-space',
        'Invalid input',
        (value) => {
          if (!value) return true;
          return validationHandle.test(value);
        }
      ),

  })

  EducationSchema = Yup.object().shape({
    school_name: Yup.string().required("This field is required.....")
      .test(
        'no-leading-space',
        'Invalid input',
        (value) => {
          if (!value) return true;
          return validationHandle.test(value);
        }
      ),
    degree_id: Yup.string().required("This field is required.....").test('no-empty-spaces', 'Invalid input', value => value && !/^\s+$/.test(value)),

    grade: Yup.string().required("This field is required.....").test(
      'no-leading-space',
      'Invalid input',
      (value) => {
        if (!value) return true;
        return validationHandle.test(value);
      }
    ),

    location: Yup.string().required("This field is required.....").test(
      'no-leading-space',
      'Invalid input',
      (value) => {
        if (!value) return true;
        return validationHandle.test(value);
      }
    ),

  });
  CertificateSchema = Yup.object().shape({
    name: Yup.string().required("This field is required.....").test(
      'no-leading-space',
      'Invalid input',
      (value) => {
        if (!value) return true;
        return validationHandle.test(value);
      }
    ),
    issuing_organization: Yup.string().required("This field is required.....").test(
      'no-leading-space',
      'Invalid input',
      (value) => {
        if (!value) return true;
        return validationHandle.test(value);
      }
    ),

    issue_date: Yup.string().required("This field is required.....").test('no-empty-spaces', 'Invalid input',
    (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }
  ),

    issue_year: Yup.string().required("This field is required.....").test('no-empty-spaces', 'Invalid input',
    (value) => {
      if (!value) return true;
      return validationHandle.test(value);
    }
  ),

  })
  companyModal = () => {
    return (
      <Model_company_details_add_fun
        data-test-id="company-modal"
        companyData2={this.state.companyData}
        setState={this.setState}
        handleClose_company_details={this.handleClose_company_details}
        handleOpen_company_details={this.handleOpen_company_details}
        CompanyvalidationSchema={this.CompanyvalidationSchema}
        Reff={this.selectRef}
        countryCode={this.state.countryCode}
        selectRefCurrentClick={() => this.selectRef.current?.click()}
        changeCountryCode={({ target: { value } }: any) => this.setState({ countryCode: value })}
        saveButton={this.saveButton}
        updateCompanyDetail={(val: any) => this.updateCompanyDetail(val)}
        onChangeHandler={(val: any, setFieldValue: any, month: any) => {
          // setFieldValue("start_date", val)
          setFieldValue("start_date", val)
          this.setState({ start_dateInitial: val })

        }}
        yearChangeHandler={(val: any, setFieldValue: any) => {
          setFieldValue('start_date', val)
          this.setState({ year_initial: val })
        }}
        fullNumberChangeHandler={(e: any, setFieldValue: any) => {
          this.setState({ phoneNumber: e.target.value })
          setFieldValue("full_phone_number", e.target.value)
        }}
      />
    )
  }

  DateFormatter = (val: any) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = val.toLocaleDateString("en-US", options);
    const parts = formattedDate.split("/");
    const rearrangedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
    this.setState({ start_date: rearrangedDate })
    return parts
  }


  CertificateForm = (handleSubmit: any, handleChange: any, setFieldValue: any, touched: any, values: any, errors: any) => {
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Typography style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }} className="text-field">Name*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="name"
                data-test-id="txtInputTitle"
                placeholder={"Name"}
                fullWidth={true}
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(
                  getIn(
                    errors,
                    "name"
                  )
                )}
               
              />
                {touched.name && Boolean(
                getIn(
                  errors,
                  "name"
                )
              ) && <Typography style={{ fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex", alignItems: "center" }}>
                  {
                    getIn(
                      errors,
                      "name"
                    )
                  }</Typography>
              }
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }} className="text-field">Issuing organization*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="issuing_organization"
                data-test-id="txtInputCompanyName"
                placeholder={"Issuing organization"}
                fullWidth={true}
                variant="outlined"
                value={values.issuing_organization}
                onChange={handleChange}
                error={touched.issuing_organization && Boolean(
                  getIn(
                    errors,
                    "issuing_organization"
                  )
                )}
              />
               {touched.issuing_organization && Boolean(
                getIn(
                  errors,
                  "issuing_organization"
                )
              ) && <Typography style={{ marginTop: "3px", display: "flex", alignItems: "center",color: "#CD4C39", fontWeight: 400,fontSize: "14px", marginLeft: "14px",   }}>
                  {
                    getIn(
                      errors,
                      "issuing_organization"
                    )
                  }</Typography>
              }
            </Box>

          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }} className="text-field">Issue Date</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomDatePicker
                name="issue_date"
                maxDate={new Date}
                views={["date", "month"]}
                value={values.issue_date}
                data-test-id="certifcateDateChangeHandler_test"
                onChange={(val) => this.certifcateDateChangeHandler(val, setFieldValue, month)}
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }} className="text-field">Issue Year</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomDatePicker
                name="issue_date"
                maxDate={new Date}
                views={["year"]}
                data-test-id="date-picker-comp"
                value={`${values.issue_date}`}
                onChange={(val) => this.certificateYearChangeHandler(val, setFieldValue)} />
            </Box>
          </Grid>
          {this.saveButton()}
        </Grid>
      </Box>
    )
  }
  model_work_experience_add_fun() {
    return (
      <div className="">
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Work Experience</h2>
          </Grid>
          <Grid item sm={1} >
            <IconButton onClick={this.handleClose} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={workExperienceFunc(this.state)}
          validationSchema={this.experienceSchema}
          data-test-id="model_work_experience_add_fun_formiktestid"
          onSubmit={values => {
            // istanbul ignore next
            const { account_id, created_at, employment_type, profile_headline, description, industry,total_years, ...newObj } = values
            this.editWorkExperience(newObj)
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.ExperienceForm(handleSubmit, handleChange, errors, setFieldValue, touched, values, this.state.employmentTypes)}
          </form>
        )}

        </Formik>
      </div>
    );
  }

  certificateYearChangeHandler = (val: any, setFieldValue: any) => {
    this.setState({ startDate: val })
    setFieldValue("issue_date", val)
  }

  certifcateDateChangeHandler = (val: any, setFieldValue: any, month: string[]) => {
    this.setState({ startYear:val})
    setFieldValue("issue_date", val)
  }

  ExperienceForm = (handleSubmit: any, handleChange: any, errors: any, setFieldValue: any, touched: any, values: any, employmentTypes: any) => {
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Designation*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="title"
                data-test-id="txtInputTitle"
                placeholder={"Designation"}
                fullWidth={true}
                variant="outlined"
                value={values.title}
                onChange={handleChange}
                error={touched.title && Boolean(
                  getIn(
                    errors,
                    "title"
                  )
                )}
              />
              {touched.title && Boolean(
                getIn(
                  errors,
                  "title"
                )
              ) && <Typography style={{  marginLeft: "14px",color: "#CD4C39",fontSize: "14px", marginTop: "3px", display: "flex",  fontWeight: 400, alignItems: "center" }}>
                 {
                    getIn(
                      errors,
                      "title"
                    )
                  }</Typography>
              }
            </Box>
          </Grid>
          <Grid item  xs={12} sm={6} className="degree_dropdown">
            <Typography className="text-field" style={{
              fontSize: 'larger',
              fontWeight: 'inherit',
            }}>Employment type*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <DropDown
                name="employment_type_id"
                value={values.employment_type_id}
                onChange={handleChange}
                items={this.state.employmentTypes}
                error={touched.employment_type_id && Boolean(
                  getIn(
                    errors,
                    "employment_type_id"
                  )
                )}
                helperText={touched.employment_type_id &&
                  getIn(
                    errors,
                    "employment_type_id"
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Company Name*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="company_name"
                data-test-id="txtInputCompanyName"
                value={values.company_name}
                fullWidth={true}
                placeholder={"Company Name"}
                variant="outlined"
                onChange={handleChange}
                error={touched.company_name && Boolean(
                  getIn(
                    errors,
                    "company_name"
                  )
                )}
              />
              {touched.company_name && Boolean(
                getIn(
                  errors,
                  "company_name"
                )
              ) && <Typography style={{
                fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex",
                alignItems: "center"
              }}>
                 {
                    getIn(
                      errors,
                      "company_name"
                    )
                  }</Typography>
              }
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Location*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="location"
                data-test-id="txtInputLocation"
                placeholder={"Location"}
                fullWidth={true}
                variant="outlined"
                value={values.location}
                onChange={handleChange}
                error={touched.location && Boolean(
                  getIn(
                    errors,
                    "location"
                  )
                )}
              />
              {touched.location && Boolean(
                getIn(
                  errors,
                  "location"
                )) && <Typography style={{ fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex", alignItems: "center" }}>
                {
                    getIn(
                      errors,
                      "location"
                    )
                  }</Typography>
              }
            </Box>

          </Grid>
          <Grid item sm={12}>
            <CustomCheckBox
              data-test-id="agreeWith"
              name="currently_work_here"
              color="primary"
              checked={values.currently_work_here}
              onChange={handleChange}
            />
            <span className="label-wrap">I'm currently working here</span>
          </Grid>
          {this.DateSection(values, setFieldValue, errors, touched)}
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Skill Set</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="skill_set"
                data-test-id="txtInputCompanyName"
                placeholder={"Skill Set"}
                fullWidth={true}
                variant="outlined"
                value={values.skill_set}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          {this.saveButton()}
        </Grid>
      </Box>
    )
  }

  updateProfileImageHandler = () => {
    this.setState({ updateProfileImg: !this.state.updateProfileImg,chooseFile:{} })
  }

  DateSection = (values: any, setFieldValue: any, errors: any, touched: any) => {
    const month = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
    const isCurrentlyWork = values?.currently_work_here;
    return (
      <>
        <Grid item sm={6} xs={12}>
          <Typography className="text-field" style={{
            fontWeight: 'inherit',
            fontSize: 'larger'
          }}>Start Date</Typography>
          <Box sx={{ paddingBottom: "12px"}}>
            <CustomDatePicker
              name="start_date"
              views={["date", "month"]}
              value={values.start_date}
              maxDate={values.end_date}
              data-test-id="date-picker"
              onChange={(val) => { this.startDateHandler(val, setFieldValue, month) }}
            />

          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography className="text-field" style={{
            fontWeight: 'inherit',
            fontSize: 'larger'
          }}>Year</Typography>
          <Box sx={{ paddingBottom: "12px" }}>
            <CustomDatePicker
              name="start_date"
              views={["year"]}
              maxDate={values.end_date}
              data-test-id="user_work_experiences_start_year_test"
              value={`${values.start_date}`}
              onChange={(val) => { this.startYearHandler(val, setFieldValue) }} />

          </Box>
        </Grid>
        {!values.currently_work_here && <>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>End Date</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomDatePicker
                name="end_date"
                views={["date", "month"]}
                minDate={values.start_date}
                maxDate={new Date().toString()}
                data-test-id="test_id_user_work_experiences_date"
                value={ values.end_date }
                onChange={(val) => { this.endDateHandler(val, setFieldValue, month) }} />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Year</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomDatePicker
                name="end_date"
                views={["year"]}
                minDate={values.start_date}
                maxDate={new Date().toString()}
                data-test-id="user_work_experiences_start_year_test_id"
                value={ values.end_date }
                onChange={(val) => { this.endYearHandler(val, setFieldValue) }}
              />
            </Box>
          </Grid>
        </>
        }
      </>
    )
  }

  startYearHandler = (val: any, setFieldValue: any) => {
    this.setState({ startYear: val.getFullYear() })
    setFieldValue("start_date", val)
  }

  endDateHandler = (val: any, setFieldValue: any, month: string[]) => {
    this.setState({ endDate: val })
    setFieldValue("end_date", val)
  }

  startDateHandler = (val: any, setFieldValue: any, month: any) => {
    this.setState({ startDate: val })
    setFieldValue("start_date", val)
  }

  endYearHandler = (val: any, setFieldValue: any) => {
    setFieldValue("end_date", val)
    this.setState({ endYear: val.getFullYear() })
  }

  WorkExperienceAddModal() {
    return (
      <div className="">
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Work Experience</h2>
          </Grid>
       <Grid item sm={1}>
            <IconButton onClick={this.handleAddClose} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={this.state.workExperience}
          validationSchema={this.experienceSchema}
          data-test-id="edit-experience"
          onSubmit={values => {
            // istanbul ignore next
            this.AddWorkExperience(values,values.start_date.getFullYear(),values.end_date.getFullYear())
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.ExperienceForm(handleSubmit, handleChange, errors, setFieldValue, touched, values, this.state.employmentTypes)}
          </form>
        )}
        </Formik>
      </div>
    );
  }

  updateEducationHandler = () => {
    this.setState({ updateEducation: false })
  }


  updateEduModal() {
    return (
      <div className=""  style={{width:"inherit"}}>
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Education</h2>
          </Grid>
          <Grid item sm={1} >
            <IconButton onClick={this.updateEducationHandler} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={EducationFunc(this.state)}
          validationSchema={this.EducationSchema}
          onSubmit={values => {
            // istanbul ignore next
            const { account_id, currently_studying_here, degree, degreeName, ...newobjjj } = values;
            this.editEducationDetail(newobjjj);
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.EducationForm(handleSubmit, touched, handleChange, setFieldValue, values, errors)}
          </form>
        )}
        </Formik>
      </div>
    );
  }

  EducationForm = (handleSubmit: any, touched: any, handleChange: any, setFieldValue: any, values: any, errors: any) => {

    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>School Name*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="school_name"
                data-test-id="txtInputTitle"
                placeholder={"School Name"}
                fullWidth={true}
                variant="outlined"
                value={values.school_name}
                onChange={handleChange}
                error={touched.school_name && Boolean(
                  getIn(
                    errors,
                    "school_name"
                  )
                )}

              />
              {touched.school_name && Boolean(
                getIn(
                  errors,
                  "school_name"
                )
              ) && <Typography style={{ fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39", fontWeight: 400, display: "flex", alignItems: "center" }}>
                  {
                    getIn(
                      errors,
                      "school_name"
                    )
                  }</Typography>
              }
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className="degree_dropdown">
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Degree*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <DropDown
                name="degree_id"
                value={values.degree_id}
                onChange={handleChange}
                items={this.state.degrees}
                error={touched.degree_id && Boolean(
                  getIn(errors, "degree_id")
                )}
                helperText={touched.degree_id &&
                  getIn(errors, "degree_id")
                }
              />
            </Box>
          </Grid>
          {this.DateSection(values, setFieldValue, errors, touched)}
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontWeight: 'inherit',
              fontSize: 'larger'
            }}>Grade*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="grade"
                data-test-id="txtInputCompanyName"
                placeholder={"Grade"}
                fullWidth={true}
                variant="outlined"
                value={values.grade}
                onChange={handleChange}
                error={touched.grade && Boolean(
                  getIn(
                    errors,
                    "grade"
                  )
                )}

              />
              { touched.grade && Boolean(
                getIn(
                  errors,
                  "grade"
                )
              )  &&<Typography style={{  display: "flex", fontSize: "14px", marginTop: "3px",marginLeft: "14px",   color: "#CD4C39", fontWeight: 400, alignItems: "center" ,}}>
                  {
                    getIn(
                      errors,
                      "grade"
                    )
                  }</Typography>
              }
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className="text-field" style={{
              fontSize: 'larger',
              fontWeight: 'inherit'
            }}>Location*</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="location"
                placeholder={"Location"}
                fullWidth={true}
                variant="outlined"
                value={values.location}
                onChange={handleChange}
                error={touched.location && Boolean(
                  getIn(
                    errors,
                    "location"
                  )
                )}

              />
              {touched.location && Boolean(
                getIn(
                  errors,
                  "location"
                )
              ) && <Typography style={{  fontWeight: 400, display: "flex", alignItems: "center" ,fontSize: "14px", marginLeft: "14px", marginTop: "3px", color: "#CD4C39",}}>
                 {
                    getIn(
                      errors,
                      "location"
                    )
                  }</Typography>}
            </Box>

          </Grid>
          {this.saveButton()}
        </Grid>
      </Box>
    )
  }

  degreeChangeHandler = (e: any, setFieldValue: any) => {
    setFieldValue("degree_id", e.target.value)
    this.setState({ degreeName: "" })
  }

  addEduModal() {
    return (
      <div className=""  style={{width:"inherit"}}>
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Education</h2>
          </Grid>
          <Grid item sm={1} >
            <IconButton onClick={this.addEducationHandler} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={this.state.educationDetail}
          validationSchema={this.EducationSchema}
          onSubmit={values => {
            // istanbul ignore next
            this.AddEducation(values,values.start_date.getFullYear(),values.end_date.getFullYear())
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.EducationForm(handleSubmit, touched, handleChange, setFieldValue, values, errors)}
          </form>
        )}
        </Formik>
      </div>
    );
  }

  updateBackGroundImg() {
    const { background_image } = this.state;
    return (
      <Box className="popup_main_div" style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}>
        <Box className="popup_width" style={{ display: 'flex', justifyContent: 'space-between', width: '600px', alignItems: "center" }}>
          <Typography className="popup_heading" style={{ fontWeight: 600, fontSize: "20px" }}>Background picture</Typography>
          <IconButton onClick={this.backImgHandler} className="pop_up_icon">
            <CloseIcon />
          </IconButton>        </Box>
        <Box className="btn_action" style={{
          width: 'calc(100% + 80px)', height: '300px', marginLeft: '-40px', display: 'flex',
          justifyContent: 'center',
          background: '#111',
          paddingBlock: "40px"
        }}>
          <img src={background_image && !background_image.includes("mp4") ? background_image : DefaultBackground} style={{ width: '100%', height: '100%', objectFit: "cover" }} />
        </Box>
        <Box className="flex_wrp" style={{ borderBottom: 'solid 1px #d6d6d6', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', width: '100%', padding: '15px', alignItems: "Center" }}>
          <img style={{ height: '18px', marginTop: '6px', cursor: 'pointer' }} src={deleteIcon} alt="" onClick={() => { this.removeProfilePicture("remove_background_image") }} />
          <Box style={{ display: 'flex' }}>
            <button
              onClick={() => {
                this.inputRef.current?.click()
              }}
              style={{
                padding: '8px 18px', border: '1px solid #536fc2', borderRadius: 6, marginRight: '15px'
                , color: '#536fc2', background: '#fff', cursor: "pointer"
              }}>Change Photo</button>
            <button
              onClick={this.uploadFile}
              disabled={!this.state.chooseFile?.name}
              style={{
                padding: '8px 18px',
                border: '1px solid #536fc2', borderRadius: 6, background: '#536fc2',
                color: '#fff', cursor:!this.state.chooseFile?.name?"not-allowed":"pointer",opacity:!this.state.chooseFile?.name ?"0.6":"unset"
              }}>Save Photo</button>
          </Box>
        </Box>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {this.state.userRole === "company" ?
            <>
              <Typography style={{ fontWeight: 'bold', marginBottom: '15px', textAlign: 'left' }}>Company name :</Typography>
              <input type="text" value={this.state.company_name}
                onChange={this.companyNameHandler}
                style={{ padding: '15px', width: '100%', borderRadius: '5px', minHeight: '50px', border: "0.7px solid #536fc2" }} />
            </> : ""
          }
          <Typography style={{ fontWeight: 'bold', marginBottom: '15px', marginTop: '30px', textAlign: 'left' }}>Profile Bio / Tagline</Typography>
          <input type="text" value={this.state.tagline }
            onChange={this.aboutYourselfHandler}
            style={{ padding: '15px', width: '100%', borderRadius: '5px', minHeight: '50px', border: "0.7px solid #536fc2" }} />
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <Button

              style={{
                letterSpacing: "2px",
                width: "200px",
                textTransform: "capitalize",
                margin: "20px auto",
               cursor:"pointer"
              }}
              data-test-id={"btnContactUs"}
              variant="contained"
              color="primary"
              onClick={() => { this.state.userRole !== "company" ? this.update_about_yourself() : this.updateCompanyDetail({}) }}
            >
              Save
            </Button>
          </Grid>
          <Grid sm={12} item  className="message_show">
            <div style={{ marginTop: '15px' }}>
              <Collapse in={this.state.userProfileMsgCollapseOpen}>
                {this.state.userProfileMsg_response && <Alert
                  severity={this.state.userProfileMsg_res_message === 'success' ? 'success' : "error"}
                  variant="filled"
                  action={
                    <IconButton
                      aria-label="close"
                      data-test-id="txtInputMessagePassword"
                      color="inherit"
                      onClick={this.CollapseOpenClose_userProfileMsg_fun}
                      size="small"
                      className="pop_up_icon"
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.userProfileMsg_response}
                </Alert>}
              </Collapse>
            </div>
          </Grid>
        </Box>
        <input type="file" ref={this.inputRef} style={{ display: 'none' }} onChange={this.fileChangeHandler} />
      </Box>
    );

  }

  fileChangeHandler = (e: any) => {
    this.setState({ chooseFile: e.target.files[0] })
  }

  aboutYourselfHandler = (e: any) => {
      this.setState({ about_yourself: e.target.value,tagline: e.target.value })
  
  }

  updateProfilePicture() {
    
    return (
      <Box className="popup_main_div" style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center'
      }}>
        <Box className="popup_width" style={{ display: 'flex', justifyContent: 'space-between', width: '600px', alignItems: "center" }}>
          <Typography className="popup_heading" style={{ fontWeight: 600, fontSize: "20px" }}>Profile Picture</Typography>
          <IconButton onClick={this.updateProfileImageHandler} className="pop_up_icon">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="btn_action" style={{
          width: 'calc(100% + 80px)', height: '250px', marginLeft: '-40px', display: 'flex',
          justifyContent: 'center',
          background: '#111'
        }}>
          <img src={this.state.profile_image ? this.state.profile_image : DefaultBackground} className="img_style" style={{ width: 'auto', height: '100%', objectFit: "cover" }} />
        </Box>
        <Box className="flex_wrp" style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center"
        }}>
          <img src={deleteIcon} style={{ height: '18px', marginTop: '6px', cursor: 'pointer' }} onClick={() => this.removeProfilePicture("remove_profile_image")} alt="" />
          <Box style={{ display: 'flex' }}>
            <button
              onClick={this.clickProfileRef}
              style={{
                padding: '8px 18px', border: '1px solid #536fc2', borderRadius: 6, marginRight: '15px'
                , background: '#fff', color: '#536fc2', cursor: "pointer"
              }}>Change Photo</button>
            <button
             disabled={!this.state.chooseFile?.name}
              onClick={this.updateProfileImg}
              style={{
                padding: '8px 18px',
                border: '1px solid #536fc2', borderRadius: 6, background: '#536fc2',
                color: '#fff', cursor:!this.state.chooseFile?.name?"not-allowed":"pointer",opacity:!this.state.chooseFile?.name ?"0.6":"unset"
              }}>Save Photo</button>
          </Box>
        </Box>
        {  this.state.userProfileMsgCollapseOpen && <Grid item sm={12} className="message_show">
            <div style={{ marginTop: '15px' }}>
              <Collapse in={this.state.userProfileMsgCollapseOpen}>
                {this.state.userProfileMsg_response && <Alert
                  variant="filled"
                  severity={this.state.userProfileMsg_res_message === 'success' ? 'success' : "error"}
                  action={
                    <IconButton
                    data-test-id="txtInputMessagePassword"
                    size="small"
                    color="inherit"
                      aria-label="close"
                      className="pop_up_icon"
                      onClick={this.CollapseOpenClose_userProfileMsg_fun}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.userProfileMsg_response}
                </Alert>}
              </Collapse>
            </div>
          </Grid>
}
        <input type="file" ref={this.profileRef} style={{ display: 'none' }} onChange={this.fileChangeHandler} />
      </Box>
    )
  }

  clickProfileRef = () => {
    this.profileRef.current?.click()
  }



  certificateHandler = () => [
    this.setState({ updateCertificate: false })
  ]

  updateCertificateModal() {
    return (
      <div className="">
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Certificates</h2>
          </Grid>
        <Grid data-test-id="" item sm={1} >
            <IconButton onClick={this.certificateHandler} className="pop_up_icon">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={CertificateFunc(this.state)}
          validationSchema={this.CertificateSchema}
          onSubmit={body => {
            // istanbul ignore next
            const { created_at, account_id, ...values } = body
            this.updateCertificate(values)
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.CertificateForm(handleSubmit, handleChange, setFieldValue, touched, values, errors)}
          </form>
        )}

        </Formik>
      </div>
    );
  }


  addCertificateModal() {
    return (
      <div className="">
        <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item sm={11}>
            <h2 className="work_exp_heading2">Certificates</h2>
          </Grid>
          <Grid item sm={1} style={{ textAlign: 'right' }}>
            <IconButton className="pop_up_icon" onClick={this.addCertificateHandler}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={this.state.certificateDetail}
          validationSchema={this.CertificateSchema}
          onSubmit={values => {
            // istanbul ignore next

            this.AddCertificate(values)
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {this.CertificateForm(handleSubmit, handleChange, setFieldValue, touched, values, errors)}
          </form>
        )}

        </Formik>
      </div>
    );
  }

  saveButton = () => {
    return (
      <>
        <Grid container style={{ paddingBottom: '25px' }}>
          <Grid item sm={12} style={{ textAlign: "center", width: "100%" }}>
            <Button
              type="submit"
              style={{
                letterSpacing: "2px",
                width: "20vw",
                textTransform: "capitalize",
                marginTop: "20px",
                borderRadius: "2px"
              }}
              data-test-id={"btnContactUs"}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} className="message_show" style={{padding:"0px 12px 0 12px"}}>
            <div style={{ marginTop: '15px' }}>
              <Collapse in={this.state.userProfileMsgCollapseOpen}>
                {this.state.userProfileMsg_response && <Alert
                  variant="filled"
                  severity={this.state.userProfileMsg_res_message === 'success' ? 'success' : "error"}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      data-test-id="txtInputMessagePassword"
                      className="pop_up_icon"
                      onClick={this.CollapseOpenClose_userProfileMsg_fun}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.userProfileMsg_response}
                </Alert>}
              </Collapse>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  iconButtonHandler = () => {
    this.setState({
      model_company_details_add: true,
      phoneNumber: this.state.full_phone_number?.substring(this.state.full_phone_number.slice(0, -10).length, 13),
      countryCode: this.state.full_phone_number.slice(0, -10)
    })
  }

  handleOpen_company_details = () => {
    this.setState({ model_company_details_add: true, countryCode: `+${this.state.full_phone_number.slice(0, -10)}` });
  };
  handleClose_company_details = () => {
    this.setState({ model_company_details_add: false });
  };
 handleVideoPlay=(backGroudImage:any)=>{
    return typeof backGroudImage === 'string'&&backGroudImage?.includes("mp4")?  <VideoPlayer videoSource={backGroudImage}/>: <img style={{
    height: '400px'
  }} src={backGroudImage} alt="cover-photo" className="profile_cover_img" />
}
handleVideoPlayForcompany=(backGroudImageVidoe:any,style:any,style1:any)=>{
return typeof backGroudImageVidoe === 'string' && backGroudImageVidoe?.includes("mp4") ?<VideoPlayer videoSource={backGroudImageVidoe}/>:<img style={{
  height: '400px',
  objectFit:style,
  background:style1
}} src={backGroudImageVidoe} alt="cover-photo" className="profile_cover_img" />
}
    
  companySection() {
    const backGroudImage=this.state.background_image ? this.state.background_image : DefaultBackground
    const backGroudImagePlaceHolder=this.state.background_image ? this.state.background_image : background_logo
    const ProfileImage=this.state.profile_image ? this.state.profile_image : DefaultBackground
    const ProfileImagePlaceHolder=this.state.profile_image ? this.state.profile_image : placeholder_logo
    const style=backGroudImagePlaceHolder===background_logo?"none":"cover"
    const style1=backGroudImagePlaceHolder===background_logo?"white":""
  
    return (
      <div className="profile_body_row" >
        <div className="profile_image_photo_abc" >
          <Box
            className="user_profile_section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#d3d9e7',
              position: 'relative',
              height: '600',
              overflow: "hidden"
            }}>
            <Box style={{
              width: '95%',
            }}>
              <h1 className="user_profile_name2">{this.handleComapnyData()}</h1>
            </Box>
            <Box className="user_profile_style" style={{
              width: '95%'
            }}>
                { this.state.editIconView ? 
                   this.handleVideoPlay(backGroudImage)
                    :      
                    this. handleVideoPlayForcompany(backGroudImagePlaceHolder,style,style1)
       }
          
            </Box>
            <Box className="user_profile_bx" style={{
              left: '70px',
              top: '420px',
              position: 'absolute',
              flexWrap: "wrap",
            }}>
          <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                style={{ cursor: 'pointer' }}
                badgeContent={
                  this.state.editIconView &&  <SmallAvatar alt="Remy Sharp" src={image_View_edit} onClick={this.updateProfileImageHandler} />
                }
                className="profie_edit_icon"
              >
              { this.state.editIconView ? 
              <Avatar alt="Profile Photo" src={ProfileImage} className="profile_photo_user" />
              :
              <Avatar alt="Profile Photo" src={ProfileImagePlaceHolder} className="profile_photo_user" />

            }
              </Badge>
            </Box>
            <Box className="user_profile_bx1" style={{
              position: 'absolute',
              top: '497px',
              left: '280px',
              flexWrap: "wrap",
              display: "flex",
              wordWrap: "break-word",
              wordBreak: "break-all",
              height:"60px",
              overflow:"hidden"
            }}>
              <h5>{this.state.tagline}</h5>
            </Box>
          { this.state.editIconView &&  <Box style={{
              position: 'absolute',
              top: '52px',
              right: '51px',
              cursor: 'pointer'
            }} className="edit_profile_iocn2"
              onClick={this.backImgHandler}
            >
              <SmallAvatar alt="Remy Sharp" style={{ cursor: "pointer" }} src={image_View_edit} />
            </Box>}
          </Box>
        </div>
        <div className="overview_contact_specialities">
          <Container style={{ width: '90%' }}>
            <div className="overview_row">
              <Grid container style={{ display: 'flex', position: 'relative' }} >
                <Grid item md={12}>
                  <h1 className="title_company1">Overview</h1>
                </Grid>
              {this.state.editIconView &&   <IconButton style={{ position: 'absolute', right: '10px', bottom: '2px' }}
                  onClick={this.iconButtonHandler}
                  className="pop_up_icon"
                >
                  <img src={edit_icon_img} alt="Edit" className="edit_icon icons_mui_dev" />
                </IconButton>}
              </Grid>
              <Grid container>
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={image_Building} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Industry
                      </Typography>
                      <Typography className='' color="textSecondary">
                        {this.handleIndustryData()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={CalenderImg} style={{ height: '53px' }} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Started On
                      </Typography>
                      <Typography className='' color="textSecondary">
                        {this.handleStartedOnDate()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={image_Users} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Company Size
                      </Typography>
                      <Typography className='' color="textSecondary">
                        {this.handleCompanySize()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>


            <hr style={{ margin: '30px 0', borderTop: '2px solid #eee' }} />

            <div className="contact_row">
              <Grid container >
                <Grid item md={12}>
                  <h1 className="title_company">Contact</h1>
                </Grid>
              </Grid>
              <Grid container >
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={image_Marker} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Location
                      </Typography>
                      <Typography className='' color="textSecondary" style={{ height: "60px" }}>
                        {this.handleLocation()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={image_Globe} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Website
                      </Typography>
                      <Typography className='' color="textSecondary" style={{ height: "60px" }}>
                        <a target="_blank" href={this.state.website ? "https://"+this.state.website : "#"}>{this.state.website ? this.state.website : "No Data"}</a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card className="overview_grid">
                    <CardContent>
                      <img src={image_Phone_call} alt="Industry" className="overview_icon_small" />
                      <Typography variant="h5" component="h2">
                        Phone
                      </Typography>
                      <Typography className='' color="textSecondary" style={{ height: "60px" }}>
                        <a href={this.state.full_phone_number ? 'tel:'+this.state.full_phone_number : "#"}>{this.state.full_phone_number ? "(+" +this.state.full_phone_number.slice(0, -10) + ")" + "" + this.state.full_phone_number.slice(-10) : "No Data"}</a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>

            <hr style={{ margin: '30px 0', borderTop: '2px solid #eee' }} />

            <div className="specialities_row">
              <Grid container >
                <Grid item md={12}>
                  <h1 className="title_company">Specialities</h1>
                </Grid>
              </Grid>
              <Grid container >
                <Grid item md={12}>
                  <p style={{ color: "grey" }}>{this.state.specialities}</p>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Dialog className="model_popup" scroll="paper" open={this.state.model_company_details_add} onClose={this.handleClose_company_details}>
          <DialogContent>
            <div className="">
              {this.companyModal()}
            </div>
          </DialogContent>
        </Dialog>
        <Dialog className="model_popup1" scroll="paper" open={this.state.updateBackImg}>
          <DialogContent style={{
            display: 'flex',
            backgroundColor: 'white',
            width: '100%',
            padding: '15px !important',
          }}>
            {this.updateBackGroundImg()}
          </DialogContent>
        </Dialog>
        <Dialog className="model_popup1" scroll="paper" open={this.state.updateProfileImg}>
          <DialogContent style={{
            display: 'flex',
            backgroundColor: 'white',
            width: '100%',
          }}>
            {this.updateProfilePicture()}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  imageSetter = (itemi: any) => {
    this.setState({
      currentCertificate: itemi.id,
      certificateDetails: itemi,
      startDate: itemi.attributes?.issue_date,
      startYear:itemi.attributes?.issue_year
    }, () => {
      this.setState({
        updateCertificate: true
      })
    })
  }

  profileSection = () => {
    return (
      <Box className="user_profile_section" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#d3d9e7',
        position: 'relative',
        height: '600',
        overflow:"hidden"
      }}>
        <Box style={{
          width: '95%',
        }}>
          <h1 className="user_profile_name2">{this.state.first_name ? this.state.first_name : ''} {this.state.last_name ? this.state.last_name : ''}</h1>
        </Box>
        <Box className="user_profile_style" style={{
          width: '95%'
        }}>
          <img style={{
            height: '400px'
          }} src={this.state.background_image ? this.state.background_image : DefaultBackground} alt="cover-photo" className="profile_cover_img" />
        </Box>
        <Box className="user_profile_bx" style={{
          position: 'absolute',
          top: '420px',
          left: '70px'
        }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
            this.state.editIconView&&  <SmallAvatar alt="Remy Sharp" src={image_View_edit} onClick={this.updateProfileImageHandler} style={{ cursor: "pointer" }} />
            }
            className="profie_edit_icon"
          >
            <Avatar alt="Profile Photo" src={this.state.profile_image ? this.state.profile_image : DefaultBackground} className="profile_photo_user" />
          </Badge>
        </Box>
        <Box className="user_profile_bx1" style={{
          position: 'absolute',
          top: '497px',
          left: '280px',
          flexWrap: "wrap",
          display: "flex",
          wordWrap: "break-word",
          wordBreak: "break-all",
          height:"60px",
          overflow:"hidden"
        }}>
          <h5>{this.state.bio}</h5>
        </Box>
     {    this.state.editIconView&&    <Box className="edit_profile_iocn2" style={{
          position: 'absolute',
          top: '52px',
          right: '51px',
          cursor: 'pointer'
        }}>
          <SmallAvatar alt="Remy Sharp" src={image_View_edit}
            data-test-id="background-edit"
            style={{ cursor: "pointer" }}
            onClick={this.backImgHandler} />
        </Box>}
      </Box>
    )
  }

  backImgHandler = () => {
    this.setState({ updateBackImg: !this.state.updateBackImg ,chooseFile:{}})
  }

  experienceSection() {
    return (
      <>
        <Container>
          <Grid container className="mbl_width_grid">
            <Grid item sm={4}>
              <h2 className="work_exp_heading">Work Experience</h2>
            </Grid>
           {this.state.editIconView && <Grid item sm={8} className="add_edit_col">
              <IconButton data-test-id="abcd" onClick={this.handleAddOpen} className="pop_up_icon">
                <img src={add_icon_img} alt="Add" className="plus_icon icons_mui_dev" />
              </IconButton>
            </Grid>}
          </Grid>
        </Container>
        <Container className="work_exp_bg">
          {this.state.work_experiences?.map((itemi: any, index: number) => {
            return (
              <Grid container className="profile_bio_row" style={{
                position: 'relative',
              }}>
           {this.state.editIconView &&     <IconButton className="pop_up_icon" style={{
                  position: 'absolute',
                  top: 2,
                  right: 0
                }}>
                  <img src={edit_icon_img} alt="Edit"
                    data-test-id={`profile-edit${index}`}
                    onClick={() => {
                      this.setState({
                        currentWorkExperience: itemi.id,
                        employmentTypeId: itemi.attributes.employment_type.id,
                        currentExperienceDetails: itemi,
                        startDate: itemi.attributes.start_date ? itemi.attributes.start_date : "",
                        endDate: itemi.attributes.end_date ? itemi.attributes.end_date : ""
                      }, () => {
                        this.handleOpen()
                      })
                    }} className="edit_icon icons_mui_dev" />
                </IconButton>}
                <Grid item md={3} className="">
                  <img src={worklogo1} className="work_exp_img" />
                </Grid>
                <Grid item md={6}>
                  <div className="work_exp_job_details">
                    <h2 className="work_exp_title">{itemi.attributes.title}</h2>
                    <h4 className="work_exp_type">{itemi.attributes.company_name} - {itemi.attributes.employment_type.employment_type_name}</h4>
                    <p className="work_exp_time" style={{ color: 'grey' }}>{itemi.attributes.start_date} {itemi.attributes.start_year} - {!itemi.attributes.currently_work_here ? itemi.attributes.end_date + " " + itemi.attributes.end_year : "Present"}</p>
                    <p className="work_exp_skills" style={{ color: 'grey' }}>{itemi.attributes.skill_set}</p>
                    <p className="work_exp_city" style={{ color: 'grey' }}>{itemi.attributes.location}</p>
                  </div>
                </Grid>
                <Grid item md={3}>

                </Grid>
                <hr style={{
                  display: 'block', height: '1px', background: '#ccc', border: '0', margin: '15px auto', width: '95%'
                  , padding: '0'
                }} />
              </Grid>
            );
          })}
        </Container>
      </>
    )
  }

  addCertificateHandler = () => {
    this.setState({ addCertificate: !this.state.addCertificate })
  }

  addEducationHandler = () => {
    this.setState({ isAddEdu: !this.state.isAddEdu })
  }
  handleUi=()=>{
    return this.state.userRole === 'company' ?
    this.companySection() 
    :
    <div className="profile_body_row" >
      <div className="profile_image_photo_abc">
        {this.profileSection()}
      </div>


      <div className="work_experience_row">
        {this.experienceSection()}
      </div>
      <div className="education_row">
        <Container>
          <Grid container className="mbl_width_grid">
            <Grid item sm={4} className="">
              <h2 className="edu_heading">Education</h2>
            </Grid>
            {this.state.editIconView &&  <Grid item sm={8} className="add_edit_col">
              <IconButton onClick={this.addEducationHandler} className="pop_up_icon">
                <img src={add_icon_img} alt="Add" className="plus_icon icons_mui_dev" />
              </IconButton>
            </Grid>}
          </Grid>
        </Container>
        <Container className="education_bg">
          {this.state.educations?.map((itemi: any, index: number) => {
            return (
              <Grid container>
                <Box style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: "100%",
                  position: 'relative'
                }}>
                  <h2 style={webstyle.listStyle} className="work_exp_education">{itemi.attributes.school_name}</h2>
                  <h4 style={webstyle.listStyle}>{itemi.attributes.degree.degree_name + " - " + itemi.attributes.grade + " Grade"} </h4>
                  <h6 style={{ color: 'grey', margin: "2px" }}>{itemi.attributes.start_date} {itemi.attributes.start_year} - {itemi.attributes.end_date} {itemi.attributes.end_year}</h6>
                  <h6 style={{ color: 'grey', margin: "2px" }}>{itemi.attributes.location}</h6>
                  {this.state.editIconView && <IconButton className="pop_up_icon" style={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px'
                  }}>
                    <img src={edit_icon_img} alt="Edit"
                      onClick={() => {
                        this.setState({
                          degreeId: itemi?.attributes?.degree?.id,
                          degreeName: itemi?.attributes?.degree?.degree_name,
                          currentCompany: itemi.id,
                          educationDetails: itemi,
                          startDate: itemi?.attributes?.start_date,
                          endDate: itemi?.attributes?.end_date,
                          startYear: itemi?.attributes?.start_year,
                          endYear: itemi?.attributes?.end_year
                        }, () => {
                          this.setState({
                            updateEducation: true
                          })
                        })
                      }}
                      className="edit_icon icons_mui_dev" />
                  </IconButton>}
                  <hr style={{
                    display: 'block', height: '1px', background: '#ccc', border: '0', margin: '15px auto', width: '95%'
                    , padding: '0'
                  }} />
                </Box>

              </Grid>

            );
          })}
        </Container>
      </div>
      <div className="education_row">
        <Container>
          <Grid container className="mbl_width_grid">
            <Grid item sm={4} className="">
              <h2 className="edu_heading">Certification</h2>
            </Grid>
            {this.state.editIconView &&   <Grid item sm={8} className="add_edit_col">
              <IconButton onClick={this.addCertificateHandler} className="pop_up_icon">
                <img src={add_icon_img} alt="Add" className="plus_icon icons_mui_dev" />
              </IconButton>
            </Grid>}
          </Grid>
        </Container>
        <Container className="education_bg">
          {this.state.certificates?.map((itemi: any, index: number) => {
            return (
              <Grid container>
                <Box style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: "100%",
                  position: 'relative'
                }}>
                  <h2 style={webstyle.listStyle} className="work_exp_education">{itemi.attributes.name}</h2>
                  <h4 style={webstyle.listStyle}>{itemi.attributes.issuing_organization}</h4>
                  <h6 style={{ color: 'grey', margin: "2px" }}>{itemi.attributes.issue_date} {itemi.attributes.issue_year}</h6>
                 {this.state.editIconView &&  <IconButton className="pop_up_icon" style={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px'
                  }}>
                    <img src={edit_icon_img} alt="Edit"
                      data-test-id="image-edit-icon"
                      onClick={() => this.imageSetter(itemi)}
                      className="edit_icon icons_mui_dev" />
                  </IconButton>}
                  <hr style={{
                    display: 'block', height: '1px', background: '#ccc', border: '0', margin: '15px auto', width: '95%'
                    , padding: '0'
                  }} />
                </Box>

              </Grid>

            );
          })}
        </Container>

      </div>


      <Dialog className="model_popup" scroll="paper" open={this.state.model_work_experience_add} onClose={this.handleClose}>
        <DialogContent>
          <div className="">
            {this.model_work_experience_add_fun()}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog className="model_popup" scroll="paper" open={this.state.updateEducation}>
        <DialogContent>
          <div className="">
            {this.updateEduModal()}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog className="model_popup" scroll="paper" open={this.state.updateCertificate}>
        <DialogContent>
          <div className="">
            {this.updateCertificateModal()}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog className="model_popup1" scroll="paper" open={this.state.updateBackImg}>
        <DialogContent style={{
          padding: '15px !important',
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
        }}>
          {this.updateBackGroundImg()}
        </DialogContent>
      </Dialog>

      <Dialog className="model_popup1" scroll="paper" open={this.state.updateProfileImg}>
        <DialogContent style={{
          display: 'flex',
          backgroundColor: 'white',
          width: '100%',
        }}>
          {this.updateProfilePicture()}
        </DialogContent>
      </Dialog>

      <Dialog className="model_popup" scroll="paper" open={this.state.isAddOpen}>
        <DialogContent style={{
          display: 'flex',
          backgroundColor: 'white',
          width: '100%',
          padding: '15px !important',
        }}>
          {this.WorkExperienceAddModal()}
        </DialogContent>
      </Dialog>
      <Dialog className="model_popup" scroll="paper" open={this.state.isAddEdu}>
        <DialogContent style={{
          display: 'flex',
          backgroundColor: 'white',
          width: '100%',
          padding: '15px !important',
        }}>
          {this.addEduModal()}
        </DialogContent>
      </Dialog>
      <Dialog className="model_popup" scroll="paper" open={this.state.addCertificate}>
        <DialogContent style={{
          display: 'flex',
          backgroundColor: 'white',
          width: '100%',
          padding: '15px !important',
        }}>
          {this.addCertificateModal()}
        </DialogContent>
      </Dialog>


    </div>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenunew navigation={undefined} id={""} />
        
        {
        this.state.userRole==""? <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
        <CircularProgress />
    </div>: 
         this.handleUi()
        }
        <FooterWeb />
        <button
          data-test-id="btn-company"
          style={{
            display: 'none',
          }} onClick={() => {
            this.setState({ userRole: 'executive', first_name: "sdfcdsdvds", last_name: "ascdcs", company_size: "20", company_name: "abc", industry: "abc" })
          }}></button>

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start





const webstyle = {
  listStyle: {
    margin: '3px'
  },
  descStyle: {
    position: 'absolute',
    top: '497px',
    left: '280px'
  }
}

// Customizable Area End
