Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StripeIntegration";
exports.labelBodyText = "StripeIntegration Body";

exports.btnExampleTitle = "CLICK ME";

exports.subScriptionPlansAPI_URL = "/bx_block_plan/subscriptions";

// Customizable Area End