Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

exports.AddBigraphy ="/account_block/account_creations/board_biography";
exports.AddWorkExpierence="/account_block/account_creations/work_experience";
exports.AddEducation="/account_block/account_creations/education";
exports.GetProfile="/account_block/accounts"
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorTitle = "Error";

exports.postContentTypeFormData="multipart/form-data";
exports.putPostAPiMethod = "PUT";
// Customizable Area End