import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: any;
  editFormData: any;
  skills: any;
  addSkills: any[];
  workExperience: any[];
  checkedWorking: boolean;
  openDialog: boolean;
  openEditDialog: boolean;
  deleteId: any;
  deleteMessage: any;
  editId: any;
  editData: any;
  openAddDialog: boolean;
  editSkills: any[];
  currently_work_here: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WorkExperienceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addWorkExperienceCallId: any;
  getWorkExperienceCallId: any;
  DeleteApiCallId: any;
  updateApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formData: {
        position: "",
        company: "",
        industry: "",
        location: "",
        startDate: "",
        endDate: "",
      },
      editFormData: {
        position: "",
        company: "",
        industry: "",
        location: "",
        from: "",
        to: "",
        skills: [],
        currently_work_here: false,
      },
      skills: "",
      addSkills: [],
      token: "",
      checkedWorking: false,
      workExperience: [],
      openDialog: false,
      deleteId: "",
      deleteMessage: "",
      openEditDialog: false,
      editId: "",
      editData: "",
      openAddDialog: false,
      editSkills: [],
      currently_work_here: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const authTokenReq = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(authTokenReq);
  }

  onHandleChange = (e: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onHandleEditChange = (e: any) => {
    this.setState({
      editFormData: {
        ...this.state.editFormData,
        [e.target.name]: e.target.value,
      },
    });
  };

  setSkills = (text: string) => {
    this.setState({
      skills: text,
    });
  };

  onChangeHandler = (e: any) => {
    e.preventDefault();
    this.setState({
      skills: "",
      addSkills: [...this.state.addSkills, { name: this.state.skills }],
    });
  };

  onChangeEditHandler = (e: any) => {
    e.preventDefault();
    this.setState({
      skills: "",
      editSkills: [...this.state.editSkills, { name: this.state.skills }],
      addSkills: [...this.state.addSkills, { name: this.state.skills }],
    });
  };

  onDeleteSkills = (index: any) => {
    this.state.addSkills.splice(index, 1);
    this.setState({});
  };

  removeSkills = (deleteId: any) => {
    const array = this.state.editSkills;
    const newArray = array.filter((item: any) => item.id !== deleteId);
    this.setState({ editSkills: newArray });
  };

  setCurrentlyWorking = (value: boolean) => {
    this.setState({ checkedWorking: value });
  };

  setCurrentlyEditWorking = (value: boolean) => {
    this.setState({ currently_work_here: value });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = localStorage.getItem("authToken") as string;
      runEngine.debugLog("Message Recived", message);
      this.setState({ token: token }, () => {
        this.getAllWorkExperience();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.getWorkExperienceCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              this.setState({ workExperience: item[1] });
            });
          }
        }
        if (apiRequestCallId === this.addWorkExperienceCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data = item[1];
              this.setState({
                workExperience: [...this.state.workExperience, data],
              });
              this.getAllWorkExperience();
              this.setState({
                formData: {
                  position: "",
                  company: "",
                  industry: "",
                  location: "",
                  startDate: "",
                  endDate: "",
                },
              });
              this.setState({ addSkills: [] });
              this.setState({ checkedWorking: false });
            });
          }
        }
        if (apiRequestCallId === this.DeleteApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              this.setState({ deleteMessage: item[1] });
            });
          }
          this.getAllWorkExperience();
        }
        if (apiRequestCallId === this.updateApiCallId) {
          {
            Object.entries(responseJson).forEach((item: any) => {
              const data = item[1];
              this.setState({
                workExperience: [...this.state.workExperience, data],
              });
              this.getAllWorkExperience();
            });
          }
        }
      }

      runEngine.debugLog("API Message Recived", message);

      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleEditClose = () => {
    this.setState({ openEditDialog: false });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  AddWorkExperience(): boolean {
    if (
      this.isStringNullOrBlank(this.state.formData.position) ||
      this.isStringNullOrBlank(this.state.formData.company) ||
      this.isStringNullOrBlank(this.state.formData.startDate) ||
      this.isStringNullOrBlank(this.state.formData.endDate) ||
      this.isStringNullOrBlank(this.state.formData.location)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token,
      };

      const attrs = {
        data: {
          attributes: {
            position: this.state.formData.position,
            company: this.state.formData.company,
            industry: this.state.formData.industry,
            from: this.state.formData.startDate,
            to: this.state.formData.endDate,
            currently_work_here: this.state.checkedWorking,
            skills: this.state.addSkills,
            location: this.state.formData.location,
          },
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addWorkExperienceCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.AddWorkExpierence
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  getAllWorkExperience() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkExperienceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWorkExpierence
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editNavigation = (id: any, item: any) => {
    const array = item.filter((obj: any) => obj.id === id);
    const data = array[0].attributes;
    this.setState({
      editFormData: {
        position: data.position,
        company: data.company,
        industry: data.industry,
        location: data.location,
        from: data.from,
        to: data.to,
      },
    });
    this.setState({ editSkills: data.skills });
    this.setState({ currently_work_here: data.currently_work_here });
    this.setState({ editId: id });
    this.setState({ openEditDialog: true });
  };

  updateWorkData(Id: any) {
    this.state.editSkills.forEach((x: any) => {
      delete x.id;
    });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const attrs = {
      data: {
        attributes: {
          position: this.state.editFormData.position,
          company: this.state.editFormData.company,
          industry: this.state.editFormData.industry,
          from: this.state.editFormData.from,
          to: this.state.editFormData.to,
          currently_work_here: this.state.currently_work_here,
          skills: this.state.editSkills,
          location: this.state.editFormData.location,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWorkExpierence + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putPostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteRecord(Id: any) {
    this.setState({ deleteId: Id });
    this.setState({ openDialog: true });
  }

  delete(Id: any) {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWorkExpierence + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getAllWorkExperience();
    return true;
  }
  // Customizable Area End
}
