import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Box, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Message } from "../../../framework/src/Message";

interface Props {
  navigation: any;
}

interface S {
  title: string;
  body: string;
  btnPositiveText: string;
  btnNegativeText: string;
  btnNeutralText: string;
}

interface SS {}

export default class AlertBlock extends BlockComponent<Props, S, SS> {
  messagePositiveButton: any;
  messageNegativeButton: any;
  messageNeutralButton: any;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      title: "",
      body: "",
      btnPositiveText: "",
      btnNegativeText: "",
      btnNeutralText: "",
    };

    this.GetTitleView = this.GetTitleView.bind(this);
    this.GetMainView = this.GetMainView.bind(this);

    this.receive = this.receive.bind(this);
  }

  render() {
    return this.state.title ||
      this.state.body ||
      this.state.btnNeutralText ||
      this.state.btnNegativeText ||
      this.state.btnPositiveText ? (
      <this.GetMainView />
    ) : null;
  }

  onNegativeButtonPress() {
    if (this.messageNegativeButton) {
      this.send(this.messageNegativeButton);
    } else {
      this.props.navigation.goBack();
    }
  }

  onPositiveButtonPress() {
    if (this.messagePositiveButton) {
      this.send(this.messagePositiveButton);
    } else {
      this.props.navigation.goBack();
    }
  }
  onNeutralButtonPress() {
    if (this.messageNeutralButton) {
      this.send(this.messageNeutralButton);
    } else {
      this.props.navigation.goBack();
    }
  }
  onCloseButtonPress() {
    this.props.navigation.goBack();
  }

  GetTitleView() {
    return this.state.title ? (
      <Typography variant="h6" color="error">
        {this.state.title}
      </Typography>
    ) : null;
  }

  GetMainView() {
    return (
      <Box sx={{ padding: "15px",minWidth:'280px'}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <this.GetTitleView />
          <CloseIcon onClick={() => this.onCloseButtonPress()} />
        </div>

        <Box sx={{marginTop:"10px"}}>
          <Typography variant="inherit">{this.state.body}</Typography>
        </Box>

        {this.state.btnPositiveText ||
        this.state.btnNegativeText ||
        this.state.btnNeutralText ? (
          <Box sx={{ textAlign: "end", padding: "15px 5px 0px 0px" }}>
            {//Neutral Button

            this.state.btnNeutralText ? (
              <Button
                onClick={() => {
                  this.onNeutralButtonPress();
                }}
                variant="contained"
                color="primary"
              >
                {this.state.btnNeutralText}
              </Button>
            ) : null}

            {//Positive & Negative Button Container

            this.state.btnNegativeText || this.state.btnPositiveText ? (
              <Box>
                {this.state.btnNegativeText ? (
                  <Button
                    onClick={() => {
                      this.onNegativeButtonPress();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {this.state.btnNegativeText}
                  </Button>
                ) : null}
                {this.state.btnPositiveText ? (
                  <>
                    <Button
                      onClick={() => {
                        this.onPositiveButtonPress();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {" "}
                      {this.state.btnPositiveText}
                    </Button>
                  </>
                ) : null}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    );
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.AlertMessage) === message.id) {
      this.navigateToAlertPage(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let alertType = message.getData(getName(MessageEnum.AlertTypeMessage));
      let title = message.getData(getName(MessageEnum.AlertTitleMessage));
      let body = message.getData(getName(MessageEnum.AlertBodyMessage));

      let btnPositiveText = message.getData(
        getName(MessageEnum.AlertButtonPositiveText)
      );
      let btnPositiveMessage = message.getData(
        getName(MessageEnum.AlertButtonPositiveMessage)
      );

      let btnNegativeText = message.getData(
        getName(MessageEnum.AlertButtonNegativeText)
      );
      let btnNegativeMessage = message.getData(
        getName(MessageEnum.AlertButtonNegativeMessage)
      );

      let btnNeutralText = message.getData(
        getName(MessageEnum.AlertButtonNeutralText)
      );
      let btnNeutralMessage = message.getData(
        getName(MessageEnum.AlertButtonNeutralMessage)
      );

      this.setState({
        title: title,
        body: body,
        btnPositiveText: btnPositiveText,
        btnNegativeText: btnNegativeText,
        btnNeutralText: btnNeutralText,
      });

      this.messagePositiveButton = btnPositiveMessage;
      this.messageNegativeButton = btnNegativeMessage;
      this.messageNeutralButton = btnNeutralMessage;
    }
  }

  navigateToAlertPage(message: Message) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAlertWebMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      message.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    msg.copyAllPropertiesOf(message);

    this.send(msg);
  }
}
