export const boardsiIntroImg = require("../assets/boardsi_intro.png");
export const boardsiLogo = require("../assets/boardsi_logo.png");
export const fitnessLogo = require("../assets/24-hour-fitness-logo-1.webp");
export const aflac = require("../assets/Aflac.webp");
export const clipart = require("../assets/clipart.webp");
export const mrsLogo = require("../assets/mrs-fields-logo.webp");
export const MTLogo = require("../assets/MT_Bank_logo.webp");
export const starbucks = require("../assets/starbucks.webp");

export const boardsiIntroImgM = require("../assets/boardsi_introM.png");
export const boardsiLogoM = require("../assets/boardsi_logoM.png");
export const scrollimg1 = require("../assets/view_image_aboutM.png");
export const imgshape1 = require("../assets/view_shape1.png");
export const imgshape2 = require("../assets/view_shape2.png");
export const imgshape3 = require("../assets/view_shape3.png");
export const sliderBackground = require("../assets/SliderBackground.png");
export const client1 = require("../assets/client1.png");
export const client2 = require("../assets/client2.png");
export const client3 = require("../assets/client3.png");
export const circle = require("../assets/view_imagebg.png");
export const aboutimg = require("../assets/view_image_about.png");
export const clientno1 = require("../assets/image_client1 (1).png");
export const clientno2 = require("../assets/image_client2.png");
export const clientno3 = require("../assets/image_client3.png");
export const clientno4 = require("../assets/image_client4.png");
export const clientimg01 = require("../assets/clientimage01.png");
export const clientimg02 = require("../assets/clientimage02.png");

export const logo1 = require("../assets/logo1.png");
export const logo2 = require("../assets/logo2.png");
export const logo3 = require("../assets/logo3.png");
export const logo4 = require("../assets/logo4.png");
