import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { CustomTextField } from "../../../components/src/CustomStyle.web";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';


// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import {
  BackgroundImage,
  boardsiLogo,
} from "./assets";


export default class ForgotPasswordweb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }



  render() {
    return (
      <>
        <div className="forgot_password_page">
          <Grid container alignItems="center"  >
            <Grid item md={8} xs={12} sm={12} style={{ order: 2 }}>
              <div className="forgot_right_col">
                <img
                  src={BackgroundImage}
                  className="forgot_image_bg"
                  alt="BackgroundImage"
                  style={{ width: '100%' }}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={12} style={{ order: 1 }}>
              <div className="forgot_left_col" style={{}}>
                <div style={{ paddingBottom: '40px', paddingTop: '20px', textAlign: 'center' }}>
                  <span>You don't have an account? </span>
                  <Link href="/EmailAccountRegistration">
                    Sign up
                  </Link>
                </div>
                <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
                  <img src={boardsiLogo} alt="logo" className="logo" />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <h1 style={{ paddingBottom: '20px' }}>Forgot Password</h1>
                  <Typography variant="body2" color="textSecondary" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                    Enter your email address you're using for your account below and we will send you a password reset link.
                  </Typography>
                </div>
                <form>
                  <div style={{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '30px' }}>
                    <Box>
                      <Typography className="text-field">Email</Typography>
                      <Box sx={{ paddingBottom: "10px" }}>
                        <CustomTextField
                          name="email"
                          data-test-id="txtInputForgotEmail"
                          placeholder={"Email"}
                          fullWidth={true}
                          variant="outlined"
                          value={this.state.forget_email}
                          onChange={this.forget_email_change}
                        />
                      </Box>
                      <div className="login-butto" style={{ padding: '25px' }}>
                        <Button
                          data-test-id=""
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={this.forgot_pass_update}
                          style={{textTransform:'capitalize'}}
                        >
                          Continue
                        </Button>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <Collapse in={this.state.CollapseOpen}>
                          <Alert
                            variant="filled"
                            severity={this.state.res_message === 'success' ? 'success' : "error"}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                data-test-id=""
                                onClick={this.CollapseOpenClose_fun}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.response_message}
                          </Alert>
                        </Collapse>
                      </div>
                    </Box>
                  </div>
                </form>
                <div style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: 'center' }}>
                  <Link href="/EmailAccountLoginBlock">
                    Back to Sign in
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
