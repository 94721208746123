import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  chooseVal: string;
  industryIds: string[] | null;
  employmentIds: string[] | null;
  degreeIds: string[] | null;
  designation_data: {}[];
  designation_customform_data: {}[];
  job: any
  errors: any;
  entry_level: string[];
  job_Type: string[];
  questions: any;
  textBox: any;
  textBoxes: any;
  MultitextBoxes: any;
  chooseOneBoxes: any;
  chooseOneBox: any;
  chooseMultipleBox: any;
  chooseMultipleBoxes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmploymentApiCallId: string = "";
  getIndustriesCallId: string = "";
  getDegreesCallId: string = "";
  getAPIDesignation_data: string = "";
  apiIDgetdesignationcustomformdata: string = "";
  createJobApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      chooseVal: "text",
      industryIds: [],
      employmentIds: [],
      degreeIds: [],
      designation_data: [],
      designation_customform_data: [],
      job: {
        job_title: "",
        location: "",
        employment_type_id: "",
        industry_id: "",
        experience_level: "",
        job_type: "",
        qualification_id: "",
        description: "",
        skills: "",
        salary_range_start: "",
        salary_range_end: "",
      },
      errors: {
        job_title: "",
        location: "",
        employment_type_id: "",
        industry_id: "",
        experience_level: "",
        job_type: "",
        qualification_id: "",
        description: "",
        skills: "",
        salary_range_start: "",
        salary_range_end: ""
      },
      questions: [],
      entry_level: ["Entry Level", "Intermediate", "Mid Level", "Senior or executive-level"],
      job_Type: ["On-Site", "Remote", "Hybrid"],
      textBoxes: [{
        question_type: "Single line text",
        description: ""
      }],
      textBox: {
        question_type: "Single line text",
        description: ""
      },
      MultitextBoxes: [{
        question_type: "Multi line text",
        description: ""
      }],
      chooseOneBoxes: [{
        question_type: "Multiple Choice",
        selection_type: "Single Selection",
        number_of_options: 0,
        description: "",
        options: []
      }],
      chooseOneBox: {
        question_type: "Multiple Choice",
        selection_type: "Single Selection",
        number_of_options: 0,
        description: "",
        options: []
      },
      chooseMultipleBox: {
        question_type: "Multiple Choice",
        selection_type: "Multi Selection",
        number_of_options: 0,
        description: "",
        options: []
      },
      chooseMultipleBoxes: [{
        question_type: "Multiple Choice",
        selection_type: "Multi Selection",
        number_of_options: 0,
        description: "",
        options: []
      }],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
      switch (apiRequestCallId) {
        case this.getEmploymentApiCallId: this.setState({ employmentIds: responseJson.data });
          break;
        case this.getDegreesCallId: this.setState({ degreeIds: responseJson.data });
          break;
        case this.getIndustriesCallId: this.setState({ industryIds: responseJson.data });
          break;
        case this.getAPIDesignation_data:
          this.setState({ designation_data: responseJson.data.slice().reverse() });
          break;
        case this.apiIDgetdesignationcustomformdata:
          this.setState({ designation_customform_data: responseJson.data });
          this.set_customfrom_data_in_uiux(responseJson.data);
          break;
        case this.createJobApiCallId:
          this.props.navigation.navigate("JobListing2")
          window.scrollTo(0, 0);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getEmploymentType();
    this.getDegrees();
    this.getIndustries();
    this.getDesignation_data();
  }

  set_customfrom_data_in_uiux(data: any) {
    if (data?.attributes?.questions?.data) {
      const updatedTextBoxes = data.attributes.questions.data
        .filter((question: any) => question.attributes.question_type === "Single line text")
        .map((singleLineTextQuestion: any) => ({
          question_type: singleLineTextQuestion.attributes.question_type,
          description: singleLineTextQuestion.attributes.description,
        }));

      this.setState({
        textBoxes: updatedTextBoxes.length > 0 ? updatedTextBoxes : [{
          question_type: "Single line text",
          description: ""
        }],
      });

      const updatedMultitextBoxes = data.attributes.questions.data
        .filter((question: any) => question.attributes.question_type === "Multi line text")
        .map((multiLineTextQuestion: any) => ({
          question_type: multiLineTextQuestion.attributes.question_type,
          description: multiLineTextQuestion.attributes.description,
        }));

      this.setState({
        MultitextBoxes: updatedMultitextBoxes.length > 0 ? updatedMultitextBoxes : [{
          question_type: "Multi line text",
          description: ""
        }],
      });

      const updatedChooseOneBoxes = data.attributes.questions.data
        .filter((question: any) =>
          (question.attributes.question_type === "Multiple Choice" || question.attributes.question_type === "Dropdown") &&
          question.attributes.selection_type === "Single Selection"
        )
        .map((singleSelectionQuestion: any) => ({
          question_type: singleSelectionQuestion.attributes.question_type,
          selection_type: singleSelectionQuestion.attributes.selection_type,
          number_of_options: singleSelectionQuestion.attributes.number_of_options,
          description: singleSelectionQuestion.attributes.description,
          options: singleSelectionQuestion.attributes.options,
        }));

      this.setState({
        chooseOneBoxes: updatedChooseOneBoxes.length > 0 ? updatedChooseOneBoxes : [{
          question_type: "Multiple Choice",
          selection_type: "Single Selection",
          number_of_options: 0,
          description: "",
          options: []
        }],
      });

      const updatedChooseMultipleBoxes = data.attributes.questions.data
        .filter((question: any) =>
          (question.attributes.question_type === "Multiple Choice" || question.attributes.question_type === "Dropdown") &&
          question.attributes.selection_type === "Multi Selection"
        )
        .map((multiSelectionQuestion: any) => ({
          question_type: multiSelectionQuestion.attributes.question_type,
          selection_type: multiSelectionQuestion.attributes.selection_type,
          number_of_options: multiSelectionQuestion.attributes.number_of_options,
          description: multiSelectionQuestion.attributes.description,
          options: multiSelectionQuestion.attributes.options,
        }));

      this.setState({
        chooseMultipleBoxes: updatedChooseMultipleBoxes.length > 0 ? updatedChooseMultipleBoxes : [{
          question_type: "Multiple Choice",
          selection_type: "Multi Selection",
          number_of_options: 0,
          description: "",
          options: []
        }],
      });
    }
  }

  addNewTextBox = () => {
    this.setState((prevState) => {
      return {
        textBoxes: [
          ...prevState.textBoxes,
          { question_type: 'Single line text', description: '' },
        ],
      };
    });
  };

  deleteTextBox = (index: number) => {
    this.setState((prevState) => {
      const updatedTextBoxes = [...prevState.textBoxes];
      updatedTextBoxes.splice(index, 1);
      return { textBoxes: updatedTextBoxes };
    });
  };

  updateTextQuestion = (event: { target: { value: any; }; }, index: number) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedTextBoxes = [...prevState.textBoxes];
      updatedTextBoxes[index].description = value;
      return { textBoxes: updatedTextBoxes };
    });
  };


  addNewMultiTextBox = () => {
    this.setState((prevState) => {
      return {
        MultitextBoxes: [
          ...prevState.MultitextBoxes,
          { question_type: 'Multi line text', description: '' },
        ],
      };
    });
  };

  deleteMultiTextBox = (index: number) => {
    this.setState((prevState) => {
      const updatedTextBoxes = [...prevState.MultitextBoxes];
      updatedTextBoxes.splice(index, 1);
      return { MultitextBoxes: updatedTextBoxes };
    });
  };

  updateMultiTextQuestion = (event: { target: { value: any; }; }, index: number) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedTextBoxes = [...prevState.MultitextBoxes];
      updatedTextBoxes[index].description = value;
      return { MultitextBoxes: updatedTextBoxes };
    });
  };


  addNewOptionBox = (index: number, chooseBox: any) => {
    const boxes = chooseBox
    const Box = chooseBox[index];
    const updatedOption = {
      ...Box,
      options: [...Box.options, { description: "" }],
      number_of_options: Box.number_of_options + 1
    }
    boxes[index] = updatedOption;
    if (chooseBox[index].selection_type === "Single Selection") {
      this.setState({ chooseOneBoxes: boxes })
    } else {
      this.setState({ chooseMultipleBoxes: boxes })
    }
  }

  deleteBox = (index: number, chooseBoxes: any) => {
    const Box = chooseBoxes;
    const updatedBox = Box.filter((box: any, i: number) => i !== index);
    { chooseBoxes[index].selection_type !== "Single Selection" ? this.setState({ chooseMultipleBoxes: updatedBox }) : this.setState({ chooseOneBoxes: updatedBox }) }
  }

  manageOption = (index: number, chooseBox: any, optionIndex: number) => {
    const boxes = chooseBox
    const Box = chooseBox[index];

    const filteredOptions = Box.options.filter((option: { description: string }, i: number) => i !== optionIndex)
    const optionUpdated = {
      ...Box,
      options: filteredOptions,
      number_of_options: Box.number_of_options - 1
    }
    boxes[index] = optionUpdated
    { chooseBox[index].selection_type === "Single Selection" ? this.setState({ chooseOneBoxes: boxes }) : this.setState({ chooseMultipleBoxes: boxes }) }
  }

  updateMultipleChoiceQuestion = (e: { target: { value: string } }, index: number, chooseBoxes: any) => {
    const MainBoxes = chooseBoxes
    const GetBox = chooseBoxes[index];
    const updatedQuestion = { ...GetBox, description: e.target.value }
    MainBoxes[index] = updatedQuestion;
    { chooseBoxes[index].selection_type === "Single Selection" ? this.setState({ chooseOneBoxes: MainBoxes }) : this.setState({ chooseMultipleBoxes: MainBoxes }) }
  }


  editOption = (index: number, optionIndex: number, e: { target: { value: string } }, chooseBox: any) => {
    const boxes = chooseBox;
    const GetBox = chooseBox[index];
    const updatedOption = { description: e.target.value };
    GetBox.options[optionIndex] = updatedOption;
    boxes[index] = GetBox;
    if (chooseBox[index].selection_type === "Single Selection") {
      this.setState({ chooseOneBoxes: boxes })
    } else {
      this.setState({ chooseMultipleBoxes: boxes })
    }
  }

  addTextBox = () => {
    this.setState({ textBoxes: [...this.state.textBoxes, this.state.textBox] })
  }

  addSingleBox = () => {
    this.setState({ chooseOneBoxes: [...this.state.chooseOneBoxes, this.state.chooseOneBox] })
  }

  addMultiChoiceBox = () => {
    this.setState({ chooseMultipleBoxes: [...this.state.chooseMultipleBoxes, this.state.chooseMultipleBox] })
  }

  createJob = (values: any) => {
    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    let Questions: any = [];
    let array = [this.state.textBoxes, this.state.MultitextBoxes, this.state.chooseMultipleBoxes, this.state.chooseOneBoxes]

    array.forEach(data => {
      if (data[0] && data[0].description) {
        Questions = [...Questions, ...data];
      }
    });

    const techString = values.skills || "";
    const techArray = techString.split(',').map((tech: string) => tech.trim());

    const data = {
      job: {
        ...values,
        skills: techArray,
        questions: Questions
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  getDegrees() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDegreesCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.degreeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getEmploymentType() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employmentEndPoint
    );
    this.getEmploymentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getIndustries() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndustriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industryEndPOint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDesignation_data() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("authToken") as string
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAPIDesignation_data = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Designation_dataEndPOint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  get_designation_customform_data = async (designationID: number) => {

    const token = localStorage.getItem("authToken") as string;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessageapdesignation_customform = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiIDgetdesignationcustomformdata = requestMessageapdesignation_customform.messageId

    requestMessageapdesignation_customform.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.api_getdesignationcustomform_endpoint + '?designation_id=' + designationID
    );

    requestMessageapdesignation_customform.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageapdesignation_customform.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobListingMethodType
    );

    runEngine.sendMessage(requestMessageapdesignation_customform.id, requestMessageapdesignation_customform);

    return true;
  };

  // Customizable Area End
}
