import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  MenuItem,
} from "@material-ui/core";

// Customizable Area Start
import "../../blocks/JobListing2/src/job.css";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomAccSelect, CustomAccTextField } from "./CustomStyle.web";


export const NewJobForm = (props: any) => {
  const { this_current, values, handleChange, degreeIds, job_Type, designation, entry_level, employmentIds, industryIds, errors, touched, setFieldValue } = props;

  return (
    <div className="account-creation-sections">
      <div className="account-content">
        <h4>Job Detail</h4>
        <Grid container spacing={4} className="new_job_input_wrap">
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Job Title</Typography>
            <Box sx={{ paddingBottom: '12px' }}>
              <CustomAccTextField
                name="job_title"
                data-test-id="txtInputOrganizationType"
                fullWidth={true}
                variant="outlined"
                value={values.job_title}
                onChange={handleChange}
                placeholder="Job Title"
              />
            </Box>
            {errors.job_title && touched.job_title && <span className="err_msg_new_job_creation">{errors.job_title}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Employment Type</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccSelect
                onChange={handleChange}
                IconComponent={() => <ExpandMoreIcon />}
                value={values.employment_type_id}
                name="employment_type_id"
                className="CustomAccSelect"
              >
                {employmentIds && employmentIds.map((size: any) => {
                  return (
                    <MenuItem value={size.id}>{size.value}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
            {errors.employment_type_id && touched.employment_type_id && <span className="err_msg_new_job_creation">{errors.employment_type_id}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Industry</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccSelect
                onChange={handleChange}
                IconComponent={() => <ExpandMoreIcon />}
                value={values.industry_id}
                name="industry_id"
                className="CustomAccSelect"
              >
                {industryIds && industryIds.map((size: any) => {
                  return (
                    <MenuItem value={size.id}>{size.value}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
            {errors.industry_id && touched.industry_id && <span className="err_msg_new_job_creation">{errors.industry_id}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Location</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="location"
                data-test-id="txtInputOrganizationType"
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                value={values.location}
                placeholder="Location"
              />
            </Box>
            {errors.location && touched.location && <span className="err_msg_new_job_creation">{errors.location}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Experience Level</Typography>
            <Box sx={{
              paddingBottom: '12px',
            }}>
              <CustomAccSelect
                onChange={handleChange}
                IconComponent={() => <ExpandMoreIcon />}
                value={values.experience_level}
                name="experience_level"
                className="CustomAccSelect"
              >
                {entry_level && entry_level.map((size: any) => {
                  return (
                    <MenuItem value={size}>{size}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
            {errors.experience_level && touched.experience_level && <span className="err_msg_new_job_creation">{errors.experience_level}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Job Type</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccSelect
                onChange={handleChange}
                IconComponent={() => <ExpandMoreIcon />}
                value={values.job_type}
                name="job_type"
                className="CustomAccSelect"
              >
                {job_Type && job_Type.map((size: any) => {
                  return (
                    <MenuItem value={size}>{size}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
            {errors.job_type && touched.job_type && <span className="err_msg_new_job_creation">{errors.job_type}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Qualification</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccSelect
                onChange={handleChange}
                IconComponent={() => <ExpandMoreIcon />}
                value={values.qualification_id}
                name="qualification_id"
                className="CustomAccSelect"
              >
                {degreeIds && degreeIds.map((size: any) => {
                  return (
                    <MenuItem value={size.id}>{size.value}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
            {errors.qualification_id && touched.qualification_id && <span className="err_msg_new_job_creation">{errors.qualification_id}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Skills(seperate with comma)</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccTextField
                name="skills"
                data-test-id="txtInputOrganizationType"
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                value={values.skills}
                placeholder="Skills"
              />
            </Box>
            {errors.skills && touched.skills && <span className="err_msg_new_job_creation">{errors.skills}</span>}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Job Description</Typography>
            <Box sx={{ paddingBottom: "12px", }}>
              <CustomAccTextField
                name="description"
                data-test-id="txtInputOrganizationType"
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                value={values.description}
                placeholder="Description"
              />
            </Box>
            {errors.description && touched.description && <span className="err_msg_new_job_creation">{errors.description}</span>}
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <Typography className="text-field">Salary Range</Typography>
            <Grid style={{ display: "flex", justifyContent: "center" }} spacing={3}>
              <Grid item sm={5}>
                <Box sx={{ paddingBottom: "12px", }}>
                  <CustomAccTextField
                    name="salary_range_start"
                    data-test-id="txtInputOrganizationType"
                    fullWidth={true}
                    variant="outlined"
                    placeholder="Start Amount"
                    onChange={handleChange}
                    value={values.salary_range_start}
                  />
                </Box>
                {errors.salary_range_start && touched.salary_range_start && <span className="err_msg_new_job_creation">{errors.salary_range_start}</span>}
              </Grid>
              <Grid item sm={2}>
                <Typography style={{ textAlign: "center", marginTop: 20, padding: '0px 5px' }}>To</Typography>
              </Grid>
              <Grid item sm={5}>
                <Box sx={{ paddingBottom: "12px", }}>
                  <CustomAccTextField
                    name="salary_range_end"
                    data-test-id="txtInputOrganizationType"
                    fullWidth={true}
                    variant="outlined"
                    placeholder="End Amount"
                    onChange={handleChange}
                    value={values.salary_range_end}
                  />
                </Box>
                {errors.salary_range_end && touched.salary_range_end && <span className="err_msg_new_job_creation">{errors.salary_range_end}</span>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="text-field">Designation</Typography>
            <Box sx={{ paddingBottom: "12px" }}>
              <CustomAccSelect
                onChange={event => {
                  setFieldValue("designation_id", event.target.value);
                  this_current.get_designation_customform_data(event.target.value)
                }}
                IconComponent={() => <ExpandMoreIcon />}
                value={this_current.state.designation_id}
                name="designation_id"
                className="CustomAccSelect"
              >
                {designation && designation.map((des_data: any) => {
                  return (
                    <MenuItem value={des_data.id}>{des_data.value}</MenuItem>
                  )
                })}
              </CustomAccSelect>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export const ButtonSection = (props: any) => {
  return (
    <>
      <div className="container" >
       <div style={{ margin: '0 auto 40px', width: '100%' }}>
          <Grid xs={12} style={{ textAlign: "end", margin: '0px 0px 0px', padding: "18px 60px 18px",  }}>
            <Button style={{ textTransform: 'capitalize' }}>
              Cancel
            </Button>
            <Button type="submit" style={{ textTransform: 'capitalize' }} className="job_done">Done</Button>
          </Grid>
        </div>
      </div>
    </>
  )
}