import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
 // Customizable Area Start
  formData: any;
  companyFormData: any;
  isExicutive: boolean;
  enablePasswordField: boolean;
  enableconfirmPasswordField : boolean;
  error: string;
  companyError: string;
  industryData:any;
  companySize:any;
  isSnackbarOpen:any;
  message:any;
 // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SignupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  industryId:any;
  companysizeid:any;
  async componentDidMount() {
    super.componentDidMount();
    this.getIndustryfn()
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    const currentUrl = window.location.href;
    // Parse the URL to get query parameters
    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    // Get the value of the 'parameterName' parameter
    const parameterValue = urlParams.get('isAgreed');

    this.state = {
      // Customizable Area Start
      industryData:[],
      isSnackbarOpen:false,
      message:"",
      companySize:[],
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        linkedin_url: "",
        checkedAgreeWith: parameterValue === "true" ? true : false,
      },
      companyFormData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        linkedin_url: "",
        checkedAgreeWith: false,
        company_name: "",
        location: "",
        company_size: "",
        industry: "",
      },
      isExicutive: false,
      enablePasswordField: true,
      enableconfirmPasswordField: true,
      error: "",
      companyError: "",
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableconfirmPasswordField: !this.state.enableconfirmPasswordField,
    });
  };

  setExicutive = (value: boolean) => {
    this.setState({ isExicutive: value });
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            localStorage.setItem("authToken", responseJson.meta.token);
            localStorage.setItem("user_id", responseJson.data.id);
            this.props.navigation.navigate("AccountCreation")
          } else {
           this.handleError(responseJson)
          }
        }
        if(apiRequestCallId===this.industryId){
          this.setState({industryData:responseJson?.data})
        }
      }
    }
    // Customizable Area End
  }

 


 
  // Customizable Area Start
  handleError=(responseJson:any)=>{
    if ( responseJson?.errors && responseJson?.errors?.length > 0) {
      const errorCode = responseJson.errors[0];
      
      switch (true) {
        case Boolean(errorCode.company_name):
          this.setState({ message: errorCode.company_name });
          break;
        case Boolean(errorCode.company_size):
          this.setState({ message: errorCode.company_size });
          break;
        case Boolean(errorCode.email):
          this.setState({ message: errorCode.email });
          break;
        case Boolean(errorCode.first_name):
          this.setState({ message: errorCode.first_name });
          break;
        case Boolean(errorCode.last_name):
          this.setState({ message: errorCode.last_name });
          break;
        case Boolean(errorCode.linkedin_url):
          this.setState({ message: errorCode.linkedin_url });
          break;
        case Boolean(errorCode.location):
          this.setState({ message: errorCode.location });
          break;
        case Boolean(errorCode.password):
          this.setState({ message: errorCode.password });
          break;
          case Boolean(errorCode.account):
            this.setState({ message: errorCode.account });
            break;
        default:
          // Handle default case if needed
          break;
      }
    }
    
    this.setState({isSnackbarOpen:true})
    Object.entries(responseJson).forEach((item: any) => {
      item[1].forEach((item: any) => {
        this.state.isExicutive === false
          ? this.setState({ error: item.account })
          : this.setState({ companyError: item.account });
      });
    });
  }
  closeSnackbar=()=>{
    this.setState({isSnackbarOpen:false})
  }
  getIndustryfn = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.industryId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/industries`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 
  createAccount(values: any) {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const data = {
      type: this.state.isExicutive === false ? "executive" : "company",
      attributes: values,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveAccountApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
