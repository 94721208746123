import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField, styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {Link} from "react-router-dom"
import * as Yup from "yup";
// Customizable Area End

import LoginWebController, { Props } from "./CommonLoginWebController.web";
import {
  BackgroundImage,
  BoardsiLogo,
  BordsiLogoWhite,
  Company,
  Executive,
  SideImage,
  dark1,
  dark2,
  EyeSlash,
  Eyeopen
} from "./assets";
import "./login.css";
import { CustomTextField } from "../../../components/src/CustomStyle.web";
// Customizable Area Start
const CustomBox = styled(Box)({
  '& fieldset': {
    top: -11
  },
  "@media(max-width:600px)":{
    '& fieldset': {
      top: -9
    },

  }
})
const Customdiv=styled('div')({
  '& .btn_select_user_type:hover': {
    background: 'unset'
  }
})
const theme = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
      contrastText: "#fff",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

});
// Customizable Area End

export default class LoginWeb extends LoginWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  // Customizable Area End
  isExicutiveEqualFalse() {
    return (
    // Customizable Area Start
      <>

        <form noValidate autoComplete="off">
          <CustomBox>
            <Typography className="text-field">Email</Typography>
            <Box sx={{ paddingBottom: "10px" }}>
              <CustomTextField
              className="textfield-style"
                name="email"
                data-test-id="txtInputEmail"
                placeholder={"Email"}
                fullWidth={true}
                value={this.state.formData.email}
                onChange={this.handleInputChange}
                variant="outlined"
              />
            </Box>
            <Typography className="text-field">Password</Typography>
            <Box sx={{ paddingBottom: "10px" }}>
              <CustomTextField
                type={
                  this.state.enablePasswordField ? "password" : "text"
                }
                placeholder={"Password"}
                fullWidth={true}
                variant="outlined"
                name="password"
                data-test-id="txtInputPassword"
                value={this.state.formData.password}
                onChange={this.handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id="txtInputPassword_eye_password_test"
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        className="signup_eye_icon_login"
                      >
                        {this.state.enablePasswordField ? (

                          <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
                        ) : (

                          <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />

                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Typography className="error-message">
              {this.state.error ? this.state.error : ""}
            </Typography>
            <div className="forget-pass-dev" style={{ textAlign: 'end' }}>
              <Link to="/ForgotPasswordweb" style={{ fontSize: '16px' }}>Forgot Password?</Link>
            </div>
            <div className="login-button">
              <Button
                type="button"
                onClick={this.handleLogin}
                data-test-id="btn_executive_click_submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                LogIn
              </Button>
            </div>
          </CustomBox>
        </form>
      </>
    // Customizable Area End
    )
  }

  isExicutiveEqualTrue() {
    return (
      // Customizable Area Start
      <form noValidate autoComplete="off">
        <CustomBox>
          <Typography className="text-field">Email</Typography>
          <Box sx={{ paddingBottom: "10px" }}>
            <TextField
              name="cemail"
              data-test-id="txtInputEmail_true"
              placeholder="Email"
              fullWidth={true}
              variant="outlined"
              value={this.state.companyFormData.cemail}
              onChange={this.handleInputChange}
            />
          </Box>
          <Typography className="text-field">Password</Typography>
          <Box sx={{ paddingBottom: "10px" }}>
            <TextField
              name="cpassword"
              data-test-id="txtInputPassword_true"
              type={
                this.state.enablePasswordField ? "password" : "text"
              }
              placeholder={"Password"}
              variant="outlined"
              value={this.state.companyFormData.cpassword}
              onChange={this.handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="signup_eye_icon_login"
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={this.handleClickShowPassword}
                      data-test-id="txtInputPassword_eye_password_test"
                    >
                      {this.state.enablePasswordField ? (
                        <img src={Eyeopen} style={{ width: '40%', marginBottom: '9px' }} />
                      ) : (
                        <img src={EyeSlash} style={{ width: '40%', marginBottom: '9px' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
            />
          </Box>
          <Typography className="error-message">
            {this.state.companyError ? this.state.companyError : ""}
          </Typography>
          <div className="forget-pass-dev" style={{ textAlign: 'end' }}>
            <Link to="/ForgotPasswordweb" style={{ fontSize: '16px' }}>Forgot Password?</Link>
          </div>
          <div className="login-button">
            <Button
              type="button"
              onClick={this.handleCompanyLogin}
              data-test-id="btn_company_click_submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              LogIn
            </Button>
          </div>
        </CustomBox>
      </form>
      // Customizable Area End
    )
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <div className="login-container">
        <Grid container>
        <Grid item md={8} xs={12} sm={12} className="left_side_img2">
          

        <div >
              <img
                src={BackgroundImage}
                alt="BackgroundImage"
                style={{ width: '100%', objectFit:'cover', height:'100vh' }}
                />
              <img src={BordsiLogoWhite} alt="logo-white" className="logo-white" />

            </div>
                </Grid>
                <Grid item md={4} xs={12} sm={12} className="scrollable-content">
                <div className="signup-container">
              <div className="signup-sub-container">
                  <img src={BoardsiLogo} alt="logo" className="logo" />

          <h1>LogIn</h1>
          <div className="login-free">
            <span>New here?</span>
            <Link to="/EmailAccountRegistration">
              {this.state.labelHeaderSignUpFree}
            </Link>
            <Customdiv className="executive">
              <Button
                onClick={() => this.setExicutive(false)}
                data-test-id="btnExecutiveLogIn"
                className="btn_select_user_type"
              >
                <div
                  className={
                    this.state.isExicutive === true
                      ? "executive-button"
                      : "executive-button-active"
                  }
                >
                  {this.state.isExicutive === true ? <img src={Executive} /> : <img src={dark1} />}

                  <p style={{ color: this.state.isExicutive === true ? '#151414' : '#5370c2' }}>
                    I'm an
                    <br /> Executive
                  </p>
                </div>
              </Button>
              <Button
                onClick={() => this.setExicutive(true)}
                data-test-id="btnComponyLogIn"
                className="btn_select_user_type"
              >
                <div
                  className={
                    this.state.isExicutive === false
                      ? "executive-button"
                      : "executive-button-active"
                  }
                >
                  {this.state.isExicutive === false ? <img src={Company} /> : <img src={dark2} />}

                  <p style={{ color: this.state.isExicutive === false ? '#100f0d' : '#5370c2' }}>
                    I'm a <br />
                    Company
                  </p>
                </div>
              </Button>
            </Customdiv>
          </div>
          {this.state.isExicutive === false ? (
            this.isExicutiveEqualFalse()
          ) : (
            ""
          )}

          {this.state.isExicutive === true ? (
            this.isExicutiveEqualTrue()
          ) : (
            ""
          )}
        </div>
                </div>
                </Grid>
        </Grid>
      </div>
    </ThemeProvider>
     // Customizable Area End
    );
  }
}

    // Customizable Area Start
    // Customizable Area End
