export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sampleImg = require("../assets/view_Rectangle.png");
export const boardsiIntro = require("../assets/view_boardsi_intro.png");
export const view_profilepic = require("../assets/view_profilepic.png");
export const unnamed = require("../assets/unnamed.png");
export const groupshape = require("../assets/groupshape.png")
export const Question1 = require("../assets/Question1.png");
export const Plus = require("../assets/plus.png");
export const Cross = require("../assets/cross.png");
export const block = require("../assets/block.png");
export const location = require("../assets/location.png");
export const Logout = require("../assets/logout.png");
export const Searchsvg = require("../assets/search2.svg");
export const insta = require("../assets/image_Instagram.png");
export const linked = require("../assets/image_Linkedin.png");
export const facebook = require("../assets/image_Facebook.png");
export const twitter = require("../assets/image_Twitter.png");
export const Whatsapp = require("../assets/image_Whatsapp.png");
export const placeholder_logo = require("../assets/placeholder.jpg");
export const background_logo = require("../assets/placeholder_logo.png");
export const deleteIcon = require("../assets/deletee.png");











