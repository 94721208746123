import React, { ChangeEvent, useRef } from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  value: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength: number;
  defaultValue?: string;
}

const TextAreaWithButtons: React.FC<Props> = ({ value, onChange = () => {}, maxLength, defaultValue }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= maxLength) {
      onChange(event);
    }
  };

  return (
    <div>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        value={value}
        defaultValue={defaultValue}
        onChange={handleInputChange} // Use the modified handler
        inputRef={textareaRef}
        className='textarea_user_bio'
      />
    </div>
  );
};

export default TextAreaWithButtons;
