Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "JobListing2";
exports.labelBodyText = "JobListing2 Body";
exports.jobListingEndPoint = "bx_block_joblisting2/jobs";
exports.jobListingMethodType = "GET";
exports.jobListingContentType = "application/json";
exports.getJobApplicationsEndPoint = "bx_block_joblisting2/jobs";
exports.jobApplicationsMethodType = "GET";
exports.btnExampleTitle = "CLICK ME";
exports.employmentEndPoint = "bx_block_profile/employment_types";
exports.industryEndPOint = "bx_block_profile/industries";
exports.degreeEndPoint = "bx_block_profile/degrees";
exports.postApiMethod = "POST";
exports.getProfileEndPointURL = "bx_block_profile_bio/profile_bios";
exports.workExperienceEndPoint = "account_block/account_creations/work_experience";
exports.getEducationEndPOint = "account_block/account_creations/education"
exports.getDetailsEndPoint = "bx_block_profile_bio/profile_bios";
exports.Executive_applied_Jobs="bx_block_joblisting2/jobs/my_applications"
exports.getArticalAPI="/bx_block_posts/posts"
exports.jobsAppliedEndpoint="/bx_block_joblisting2/jobs/my_applications"
exports.jobApplicantEndpoint="/bx_block_joblisting2/jobs/recent_applicants";
exports.nudgeApiEndpoint="/bx_block_cfremindernudge/nudge_notifications"
exports.JobsAppliedExecutiveEndPoint="/bx_block_joblisting2/jobs"
exports.Designation_dataEndPOint="/bx_block_joblisting2/custom_forms/designations"
exports.api_getdesignationcustomform_endpoint = "bx_block_joblisting2/custom_forms";
// Customizable Area End