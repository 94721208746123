import React, { Component } from "react";
import { failSubsription } from "./assets";
export default class FailSubscription extends Component{
    handleSuccess(){
        alert("Payment unsuccessful.")
    }
   
    render(){
        return <>
        <img src={failSubsription} style={{ margin:"auto",maxWidth:"300px"}}/>
        {this.handleSuccess()}
        </>
    }
}