import { AccCamera, AccCircle, AccDefault, AccShapes, AccWhiteCircle, Shape } from "../../blocks/email-account-registration/src/assets"
import {
    Typography,
    Grid,
    Button,
    Container,
    Badge,
    Avatar,
  } from "@material-ui/core";
  import "./ApplicationHeader.css"

  import {styled } from "@material-ui/core/styles";
  import "../../blocks/email-account-registration/src/signup.css"
  import "./user_profile_block.css"
  import React from "react";


  const SmallAvatar = styled(Avatar)(({ theme }: any) => ({
    width: 45,
    height: 45,
    border: `2px solid ${theme.palette.background.paper}`
  }));



export const ApplicationHeader = (props: { tagline: string,user_name: string,profile_image: string,user_role: string,background_image: string,about_yourself: string}) =>{
    
  const {user_name,profile_image,background_image,about_yourself,user_role,tagline} = props;
  
  return (
        <div className="account-container">

        <div className="account-profile-images">
          {background_image ? (
            <img
              src={background_image}
              className="acccount-default"
              alt="background-image"
            />
          ) : <img
            src={Shape}
            className="acccount-default"
            alt="background-image"
          />}
          <img
            src={AccCircle}
            className="account-circle"
            alt="background-image"
          />
          <label>
            <input
              type="file"
              data-test-id="backgroundImage"
            />
          </label>
        </div>
        <div className="profile_image_photo">
          <Container>
            <Grid container >
              <Grid>
                <img src={ background_image? background_image : AccDefault} alt="cover-photo" className="profile_cover_img" />
              </Grid>
            </Grid>
            <Grid container className="profile_bio_row">
              <Grid item md={3} className="profile_avatar_img_col">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src={AccCircle} />
                  }
                  className="profie_edit_icon"
                >
                  <Avatar alt="Profile Photo" src={profile_image ? profile_image :  AccDefault} className="profile_photo_user" />
                </Badge>
              </Grid>
              <Grid>
                <h1 className="user_profile_name">{user_name? user_name : "No Data"}</h1>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="account-profile">
          <div className="job-application-content">
            <h3>{user_name}</h3>
            <label className="profile-wrap">
              <div style={{position: "relative"}}>
                {!profile_image? (
                  <>
                    <img
                      src={AccWhiteCircle}
                      className="acc-profile-image"
                      alt="background-image"
                    />
                    <img
                      src={AccCamera}
                      className="camera_icon-css"
                      // style={{position: "absolute",top: "41%",left: "41%",height: "30%"}}
                      alt="background-image"
                    />
                  </>
                ) :
                  <img
                    src={profile_image}
                    alt="imggg"
                    style={{height:"90px",width: '90px',borderRadius: '100%'}}
                    className="acc-profile-image"
                  />
                }
              </div>
              <input
                type="file"
                data-test-id="profileImage"
              />
            </label>
          <Typography className="profile-detail">{tagline? tagline: about_yourself}</Typography>
          </div>
        </div>
        <img
          src={AccShapes}
          className="account-shapes"
          alt="background-image"
        />
      </div>
    )
}