export const workExperienceFunc = (state: any) => {
  if (state.currentExperienceDetails.attributes) {
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMonth = state?.startDate && month.indexOf((state?.startDate.toString() ?? "02 Sep").split(" ")[1]) + 1;
    const getDate = state?.startDate && (state?.startDate.toString() ?? "02 Sep").split(" ")[0];
    
    // Check if state.endDate exists and is not null before using it
    const getEndMonth = state?.endDate 
      ? month.indexOf((state?.endDate.toString() ?? "02 Sep").split(" ")[1]) + 1
      : new Date().getMonth() + 1;
    
    // Check if state.endDate exists and is not null before using it
    const getEndDate = state?.endDate 
      ? (state?.endDate.toString() ?? "02 Sep").split(" ")[0]
      : new Date().getDate().toString();

    const initialState = {
      ...state?.currentExperienceDetails.attributes,
      employment_type_id: state?.employmentTypeId,
      start_date: new Date(`${state?.currentExperienceDetails.attributes.start_year}-${getMonth}-${getDate}`),
      end_date: state?.currentExperienceDetails.attributes.end_year
        ? new Date(`${state?.currentExperienceDetails.attributes.end_year}-${getEndMonth}-${getEndDate}`)
        : new Date(`${new Date().getFullYear()}-${getEndMonth}-${getEndDate}`)
    };

    console.log("initialstate", initialState);

    return initialState;
  }
};

export const validationHandle=/^(?!.* {2,}).*[^ ].*$/

export const EducationFunc = (state: any) => {
  if(state.educationDetails.attributes){
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMonth = state?.startDate && month.indexOf((state?.startDate.toString() ?? "02 Sep").split(" ")[1]) + 1;
    const getDate =state?.startDate &&  (state?.startDate.toString() ?? "02 Sep").split(" ")[0]
    const listt = state?.endDate && (state?.endDate.toString() ?? "02 Sep").split(" ")[1];
    const getEndMonth = month.indexOf(listt) + 1;
    const getEndDate = state?.endDate && (state?.endDate.toString() ?? "02 Sep").split(" ")[0]
    const initialState = {
      ...state.educationDetails.attributes,
      start_date:state?.educationDetails?.attributes?.start_year &&  new Date(`${state.educationDetails.attributes.start_year}-${getMonth}-${getDate}`),
      end_date: state?.educationDetails?.attributes?.end_year && new Date(`${state.educationDetails.attributes.end_year}-${getEndMonth}-${getEndDate}`),
      degree_id:state.degreeId && state.degreeId,
      degreeName: state.degreeName && state.degreeName
    }
    return initialState
  }
}

export const CertificateFunc = (state:any) => {
  if(state.certificateDetails.attributes){
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMonth = month.indexOf((state?.startDate.toString() ?? "02 Sep").split(" ")[1]) + 1;
    const getDate = (state?.startDate.toString() ?? "02 Sep").split(" ")[0]
    const initialState = {
      ...state?.certificateDetails.attributes,
      issue_date:state?.certificateDetails?.attributes?.issue_year &&  new Date(`${state.certificateDetails.attributes.issue_year}-${getMonth}-${getDate}`),
    }

    return initialState
  }
}