import React from "react";

// Customizable Area Start
import "../../../components/src/user_profile_block.css";

import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Container,
  Badge,
  Avatar,
  IconButton,
  Collapse,CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Formik } from "formik";

import * as Yup from "yup";
import "./account.css"
import { VideoPlayer2 } from "../../../components/src/CompanyPopUp.web";

// Customizable Area End
import AccountCreationController, {
  Props
} from "./AccountCreationController.web";
import {
  AccCamera,
  AccCheck,
  AccCircle,
  AccDefault,
  AccShapes,
  AccSuccess,
  AccWhiteCircle,
  Checkbox,
  Shape,
} from "./assets";
import {
  CustomCheckBox,
  CustomAccTextField,
} from "../../../components/src/CustomStyle.web";
import "./signup.css";
import DropDown from "../../../components/src/AccDropdown.web";
import FooterWeb from "../../../components/src/FooterWeb";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import moment from "moment";
import { rawCountries } from "../../../components/src/rawCountryData";
import NavigationMenunew from "../../navigationmenu/src/NavigationMenunew.web";
import { CompanyAccountCreation } from "../../../components/src/CompanyAccountCreation";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';


const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 45,
  height: 45,
  border: `2px solid ${theme.palette.background.paper}`
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#536fc2",
      contrastText: "#fff"
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});





const month = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
const countries = [...rawCountries];
const companySize = ["1-50", "51-200", "201-500", "501-1000"]

export default class AccountCtreationWeb extends AccountCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.bgRef = React.createRef<HTMLInputElement>();
    // Customizable Area End
  }

  bgRef: React.RefObject<HTMLInputElement> | null

  IconCheckbox = () => (
    <img src={Checkbox} alt="checkbox" className="checkbox-icon" />
  );

  IconCheckedbox = () => (
    <img src={AccCheck} alt="checkbox" className="checkbox-icon" />
  );

  taglineHandler = (e: { target: { value: string } }) => { this.setState({ tagline: e.target.value }) }





  navigator = () => { this.props.navigation.navigate("JobListing2") }

  renderSuccessDialog() {
    return (
      // Customizable Area Start
      <div>
        <Dialog
          open={this.state.openDialog}
          className="account-success-content"
          style={{ borderRadius: "20px" }}
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography variant="h4">Account Created Successfully</Typography>
            <img src={AccSuccess} />
          </DialogTitle>
          <DialogContent className="account-success-content">
            <DialogContentText>
              <Typography variant="h2">
                Your account has been created successfully.You can access
                website.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <Box>
            <Button
              className="correct-btn"
              data-test-id="correctBtn"
              onClick={() => {
                this.handleClose();
                this.props.navigation.navigate("UserProfileBasicBlockweb",{id:`${localStorage.getItem("user_id")}`})
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 0);
              }}
              variant="contained"
              color="primary"
              style={{ marginBottom: "20px", textAlign: "center" }}
              fullWidth
            >
              Done
            </Button>
          </Box>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }

  dateSetterFunc = (date: any) => this.setState({ start_date: date });
  yearSetterFunc = (year: any) => this.setState({ start_year: year });

  companyAcc = (values: any, errors: any, handleChange: any, setFieldValue: any, touched: any) => {
    return (
      // Customizable Area Start
      <CompanyAccountCreation
        data-test-id="company-acc-creation"
        dateSetter={this.dateSetterFunc}
        yearSetter={this.yearSetterFunc}
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        countryCodeHandler={this.countryCodeHandler}
        collapse_open={this.state.collapse_open}
      />
      // Customizable Area End
    )
  }

  bgImageController = () => {
    this.bgRef?.current?.click()
  }

 




  executive_account_create() {
    const {

      degreeData,
    } = this.state;
    return (
      // Customizable Area Start
      <>
        <Formik
          initialValues={this.state.executiveAccountInitialState}
          validationSchema={this.validationSchemaExecutive}
          onSubmit={values => {
            this.createAccount(values)
          }}
          data-test-id="formik-test-id"
        >
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <>
                <div className="account-creation-sections">
                  <div className="account-content">
                    <Grid container style={{ alignItems: 'center', marginBottom: '20px' }}>
                      <Grid item sm={12}>
                        <h4>Work Experiences </h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} className="new_job_input_wrap">
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          Designation*
                        </Typography>
                        <Box >
                          <CustomAccTextField
                            name="title"
                            fullWidth={true}
                            data-test-id="Title_test_id"
                            variant="outlined"
                            placeholder="Designation"
                            value={values.title}
                            onChange={handleChange}

                          />
                        </Box>
                        {errors.title && touched.title && <span className="err_msg_account_creation">{errors.title}</span>}

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Employment type*</Typography>
                        <Box className="degree_select_field">
                          <DropDown
                            name="employment_type_id"
                            value={values.employment_type_id || ''}
                            onChange={handleChange}
                            items={this.state.employementData}
                          />
                        </Box>
                        {errors.employment_type_id && touched.employment_type_id && <span className="err_msg_account_creation">{errors.employment_type_id}</span>}

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Company Name*</Typography>
                        <Box>
                          <CustomAccTextField
                            name="company_name"
                            data-test-id="txtInputCompanyName"
                            placeholder={"Company Name"}
                            fullWidth={true}
                            variant="outlined"
                            value={values.company_name}
                            onChange={handleChange}

                          />
                        </Box>
                        {errors.company_name && touched.company_name && <span className="err_msg_account_creation">{errors.company_name}</span>}
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Location</Typography>
                        <Box>
                          <CustomAccTextField
                            name="location"
                            data-test-id="txtInputLocation"
                            placeholder={"Location"}
                            fullWidth={true}
                            variant="outlined"
                            value={values.location}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={12} style={{ marginBottom: "25px" }}>
                        <CustomCheckBox
                          data-test-id="agreeWith"
                          name="currently_work_here"
                          color="primary"
                          value={values.currently_work_here}
                          onChange={event => {
                            const { checked } = event.target;
                            setFieldValue("currently_work_here", checked);
                          }}
                        />
                        <span className="label-wrap">I'm currently working here</span>
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Start Date</Typography>
                        <Box>
                          <CustomDatePicker
                            name="start_date"
                            views={["date", "month"]}
                            value={values.start_date || null}
                            maxDate={values.end_date}
                            data-test-id="date-picker-1"
                            onChange={(val) => {
                              this.setState({ workStartDate: val.getDate() + " " + month[val.getMonth()] })
                              setFieldValue("start_date", val)
                            }}
                          />
                        </Box>

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Year</Typography>
                        <Box>
                          <CustomDatePicker
                            name="start_date"
                            views={["year"]}
                            data-test-id="work_start_year"
                            value={values.start_date}
                            maxDate={values.end_date}
                            onChange={(val) => {
                              this.setState({ workStartYear: val.getFullYear() })
                              setFieldValue("start_date", val)
                            }} />
                        </Box>
                      </Grid>
                      {values.currently_work_here ? null : (
                        <>
                          <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                            <Typography className="text-field" style={{
                              fontWeight: 'inherit',
                              fontSize: 'larger'
                            }}>End Date</Typography>
                            <Box>
                              <CustomDatePicker
                                name="end_date"
                                views={["date", "month"]}
                                data-test-id="work_experiences_date"
                                value={values.end_date}
                                minDate={values.start_date}
                                maxDate={new Date().toString()}
                                onChange={(val) => {
                                  this.setState({ workEndDate: val.getDate() + " " + month[val.getMonth()] })
                                  setFieldValue("end_date", val)
                                }} />
                            </Box>
                          </Grid>
                          <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                            <Typography className="text-field" style={{
                              fontWeight: 'inherit',
                              fontSize: 'larger'
                            }}>Year</Typography>
                            <Box>
                              <CustomDatePicker
                                name="end_date"
                                views={["year"]}
                                data-test-id="start_year_test_id"
                                value={values.end_date}
                                minDate={values.start_date}
                                maxDate={new Date().toString()}
                                onChange={(val) => {
                                  this.setState({ EndYear: val.getFullYear() })
                                  setFieldValue("end_date", val)
                                }} />
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field" style={{
                          fontWeight: 'inherit',
                          fontSize: 'larger'
                        }}>Skill Set</Typography>
                        <Box>
                          <CustomAccTextField
                            name="skill_set"
                            data-test-id="txtInputCompanyName"
                            placeholder={"Skill Set"}
                            fullWidth={true}
                            variant="outlined"
                            value={values.skill_set}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="account-creation-sections">
                  <div className="account-content">
                    <Grid container style={{ alignItems: 'center', marginBottom: '20px', justifyContent: "space-between" }}>
                      <Grid item sm={12}>
                        <h4>Education Info </h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} className="new_job_input_wrap">
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          School name*
                        </Typography>
                        <Box>
                          <CustomAccTextField
                            name="school_name"
                            placeholder="school name"
                            fullWidth
                            variant="outlined"
                            value={values.school_name}
                            onChange={handleChange}
                          />
                        </Box>
                        {errors.school_name && touched.school_name && <span className="err_msg_account_creation">{errors.school_name}</span>}

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">Degree*</Typography>
                        <Box className="degree_select_field">
                          <DropDown
                            name="degree_id"
                            value={values.degree_id || ''}
                            onChange={handleChange}
                            items={degreeData}
                          />
                        </Box>
                        {errors.degree_id && touched.degree_id && <span className="err_msg_account_creation">{errors.degree_id}</span>}

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          Start Date
                        </Typography>
                        <Box>
                          <CustomDatePicker
                            name="education_start_date"
                            value={values.education_start_date}
                            maxDate={values.education_end_date}
                            onChange={value => {
                              this.setState({ educationStartDate: value.getDate() + " " + month[value.getMonth()] })
                              setFieldValue("education_start_date", value)

                            }}
                            views={["date", "month"]}
                            data-test-id="educations_start_date_test_id"
                          />
                        </Box>

                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">Year</Typography>
                        <Box>
                          <CustomDatePicker
                            name="education_start_date"
                            value={values.education_start_date}
                            data-test-id="educations_test_id"
                            maxDate={values.education_end_date}
                            onChange={value => {
                              setFieldValue("education_start_date", value)
                              this.setState({ educationStartYear: value.getFullYear() })

                            }}
                            views={["year"]}
                          />                        </Box>
                      </Grid>

                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">End Date</Typography>
                        <Box>
                          <CustomDatePicker
                            name="education_end_date"
                            value={values.education_end_date}
                            minDate={values.education_start_date}
                            maxDate={new Date().toString()}
                            onChange={val => {
                              this.setState({ educationEndDate: val.getDate() + " " + month[val.getMonth()] })
                              setFieldValue("education_end_date", val)

                            }}
                            views={["date", "month"]}
                            data-test-id="educations_end_date_test_id"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">Year</Typography>
                        <Box>
                          <CustomDatePicker
                            name="education_end_date"
                            value={values.education_end_date}
                            minDate={values.education_start_date}
                            maxDate={new Date().toString()}
                            onChange={value => {
                              setFieldValue("education_end_date", value)
                              this.setState({ educationEndYear: value.getFullYear() })

                            }}
                            views={["year"]}
                            data-test-id="educations_end_year_test_id"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>

                        <Typography className="text-field">Grade</Typography>
                        <Box>
                          <CustomAccTextField
                            name="grade"
                            value={values.grade}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            placeholder="grade"

                          />
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          Location
                        </Typography>
                        <Box >
                          <CustomAccTextField
                            name="school_location"
                            fullWidth={true}
                            variant="outlined"
                            value={values.school_location}
                            onChange={handleChange}
                            placeholder="location"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="account-creation-sections">
                  <div className="account-content">
                    <Grid container style={{ alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                      <Grid item sm={12}>
                        <h4>Certification Info</h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} className="new_job_input_wrap">
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">Name*</Typography>
                        <Box >
                          <CustomAccTextField
                            name="certificate_name"
                            fullWidth={true}
                            placeholder="certificate name"
                            variant="outlined"
                            value={values.certificate_name}
                            onChange={handleChange}

                          />
                        </Box>
                        {errors.certificate_name && touched.certificate_name && <span className="err_msg_account_creation">{errors.certificate_name}</span>}
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          Issuing Organization*
                        </Typography>
                        <Box >
                          <CustomAccTextField
                            name="issuing_organization"
                            placeholder="issuing organization"
                            fullWidth={true}
                            variant="outlined"
                            value={values.issuing_organization}
                            onChange={handleChange}
                          />
                        </Box>
                        {errors.issuing_organization && touched.issuing_organization && <span className="err_msg_account_creation">{errors.issuing_organization}</span>}
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">
                          Issue Date
                        </Typography>
                        <Box>
                          <CustomDatePicker
                            name="issue_date"
                            value={values.issue_date}
                            maxDate={new Date().toString()}
                            onChange={val => {
                              setFieldValue(
                                "issue_date",
                                moment(val)
                              );
                              this.setState({ issueDate: val.getDate() + " " + month[val.getMonth()] })
                            }}
                            views={["date", "month"]}
                            data-test-id="certificates_start_date_test_id"
                          />
                        </Box>
                      </Grid>

                      <Grid item md={6} xs={12} style={{ marginBottom: "25px" }}>
                        <Typography className="text-field">Year</Typography>
                        <Box >
                          <CustomDatePicker
                            name="issue_year"
                            value={values.issue_year}
                            maxDate={new Date().toString()}
                            onChange={value => {
                              setFieldValue(
                                "issue_year",
                                moment(value).format("YYYY")
                              );
                              this.setState({ issueYear: value.getFullYear() })
                            }}
                            views={["year"]}
                            data-test-id="certificates_start_year_test_id"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="save-button save_account_creation">

                  <Button type="submit" variant="contained" color="primary" data-test-id="account_creation_fun_createAccount">
                    Save
                  </Button>

                </div>
                <div style={{ margin: '0px 50px 50px 50px' }}>
                  <Collapse in={this.state.CollapseOpen_account_creation}>
                    <Alert
                      variant="filled"
                      severity={this.state.account_creation_res_message === 'success' ? 'success' : "error"}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          data-test-id="test_CollapseOpenClose_account_creation_fun"
                          onClick={this.CollapseOpenClose_account_creation_fun}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.response_message_account_creation}
                    </Alert>
                  </Collapse>
                </div>

              </>
            </form>
          )}
        </Formik>
      </>
      // Customizable Area End
    )
  }
// Customizable Area Start
handleVideoImage(backgroundImage:any){
return typeof backgroundImage === 'string'&& backgroundImage.includes("mp4")?<VideoPlayer2 videoSource={backgroundImage} bgImageController={this.bgImageController}/>: <img
src={backgroundImage}
className="acccount-default"
alt="background-image"
onClick={this.bgImageController}
/>
}
account_creation_function(){
  const {
    backgroundImage,
    profileImage,
    userType,
    degreeData,
    successMsg
  } = this.state;
  return(
    <>
      <ThemeProvider theme={theme}>
          {successMsg ? this.renderSuccessDialog() : ""}
          <div className="account-container new-job-bnr">

            <div className="account-profile-images" style={{zIndex:333}}>
              {backgroundImage ? (
                this.handleVideoImage(backgroundImage)
              ) : <img
                src={Shape}
                className="acccount-default"
                alt="background-image"
                onClick={this.bgImageController}
              />}
              <img
                src={AccCircle}
                className="account-circle"
                alt="background-image"
              />
              <label>
                <input
                  type="file"
                  ref={this.bgRef}
                  onChange={this.onImageChange}
                  data-test-id="backgroundImage"
                />
              </label>
            </div>

            <div className="profile_image_photo" style={{ display: 'none !important' }}>
              <Container>
                <Grid container >

                  <Grid>
                    <img src={this.state.backgroundImageData ? this.state.backgroundImageData : AccDefault} alt="cover-photo" className="profile_cover_img" />
                  </Grid>
                </Grid>
                <Grid container className="profile_bio_row">
                  <Grid item md={3} className="profile_avatar_img_col">
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp" className="mbl_d_none" src={AccCircle} />
                      }
                      className="profie_edit_icon"
                    >
                      <Avatar alt="Profile Photo" src={profileImage ? profileImage : AccDefault} className="profile_photo_user" />
                    </Badge>
                  </Grid>
                  <Grid>
                    <h1 className="user_profile_name">{this.state.userName ? this.state.userName : "No Data"}</h1>
                  </Grid>

                </Grid>
              </Container>
            </div>


            <div className="account-profile">
              <div className="account-creation-content">
                <h1>Account Creation</h1>
                <div className="button-label account-later-btn">
                  <label style={{ width: '110px', }}>
                    <div className="camera-icon">
                      {!profileImage ? (
                        <>
                          <img
                            src={AccWhiteCircle}
                            className="acc-profile-image"
                            alt="background-image"
                          />
                          <img
                            src={AccCamera}
                            className="account-camera"
                            alt="background-image"
                          />
                        </>
                      ) :
                        <img
                          src={this.state.profileImage}
                          className="acc-profile-image"
                        />
                      }
                    </div>
                    <input
                      type="file"
                      onChange={this.onProfileImageChange}
                      data-test-id="profileImage"
                    />
                  </label>
                  {userType === "company" && <button
                    className='maybe-later-btn'
                    onClick={this.navigator}
                  >
                    Maybe Later
                  </button>}
                </div>

                <div className="account-creation-user-name">
                  <input type="text" style={{
                    border: '0.5px solid #536fc2',
                    width: '100%',
                    height: '30px',
                    padding: '7px',
                    fontSize: "13px"
                  }}
                    className="account_craetion_username_input"
                    value={this.state.usename_account_creation}
                    onChange={this.handle_usename_account_creation}
                    placeholder={this.state.userType === "company" ? "Enter company name" : "Enter user name"}
                  />
                </div>
                <div className="account-creation-tagline">
                  <input
                    className="accountcreation_tagline_input"
                    value={this.state.tagline}
                    onChange={this.taglineHandler}
                    placeholder="Enter tagline"
                    type="text"
                  />
                </div>
              </div>
            </div>


            <img
              src={AccShapes}
              className="account-shapes new-job-bnr-img1"
              alt="background-image"
            />
          </div>



          {userType === 'company' && this.state.companyData .company_size!==""?
            <Formik
              data-test-id="company_form"
              initialValues={this.state.companyData}
              validationSchema={this.companyValidationSchema}
              onSubmit={values => {
                // istanbul ignore next
                this.accountCreation(values)
              }}
            >{({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue
            }) => (<form autoComplete="off" onSubmit={handleSubmit}>
              {this.companyAcc(values, errors, handleChange, setFieldValue, touched)}
            </form>
            )}
            </Formik>
            :

            this.executive_account_create()
          }
          <FooterWeb />
        </ThemeProvider>
    </>
  )
}
// Customizable Area End

  render() {
    const {
      backgroundImage,
      profileImage,
      userType,
      degreeData,
      successMsg
    } = this.state;


    return (
      // Customizable Area Start
      <>
      {this.state.userType !== null && this.state.userType !== "" ? (
        <>
        <NavigationMenunew navigation={undefined} id={""} />
        {this.account_creation_function()}
        </>
        ) : (
          <div id="loader" style={{ textAlign: 'center', margin: '150px 0px' }}>
            <CircularProgress />
          </div>
        )}
      </>
      // Customizable Area End
    );
  }
}
