import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { loadStripe } from '@stripe/stripe-js';

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subscription_id: number;
  secretKey:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StripeIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ScriptionPlansAPI: string = "";
  getKeyId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subscription_id: 0,
      secretKey:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.ScriptionPlansAPI) {
        console.log(responseJson);
        this.setState({ subscription_id: responseJson?.subscription_id })
      }
     else  if(this.getKeyId===apiRequestCallId){
        this.setState({secretKey:loadStripe(responseJson?.key)})
        
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    this.getKeyStripeFn()
  }

 getKeyStripeFn=()=>{
  const token = localStorage.getItem("authToken") as string;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getKeyId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_stripe_integration/stripe_payments/get_key"
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}


  // Customizable Area End
}
