import React, { useState } from "react";

// Customizable Area Start
// import "../../../components/src/user_profile_block.css";
// import "../../email-account-registration/src/signup.css"
import {
   Typography,
   Box,
   Button,
   Grid,
   Collapse,
   IconButton,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, InstapaperShareButton } from 'react-share'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import "./share.css"
import { Cross, facebook, insta, linked, twitter,placeholder_logo } from "../../blocks/JobListing2/src/assets";
export const SharePopUp = (props: any) => {
   const [isCollapseOpen_share_popup, setIsCollapseOpen_share_popup] = useState(false);
   const { data: { attributes: { job_title, company: { company_name, profile_image }, location } }, id, closeModal } = props;
   const currentURL = window.location.href;
   const path = currentURL.split('/').slice(0, 3).join('/');
   const baseUrl = path + "/JobDetails";
   let url = baseUrl + "/" + id;


   const handleCopyLink = () => {
      setIsCollapseOpen_share_popup(true);
   };

   const closeSuccessMessage = () => {
      setIsCollapseOpen_share_popup(false);
   };

   React.useEffect(() => {
      if (isCollapseOpen_share_popup) {
         const timer = setTimeout(() => {
            closeSuccessMessage();
         }, 2000);
         return () => {
            clearTimeout(timer);
         };
      }
   }, [isCollapseOpen_share_popup]);

   return <>
      <Box>
         <Box className="cross_icon_share_popup" style={{ textAlign: 'right', position: 'absolute', right: '30px', top: '10px' }}>
            <img src={Cross} onClick={closeModal} className="left_arrow" /></Box>
         <Box className="img_box">
            <img src={profile_image === null ? placeholder_logo : profile_image} className="card-logo"></img>

            {/* <img src={profile_image} alt="" /> */}
            <Typography className="">{job_title}</Typography>
            <span>{company_name + "." + location}</span>
         </Box>
         <Box className="content_box">
            <Typography className="sharefields">Share on</Typography>
            <Box className="share_box">
               <ul>
                  <li>
                     <Typography className="">Facebook</Typography>
                     <FacebookShareButton url={`${url}`}>
                        <img src={facebook} alt="" />
                     </FacebookShareButton>
                  </li>
                  <li>
                     <Typography className="">Twitter</Typography>
                     <TwitterShareButton url={`${url}`}>
                        <img src={twitter} alt="" />
                     </TwitterShareButton>
                  </li>
                  <li>
                     <Typography className="">Linkedin</Typography>
                     <LinkedinShareButton url={url}>
                        <img src={linked} alt="" />
                     </LinkedinShareButton>
                  </li>
               </ul>
            </Box>

            <Typography className="or">or</Typography>
            <Box className="input_box">
               <input value={url} type="text" />
               <CopyToClipboard text={url} onCopy={handleCopyLink}>
                  <Button className="copy_link_btn">Copy Link</Button>
               </CopyToClipboard>
               <Grid item sm={12} className="message_show_share_popup">
                  <div style={{ marginTop: '15px' }}>
                     <Collapse in={isCollapseOpen_share_popup}>
                        <Alert
                           action={
                              <IconButton
                                 aria-label="close"
                                 color="inherit"
                                 size="small"
                                 data-test-id="txtInputMessagePassword"
                                 onClick={closeSuccessMessage}
                              >
                                 <CloseIcon fontSize="inherit" className="succes_msg_cross_btn" />
                              </IconButton>
                           }
                           variant="filled"
                           severity="success"

                        >
                           Link copied to clipboard
                        </Alert>
                     </Collapse>
                  </div>
               </Grid>
            </Box>
         </Box>
      </Box>
   </>
}